import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    Input,
    OnInit,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { ReversePipe } from 'ngx-pipes';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Control, ControlPosition } from 'leaflet';

import { Legend } from 'src/app/models/Layer';
import { Indicator } from 'src/app/models/Indicator';

import { DataService } from 'src/app/services/DataService';
import { EventService } from 'src/app/services/event.service';
import { LegendService } from 'src/app/services/legend.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MapService } from 'src/app/services/map.service';
import { PlotIndicatorService } from 'src/app/services/plotIndicator/plot-indicator.service';
import { UsefulService } from 'src/app/services/UsefulService';

import { LegendModalComponent } from './modals/legend-modal.component';

@UntilDestroy()
@Component({
    selector: 'map-legend',
    templateUrl: './map-legend.template.html',
    styleUrls: ['./map-legend.component.scss'],
    providers: [ReversePipe],
})
export class MapLegendComponent implements AfterViewInit, OnInit, OnDestroy {
    private destroy$ = new Subject<void>();

    @ViewChild('legendPanel', { static: true }) legendPanelRef: ElementRef;

    public legendControl: Control;
    @Input() position: ControlPosition = 'bottomleft';
    public isLegendCollapsed = false;
    public showMobileUi = false;

    public indicatorsPlot = [];
    public indicatorsData = [];
    public legend = null;
    public indicatorPlot = null;
    public isAnyIndicatorPlotted = false;
    public isParametersAvailable = false;
    public isFiltersAvailable = false;

    public customLegend = null;

    constructor(
        private modalService: NgbModal,
        @Inject(DataService) private dataService: DataService,
        @Inject(EventService) private eventService: EventService,
        @Inject(LegendService) private legendService: LegendService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(MapService) private mapService: MapService,
        @Inject(PlotIndicatorService) private plotIndicatorService: PlotIndicatorService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    ngOnInit(): void {
        const preferences = this.localStorageService.get('preferences');
        const modulesAccess = preferences.modules;

        this.isParametersAvailable = modulesAccess.indicatorParameters;
        this.isFiltersAvailable = modulesAccess.filters;

        this.showMobileUi = this.usefulService.showMobileUi;
        this.isLegendCollapsed = this.showMobileUi;

        this.mapService.map$
            .pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe(async (map) => {
                if (map) {
                    this.legendService.initControl();
                    this.legendService.addControlToMap();
                }
            });

        this.eventService.indicatorPloted
            .pipe(untilDestroyed(this))
            .subscribe(() => this._newIndicatorPloted());

        this.eventService.indicatorUnploted.pipe(untilDestroyed(this)).subscribe(() => {
            this.indicatorsData = this.dataService.activeIndicatorsList;
            this.indicatorsPlot = Object.values(this.plotIndicatorService.plotedIndicators);
            this.isAnyIndicatorPlotted = this.indicatorsData.length > 0;
        });

        this.eventService.indicatorsPlotedFromScenario
            .pipe(untilDestroyed(this))
            .subscribe(() => this._newIndicatorPloted());
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private _newIndicatorPloted() {
        this.indicatorsData = this.dataService.activeIndicatorsList;
        this.indicatorsPlot = Object.values(this.plotIndicatorService.plotedIndicators);
        this.isAnyIndicatorPlotted = true;

        this.indicatorsPlot.forEach((indicator) => (indicator.isCollapsed = this.showMobileUi));
    }

    onMouseWheel(event: WheelEvent) {
        event.stopPropagation();
    }

    ngAfterViewInit() {
        const legendPanelElement = this.legendPanelRef.nativeElement;
        const map = this.mapService.map;

        legendPanelElement.addEventListener('mousedown', () => map.dragging.disable());
        legendPanelElement.addEventListener('mouseup', () => map.dragging.enable());

        legendPanelElement.addEventListener('mouseover', () => map.doubleClickZoom.disable());
        legendPanelElement.addEventListener('mouseout', () => map.doubleClickZoom.enable());

        map.addEventListener('mouseup', () => map.dragging.enable());
    }

    createDashArray(legend: any, indicatorPlot: any, width: number = 40) {
        let legendDashArray: any;
        if (legend) {
            legendDashArray = legend.dashArray;
        }
        const indicatorDashArray = indicatorPlot.dashArray;
        let dashArray = legendDashArray || indicatorDashArray;

        if (!dashArray) {
            return;
        }

        if (typeof dashArray == 'string' && !isNaN(parseInt(dashArray))) {
            dashArray = [dashArray];
        }

        if (dashArray.length === 1) return [width];

        const toReturn: Array<number> = [];
        const totDash = dashArray.reduce((t: number, a: number) => t + a, 0) * 3;

        dashArray.forEach((elem: number) => {
            for (let i = 0; i < 3; i++) {
                toReturn.push(Math.round((elem / totDash) * width));
            }
        });

        return toReturn;
    }

    round(value: number) {
        return Math.round(value).toString();
    }

    openLegendModal(indicatorPlot: any, legend: Legend = null) {
        if (!legend) {
            this.customLegend = {
                color: indicatorPlot.unavailableColor,
                isLegendNull: true,
            };
        } else {
            this.legend = legend;
            this.customLegend = { ...legend };
        }
        this.indicatorPlot = indicatorPlot;

        const modalRef = this.modalService.open(LegendModalComponent);
        modalRef.componentInstance.indicatorPlot = this.indicatorPlot;
        modalRef.componentInstance.legend = this.legend;
        modalRef.componentInstance.customLegend = this.customLegend;
        modalRef.result.then(
            (result) => this.validateLegend(),
            () => {},
        );
    }

    validateLegend() {
        if (this.customLegend.isLegendNull) {
            this.indicatorPlot.unavailableColor = this.customLegend.color;
        } else {
            this.legend.color = this.customLegend.color;
            this.legend.default_radius_weight = this.customLegend.default_radius_weight;
            this.legend.default_fillopacity = this.customLegend.default_fillopacity;
            this.legend.default_icon = this.customLegend.default_icon;
            this.legend.dashArray = this.customLegend.dashArray;

            const type = this.indicatorPlot.type;
            const form = this.indicatorPlot.form;
            const legendId = this.legend.id_class;

            if (!this.legend.lib) {
                this.indicatorPlot.unavailableColor = this.customLegend.color;
            } else {
                const index = this.legend.id;
                this.indicatorPlot.tableCouleurs[index] = this.customLegend.color;

                if (type == 'class') {
                    const classLabel = this.indicatorPlot.customClassParameters.find(
                        (c: any) => c.id_class == legendId,
                    );
                    // const customClassParameters = JSON.parse(JSON.stringify(classLabel));
                    classLabel.default_color = this.customLegend.color;
                    classLabel.default_fillopacity = this.customLegend.default_fillopacity;

                    if (!['poly', 'chart'].includes(form)) {
                        classLabel.default_radius_weight = this.customLegend.default_radius_weight;
                    }

                    if (form == 'line') {
                        classLabel.dashArray = this.customLegend.dashArray;
                        // $scope.indicatorPlot.dashArray = this.customLegend.dashArray;
                    }

                    if (form == 'icon') {
                        classLabel.default_icon = this.customLegend.default_icon;
                    }
                }
            }
        }

        this.plotIndicatorService.refreshLayer(this.indicatorPlot);
    }

    isToolbarAvailable(indicator: Indicator) {
        const isCustomTerritory = indicator.isCustomTerritory;
        const isGranularityAvailable = !isCustomTerritory && indicator.granularity;
        const isFilterable = this.isFiltersAvailable && indicator.static_dynamic == 'dyn';
        const isAvailable = isGranularityAvailable || this.isParametersAvailable || isFilterable;
        return isAvailable;
    }
}
