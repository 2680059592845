'use strict';

import { Component, Inject, Input } from '@angular/core';

import { RestService } from 'src/app/services/RestService';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'resetPasswordModal',
    templateUrl: './reset-password.template.html',
})
export class ResetPasswordModal {
    @Input() userId: number;

    public password: string = null;
    public confirmedPassword: string = null;

    constructor(
        public activeModal: NgbActiveModal,
        @Inject(RestService) private restService: RestService,
    ) {}

    async validate() {
        const data = { generalInfo: { password: this.password } };
        await this.restService.updateUser(this.userId, data);

        this.activeModal.close();
    }
}
