<stHeader [isAdminPage]="true"></stHeader>
<stNavbar></stNavbar>

<div class="ligneFlexStart windowAdmin">
  <div class="flexComponentOne bordureExtContenair"></div>

  <div class="colFlexStartWrap" style="max-width: 1400px; width: 100%" *ngIf="filter">
    <div>
      <!--<small>Administation > Gestion des utilisateurs > consulter / modifier utilisateur </small>-->
    </div>
    <div class="adm_main_title ligneFlexStartCentered">
      <div style="padding-right: 15px; color: rgb(33,68,111);">
        <span class="fa fa-users fa-2x"></span>
      </div>
      <div>Critères : consulter / modifier </div>
    </div>

    <div class="ligneFlexStart">
      <div style="margin-top: 7px; padding-right: 15px; width: 200px">
        <span><b>Nom de la famille :</b></span>
      </div>
      <div style="margin-top: 7px; padding-left: 13px; min-width: 178px; margin-bottom: 22px;">
        <span [hidden]="filter.modify">{{ filter.label }} </span>
        <stInput type="text" style="max-width:150px" [hidden]="!filter.modify" [(ngModel)]="filter.label"></stInput>
      </div>
      <div style="margin-top: 7px; padding-left: 10px; ">
        <i class="fa fa-pencil cursor-pointer" ngbTooltip="modifier" [hidden]="filter.modify"
          (click)="filter.modify=true"></i>
      </div>
    </div>

    <div class="ligneFlexStart">
      <div style=" padding-right: 15px; width: 200px">
        <span><b>Table de données :</b></span>
      </div>
      <div style="padding-left: 13px; min-width: 178px; margin-bottom: 22px;">
        <span [hidden]="filter.modify">{{ filter.tbl }}</span>
        <stInput type="text" style="max-width:150px" [hidden]="!filter.modify" [(ngModel)]="filter.tbl">
        </stInput>
      </div>

      <div style="padding-left: 10px;">
        <i class="fa fa-pencil cursor-pointer" ngbTooltip="modifier" [hidden]="filter.modify"
          (click)="filter.modify=true"></i>
      </div>
    </div>

    <div class="ligneFlexStart">
      <stButton btnLabel="Enregistrer" btnClass="btn-st-primary" [hidden]="!filter.modify" (click)="saveFilter()">
      </stButton>
    </div>

    <div class="colFlexStretch" style="width:100%; flex-wrap: wrap">
      <div *ngFor="let category of filter.categories ">

        <div style="flex:1; min-width: 450px; border-top: 1px solid #ddd ; margin-top: 20px"></div>

        <div (click)='category.collapse = !category.collapse' style="padding: 0px 20px 30px 20px; cursor: pointer">
          <h4 style="color:#0067C6">
            <span [hidden]='category.collapse' class="fa fa-angle-down cursor-pointer" style="padding:5px"
              ngbTooltip="moins" tooltip-append-to-body="true"></span>
            <span [hidden]='!category.collapse' class="fa fa-angle-right cursor-pointer" style="padding:5px"
              ngbTooltip="plus" tooltip-append-to-body="true"></span>
            <b>{{ category.label }} :</b>
          </h4>
        </div>

        <div [hidden]="category.collapse" style="padding: 0px 20px 10px 40px">
          <div class="ligneFlexStart">
            <div style="margin-top: 7px; padding-right: 15px; width: 200px">
              <span><b>Colonne en base :</b></span>
            </div>
            <div style="margin-top: 7px; padding-left: 13px; min-width: 178px; margin-bottom: 22px;">
              <span [hidden]="category.modify">{{category.champ_associe}}</span>
              <stInput type="text" style="max-width:250px" [hidden]="!category.modify"
                [(ngModel)]="category.champ_associe"></stInput>
            </div>
            <div style="margin-top: 7px; padding-left: 13px; ">
              <i class="fa fa-pencil cursor-pointer" ngbTooltip="modifier" (click)="category.modify=true"
                [hidden]="category.modify"></i>
            </div>
          </div>

          <div class="ligneFlexStart">
            <div style="padding-right: 15px; width: 200px">
              <span><b>Libellé du critere :</b></span>
            </div>
            <div style="padding-left: 13px; min-width: 178px; margin-bottom: 22px;">
              <span [hidden]="category.modify">{{ category.label }}</span>
              <stInput type="text" style="max-width:250px" [hidden]="!category.modify" [(ngModel)]="category.label">
              </stInput>
            </div>
            <div style="padding-left: 13px;">
              <i class="fa fa-pencil cursor-pointer" ngbTooltip="modifier" (click)="category.modify=true"
                [hidden]="category.modify"></i>
            </div>
          </div>

          <stButton btnLabel="Supprimer le critère" btnClass="btn-danger" (click)="deleteCategory(category)"></stButton>
          <stButton btnLabel="Enregistrer le critère" btnClass="btn-st-primary" (click)="saveCategory(category)"
            [hidden]="!category.modify"></stButton>
        </div>

        <div [hidden]="category.collapse" style="padding: 0px 20px 10px 40px">
          <div class="ligneFlexStart">
            <div style="padding-right: 25px; width: 200px">
              <h4><b>Déterminants</b></h4>
            </div>
          </div>

          <div class="ligneFlexStart">
            <div style="padding-right: 15px; width: 250px">
              <span><b>Libellé</b></span>
            </div>

            <div style="padding-left: 13px; min-width: 178px; margin-bottom: 22px;">
              Identifiant
            </div>
            <div style="padding-left: 13px; min-width: 178px; margin-bottom: 22px;">
              Ordre
            </div>
          </div>

          <div class="ligneFlexStart" *ngFor="let criteria of category.criteria | orderByImpure : 'order'">
            <div style="margin-top: 7px; padding-right: 15px; width: 250px">
              <span [hidden]="criteria.modify">{{ criteria.lib_dter }}</span>
              <stInput type="text" style="max-width:200px" [hidden]="!criteria.modify" [(ngModel)]="criteria.label">
              </stInput>
            </div>
            <div style="margin-top: 7px; padding-left: 13px; min-width: 178px; margin-bottom: 22px;">
              <span [hidden]="criteria.modify">{{ criteria.id }}</span>
              <stInput type="number" style="max-width:80px" [hidden]="!criteria.modify" [(ngModel)]="criteria.id">
              </stInput>
            </div>
            <div style="margin-top: 7px; padding-left: 13px; min-width: 178px; margin-bottom: 22px;">
              <span [hidden]="criteria.modify">{{ criteria.order }}</span>
              <stInput type="number" style="max-width:80px" [hidden]="!criteria.modify" [(ngModel)]="criteria.order">
              </stInput>
            </div>
            <div style="margin-top: 7px; padding-left: 10px;">
              <i class="fa fa-pencil cursor-pointer" ngbTooltip="modifier" [hidden]="criteria.modify"
                (click)="criteria.modify=true"></i>
              <i class="fa fa-2x fa-floppy-o cursor-pointer" ngbTooltip="Enregistrer" [hidden]="!criteria.modify"
                (click)="saveCriteria(criteria)"></i>
              <i class="fa fa-2x fa-trash-o cursor-pointer" ngbTooltip="Supprimer" [hidden]="!criteria.modify"
                (click)="deleteCriteria(criteria)"></i>
            </div>
          </div>

          <stButton btnLabel="Ajouter un déterminant" btnClass="btn-st-primary"
            (click)="addCriteria(category.id_critere)">
          </stButton>
        </div>
      </div>

      <div style="flex:1; min-width: 450px; border-top: 1px solid #ddd ; margin-bottom: 20px"></div>
      <stButton btnLabel="Ajouter un critère" btnClass="btn-st-primary" (click)="addCategory()"></stButton>

      <div style="flex:1; min-width: 450px; border-top: 1px solid #ddd ; margin-top: 20px">
        <div style="padding: 20px 20px 15px 20px">
          <h4 style="color:#0067C6"><b>Retourner à la page de gestion des critères</b></h4>
        </div>
        <div style="padding: 0px 20px 10px 40px">
          <div class="ligneFlexStart" style="width:100%; padding-top : 20px; padding-bottom : 20px;  flex-wrap: wrap">
            <stButton btnLabel="Retour" btnClass="btn-st-default" (click)="goToAdminFilter()">
            </stButton>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flexComponentOne bordureExtContenair"></div>
</div>