import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'sydesl-tutorial',
    templateUrl: './sydesl-tutorial.template.html',
    styleUrls: ['./sydesl-tutorial.component.scss'],
})
export class SydeslTutorialModalComponent implements OnInit {
    @Input() cadastre: string;
    customClass: string;

    constructor(public activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        this.customClass = `modal-xl tutorial ${this.cadastre}`;
    }
}
