<stHeader [isAdminPage]="true"></stHeader>
<stNavbar></stNavbar>

<div id="group-update" class="container-fluid py-4">
  <h3 class="text-st-secondary my-5">
    <span class="fa-stack fa-lg text-st-secondary">
      <i class="fa fa-circle-thin fa-stack-2x"></i>
      <i class="fa fa-users fa-stack-1x"></i>
    </span>
    Groupe - Consulter / Modifier
  </h3>

  <hr class="separator">

  <div id="general-info" class="container-fluid">
    <h4 class="d-inline-block mt-0">
      <b>Informations générales</b>
    </h4>
    <ng-template #tooltipContent>
      <div>Modifier</div>
    </ng-template>
    <span class="cursor-pointer ms-5" *ngIf="!generalInfo.isEditing" (click)="generalInfo.isEditing = true"
      [ngbTooltip]="tooltipContent">
      <i class="fa fa-pencil"></i>
    </span>
    <div class="container-fluid">
      <form name="_nomFG" novalidate>
        <div class="row mb-3" [ngClass]="{'d-flex align-items-center': !generalInfo.isEditing}">
          <label for="group-name" class="col-3 control-label" [ngClass]="{'py-0': !generalInfo.isEditing}">
            Nom du groupe<SUP>*</SUP> :
          </label>
          <div class="col-3" *ngIf="!generalInfo.isEditing">
            {{ group?.group.nom }}
          </div>
          <div class="col-3" *ngIf="generalInfo.isEditing">
            <stInput type="text" innerId="group-name" name="_nom" [(ngModel)]="groupTmp.nom"
              [isDisabled]="generalInfo.isSaving" required>
            </stInput>
            <i class="invalid-feedback" *ngIf="!groupTmp?.nom">
              Le nom du groupe est requis.
            </i>
          </div>
        </div>

        <div class="row mb-3 d-flex align-items-center display-name">
          <label for="display-name" class="col-3 control-label py-0">
            Nom à afficher dans le bandeau :
          </label>
          <div class="col-3" *ngIf="!generalInfo.isEditing">
            {{ group?.group.name_site }}
          </div>
          <div class="col-3" *ngIf="generalInfo.isEditing">
            <stInput type="text" innerId="display-name" name="_name_site" [(ngModel)]="groupTmp.name_site"
              [isDisabled]="generalInfo.isSaving"></stInput>
          </div>
        </div>
        <div class="row mb-3 end-validity-date" [ngClass]="{'d-flex align-items-center': !generalInfo.isEditing}">
          <label class="col-3 control-label" [ngClass]="{'py-0': !generalInfo.isEditing}">
            Date de fin de validité des droits<SUP>*</SUP> :
          </label>
          <div class="col-3" *ngIf="!generalInfo.isEditing">
            {{ groupTmp?.endingValidityDate | date:"dd/MM/yyyy" }}
          </div>
          <div class="col-3" *ngIf="generalInfo.isEditing">
            <stCalendar name="_nom_calendar" [(date)]="groupTmp.formatedDate" [isDisabled]="generalInfo.isSaving">
            </stCalendar>
            <i class="invalid-feedback" *ngIf="!groupTmp.formatedDate">
              La date de fin de validité est requise.
            </i>
          </div>
          <div class="col-5">
            <i>
              À partir de cette date, tous les comptes du groupe seront désactivés, même si leurs
              dates de création sont postérieures à celle-ci.
            </i>
          </div>
        </div>

        <div class="row mb-3 d-flex align-items-center logo">
          <label for="logo" class="col-3 control-label py-0">
            Logo :
          </label>
          <div class="col-3" *ngIf="generalInfo.isEditing">
            <input type="file" id="logo" name="myImage" (change)="getImagebase64($event)"
              [disabled]="generalInfo.isSaving" />
          </div>
          <div class="col-6 group-logo">
            <img [src]="groupTmp.logo" alt="group-logo" *ngIf="!!groupTmp?.logo">
          </div>
        </div>
      </form>
      <div class="d-flex align-items-center mt-5" *ngIf="generalInfo.isEditing">
        <stButton btnLabel="Annuler" btnClass="btn-st-default me-3" (click)="cancelGeneralInfo()"
          [isDisabled]="generalInfo.isSaving">
        </stButton>
        <stButton btnLabel="Enregistrer les modifications" btnClass="btn-st-primary ms-3"
          (click)="validateGeneralInfo()"
          [isDisabled]="_nomFG?._nom.$error.required || generalInfo.isSaving || !groupTmp?.nom || !groupTmp?.formatedDate">
        </stButton>
        <span class="fa fa-2x fa-spinner fa-spin text-st-active ms-5" *ngIf="generalInfo.isSaving"></span>
      </div>
    </div>
  </div>

  <hr class="separator">

  <div id="territory-rights" class="container-fluid">
    <h4 class="d-inline-block mt-0">
      <b>Droits sur les territoires</b>
    </h4>
    <span class="cursor-pointer ms-5" *ngIf="!territoryInfo.isEditing" (click)="territoryInfo.isEditing = true"
      [ngbTooltip]="tooltipContent">
      <i class="fa fa-pencil"></i>
    </span>

    <div class="container-fluid">
      <div id="geographical-boundaries">
        <h5>
          <b>Périmètre géographique</b>
        </h5>

        <form novalidate>
          <div class="row mb-3 d-flex align-items-center">
            <label class="col-3 control-label py-0">
              Echelle de territoire<sup>*</sup> :
            </label>
            <div class="col-3" *ngIf="!territoryInfo.isEditing">
              {{ selectedTerritoryScale?.libelle_short }}
            </div>
            <div class="col-3" *ngIf="territoryInfo.isEditing">
              <stSelect [data]="terService.territoryScales" order-by="ordre" labelField="libelle_short"
                valueField="fullObject" [(ngModel)]="selectedTerritoryScaleTmp" (change)="selectTerritoryScale()"
                [isDisabled]="territoryInfo.isSaving" [ngModelOptions]="{standalone: true}" type="primary"></stSelect>
            </div>
          </div>
          <div class="row mb-3" [ngClass]="{'d-flex align-items-center': !territoryInfo.isEditing}">
            <label for="territory" class="col-3 control-label" [ngClass]="{'py-0': !territoryInfo.isEditing}">
              <span *ngIf="selectedTerritories.length == 1">
                Territoire<sup>*</sup> :
              </span>
              <span *ngIf="selectedTerritories.length > 1">
                Territoires<sup>*</sup> :
              </span>
            </label>
            <div class="col-3" *ngIf="!territoryInfo.isEditing">
              {{ territoryLabel }}
            </div>
            <div class="col-3" *ngIf="territoryInfo.isEditing">
              <stInputSearch class="dropdown btn-block" innerId="territory" innerClass="border-07" label="labelId"
                placeholder="Saisir le nom ou code INSEE" [options]="territories" (onSelect)="addTerritory($event)"
                *ngIf="territories.length > 0" [isDisabled]="!selectedTerritoryScale.id || territoryInfo.isSaving">
              </stInputSearch>
              <i class="invalid-feedback" *ngIf="!selectedTerritoriesTmp.length">
                Au moins un territoire est requis.
              </i>
            </div>
          </div>
          <div class="selected-territory" *ngIf="territoryInfo.isEditing">
            <div class="btn-group my-3 me-3" *ngFor="let territory of selectedTerritoriesTmp; let idx = index">
              <stButton btnLabel="{{ territory.label }}" btnClass="btn-st-default d-flex align-items-center"
                iconFaRight="fa fa-times ps-2" [isDisabled]=territoryInfo.isSaving (click)="removeTerritory(idx)">
              </stButton>
            </div>
          </div>
        </form>
      </div>

      <div id="granularity">
        <h5>
          <b>Découpage géographique</b>
        </h5>

        <form novalidate>
          <div class="row mb-3 d-flex align-items-center">
            <label class="col-3 control-label py-0">
              Granularité<sup>*</sup> :
            </label>
            <div class="col-3" *ngIf="!territoryInfo.isEditing">
              {{ selectedGranularity?.libelle_short }}
            </div>
            <div class="col-3" *ngIf="territoryInfo.isEditing">
              <stSelect [data]="granularityOptions" order-by="ordre" labelField="libelle_short" valueField="fullObject"
                [(ngModel)]="selectedGranularityTmp" [isDisabled]="territoryInfo.isSaving"
                [ngModelOptions]="{standalone: true}" type="primary"></stSelect>
            </div>
          </div>
        </form>
      </div>

      <div class="d-flex align-items-center mt-5" *ngIf="territoryInfo.isEditing">
        <stButton btnLabel="Annuler" btnClass="btn-st-default me-3" (click)="cancelTerritory()"
          [isDisabled]="territoryInfo.isSaving">
        </stButton>
        <stButton btnLabel="Enregistrer les modifications" btnClass="btn-st-primary ms-3" (click)="validateTerritory()"
          [isDisabled]="territoryInfo.isSaving || !selectedTerritoriesTmp.length">
        </stButton>
        <span class="fa fa-2x fa-spinner fa-spin text-st-active ms-5" *ngIf="territoryInfo.isSaving"></span>
      </div>
    </div>
  </div>

  <hr class="separator">

  <div id="indicator-rights" class="container-fluid">
    <h4 class="d-inline-block mt-0">
      <b>Droits sur les indicateurs</b>
    </h4>
    <span class="cursor-pointer ms-5" *ngIf="!indicatorRight.isEditing" (click)="indicatorRight.isEditing = true"
      [ngbTooltip]="tooltipContent">
      <i class="fa fa-pencil"></i>
    </span>

    <div class="container-fluid">
      <div class="container-fluid mb-3" *ngFor="let theme of indicatorsByTheme | orderBy : 'libelle'">
        <span class="cursor-pointer" (click)="theme.open = !theme.open">
          <i class="fa fa-caret-down iw-4" *ngIf="theme.open"></i>
          <i class="fa fa-caret-right iw-4" *ngIf="!theme.open"></i>
          <span class="text-st-secondary fs-6">
            <b>{{ theme.libelle }}</b>
          </span>
        </span>

        <span class="mx-4" [ngClass]="{'cursor-pointer': indicatorRight.isEditing}"
          (click)="selectAllIndicatorFromTheme(theme)">
          <span *ngIf="theme.full == 2" class="fa fa-star"></span>
          <span *ngIf="theme.full == 1" class="fa fa-star-half-o"></span>
          <span *ngIf="theme.full == 0" class="fa fa-star-o"></span>
        </span>

        <ng-container *ngFor="let ss_theme of theme.ss_theme | orderBy: 'ordre'">
          <div class="container-fluid mb-2" *ngIf="theme.open">
            <span class="cursor-pointer" (click)="ss_theme.open = !ss_theme.open">
              <i class="fa fa-caret-down iw-4" *ngIf="ss_theme.open"></i>
              <i class="fa fa-caret-right iw-4" *ngIf="!ss_theme.open"></i>
              <span>
                <b>{{ ss_theme.libelle }}</b>
              </span>
            </span>

            <span class="mx-4" [ngClass]="{'cursor-pointer': indicatorRight.isEditing}"
              (click)="selectAllIndicatorFromSubTheme(ss_theme)">
              <span *ngIf="ss_theme.full == 2" class="fa fa-star"></span>
              <span *ngIf="ss_theme.full == 1" class="fa fa-star-half-o"></span>
              <span *ngIf="ss_theme.full == 0" class="fa fa-star-o"></span>
            </span>

            <div class="container-fluid px-5" *ngIf="ss_theme.open">
              <ng-container *ngFor="let indicator of ss_theme.indicators | sortBy: 'ordre'">
                <stCheckbox innerId="indicator-{{ indicator.id_indicateur }}" [(ngModel)]="indicator.active"
                  [isDisabled]="!indicatorRight.isEditing">
                  {{ indicator.libelle_indic_short }}
                  <span *ngIf="indicator.unit && indicator.unit !== '%'">
                    - {{ indicator.unit }}
                  </span>
                </stCheckbox>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="d-flex align-items-center mt-5" *ngIf="indicatorRight.isEditing">
        <stButton btnLabel="Annuler" btnClass="btn-st-default me-3" (click)="cancelIndicator()"
          [isDisabled]="indicatorRight.isSaving">
        </stButton>
        <stButton btnLabel="Enregistrer les modifications" btnClass="btn-st-primary ms-3" (click)="validateIndicator()"
          [isDisabled]="indicatorRight.isSaving"></stButton>
        <span class="fa fa-2x fa-spinner fa-spin text-st-active ms-5" *ngIf="indicatorRight.isSaving"></span>
      </div>
      <div class="mt-5" *ngIf="!indicatorRight.isEditing">
        <stButton btnLabel="Affecter à tous les utilisateurs" btnClass="btn-st-primary me-3"
          (click)="assignToAllUsers()"></stButton>
      </div>
    </div>
  </div>

  <hr class="separator">

  <div class="container-fluid">
    <h4 class="mt-0">
      <b>Retourner à la page de gestion des groupes</b>
    </h4>
    <stButton btnLabel="Retour" btnClass="btn-st-default" (click)="redirectToAdminGroups()">
    </stButton>
  </div>
</div>