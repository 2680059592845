import { Component, EventEmitter, Input, Output } from '@angular/core';

('use strict');

@Component({
    selector: 'stCalendar',
    template: `<input
        type="date"
        name="{{ name }}"
        class="form-control"
        id="{{ innerId }}"
        [(ngModel)]="date"
        [disabled]="isDisabled"
        (ngModelChange)="onChange($event)"
    />`,
})
export class StCalendarComponent {
    @Input() name: string;
    @Input() innerId: string;
    @Input() isDisabled: boolean;
    @Input() date: string;
    @Output() dateChange = new EventEmitter();

    constructor() {}

    onChange(date: string) {
        this.dateChange.emit(date);
    }
}
