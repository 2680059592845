import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CadastreSolaireInfoModalComponent } from '../../../../../../modals/cadastre-solaire-info-modal.component';

import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SolarPvSurplusService } from 'src/app/services/cadastre-solaire/cadastre-solaire-pv-surplus.service';
import { SolarService } from 'src/app/services/cadastre-solaire/cadastre-solaire.service';
import { UsefulService } from 'src/app/services/UsefulService';

@Component({
    selector: 'cadastreSolairePvSurplusEquipments',
    templateUrl: './cadastre-solaire-pv-surplus-equipments.template.html',
    styleUrls: ['./cadastre-solaire-pv-surplus-equipments.component.scss'],
})
export class CadastreSolairePvSurplusEquipmentsComponent implements OnInit {
    public showMobileUi: boolean = false;

    public equipmentHeaderPv: string;
    public modularity: any;
    public typologyOptions = [
        {
            value: 'housing',
            label: 'Résidentiel',
        },
        {
            value: 'tertiary',
            label: 'Tertiaire',
        },
    ];

    public housingFormGroup: FormGroup;
    public equipments: { label: string; value: string; active: boolean; message: string }[];
    public isHousingFormValid: boolean = false;

    constructor(
        private readonly formBuilder: FormBuilder,
        private modalService: NgbModal,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(SolarPvSurplusService)
        public solarPvSurplusService: SolarPvSurplusService,
        @Inject(SolarService) public solarService: SolarService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    ngOnInit(): void {
        this.showMobileUi = this.usefulService.showMobileUi;
        this.modularity = this.localStorageService.get('preferences').modularity.solar;

        this.equipmentHeaderPv = this.solarPvSurplusService.pvInfo.message.equipement_header;

        if (this.solarPvSurplusService.equipmentsData.usableFloorArea == null) {
            this.solarPvSurplusService.equipmentsData.usableFloorArea =
                this.solarPvSurplusService.setDefaultUsableFloorArea();
            this.solarPvSurplusService.equipmentsData.occupantCount =
                this.solarPvSurplusService.setDefaultOccupantCount();
        }

        const defaultOccupantCount = this.solarPvSurplusService.equipmentsData.occupantCount;
        const defaultUsableFloorArea = this.solarPvSurplusService.equipmentsData.usableFloorArea;

        this.housingFormGroup = this.formBuilder.group({
            occupantCount: [defaultOccupantCount, [Validators.required, Validators.min(1)]],
            usableFloorArea: [defaultUsableFloorArea, [Validators.required, Validators.min(1)]],
        });

        this.equipments = JSON.parse(
            JSON.stringify(this.solarPvSurplusService.equipmentsData.uses),
        );

        this.checkHousingFormValidity();
        this.housingFormGroup.valueChanges.subscribe((values) => this.checkHousingFormValidity());
    }

    public cancel() {
        this.solarService.updatePvStage('pvValorizationStage');
    }

    public goToStage(stage: string) {
        this.solarPvSurplusService.updateStage(stage);
    }

    public validate() {
        const occupantCountCtrl = this.housingFormGroup.get('occupantCount');
        const isDifferentOccupantCount =
            Number(occupantCountCtrl.value) !=
            this.solarPvSurplusService.equipmentsData.occupantCount;

        const usableFloorAreaCtrl = this.housingFormGroup.get('usableFloorArea');
        const isDifferentUsableFloorArea =
            Number(usableFloorAreaCtrl.value) !=
            this.solarPvSurplusService.equipmentsData.usableFloorArea;

        const newEquipments = this.equipments.filter((equipment) => equipment.active).sort();
        const previousEquipments = this.solarPvSurplusService.equipmentsData.uses
            .filter((equipment) => equipment.active)
            .sort();
        const isDifferentEquipment =
            JSON.stringify(newEquipments) !== JSON.stringify(previousEquipments);

        if (isDifferentOccupantCount || isDifferentUsableFloorArea || isDifferentEquipment) {
            this.solarPvSurplusService.equipmentsData.occupantCount = Number(
                occupantCountCtrl.value,
            );
            this.solarPvSurplusService.equipmentsData.usableFloorArea = Number(
                usableFloorAreaCtrl.value,
            );
            this.solarPvSurplusService.equipmentsData.uses = this.equipments;
        }

        this.solarPvSurplusService.initImportedElectricLoad();
        this.solarPvSurplusService.updateStage('consumptionStage');
    }

    public checkHousingFormValidity() {
        this.isHousingFormValid = this.housingFormGroup.status === 'VALID';
    }

    public openInfoModal(body: string, title: string) {
        if (title == 'Production') {
            body +=
                "Le taux d'autoconsommation représente la part de l'électricité produite par les panneaux solaires qui sera directement consommée au sein du logement.";
        }

        this.modalService.dismissAll();
        const modalRef = this.modalService.open(CadastreSolaireInfoModalComponent, { size: 'xs' });
        modalRef.componentInstance.body = body;
        modalRef.componentInstance.title = title;
    }
}
