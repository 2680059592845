import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { EventService } from './event.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    public themeObs = new BehaviorSubject<any>(null);
    themeObs$: Observable<any[]> = this.themeObs.asObservable();

    public themeId: string;
    public theme: any;

    private themesInfo = [
        {
            id: 'light',
            info: {
                color: {
                    '--base00': 'rgba(255, 255, 255, 1)',
                    '--base01': 'rgba(255, 255, 255, 1)',
                    '--base02': 'rgba(255, 255, 255, 1)',
                    '--base02-light': 'rgba(239, 239, 239, 0.85)',
                    '--base03': 'rgba(20, 2, 0, 1)',
                    '--base04': 'rgba(74, 74, 76, 1)',
                    '--base05': 'rgba(255, 255, 255, 1)',
                    '--base06': 'rgba(85, 85, 85, 1)',
                    '--base07': 'rgba(53, 75, 146, 1)',
                    '--base08': 'rgba(85, 85, 85, 1)',
                    '--base09': 'rgba(248, 249, 250, 1)',
                    '--base10': '#rgba(252, 252, 253, 1)',
                    '--base11': '#f0f0f0',
                    '--base12': 'rgba(51, 51, 51, 1)',
                    '--base13': '#ffffff',
                    '--base14': '#e9ecef',
                    '--primary': 'rgba(53, 75, 146, 1)',
                    '--secondary': 'rgba(53, 75, 146, 1)',
                    '--default': 'rgba(225, 225, 225, 1)',
                    '--default-active': 'rgba(200, 200, 200, 1)',
                    '--info': 'rgba(26, 32, 81, 1)',
                    '--active': 'rgba(251, 133, 118, 1)',
                    '--muted': 'rgba(128, 128, 128, 1)',
                },
                size: {
                    '--html-font-size': '14px',
                    '--body-font-size': '14px',
                },
                font: { '--font': 'Gibson, sans-serif' },
                img: {
                    siterreLogo: 'siterre_logo_blue.svg',
                },
            },
        },
        {
            id: 'dark',
            info: {
                color: {
                    '--base00': 'rgba(0, 0, 0, 1)',
                    '--base01': 'rgba(20, 20, 20, 1)',
                    '--base02': 'rgba(29, 29, 27, 1)',
                    '--base02-light': 'rgba(51, 51, 51, 0.85)',
                    '--base03': 'rgba(248, 249, 250, 1)',
                    '--base04': 'rgba(248, 249, 250, 1)',
                    '--base05': 'rgba(51, 51, 51, 1)',
                    '--base06': 'rgba(85, 85, 85, 1)',
                    '--base07': 'rgba(248, 249, 250, 1)',
                    '--base08': 'rgba(248, 249, 250, 1)',
                    '--base09': 'rgba(33, 37, 41, 1)',
                    '--base10': 'rgba(33, 37, 41, 1)',
                    '--base11': 'rgba(54, 54, 51, 1)',
                    '--base12': 'rgba(51, 51, 51, 1)',
                    '--base13': '#323232',
                    '--base14': '#5f5f5a',
                    '--primary': 'rgba(46, 117, 255, 1)',
                    '--secondary': 'rgba(95, 95, 90, 1)',
                    '--default': 'rgba(50, 50, 50, 1)',
                    '--default-active': 'rgba(100, 100, 100, 1)',
                    '--info': 'rgba(95, 95, 90, 1)',
                    '--active': 'rgba(239, 190, 44, 1)',
                    '--muted': 'rgba(128, 128, 128, 1)',
                },
                size: {
                    '--html-font-size': '14px',
                    '--body-font-size': '14px',
                },
                font: { '--font': 'Gibson, sans-serif' },
                img: {
                    siterreLogo: 'siterre_logo_white.svg',
                },
            },
        },
        {
            id: 'cadastre_bdr',
            info: {
                color: {
                    '--base00': 'rgba(51, 51, 51, 1)',
                    '--base01': 'rgba(36, 36, 36, 1)',
                    '--base02': 'rgba(29, 29, 27, 1)',
                    '--base02-light': 'rgba(29, 29, 27, 0.85)',
                    '--base03': 'rgba(248, 249, 250, 1)',
                    '--base04': 'rgba(248, 249, 250, 1)',
                    '--base05': 'rgba(51, 51, 51, 1)',
                    '--base06': 'rgba(85, 85, 85, 1)',
                    '--base07': 'rgba(248, 249, 250, 1)',
                    '--base08': 'rgba(248, 249, 250, 1)',
                    '--base09': 'rgba(33, 37, 41, 1)',
                    '--base10': 'rgba(33, 37, 41, 1)',
                    '--base11': 'rgba(33, 37, 41, 1)',
                    '--base12': 'rgba(51, 51, 51, 1)',
                    '--base13': '#e9ecef',
                    '--base14': '#e9ecef',
                    '--primary': 'rgba(46, 117, 255, 1)',
                    '--secondary': 'rgba(95, 95, 90, 1)',
                    '--default': 'rgba(38, 42, 46, 1)',
                    '--default-active': 'rgba(66, 70, 73, 1)',
                    '--info': 'rgba(95, 95, 90, 1)',
                    '--active': 'rgba(255, 205, 0, 1)',
                    '--muted': 'rgba(128, 128, 128, 1)',
                },
                size: {
                    '--html-font-size': '14px',
                    '--body-font-size': '14px',
                },
                font: { '--font': 'Overpass, sans-serif' },
                img: {},
            },
        },
        {
            id: 'cartocopro',
            info: {
                color: {
                    '--base00': 'rgba(255, 255, 255, 1)',
                    '--base01': 'rgba(255, 255, 255, 1)',
                    '--base02': 'rgba(255, 255, 255, 1)',
                    '--base02-light': 'rgba(239, 239, 239, 0.85)',
                    '--base03': 'rgba(20, 2, 0, 1)',
                    '--base04': 'rgba(74, 74, 76, 1)',
                    '--base05': 'rgba(255, 255, 255, 1)',
                    '--base06': 'rgba(85, 85, 85, 1)',
                    '--base07': '#005681',
                    '--base08': 'rgba(85, 85, 85, 1)',
                    '--base09': 'rgba(248, 249, 250, 1)',
                    '--base10': '#fcfcfd',
                    '--base11': '#f0f0f0',
                    '--base12': 'rgba(51, 51, 51, 1)',
                    '--primary': '#005681',
                    '--secondary': '#005681',
                    '--default': 'rgba(225, 225, 225, 1)',
                    '--default-active': 'rgba(200, 200, 200, 1)',
                    '--info': 'rgba(26, 32, 81, 1)',
                    '--active': '#A2D0A2',
                    '--muted': 'rgba(128, 128, 128, 1)',
                },
                size: {
                    '--html-font-size': '13px',
                    '--body-font-size': '13px',
                },
                font: { '--font': 'Poppins, sans-serif' },
                img: { siterreLogo: 'carto_copro.png' },
            },
        },
    ];
    profileName = environment.name;

    constructor(
        @Inject(EventService) private eventService: EventService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
    ) {
        this.get();
        this.save();
        this.apply();
    }

    clear() {
        this.themeObs.next(null);
    }

    updateTheme(themeId: string) {
        const availableThemes = this.themesInfo.map((themeInfo) => themeInfo.id);
        if (!availableThemes.includes(themeId)) {
            throw new Error(`Theme '${themeId}' is not available.`);
        }
        this.themeId = themeId;
    }

    get() {
        let savedThemeId: string;
        if (this.profileName == 'siterre') {
            savedThemeId = this.localStorageService.get('themeId');
        }

        let defaultThemeId: string = 'light';
        const preferences = this.localStorageService.get('preferences');
        if (preferences) {
            defaultThemeId = preferences.init.themeId || defaultThemeId;
        }

        const themeId = savedThemeId ? savedThemeId : defaultThemeId;
        this.updateTheme(themeId);
    }

    save() {
        if (this.profileName == 'siterre') {
            localStorage.themeId = this.themeId;
        }
    }

    apply() {
        this.theme = this.themesInfo.find((t) => t.id == this.themeId);
        this.setProperty('color');
        this.setProperty('size');
        this.setProperty('font');

        this.setImg();
        this.themeObs.next(this.theme);
        document.documentElement.setAttribute('data-st-theme', this.theme.id);
    }

    setProperty(property: string) {
        const htmlStyle = document.body.parentElement.style;
        for (let variable in this.theme.info[property]) {
            const value = this.theme.info[property][variable];
            htmlStyle.setProperty(variable, value);
        }
    }

    setImg() {
        this.eventService.emit('themeUpdated');
    }
}
