<ngb-accordion
    #acc="ngbAccordion"
    [id]="innerId"
    [activeIds]="actualActiveIds"
    [attr.data-st-theme]="theme"
>
    <ng-container *ngFor="let item of items | orderByImpure : 'order'; let index = index">
        <ngb-panel id="{{ innerId }}-{{ index }}" [cardClass]="cardClass">
            <ng-template ngbPanelTitle> {{ item.label }} </ng-template>

            <ng-template ngbPanelContent>
                <ng-container
                    [ngTemplateOutlet]="contentTemplate"
                    [ngTemplateOutletContext]="{ $implicit: item }"
                ></ng-container>
            </ng-template>
        </ngb-panel>
    </ng-container>
</ngb-accordion>
