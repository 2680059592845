<ng-template #rt let-r="result" let-t="term">
    <ng-container *ngIf="r.source == 'address' && !r.number">
        <div class="text-center is-disabled">
            <i>Adresses</i>
        </div>
    </ng-container>
    <ng-container *ngIf="r.source == 'address' && r.number">
        <div class="d-flex justify-content-between">
            <div>
                <ngb-highlight [result]="r.label" [term]="t"></ngb-highlight>
            </div>
            <div class="ms-3">{{ r.type }}</div>
        </div>
    </ng-container>

    <ng-container *ngIf="r.source == 'parcel' && !r.number">
        <div>
            <hr class="mt-0 mb-2" *ngIf="areAddressAndParcelFound" />
            <div class="text-center is-disabled">
                <i>Parcelles</i>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="r.source == 'parcel' && r.number">
        <div>
            <ngb-highlight [result]="r.label" [term]="t"></ngb-highlight>
        </div>
    </ng-container>

    <ng-container *ngIf="r.source == 'territory' && !r.number">
        <div>
            <hr class="mt-0 mb-2" *ngIf="areAddressOrParcelAndTerritoryFound" />
            <div class="text-center is-disabled">
                <i>Territoires</i>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="r.source == 'territory' && r.number">
        <div class="d-flex justify-content-between">
            <div>
                <ngb-highlight [result]="r.label" [term]="t"></ngb-highlight>
            </div>
            <div class="ms-3">{{ r.type }}</div>
        </div>
    </ng-container>

    <ng-container *ngIf="r.isLast">
        <div class="is-disabled last" [ngClass]="{'no-results': !isAnyResultFound}">
            <small>Continuer à taper pour affiner la recherche</small>
            <div class="dot-pulse me-4"></div>
        </div>
    </ng-container>
</ng-template>

<div
    id="search-bar"
    class="input-group"
    [ngClass]="{'active': isSearchBarOpen, 'w-initial': !isSearchBarOpen || !isSearchBarAvailable}"
    [hidden]="!isAvailable"
>
    <a
        href="javascript:void(0)"
        class="input-group-text d-flex align-items-center justify-content-center"
        [title]="placeholder"
        role="button"
        (click)="toggleSearchBar()"
        *ngIf="isSearchBarAvailable"
    >
        <i class="fa fa-search" aria-hidden="true"></i>
    </a>
    <ng-container *ngIf="isSearchBarAvailable">
        <input
            id="search-input"
            type="text"
            class="form-control"
            [ngClass]="{disabled: isDisabled}"
            [placeholder]="placeholder"
            [ngbTypeahead]="search"
            [focusFirst]="false"
            [placement]="'bottom-end'"
            popupClass="search-bar-options"
            [resultTemplate]="rt"
            [inputFormatter]="formatter"
            [disabled]="isDisabled"
            (selectItem)="onOptionSelected($event.item)"
            (wheel)="onMouseWheel($event)"
            #instance="ngbTypeahead"
            [hidden]="!isSearchBarOpen"
        />
    </ng-container>
    <a
        href="javascript:void(0)"
        class="input-group-text d-flex align-items-center justify-content-center"
        id="geolocalize"
        title="Géolocalisation"
        (click)="geolocalizeMe()"
        [hidden]="!isSearchBarOpen"
        *ngIf="isSearchGeolocationAvailable"
    >
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
            <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
            <path
                d="M256 0c17.7 0 32 14.3 32 32V66.7C368.4 80.1 431.9 143.6 445.3 224H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H445.3C431.9 368.4 368.4 431.9 288 445.3V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.3C143.6 431.9 80.1 368.4 66.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H66.7C80.1 143.6 143.6 80.1 224 66.7V32c0-17.7 14.3-32 32-32zM128 256a128 128 0 1 0 256 0 128 128 0 1 0 -256 0zm128-80a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"
            />
        </svg>
        <!-- <i class="fa fa-crosshairs" aria-hidden="true"></i> -->
    </a>
</div>
