'use strict';

import { Component, Inject } from '@angular/core';
import { ReversePipe } from 'ngx-pipes';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { FileSaverService } from 'ngx-filesaver';

import { DataService } from 'src/app/services/DataService';
import { DownloadService } from 'src/app/services/DownloadService';
import { ModuleService } from 'src/app/services/module.service';

@Component({
    selector: 'download',
    templateUrl: './download.template.html',
    styleUrls: ['./download.component.css'],
    providers: [ReversePipe],
})
export class DownloadComponent {
    isDownloading = false;

    constructor(
        private fileSaverService: FileSaverService,
        @Inject(DataService) private dataService: DataService,
        @Inject(DownloadService) private downloadService: DownloadService,
        @Inject(ModuleService) public moduleService: ModuleService,
    ) {}

    getActiveIndicatorsList = () => {
        return this.dataService.activeIndicatorsList;
    };

    downloadShapefile = async (indicator: any) => {
        try {
            this.isDownloading = true;
            const blob = await this.downloadService.shapefile(indicator);
            const indicatorLabel = this.downloadService.cleanLabel(indicator.libelle_indic_complet);
            this.fileSaverService.save(blob, indicatorLabel + '.zip');
        } catch (error) {
            console.error('Error downloadShapefile', error);
        } finally {
            this.isDownloading = false;
        }
    };

    downloadCsv = (indicator: any) => {
        let options = {
            title: 'export',
            fieldSeparator: ';',
            decimalseparator: ',',
            headers: this.downloadService.setCsvHeader(indicator),
        };
        const indicatorLabel = this.downloadService.cleanLabel(indicator.libelle_indic_complet);
        return new ngxCsv(this.downloadService.setCsvBody(indicator), indicatorLabel, options);
    };
}
