<stTab
    class="indicatorParameters"
    label="Paramétrage"
    [visible]="moduleService.isModuleActive.indicatorParameters"
>
    <div class="d-flex flex-column h-100">
        <div ngbDropdown class="m-4" *ngIf="isActiveIndicatorsAvailable() && indicatorData">
            <button ngbDropdownToggle type="button" class="btn btn-st-default w-100 text-wrap">
                {{ indicatorData.libelle_indic_complet }}
            </button>
            <div ngbDropdownMenu class="w-100 text-center">
                <ng-container *ngFor="let indicator of indicatorsData">
                    <button
                        ngbDropdownItem
                        *ngIf="indicator != null"
                        (click)="changeSelectedIndicator(indicator.id_indicateur);"
                    >
                        <small> {{ indicator.libelle_indic_complet }} </small>
                    </button>
                </ng-container>
            </div>
        </div>

        <div
            class="container-fluid mx-0 legend-form-border flex-grow-1"
            *ngIf="isActiveIndicatorsAvailable() && indicatorPlot"
        >
            <ng-container *ngIf="modularity.legend">
                <div
                    class="value-legend-parameters"
                    *ngIf="indicatorPlot.type == 'valeur' && indicatorPlot.distribution && hasMultipleDataToPlot()"
                >
                    <h4>Légende</h4>

                    <div class="container-fluid">
                        <div class="row d-flex align-items-center mb-3 legend-class-count">
                            <label for="legend-class-count" class="col-5 control-label py-0">
                                Nombre de classes
                            </label>
                            <div class="col-7">
                                <stSelect
                                    innerId="legend-class-count"
                                    [(ngModel)]="indicatorPlot.classCount"
                                    [data]="indicatorPlot.classCountsAvailable"
                                    (change)="refreshLayer()"
                                    labelField="fullObject"
                                    valueField="fullObject"
                                    type="primary"
                                >
                                </stSelect>
                            </div>
                        </div>

                        <div class="row d-flex align-items-center mb-3 legend-method">
                            <label for="legend-method" class="col-5 control-label py-0">
                                Méthode
                            </label>
                            <div class="col-7">
                                <stSelect
                                    innerId="legend-method"
                                    [(ngModel)]="indicatorPlot.distribution.method"
                                    [data]="distributionValueService.methodsInfo"
                                    (change)="refreshLayer()"
                                    labelField="label"
                                    valueField="fullObject"
                                    type="primary"
                                >
                                </stSelect>
                            </div>
                        </div>

                        <ng-container *ngIf="indicatorPlot.distribution.method.id == 'manuel'">
                            <div
                                class="row d-flex align-items-center mb-3 legend-boundary"
                                *ngFor="let n of getRange(indicatorPlot.classCount + 1)"
                            >
                                <label
                                    for="legend-boundary-{{ n }}"
                                    class="col-5 control-label py-0"
                                >
                                    Borne {{ n + 1 }}
                                </label>
                                <div class="col-7">
                                    <stInput
                                        *ngIf="n == 0"
                                        type="number"
                                        innerId="legend-boundary-{{ n }}"
                                        class="primary"
                                        [(ngModel)]="indicatorPlot.legendBoundaries[n][0]"
                                        [isDisabled]="true"
                                    >
                                    </stInput>
                                    <stInput
                                        *ngIf="n > 0 && n < indicatorPlot.classCount"
                                        type="number"
                                        innerId="legend-boundary-{{ n }}"
                                        class="primary"
                                        [(ngModel)]="indicatorPlot.legendBoundaries[n][0]"
                                        (ngBlur)="refreshLayer()"
                                    >
                                    </stInput>
                                    <stInput
                                        *ngIf="n == indicatorPlot.classCount"
                                        type="number"
                                        innerId="legend-boundary-{{ n }}"
                                        [(ngModel)]="indicatorPlot.legendBoundaries[n - 1][1]"
                                        class="primary"
                                        [isDisabled]="true"
                                    >
                                    </stInput>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <hr class="split my-4" />
                </div>

                <div
                    class="class-legend-parameters"
                    *ngIf="indicatorPlot.type == 'class' && indicatorPlot.classParameters"
                >
                    <h4>Légende</h4>

                    <div class="container-fluid">
                        <div class="row d-flex align-items-center mb-3 max-class-count">
                            <label for="max-class-count" class="col-5 control-label py-0">
                                Nombre de classes
                            </label>
                            <div class="col-7">
                                <stInput
                                    type="number"
                                    innerId="max-class-count"
                                    class="primary"
                                    [min]="0"
                                    [(ngModel)]="manageMaxClassCount.value"
                                    (ngModelChange)="setClassIndicatorAggregatedValue()"
                                >
                                </stInput>
                            </div>
                        </div>

                        <div class="row d-flex align-items-center mb-3 sort-legend-by">
                            <label for="sort-legend-by" class="col-5 control-label py-0">
                                Trier les valeurs par
                            </label>
                            <div class="col-7">
                                <stSelect
                                    innerId="sort-legend-by"
                                    [(ngModel)]="indicatorPlot.classParameters.sort_legend_by"
                                    [data]="manageSortLegendBy.options"
                                    (change)="setClassIndicatorAggregatedValue()"
                                    labelField="label"
                                    valueField="value"
                                    type="primary"
                                >
                                </stSelect>
                            </div>
                        </div>
                    </div>

                    <hr class="split my-4" />
                </div>
            </ng-container>

            <ng-container *ngIf="modularity.shape">
                <div class="form-parameters">
                    <h4>Forme</h4>

                    <div class="container-fluid">
                        <ng-container *ngIf="indicatorPlot.vector_base == 'territories'">
                            <div class="row d-flex align-items-center mb-3 indicator-shape">
                                <label for="indicator-shape" class="col-5 control-label py-0">
                                    Représentation
                                </label>
                                <div class="col-7">
                                    <stSelect
                                        innerId="indicator-shape"
                                        [(ngModel)]="manageShape.value"
                                        [data]="manageShape.options[indicatorPlot.form]"
                                        (change)="updateShape()"
                                        labelField="label"
                                        valueField="value"
                                        type="primary"
                                    >
                                    </stSelect>
                                </div>
                            </div>
                        </ng-container>

                        <div class="color-panel mb-4 form-color-tint">
                            <ul
                                class="nav nav-pills nav-fill w-100 d-flex justify-content-between switch-color-tint p-1"
                            >
                                <li
                                    class="me-1 w-25 text-center shadow-sm nav-item"
                                    role="presentation"
                                    *ngFor="let info of gradientColorInfo[indicatorPlot.indicatorId]"
                                >
                                    <a
                                        class="nav-link nav-blue py-2 {{ info.classHeader }}"
                                        id="tab-{{ info.name }}"
                                        data-bs-toggle="tab"
                                        href="#{{ info.name }}-tint"
                                        target="_self"
                                    >
                                        {{ info.label }}
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content">
                                <div
                                    class="tab-pane {{ info.classPane }}"
                                    id="{{ info.name }}-tint"
                                    *ngFor="let info of gradientColorInfo[indicatorPlot.indicatorId]"
                                >
                                    <div
                                        class="barreCouleur"
                                        *ngFor="let colorsInfo of info.colorsInfo"
                                        (click)="updateColorGradient(colorsInfo.name)"
                                        [title]="colorsInfo.name"
                                        [ngStyle]="{'opacity': indicatorPlot.indicatorfillopacity }"
                                    >
                                        <div
                                            *ngFor="let color of colorsInfo.colors"
                                            [ngStyle]="{'background-color':  color}"
                                            class="cubeStrech"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="container-fluid">
                            <div
                                class="row d-flex align-items-center mb-3 shape-thickness"
                                *ngIf="!indicatorPlot.form.includes('poly')"
                            >
                                <label for="shape-thickness" class="col-5 control-label py-0">
                                    Epaisseur
                                </label>
                                <div class="col-7">
                                    <stRange
                                        *ngIf="indicatorPlot"
                                        innerId="shape-thickness"
                                        [min]="0.1"
                                        [max]="4"
                                        [step]="0.1"
                                        [value]="indicatorPlot.default_radius_weight"
                                        [(ngModel)]="indicatorPlot.default_radius_weight"
                                        (ngModelChange)="refreshLayer(false)"
                                    >
                                    </stRange>
                                </div>
                            </div>

                            <div class="row d-flex align-items-center mb-3 form-opacity">
                                <label for="form-opacity" class="col-5 control-label py-0">
                                    Opacité
                                </label>
                                <div class="col-7">
                                    <stRange
                                        *ngIf="indicatorPlot"
                                        innerId="form-opacity"
                                        class="d-flex align-items-center"
                                        [min]="0"
                                        [max]="1"
                                        [step]="0.1"
                                        [value]="indicatorPlot.indicatorfillopacity"
                                        [(ngModel)]="indicatorPlot.indicatorfillopacity"
                                        (ngModelChange)="refreshLayer(false)"
                                    >
                                    </stRange>
                                </div>
                            </div>

                            <div
                                class="row d-flex align-items-center mb-3 border-pattern"
                                *ngIf="indicatorPlot.form === 'line'"
                            >
                                <label for="border-pattern" class="col-5 control-label py-0">
                                    Motif
                                </label>
                                <div class="col-7">
                                    <div id="border-pattern">
                                        <div
                                            class="cursor-pointer pattern-line"
                                            *ngFor="let option of indicatorPlot.dashArray_list"
                                            [ngClass]="{'selected': isPatternSelected(option)}"
                                            (click)="updatePattern(option)"
                                        >
                                            <div *ngIf="option.length > 1">
                                                <span
                                                    class="d-inline-block discreet-line"
                                                    *ngFor="let width of duplicateDashArray(option); let idx = index"
                                                    [ngStyle]="{
                            'width': width + 'px',
                            'opacity': idx % 2 === 0 ? 1 : 0
                          }"
                                                >
                                                </span>
                                            </div>
                                            <span
                                                class="d-inline-block continuous-line"
                                                *ngIf="option.length === 1"
                                            >
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="row d-flex align-items-center mb-3 border-symbole"
                                *ngIf="indicatorPlot.form === 'icon' && indicatorPlot.type == 'valeur'"
                            >
                                <label for="border-symbole" class="col-5 control-label py-0">
                                    <span> Symbole </span>
                                    <a
                                        href="https://fontawesome.com/v4.7.0/icons/"
                                        target="_blank"
                                        ngbTooltip="Cliquez ici pour voir la liste complète"
                                        placement="top"
                                        container="body"
                                    >
                                        <i class="fa fa-info-circle"></i>
                                    </a>
                                </label>
                                <div class="col-6">
                                    <div id="border-symbole">
                                        <ng-template #rt let-r="result" let-t="term">
                                            <i class="fa fa-{{ r }} me-2"></i>
                                            <ngb-highlight [result]="r" [term]="t"></ngb-highlight>
                                        </ng-template>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Saisir le code du symbole"
                                            [(ngModel)]="indicatorPlot.default_icon"
                                            [ngbTypeahead]="search"
                                            [resultTemplate]="rt"
                                            [inputFormatter]="formatter"
                                        />
                                    </div>
                                </div>
                                <div class="col-1">
                                    <i class="fa fa-{{ indicatorPlot.default_icon }} fa-2x"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr
                    class="split my-4"
                    *ngIf="modularity.border && !['line', 'migration', 'chart', 'heatmap', 'poly3D'].includes(indicatorPlot.form)"
                />
            </ng-container>

            <ng-container *ngIf="modularity.border">
                <div
                    class="border-parameters"
                    *ngIf="!['line', 'migration', 'chart', 'heatmap', 'poly3D'].includes(indicatorPlot.form)"
                >
                    <h4>Contour</h4>

                    <div class="container-fluid">
                        <div class="row d-flex align-items-center mb-3 border-color">
                            <label for="border-color" class="col-5 control-label py-0">
                                Couleur
                            </label>
                            <div class="col-7">
                                <stColor
                                    innerId="border-color"
                                    (change)="refreshLayer(false)"
                                    [(ngModel)]="indicatorPlot.indicatorcontourcolor"
                                >
                                </stColor>
                            </div>
                        </div>

                        <div class="row d-flex align-items-center mb-3 border-thickness">
                            <label for="border-thickness" class="col-5 control-label py-0">
                                Epaisseur
                            </label>
                            <div class="col-7">
                                <stRange
                                    *ngIf="indicatorPlot"
                                    innerId="border-thickness"
                                    class="d-flex align-items-center"
                                    [min]="0"
                                    [max]="2"
                                    [step]="0.1"
                                    [value]="indicatorPlot.indicatorcontourweight"
                                    [(ngModel)]="indicatorPlot.indicatorcontourweight"
                                    (ngModelChange)="refreshLayer(false)"
                                >
                                </stRange>
                            </div>
                        </div>

                        <div class="row d-flex align-items-center mb-3 border-opacity">
                            <label for="border-opacity" class="col-5 control-label py-0">
                                Opacité
                            </label>
                            <div class="col-7">
                                <stRange
                                    *ngIf="indicatorPlot"
                                    innerId="border-opacity"
                                    class="d-flex align-items-center"
                                    [min]="0"
                                    [max]="1"
                                    [step]="0.1"
                                    [value]="indicatorPlot.indicatorcontouropacity"
                                    [(ngModel)]="indicatorPlot.indicatorcontouropacity"
                                    (ngModelChange)="refreshLayer(false)"
                                >
                                </stRange>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="modularity.saving_management">
                <hr class="split mt-4" />
                <div class="container-fluid p-4 save-parameters">
                    <div class="row">
                        <div class="col-6">
                            <stButton
                                btnLabel="Valeurs par défaut"
                                btnClass="btn-st-default w-100"
                                (click)="setDefaultParameters('app')"
                                *ngIf="!showSetDefaultParametersModal"
                            >
                            </stButton>
                            <stButton
                                btnLabel="Valeurs par défaut"
                                btnClass="btn-st-default w-100"
                                (click)="openSetDefaultParametersModal()"
                                *ngIf="showSetDefaultParametersModal"
                            >
                            </stButton>
                        </div>
                        <div class="col-6">
                            <div id="map-parameters-save-dropdown" class="btn-group w-100">
                                <button
                                    type="button"
                                    class="btn btn-st-primary"
                                    (click)="openSaveParametersModal('user')"
                                >
                                    Enregistrer
                                </button>
                                <div
                                    class="btn-group"
                                    ngbDropdown
                                    role="group"
                                    *ngIf="[1, 2].includes(user.role)"
                                >
                                    <button
                                        type="button"
                                        class="btn btn-st-primary dropdown-toggle-split"
                                        ngbDropdownToggle
                                        (click)="scrollToBottom()"
                                    ></button>
                                    <div class="dropdown-menu" ngbDropdownMenu>
                                        <button
                                            ngbDropdownItem
                                            (click)="openSaveParametersModal('group')"
                                            *ngIf="[1, 2].includes(user.role)"
                                        >
                                            Pour le groupe
                                        </button>
                                        <button
                                            ngbDropdownItem
                                            (click)="openSaveParametersModal('app')"
                                            *ngIf="user.role == 1"
                                        >
                                            Pour tout le monde
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</stTab>
