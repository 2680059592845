<stHeader [isAdminPage]="true"></stHeader>
<stNavbar></stNavbar>

<div id="group-add" class="container-fluid py-4">
  <h3 class="text-st-secondary my-5">
    <span class="fa-stack fa-lg text-st-secondary">
      <i class="fa fa-circle-thin fa-stack-2x"></i>
      <i class="fa fa-users fa-stack-1x"></i>
    </span>
    Nouveau groupe
  </h3>

  <hr class="separator">

  <div id="general-info" class="container-fluid">
    <h4 class="mt-0">
      <b>Informations générales</b>
    </h4>

    <div class="container-fluid">
      <form name="_nomFG" novalidate>
        <div class="row mb-3">
          <label for="group-name" class="col-3 control-label">
            Nom du groupe<SUP>*</SUP> :
          </label>
          <div class="col-3">
            <stInput innerId="group-name" type="text" name="_nom" [(ngModel)]="group.name" required
              [ngModelOptions]="{standalone: true}"></stInput>
          </div>
        </div>
        <div class="row mb-3">
          <label for="name-to-display" class="col-3 control-label">
            Nom à afficher dans le bandeau :
          </label>
          <div class="col-3">
            <stInput innerId="name-to-display" type="text" name="_nom" [(ngModel)]="group.name_site"
              [ngModelOptions]="{standalone: true}"></stInput>
          </div>
        </div>
        <div class="row mb-3">
          <label for="validity-date" class="col-3 control-label">
            Date de fin de validité des droits :
          </label>
          <div class="col-3">
            <stCalendar innerId="validity-date" name="_nom" [(date)]="group.formatedDate"></stCalendar>
          </div>
          <div class="col-5">
            <i>
              A partir de cette date, tous les comptes du groupe seront désactivés, même si leurs
              dates sont supérieurs à celle-ci.
            </i>
          </div>
        </div>
        <div class="row mb-3 d-flex align-items-center logo">
          <label for="logo" class="col-3 control-label py-0">
            Logo :
          </label>
          <div class="col-3">
            <input id="logo" type="file" name="myImage" (change)="getImagebase64($event)" />
          </div>
          <div class="col-6 group-logo">
            <img [src]="group.logo" alt="group-logo" *ngIf="!!group.logo">
          </div>
        </div>
      </form>
    </div>

  </div>

  <hr class="separator">

  <div id="territory-rights" class="container-fluid">
    <h4 class="mt-0">
      <b>Droits sur les territoires</b>
    </h4>

    <div id="geographical-boundaries" class="container-fluid">
      <h5>
        <b>Périmètre géographique</b>
      </h5>

      <form novalidate>
        <div class="row mb-3 d-flex align-items-center">
          <label for="territory-scale" class="col-3 control-label py-0">
            Echelle de territoire<sup>*</sup> :
          </label>
          <div class="col-3">
            <div class="w-100">
              <stSelect [(ngModel)]="selectedTerritoryScale" [data]="territoryScales" order-by="id"
                (change)="selectTerritoryScale()" labelField="libelle_short" valueField="fullObject" type="primary"
                [ngModelOptions]="{standalone: true}">
              </stSelect>
            </div>
          </div>
        </div>
        <div class="row mb-3 d-flex align-items-center">
          <label for="territory" class="col-3 control-label py-0">
            Territoire<sup>*</sup> :
          </label>
          <div class="col-3">
            <stInputSearch class="dropdown w-100" innerId="territory-input" innerClass="border-07" label="labelId"
              placeholder="Saisir le nom ou code INSEE" [options]="territories" (onSelect)="addTerritory($event)"
              *ngIf="territories.length > 0" [isDisabled]="!selectedTerritoryScale.id">
            </stInputSearch>
          </div>
        </div>
        <div class="selected-territory">
          <div class="btn-group my-3 me-3" *ngFor="let territory of selectedTerritories; let idx = index">
            <stButton btnLabel="{{ territory.label }}" btnClass="btn-st-default d-flex align-items-center"
              iconFaRight="fa fa-times ps-2" (click)="removeTerritory(idx)"></stButton>
          </div>
        </div>
      </form>
    </div>

    <div id="granularity" class="container-fluid">
      <h5>
        <b>Découpage géographique</b>
      </h5>

      <form novalidate>
        <div class="row mb-3">
          <label for="scale" class="col-3 control-label">
            Granularité<sup>*</sup> :
          </label>
          <div class="col-3">
            <stSelect [data]="granularities" order-by="ordre" labelField="libelle_long" valueField="fullObject"
              [(ngModel)]="selectedGranularity" [ngModelOptions]="{standalone: true}" type="primary">
            </stSelect>

            <i class="invalid-feedback" *ngIf="!selectedGranularity.id">
              Vous devez choisir une granularité.
            </i>
          </div>
        </div>
      </form>
    </div>
  </div>

  <hr class="separator">

  <div id="indicator-rights" class="container-fluid">
    <h4 class="mt-0">
      <b>Droits sur les indicateurs</b>
    </h4>

    <div class="container-fluid mb-3" *ngFor="let theme of indicatorsByTheme | orderBy : 'libelle'">
      <span class="cursor-pointer" (click)="theme.open = !theme.open">
        <i class="fa fa-caret-down iw-4" *ngIf="theme.open"></i>
        <i class="fa fa-caret-right iw-4" *ngIf="!theme.open"></i>
        <span class="text-st-secondary fs-6">
          <b>{{ theme.libelle }}</b>
        </span>
      </span>

      <span class="cursor-pointer mx-4" (click)="selectAllIndicatorFromTheme(theme)">
        <span *ngIf="theme.full == 2" class="fa fa-star"></span>
        <span *ngIf="theme.full == 1" class="fa fa-star-half-o"></span>
        <span *ngIf="theme.full == 0" class="fa fa-star-o"></span>
      </span>

      <ng-container *ngFor="let ss_theme of theme.ss_theme | orderBy: 'ordre'">
        <div class="container-fluid mb-2" *ngIf="theme.open">
          <span class="cursor-pointer" (click)="ss_theme.open = !ss_theme.open">
            <i class="fa fa-caret-down iw-4" *ngIf="ss_theme.open"></i>
            <i class="fa fa-caret-right iw-4" *ngIf="!ss_theme.open"></i>
            <span>
              <b>{{ ss_theme.libelle }}</b>
            </span>
          </span>

          <span class="cursor-pointer mx-4" (click)="selectAllIndicatorFromSubTheme(ss_theme)">
            <span *ngIf="ss_theme.full == 2" class="fa fa-star"></span>
            <span *ngIf="ss_theme.full == 1" class="fa fa-star-half-o"></span>
            <span *ngIf="ss_theme.full == 0" class="fa fa-star-o"></span>
          </span>

          <div class="container-fluid px-5" *ngIf="ss_theme.open">
            <ng-container *ngFor="let indicator of ss_theme.indicators | sortBy: 'ordre'">
              <stCheckbox innerId="indicator-{{ indicator.id_indicateur }}" [(ngModel)]="indicator.active"
                (change)="manageIndicator(indicator)">
                {{ indicator.libelle_indic_short }}
                <span *ngIf="indicator.unit && indicator.unit !== '%'">
                  - {{ indicator.unit }}
                </span>
              </stCheckbox>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <hr class="separator">

  <div id="validate-add-group" class="container-fluid">
    <h4 class="mt-0">
      <b>Terminer la création du nouveau groupe</b>
    </h4>

    <stButton btnLabel="Annuler" btnClass="btn-st-default mx-3" (click)="redirectToAdminGroups()">
    </stButton>
    <stButton btnLabel="Valider" btnClass="btn-st-primary mx-3" (click)="validate()"
      [isDisabled]="_nomFG?._nom.error.required || !selectedGranularity.id || !selectedTerritories.length || !selectedTerritoryScale.id || !selectedIndicatorIds.length">
    </stButton>
  </div>
</div>