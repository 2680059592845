import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { User } from 'src/app/models/User';
import { RestService } from 'src/app/services/RestService';

@Injectable({ providedIn: 'root' })
export class UserResolver implements Resolve<User> {
    constructor(private restService: RestService) {}

    resolve(route: ActivatedRouteSnapshot): Promise<User> {
        const userId = parseInt(route.paramMap.get('userId'));
        return this.restService.getUser(userId);
    }
}
