import { Inject, Injectable } from '@angular/core';
import { Indicator } from 'src/app/models/Indicator';

import { ColorService } from 'src/app/services/ColorService';
import { UsefulService } from 'src/app/services/UsefulService';

@Injectable({
    providedIn: 'root',
})
export class DistributionClassService {
    constructor(
        @Inject(ColorService) private colorService: ColorService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    run(indicatorPlot: any, refreshIntervals?: boolean) {
        // const intervals = [];

        // for (let param in indicatorPlot.dataToPlot) {
        //     let data = indicatorPlot.dataToPlot[param];
        //     data = !this.isArray(data) ? [data] : data;

        //     data.filter(
        //         (d: any) => !intervals.includes(d.value) && typeof d.value === 'number',
        //     ).forEach((d: any) => intervals.push(d.value));
        // }

        // actually it looks like this code above does nothing interesting...
        return [];
    }

    isArray = (a: any) => {
        return !!a && a.constructor === Array;
    };

    setColors(indicatorPlot: Indicator, count?: number) {
        let degrade = indicatorPlot.newColorGradient
            ? indicatorPlot.newColorGradient
            : indicatorPlot.degrade;
        let colorTable = indicatorPlot.tableCouleurs ? indicatorPlot.tableCouleurs : [];

        if (!(degrade == 'random' && colorTable.length > 0)) {
            const colorCount = !count ? indicatorPlot.class_label.length : count;
            colorTable = this.colorService.getColorPalette(degrade, colorCount);
        }

        if (indicatorPlot.form === 'point_proportional') {
            colorTable = colorTable.map((c: any) => colorTable[0]);
        }

        indicatorPlot.tableCouleurs = colorTable;
        return colorTable;
    }

    setLegend(indicatorPlot: Indicator, refreshIntervals: boolean = true) {
        const uniqueClassIds = indicatorPlot.classParameters
            ? this.getUniqueClassIdsByFrequency(indicatorPlot)
            : this.getDefaultUniqueClassIds(indicatorPlot);

        const customClassParameters = this.updateClassLabel(indicatorPlot, uniqueClassIds);
        const colorTable = this.setColors(indicatorPlot, customClassParameters.length);

        const newColorGradient = indicatorPlot.newColorGradient;
        const degrade = indicatorPlot.newColorGradient
            ? indicatorPlot.newColorGradient
            : indicatorPlot.degrade;

        let newLegends = customClassParameters
            .map((classInfo: any, index: number) => {
                if (newColorGradient) {
                    classInfo.default_color = colorTable[index];
                } else {
                    classInfo.default_color = classInfo.default_color
                        ? classInfo.default_color
                        : colorTable[index];
                }
                return classInfo;
            })
            .map((classInfo: any) => ({
                id: classInfo.ordre,
                id_class: classInfo.id_class,
                lib: classInfo.lib_class,
                color: classInfo.default_color,
                default_radius_weight: classInfo.default_radius_weight || 1,
                default_contouropacity: classInfo.default_contouropacity || 1,
                default_fillopacity: classInfo.default_fillopacity || 1,
                default_icon: classInfo.default_icon,
                dashArray: classInfo.dashArray,
                exist: refreshIntervals ? classInfo.exist : true,
            }));

        if (indicatorPlot.form === 'point_proportional') {
            newLegends = newLegends.map((legend: any, index: number) => {
                legend.default_radius_weight =
                    (index + 1) *
                    indicatorPlot.default_radius_weight *
                    indicatorPlot.weightMultiplier;
                return legend;
            });
        }

        indicatorPlot.legende = newLegends;

        // override degrade with its new values (eventually) then remove newColorGradient
        indicatorPlot.degrade = degrade;
        delete indicatorPlot.newColorGradient;
    }

    getDefaultUniqueClassIds(indicatorPlot: Indicator) {
        const values = indicatorPlot.geojson.features.map(
            (feature: any) => feature.properties.value,
        );
        const uniqueClassIds = [...new Set(values)];
        return uniqueClassIds;
    }

    getUniqueClassIdsByFrequency(indicatorPlot: Indicator) {
        const parameters = indicatorPlot.classParameters;
        const sortLegendBy = parameters.sort_legend_by || 'default';

        const uniqueClassIds =
            sortLegendBy == 'default'
                ? this.getDefaultUniqueClassIds(indicatorPlot)
                : this.getTopClassIdsByFrequency(indicatorPlot);

        const index = uniqueClassIds.indexOf(parameters.aggregated_class_id);
        if (index !== -1) {
            uniqueClassIds.splice(index, 1); // Remove the item from its current position
            uniqueClassIds.push(parameters.aggregated_class_id); // Add it to the end
        }
        return uniqueClassIds;
    }

    getTopClassIdsByFrequency(indicatorPlot: Indicator) {
        const classIds = indicatorPlot.geojson.features.map((feature) => feature.properties.value);
        const frequencyByClassId = this.usefulService.countFrequencyByValue(classIds);

        const uniqueClassIds = Object.keys(frequencyByClassId).map((x) => Number(x));
        const parameters = indicatorPlot.classParameters;
        const maxClassCount = parameters.max_class_count || uniqueClassIds.length;
        const sortLegendBy = parameters.sort_legend_by;

        // sort by desc
        if (sortLegendBy == 'desc') {
            const topMostFrequent = this.usefulService.getTopMostFrequent(
                uniqueClassIds,
                frequencyByClassId,
                maxClassCount,
            );
            return topMostFrequent;
        }
        // sort by asc
        const topLessFrequent = this.usefulService.getTopLessFrequent(
            uniqueClassIds,
            frequencyByClassId,
            maxClassCount,
        );
        return topLessFrequent;
    }

    updateClassLabel(indicatorPlot: Indicator, uniqueClassIds: number[]) {
        const classLabels = JSON.parse(
            JSON.stringify(
                indicatorPlot.class_label.filter((classInfo: any) =>
                    uniqueClassIds.includes(classInfo.id_class),
                ),
            ),
        );

        const parameters = indicatorPlot.classParameters || {};
        const sortLegendBy = parameters.sort_legend_by || 'default';
        if (sortLegendBy != 'default')
            classLabels.sort((a: any, b: any) => {
                const indexA = uniqueClassIds.indexOf(a.id_class);
                const indexB = uniqueClassIds.indexOf(b.id_class);

                // If either value is not found in uniqueClassIds, move it to the end.
                if (indexA === -1) return 1;
                if (indexB === -1) return -1;

                // Compare the indices to determine the order.
                return indexA - indexB;
            });

        indicatorPlot.customClassParameters = classLabels;
        indicatorPlot.classCount = classLabels.length;

        return classLabels;
    }

    init(indicatorPlot: any) {
        this.cleanClassLabel(indicatorPlot);

        indicatorPlot.distribution = {
            type: 'class',
        };
    }

    cleanClassLabel(indicatorPlot: any) {
        if (indicatorPlot.form === 'line') {
            const classLabels = indicatorPlot.class_label;
            indicatorPlot.class_label = classLabels.map((c: any) => {
                const dashArray = c.dash_array || indicatorPlot.dashArray;
                c.dashArray =
                    typeof dashArray === 'string'
                        ? dashArray.split(',').map((x: string) => Number(x))
                        : dashArray;
                return c;
            });
        }
    }
}
