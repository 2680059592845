<div class="modal-header">
    <h4 class="modal-title">Modifier la légende</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body" *ngIf="customLegend">
    <div class="container-fluid" id="legend-form">
        <div class="row d-flex align-items-center color">
            <label for="color" class="col-5 control-label py-0 ps-0"> Couleur </label>
            <div class="col-7">
                <stColor innerId="color" [(ngModel)]="customLegend.color"></stColor>
            </div>
        </div>

        <div
            class="row d-flex align-items-center thickness"
            *ngIf="customLegend.lib && indicatorPlot.type === 'class' && !['poly', 'chart'].includes(indicatorPlot.form)"
        >
            <label for="thickness" class="col-5 control-label py-0 ps-0"> Epaisseur </label>
            <div class="col-7">
                <stRange
                    *ngIf="['cluster', 'point'].includes(indicatorPlot.form)"
                    innerId="thickness"
                    [min]="0"
                    [max]="4"
                    [step]="0.1"
                    [value]="customLegend.default_radius_weight"
                    [(ngModel)]="customLegend.default_radius_weight"
                >
                </stRange>
                <stRange
                    *ngIf="!['cluster', 'point'].includes(indicatorPlot.form)"
                    innerId="thickness"
                    [min]="0"
                    [max]="6"
                    [step]="0.1"
                    [value]="customLegend.default_radius_weight"
                    [(ngModel)]="customLegend.default_radius_weight"
                >
                </stRange>
            </div>
        </div>

        <div
            class="row d-flex align-items-center opacity"
            *ngIf="customLegend.lib && indicatorPlot.type === 'class'"
        >
            <label for="opacity" class="col-5 control-label py-0 ps-0"> Opacité </label>
            <div class="col-7">
                <stRange
                    innerId="opacity"
                    [min]="0"
                    [max]="1"
                    [step]="0.1"
                    [value]="customLegend.default_fillopacity"
                    [(ngModel)]="customLegend.default_fillopacity"
                ></stRange>
            </div>
        </div>

        <div
            class="row d-flex align-items-center pattern"
            *ngIf="customLegend.lib && indicatorPlot.type === 'class' && indicatorPlot.form === 'line'"
        >
            <label for="legend-pattern" class="col-5 control-label py-0 ps-0"> Motif </label>
            <div class="col-7">
                <div id="legend-pattern">
                    <div
                        class="cursor-pointer pattern-line"
                        *ngFor="let option of indicatorPlot.dashArray_list"
                        [ngClass]="{'selected': isPatternSelected(option)}"
                        (click)="customLegend.dashArray = option"
                    >
                        <ng-container *ngIf="option.length > 1">
                            <span
                                class="d-inline-block discreet-line"
                                *ngFor="let width of duplicateDashArray(option); let idx = index"
                                [ngStyle]="{'width': width + 'px', 'opacity': idx % 2 === 0 ? 1 : 0}"
                            >
                            </span>
                        </ng-container>

                        <span class="d-inline-block continuous-line" *ngIf="option.length === 1">
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="row d-flex align-items-center symbole"
            *ngIf="customLegend.lib && indicatorPlot.type === 'class' && indicatorPlot.form === 'icon'"
        >
            <label for="symbole" class="col-5 control-label py-0 ps-0">
                Symbole
                <a
                    href="https://fontawesome.com/v4.7.0/icons/"
                    target="_blank"
                    class="fa fa-info-circle"
                    ngbTooltip="Cliquez ici pour voir la liste complète"
                    placement="top"
                    container="body"
                >
                    <i class="fa fa-info-circle"></i>
                </a>
            </label>
            <div class="col-7">
                <div id="symbole">
                    <ng-template #rt let-r="result" let-t="term">
                        <i class="fa fa-{{ r }} me-2"></i>
                        <ngb-highlight [result]="r" [term]="t"></ngb-highlight>
                    </ng-template>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Saisir le code du symbole"
                        [(ngModel)]="customLegend.default_icon"
                        [ngbTypeahead]="search"
                        [resultTemplate]="rt"
                        [inputFormatter]="formatter"
                    />
                </div>
            </div>
        </div>
    </div>

    <hr class="separator" />

    <div class="container-fluid">
        <div class="row d-flex align-items-center mx-0 preview">
            <label for="preview" class="col-5 control-label py-0 ps-0"> Aperçu </label>

            <div class="col-7 d-flex align-items-center justify-content-center">
                <span
                    class="flatInput mb-0"
                    *ngIf="['poly', 'poly3D', 'chart'].includes(indicatorPlot.form)"
                    [ngStyle]="{
                        'background-color': customLegend.color,
                        'opacity': customLegend.default_fillopacity * indicatorPlot.indicatorfillopacity
                    }"
                >
                </span>
                <span
                    *ngIf="['point', 'point_poste_10', 'point_poste_20'].includes(indicatorPlot.form) || indicatorPlot.form == 'heatmap'"
                    class="flatInput mb-0"
                    [ngStyle]="{
                        'background-color': customLegend.color,
                        'opacity': customLegend.default_fillopacity * indicatorPlot.indicatorfillopacity,
                        'border': indicatorPlot.indicatorcontourweight + 'px solid rgb(0, 0, 0)',
                        'border-radius': (2 * indicatorPlot.weightMultiplier * customLegend.default_radius_weight * indicatorPlot.default_radius_weight + indicatorPlot.indicatorcontourweight) + 'px',
                        'height': (2 * indicatorPlot.weightMultiplier * customLegend.default_radius_weight * indicatorPlot.default_radius_weight + indicatorPlot.indicatorcontourweight) + 'px',
                        'width': (2 * indicatorPlot.weightMultiplier * customLegend.default_radius_weight * indicatorPlot.default_radius_weight + indicatorPlot.indicatorcontourweight) + 'px'
                        }"
                >
                </span>
                <span
                    *ngIf="indicatorPlot.form == 'point_proportional'"
                    class="flatInput mb-0"
                    [ngStyle]="{
                        'background-color': customLegend.color,
                        'opacity': customLegend.default_fillopacity * indicatorPlot.indicatorfillopacity,
                        'border': indicatorPlot.indicatorcontourweight + 'px solid rgb(0, 0, 0)',
                        'border-radius': (2 * indicatorPlot.weightMultiplier * customLegend.default_radius_weight * indicatorPlot.default_radius_weight + indicatorPlot.indicatorcontourweight) + 'px',
                        'height': round(customLegend.default_radius_weight * 2) + 'px',
                        'width': round(customLegend.default_radius_weight * 2) + 'px'
                    }"
                >
                </span>
                <span
                    *ngIf="indicatorPlot.form == 'cluster'"
                    class="flatInput mb-0"
                    [ngStyle]="{
                        'background-color': customLegend.color,
                        'opacity': customLegend.default_fillopacity * indicatorPlot.indicatorfillopacity,
                        'border': indicatorPlot.indicatorcontourweight + 'px solid rgb(0, 0, 0)',
                        'border-radius': (2 * customLegend.default_radius_weight * indicatorPlot.default_radius_weight + indicatorPlot.indicatorcontourweight) + 'px',
                        'height': (2 * customLegend.default_radius_weight * indicatorPlot.default_radius_weight + indicatorPlot.indicatorcontourweight) + 'px',
                        'width': (2 * customLegend.default_radius_weight * indicatorPlot.default_radius_weight + indicatorPlot.indicatorcontourweight) + 'px'
                    }"
                >
                </span>
                <span
                    *ngIf="['line', 'migration'].includes(indicatorPlot.form)"
                    class="flatInput mb-0"
                    [ngStyle]="{
                    'opacity': customLegend.default_fillopacity,
                    'width': '100%'}"
                >
                    <ng-container *ngIf="customLegend.dashArray.length > 1">
                        <span
                            class="d-inline-block discreet-line"
                            *ngFor="let width of duplicateDashArray(legend.dashArray); let idx = index"
                            [ngStyle]="{
                                    'background-color': legend.color,
                                    'border-color': legend.color,
                                    'border-radius': '20%',
                                    'width': width + 'px',
                                    'opacity': idx % 2 === 0 ? 1 : 0
                                }"
                        >
                        </span>
                    </ng-container>
                    <span
                        class="d-inline-block continuous-line"
                        *ngIf="[0, 1].includes(customLegend.dashArray.length)"
                        [ngStyle]="{
                            'background-color': customLegend.color,
                            'border-color': customLegend.color,
                            'border-radius': '20%'
                        }"
                    >
                    </span>
                </span>
                <div *ngIf="indicatorPlot.form === 'icon'">
                    <i
                        class="fa fa-{{ customLegend.default_icon || indicatorPlot.default_icon }}"
                        [ngStyle]="{
                        'color': customLegend.color,
                        'opacity': customLegend.default_fillopacity * indicatorPlot.indicatorfillopacity,
                        'font-size': round(5 * customLegend.default_radius_weight * indicatorPlot.default_radius_weight) + 'px',
                        'text-shadow': -indicatorPlot.indicatorcontourweight + 'px 0 ' + indicatorPlot.indicatorcontourcolor + ', 0 ' + indicatorPlot.indicatorcontourweight + 'px ' + indicatorPlot.indicatorcontourcolor + ', ' + indicatorPlot.indicatorcontourweight + 'px 0 ' + indicatorPlot.indicatorcontourcolor + ', 0 -' + indicatorPlot.indicatorcontourweight + 'px ' + indicatorPlot.indicatorcontourcolor
                        }"
                    >
                    </i>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <stButton btnLabel="Annuler" btnClass="btn-st-default me-1" (onClick)="close()"></stButton>
    <stButton btnLabel="Valider" btnClass="btn-st-primary ms-1" (onClick)="validate()"></stButton>
</div>
