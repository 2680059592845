'use strict';

import * as L from 'leaflet';
import { MapService } from '../services/map.service';
import { PlotFactory } from './PlotFactory';

export class PlotProportionalPointFactory extends PlotFactory {
    public weightMultiplier = 1.5;
    public constant_weight: number;

    constructor(indicatorId: number, mapService: MapService) {
        super(indicatorId, mapService);
        this.form = 'point_proportional';
        this.default_radius_weight = 2;
        this.indicatorcontourweight = 2;
        this.constant_weight = 5;
    }

    addLayer(map: L.Map) {
        if (this.geojsonLayer != null) {
            this.removeLayer(map);
        }

        this.geojsonLayer = L.geoJson(this.geojson, {
            pointToLayer: this.getStyle.bind(this),
            onEachFeature: this.onEachFeature.bind(this),
        });

        map.addLayer(this.geojsonLayer);
        // this.mapService.addLayers(this.geojsonLayer.getLayers());
    }

    getWeight(value) {
        if (this.type == 'class' && value !== null && value !== undefined) {
            const legend = this.legende.find((l) => l.id_class === value);
            if (legend) {
                legend.exist = true;
                return legend.default_radius_weight;
            }
            return this.weightMultiplier * this.default_radius_weight;
        }

        if ((value === null || value === undefined) && this.zero_if_null_dyn) {
            value = 0;
        }

        if (value === 0 && this.separate_zero_in_lgd === 1) {
            return this.weightMultiplier * this.default_radius_weight;
        }

        if (typeof value === 'number' && !isNaN(value)) {
            const index = this.getLegendIndex(value);
            const legende = this.legende[index];
            return legende.default_radius_weight;
        }

        return this.weightMultiplier * this.default_radius_weight;
    }

    getStyle(feature: GeoJSON.Feature, latLng?: { lat: number; lng: number }) {
        return L.circleMarker(latLng, {
            radius: this.getWeight(feature.properties.value),
            fillColor: this.getColor(feature.properties.value),
            color: this.indicatorcontourcolor,
            weight: this.indicatorcontourweight,
            opacity: this.getContourOpacity(feature.properties.value),
            fillOpacity: this.getFillOpacity(feature.properties.value),
        });
    }

    highlightFeature(layer: any) {
        layer.setStyle({
            weight: 3 * this.indicatorcontourweight,
            color: this.indicatorcontourcolor,
        });
    }

    unhighlightFeature(layer: any) {
        layer.setStyle({
            weight: this.indicatorcontourweight,
            color: this.indicatorcontourcolor,
        });
    }
}
