import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { RestService } from 'src/app/services/RestService';

@Injectable({ providedIn: 'root' })
export class FilterResolver implements Resolve<any> {
    constructor(private restService: RestService) {}

    resolve(route: ActivatedRouteSnapshot): Promise<any> {
        const archiCritdterId = parseInt(route.paramMap.get('archiCritdterId'));
        return this.restService.getFilter(archiCritdterId);
    }
}
