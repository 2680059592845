export function round(value, decimalCount = 0) {
    if (value === undefined) {
        return value;
    }

    if (isNaN(value)) {
        var error = TypeError('Value is not a number.');
        return error;
    }

    var roundedValue = Math.round(value * Math.pow(10, decimalCount)) / Math.pow(10, decimalCount);

    return roundedValue;
}

export function floor(value, decimalCount = 0) {
    if (value === undefined) {
        return value;
    }

    if (isNaN(value)) {
        return TypeError('Value is not a number.');
    }

    var flooredValue = Math.floor(value * Math.pow(10, decimalCount)) / Math.pow(10, decimalCount);

    return flooredValue;
}

export function ceil(value, decimalCount = 0) {
    if (value === undefined) {
        return value;
    }

    if (isNaN(value)) {
        return TypeError('Value is not a number.');
    }

    var ceiledValue = Math.ceil(value * Math.pow(10, decimalCount)) / Math.pow(10, decimalCount);

    return ceiledValue;
}

export function stringifyNumber(value, decimalCount = 0, addSpacing = true) {
    var stringifiedValue = value.toString();
    stringifiedValue = stringifiedValue.replace('.', ',');

    if (decimalCount > 0) {
        var decimalPart = stringifiedValue.slice(stringifiedValue.indexOf(',') + 1);
        if (decimalCount > decimalPart.length) {
            decimalPart = decimalPart + '0'.repeat(decimalCount - decimalPart.length);
        }
    }

    if (addSpacing) {
        stringifiedValue = stringifiedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
    return stringifiedValue;
}
