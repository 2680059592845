<div id="cs-results" class="d-flex flex-column h-100">
    <div class="flex-grow-1 overflow-auto">
        <div class="w-100 p-3">
            <div class="header mb-3">
                <h5 class="d-flex justify-content-between align-items-center">
                    <span class="text-st-primary">Mes simulations</span>
                    <stButton
                        btnClass="btn-outline-st-primary btn-sm"
                        btnLabel="Supprimer cette simulation"
                        (click)="openDeleteModal()"
                    >
                    </stButton>
                </h5>
                <div
                    id="simulation-buttons"
                    class="solar-card d-flex bg-st-base00 py-2 px-0 overflow-auto"
                >
                    <div
                        *ngFor="let result of solarService.results"
                        id="simulation-button-{{ result.id }}"
                    >
                        <a
                            target="_blank"
                            class="card-button no-text-decoration"
                            [ngClass]="{active: currentResult.id == result.id}"
                            (click)="loadCurrentResult(result)"
                        >
                            <h6 class="mb-0">Simulation {{ result.id }}&nbsp;:</h6>
                            <small>{{ result.label }}</small>
                        </a>
                    </div>

                    <div *ngIf="solarService.results.length < maxResultsCount">
                        <a
                            target="_blank"
                            class="card-button no-text-decoration add-simulation"
                            (click)="addSimulation()"
                        >
                            <!-- <i class="fa fa-plus fa-2x"></i> -->
                            <svg
                                class="plus-icon"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                <path
                                    d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                                />
                            </svg>
                            <small class="d-block"> Ajouter une simulation </small>
                        </a>
                    </div>
                </div>
            </div>

            <div class="body">
                <h5 class="text-st-primary">Résultats</h5>

                <div class="container-fluid solar-card mb-3 installation">
                    <div class="row g-2 d-flex align-items-center justify-content-between">
                        <div class="col-sm-1 d-flex justify-content-center">
                            <span class="panel-icon" [title]="tooltip.installation">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                    <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                    <path
                                        d="M96 0C80.7 0 67.6 10.8 64.6 25.7l-64 320c-1.9 9.4 .6 19.1 6.6 26.6S22.4 384 32 384H288v64H224c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H352V384H608c9.6 0 18.7-4.3 24.7-11.7s8.5-17.2 6.6-26.6l-64-320C572.4 10.8 559.3 0 544 0H96zm5.4 168L122.2 64h90.4L202.3 168H101.4zm-9.6 48H197.5L187.1 320H71L91.8 216zm153.9 0H394.3l10.4 104H235.3l10.4-104zm196.8 0H548.2L569 320h-116L442.5 216zm96-48H437.7L427.3 64h90.4l20.8 104zm-149.1 0h-139L260.9 64H379.1l10.4 104z"
                                    />
                                </svg>
                            </span>
                        </div>
                        <div class="col-sm">
                            <div class="row gy-2 gx-4 d-flex align-items-center">
                                <div
                                    class="col-sm"
                                    [innerHtml]="currentResult.htmlInstallation"
                                ></div>
                                <div
                                    class="col-sm-auto question-mark-container"
                                    *ngIf="currentResult.technology == 'thermal'"
                                >
                                    <stButton
                                        btnLabel="+&nbsp;d'infos"
                                        btnClass="btn-st-default w-100"
                                        (onClick)="openInfoModal(tooltipInfoByTechnology[currentResult.technology].installationDetail, titleInstallation)"
                                    >
                                    </stButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid solar-card mb-3 investment">
                    <div class="row g-2 d-flex align-items-center justify-content-between">
                        <div class="col-sm-1 d-flex justify-content-center">
                            <i class="fa fa-tasks fa-2x" [title]="tooltip.investment"></i>
                        </div>
                        <div class="col-sm">
                            <div class="row gy-2 gx-4 d-flex align-items-center">
                                <div
                                    class="col-sm"
                                    [innerHtml]="currentResult.htmlInvestment"
                                ></div>
                                <div class="col-sm-auto question-mark-container">
                                    <stButton
                                        btnLabel="+&nbsp;d'infos"
                                        btnClass="btn-st-default w-100"
                                        (onClick)="openInfoModal(tooltipInfoByTechnology[currentResult.technology].investmentDetail, titleInvestment)"
                                    >
                                    </stButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mb-3 production">
                    <div class="container-fluid solar-card">
                        <div class="row g-2d-flex align-items-center justify-content-between">
                            <div class="col-sm-1 d-flex justify-content-center">
                                <i class="fa fa-bolt fa-2x" [title]="tooltip.production"> </i>
                            </div>
                            <div class="col-sm">
                                <div class="row gy-2 gx-4 d-flex align-items-center">
                                    <div
                                        class="col-sm"
                                        [innerHtml]="currentResult.htmlProduction"
                                    ></div>
                                    <div class="col-sm-auto question-mark-container">
                                        <stButton
                                            btnLabel="+&nbsp;d'infos"
                                            btnClass="btn-st-default w-100"
                                            (onClick)="openInfoModal(tooltipInfoByTechnology[currentResult.technology].productionDetail, titleProduction)"
                                        >
                                        </stButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="isCurrentResultSurplus()">
                        <cadastreSolairePvSurplusPlot></cadastreSolairePvSurplusPlot>
                    </ng-container>
                </div>

                <div class="container-fluid solar-card mb-3 selling-gain">
                    <div class="row g-2 d-flex align-items-center justify-content-between">
                        <div class="col-sm-1 d-flex justify-content-center">
                            <ng-template #economyTooltip>
                                <div [innerHtml]="tooltip.economy"></div>
                            </ng-template>
                            <i class="fa fa-eur fa-2x" [title]="tooltip.economy"></i>
                        </div>
                        <div class="col-sm">
                            <div class="row gy-2 gx-4 d-flex align-items-center">
                                <div class="col-sm" [innerHtml]="currentResult.htmlEconomy"></div>
                                <div class="col-sm-auto question-mark-container">
                                    <stButton
                                        btnLabel="+&nbsp;d'infos"
                                        btnClass="btn-st-default w-100"
                                        (onClick)="openInfoModal(tooltipInfoByTechnology[currentResult.technology].economyDetail, titleEconomy)"
                                    >
                                    </stButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="!!tooltipInfoByTechnology[currentResult.technology].warning">
                    <div
                        [innerHtml]="tooltipInfoByTechnology[currentResult.technology].warning"
                    ></div>
                </ng-container>
                <ng-container *ngIf="!tooltipInfoByTechnology[currentResult.technology].warning">
                    <div class="text-center">
                        <small>
                            Ces données sont issues d’un traitement automatisé et fournissent un
                            premier ordre d’idée. Cela ne remplace pas la visite d'un technicien
                            agréé.
                        </small>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="footer border-top">
        <div class="container-fluid py-3">
            <div class="row">
                <div
                    [ngClass]="{'col-6': modularity.downloadResults, 'col-12': !modularity.downloadResults}"
                >
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-primary w-100 h-100"
                        [btnLabel]="contactButtonLabel"
                        (onClick)="openContactModal()"
                    >
                    </stButton>
                </div>
                <div class="col-6" *ngIf="modularity.downloadResults">
                    <button
                        type="button"
                        class="btn btn-st-primary solar-pdf"
                        (click)="downloadPdf()"
                        [disabled]="isResultsDownloading"
                    >
                        <span *ngIf="!isResultsDownloading">Télécharger le récapitulatif</span>
                        <i class="fa fa-spinner fa-pulse" *ngIf="isResultsDownloading"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
