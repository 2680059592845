<div id="confirmationModal" tabindex="-1" aria-labelledby="confirmationModal">
  <div class="modal-header">
    <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()"><span
        aria-hidden="true">&times;</span></button> -->
    <h4 class="modal-title text-st-active">{{ applyChangesInfo.stream.header }}</h4>
  </div>
  <div class="modal-body">
    <p class="text-justify" *ngIf="!!applyChangesInfo.element">
      Souhaitez-vous ajouter à la sélection tous les éléments réseaux
      <span class="text-st-primary">
        {{ applyChangesInfo.stream.body }}
      </span>
      de l'élément
      <span class="text-st-active">
        {{ applyChangesInfo.element.label }}
      </span>
      <span *ngIf="!applyChangesInfo.hypothesisInfo.length">
        ?
      </span>
      <span *ngIf="applyChangesInfo.hypothesisInfo.length">
        et appliquer les modifications des hypothèses de cet élément réseau ?
      </span>
    </p>
    <ul class="mb-3" *ngIf="applyChangesInfo.hypothesisInfo.length">
      <li *ngFor="let hypothesis of applyChangesInfo.hypothesisInfo">
        {{ hypothesis.label }} : {{ hypothesis.value }} {{ hypothesis.unit }}
      </li>
    </ul>
  </div>
  <div class="modal-footer">
    <stButton btnLabel="Annuler" btnClass="btn-st-default me-3 px-5" (onClick)="close()"></stButton>
    <stButton btnLabel="Valider" btnClass="btn-st-primary ms-3 px-5" (onClick)="validate()"></stButton>
  </div>
</div>