'use strict';

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'singleInput',
    templateUrl: './single-input.template.html',
})
export class SingleIputComponent {
    @Input() value: number;
    @Output() valueChange = new EventEmitter();
    @Input() popoverInfo: string;
    @Input() label: string;

    constructor() {}

    onChange(value: number) {
        this.valueChange.emit(value);
    }
}
