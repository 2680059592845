'use strict';

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ButtonParameters } from 'src/app/models/ButtonParameters';

@Component({
    selector: 'confirmationModal',
    templateUrl: './parameters-modal.template.html',
})
export class MapParametersModalComponent implements OnInit {
    @Input() levelAvailable: string[];

    public manageResetLevel = {
        level: '',
        options: [
            {
                label: 'Rétablir ma dernière valeur enregistrée',
                level: 'user',
                show: false,
                checked: false,
                isAvailable: false,
            },
            {
                label: 'Rétablir la valeur par défaut de mon groupe',
                level: 'group',
                show: false,
                checked: false,
                isAvailable: false,
            },
            {
                label: `Rétablir la valeur par défaut globale de l'indicateur`,
                level: 'app',
                show: false,
                checked: false,
                isAvailable: false,
            },
        ],
    };

    ngOnInit(): void {
        this._setAvailableRadioButton();
        this._checkFirstRadioButtonDisplayed();
    }

    constructor(public activeModal: NgbActiveModal) {}

    private _setAvailableRadioButton() {
        this.manageResetLevel.options.forEach(
            (params: ButtonParameters) =>
                (params.show = this.levelAvailable.includes(params.level)),
        );
    }

    private _checkFirstRadioButtonDisplayed() {
        this.manageResetLevel.options.forEach(
            (params: ButtonParameters) => (params.checked = false),
        );
        const option = this.manageResetLevel.options.find(
            (params: ButtonParameters) => params.show,
        );
        if (option) {
            option.checked = true;
            this.manageResetLevel.level = option.level;
        }
    }

    validate() {
        this.activeModal.close(this.manageResetLevel.level);
    }

    close() {
        this.activeModal.dismiss('cancel');
    }
}
