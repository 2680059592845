<stHeader [isAdminPage]="true"></stHeader>
<stNavbar page="filters-manage"></stNavbar>

<div class="ligneFlexStart windowAdmin">
  <div class="flexComponentOne bordureExtContenair"></div>

  <div class="colFlexStartWrap admin_maincontenair">
    <div class="adm_main_title ligneFlexStartCentered">
      <div><span class="fa fa-user fa-2x" style="padding-right: 15px; color: rgb(33,68,111);"></span></div>
      <div>Gestion des filtres</div>
    </div>
    <div class="ligneFlexStart" style="width:100%">
      <div style="padding: 30px 20px 40px 20px; text-align: justify">
        <h4>
          <span class="fa fa-life-ring "></span>
          <span>Gestion des familles de filtres</span>
        </h4>
        <p>En tant que super administrateur, vous avez à charge la gestion des filtres de Siterre.</p>
        <p>Une famille de filtre est composée d'un certain nombre de filtres unitaires :</p>
        <ul>
          <li> Tous les filtres d'une même famille doivent être déclarés dans la même table de données </li>
          <li> Un indicateur associé à une famille de filtres doit être déclaré dans la même table de données
            également </li>
        </ul>
        <div style="padding-top: 10px">Le tableau ci-dessous vous permet de gérer les filtres.</div>
      </div>

      <div class="flexComponentOne"></div>

      <div class="card">
        <div class="list-group list-group-flush" style="padding: 20px;">
          <div class="modal-header list-group-item p-1">
            <h4 class="modal-title">Dashboard administation des filtres</h4>
          </div>
          <div class="modal-body list-group-item p-1">
            <div class="p-1">
              <b>Nombre total de familles </b> : {{ totalFilters }} filtre(s)
            </div>
          </div>
          <div class="modal-footer p-3">
            <stButton btnLabel="Créer une nouvelle famille de filtres" btnClass="btn-st-primary"
              (click)="goToNewFilter()"></stButton>
          </div>
        </div>
      </div>
    </div>


    <div class="ligneFlexStart" style="width:100%">
      <div class="flexComponentOne" style="padding: 10px 0px 20px 20px;">

        <stPagination text="Nombre de filtres par page :" [values]="nbLinesPerPageList"
          [(nbLinesPerPage)]="nbLinesPerPage" [total]="totalFilters" [(pageSelected)]="selectedPage">
          <table class="modalTable" style="min-width: 750px">
            <tr>
              <th style="width: 60px ; text-align: center">
                ID
                <span class="fa fa fa-sort cursor-pointer" (click)="varSort('id_archi_critdter')"></span>
              </th>
              <th style="min-width: 100px ; text-align: left">
                Nom
                <span class="fa fa fa-sort cursor-pointer" (click)="varSort('nom_archi_critdter')"></span>
              </th>
              <th style="min-width: 100px; text-align: left">
                Table de données
                <span class="fa fa fa-sort cursor-pointer" (click)="varSort('tbl')"></span>
              </th>
              <th style="min-width: 100px ; text-align: left">
                Nombre de filtres
                <span class="fa fa fa-sort cursor-pointer" (click)="varSort('nb_critere')"></span>
              </th>
              <th style="min-width: 180px ; text-align: left ">
                Nombre d'indicateurs
                <span class="fa fa fa-sort cursor-pointer" (click)="varSort('nb_indic')"></span>
              </th>
              <th style="text-align: center">
                Action
              </th>
            </tr>
            <ng-container *ngFor="let filter of filters | orderBy: sortValue; let index = index">
              <tr *ngIf="tabFilter(index)">
                <td style="text-align: center">
                  {{ filter.id_archi_critdter }}
                </td>
                <td style="text-align: left">
                  {{ filter.nom_archi_critdter }}
                </td>
                <td style="text-align: left">
                  {{ filter.tbl }}
                </td>
                <td style="text-align: center">
                  {{ filter.nb_critere }}
                </td>
                <td style="text-align: center">
                  {{ filter.nb_indic }}
                </td>

                <td style="text-align: center">
                  <span (click)="goToUpdateFilter(filter)" class="fa fa-pencil-square-o cursor-pointer"
                    style="padding:5px" ngbTooltip="Voir / modifier" container="body"></span>
                  <span class="fa fa-trash-o cursor-pointer" style="padding:5px" (click)="deleteFiltre(filter)"
                    ngbTooltip="Supprimer" container="body"></span>
                </td>
              </tr>
            </ng-container>
          </table>
        </stPagination>
      </div>
    </div>
  </div>
  <div class="flexComponentOne bordureExtContenair"></div>
</div>