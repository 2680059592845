('use strict');

import { Inject, Injectable } from '@angular/core';
import { PrLabel } from '../models/PrTypes';
import { Indicator, IndicatorsLibrary } from '../models/Indicator';
import { TerritoryLabel } from '../models/TerritoryTypes';
import { Theme } from '../models/Theme';
import { EventService } from './event.service';
import { ImportedDataService } from './ImportedDataService';
import { RestService } from './RestService';
import { TerService } from './TerService';

@Injectable({
    providedIn: 'root',
})
export class DataService {
    private customIndicatorId: number = 0;
    private libraryIndicatorByTheme: Theme[] = [];
    private indicatorTmp: Indicator;

    public activeIndicators: IndicatorsLibrary = {};
    public activeIndicatorsList: Indicator[] = [];
    // public selectedTerritoryOnMap: TerritoryLabel = {
    //     id_ter: null,
    //     lib_ter: null,
    // };
    // public resSelectedOnMap: PrLabel = { id: null, label: null, typeId: null };
    public activeScenario: any = null;

    constructor(
        @Inject(EventService) private eventService: EventService,
        @Inject(ImportedDataService) private importedDataService: ImportedDataService,
        @Inject(RestService) private restService: RestService,
        @Inject(TerService) private terService: TerService,
    ) {
        this.indicatorTmp = {
            allow_float: 1,
            allow_unit_scale: 0,
            champ_divider: null,
            crrsdc_ter_year_geo: this.terService.geoYear,
            dash_array: [1],
            decimal_count: 2,
            defaut_class_method: 'quantile',
            defaut_class_number: 5,
            default_radius_weight: 1,
            degradeId: 12,
            degrade: 'GrnYelRed',
            exclude_null: 1,
            hide_if_null: 1,
            facteur_to_main_unit: 1,
            form: 'poly',
            granularity_min: 'ter_01',
            granularity_min_int: 1,
            granularity_max: 'ter_99',
            id_indicateur: this.customIndicatorId,
            id_source: null,
            indicatorfillopacity: 0.8,
            indicatorcontouropacity: 1,
            indicatorcontourcolor: 'black',
            indicatorcontourweight: 1,
            info_description: null,
            info_nom: null,
            info_precaution: null,
            isCustomTerritory: true,
            isImported: true,
            levelAvailable: ['app'],
            libelle_indic_complet: 'Indicateur personnalisé',
            libelle_indic_short: 'Indicateur personnalisé',
            ordre: 39,
            //separate_zero_in_lgd:1,
            static_dynamic: 'stat',
            tbl: '',
            text_null: null,
            type: 'valeur',
            unit: null,
            unit_short: null,
            unit_trunc: 0,
            unit_type: 2,
            vector_base: 'territories',
            zero_if_null_dyn: 0,
        };
    }

    clear() {
        this.customIndicatorId = 0;
        this.libraryIndicatorByTheme = [];
        this.activeIndicators = {};
        this.activeIndicatorsList = [];
        this.activeScenario = null;
    }

    async getIndicatorLibraryByTheme() {
        try {
            if (this.libraryIndicatorByTheme.length) {
                return this.libraryIndicatorByTheme;
            }

            this.libraryIndicatorByTheme = await this.restService.getIndicatorByModule();
            return this.libraryIndicatorByTheme;
        } catch (error) {
            console.error('Error getIndicatorLibraryByTheme', error);
            throw error;
        }
    }

    async getIndicator(indicatorId: number, save: boolean = true) {
        if (this.activeIndicators[indicatorId]) {
            console.warn(`Warning getIndicator: Indicator ${indicatorId} is already defined.`);
            return this.activeIndicators[indicatorId];
        }

        if (indicatorId < 0) {
            const indicator = JSON.parse(
                JSON.stringify(this.importedDataService.getIndicator(indicatorId)),
            );
            this.activeIndicators[indicatorId] = indicator;
            this.activeIndicatorsList.push(indicator);
            return indicator;
        }

        try {
            const indicatorData = await this.restService.getIndicator(indicatorId);
            indicatorData.isCustomTerritory =
                ['none', 'solaire'].includes(indicatorData.vector_base) ||
                indicatorData.plugin == 'prosper_reseaux';

            if (save) {
                this.activeIndicators[indicatorId] = indicatorData;

                const data = this.activeIndicatorsList.find(
                    (data) => data.id_indicateur == indicatorId,
                );
                if (data) {
                    const index = this.activeIndicatorsList.indexOf(data);
                    this.activeIndicatorsList.splice(index, 1);
                }
                this.activeIndicatorsList.push(indicatorData);
                this.eventService.emit('indicatorDataAdded', indicatorData);
            }

            return indicatorData;
        } catch (error) {
            console.error('Error getIndicator', error);
            throw error;
        }
    }

    deleteAllIndicators() {
        this.activeIndicators = {};
        this.activeIndicatorsList = [];
        this.eventService.emit('indicatorDataDeleted');
    }

    deleteIndicator(indicatorId: number) {
        if (this.activeIndicators[indicatorId]) {
            delete this.activeIndicators[indicatorId];
            this.activeIndicatorsList = this.activeIndicatorsList.filter(
                (x) => x.id_indicateur != indicatorId,
            );

            this.eventService.emit('indicatorDataDeleted');
        }
    }

    createIndicator() {
        this.customIndicatorId -= 1;
        const indicator = JSON.parse(JSON.stringify(this.indicatorTmp));
        indicator.id_indicateur = this.customIndicatorId;
        indicator.indicatorId = this.customIndicatorId;
        return indicator;
    }

    addActiveIndicatorFromFile(year?: number) {
        this.customIndicatorId -= 1;
        const indicator = JSON.parse(JSON.stringify(this.indicatorTmp));
        indicator.id_indicateur = this.customIndicatorId;
        indicator.indicatorId = this.customIndicatorId;
        indicator.crrsdc_ter_year_geo = year || this.terService.territoryScale.year;
        return indicator;
    }

    async createIndicatorCustomization(data: any) {
        try {
            await this.restService.createIndicatorCustomization(data);
        } catch (error) {
            console.error('Error createIndicatorCustomization', error);
            throw error;
        }
    }

    async updateIndicatorCustomization(data: any) {
        try {
            await this.restService.updateIndicatorCustomization(data);
        } catch (error) {
            console.error('Error updateIndicatorCustomization', error);
            throw error;
        }
    }

    async updateIndicator(indicatorId: number, parameters: { level: string }) {
        try {
            const indicatorData = await this.restService.getIndicator(indicatorId, parameters);
            this.activeIndicators[indicatorId] = indicatorData;

            const indicator = this.activeIndicatorsList.find(
                (indicator) => indicator.id_indicateur == indicatorId,
            );
            if (indicator) {
                const index = this.activeIndicatorsList.indexOf(indicator);
                this.activeIndicatorsList.splice(index, 1);
            }
            this.activeIndicatorsList.push(indicatorData);
            return indicatorData;
        } catch (error) {
            console.error('Error updateIndicator', error);
            throw error;
        }
    }

    // addActiveEmptyIndicator() {
    //     const indicatorId = 99999;
    //     const indicatorData = {
    //         empty: true,
    //         form: 'poly',
    //         hide_if_null: 0,
    //         id_indicateur: this.customIndicatorId,
    //         static_dynamic: 'stat',
    //         vector_base: 'territories',
    //     };
    //     this.activeIndicators[indicatorId] = indicatorData;
    //     this.activeIndicatorsList.push(indicatorData);
    // }
}
