<div id="load-territories-control">
    <div class="leaflet-bar leaflet-control" *ngIf="isShown">
        <stButton
            *ngIf="isShown"
            btnClass="text-st-primary py-2"
            [btnLabel]="btnLabel"
            (onClick)="updateTerritories()"
            [title]="btnLabel"
        ></stButton>
    </div>
</div>
