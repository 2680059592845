import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Indicator } from 'src/app/models/Indicator';
import { RestService } from 'src/app/services/RestService';

@Injectable({ providedIn: 'root' })
export class IndicatorResolver implements Resolve<Indicator> {
    constructor(private restService: RestService) {}

    resolve(route: ActivatedRouteSnapshot): Promise<Indicator> {
        const indicatorId = parseInt(route.paramMap.get('indicatorId'));
        return this.restService.getIndicator(indicatorId, {
            level: 'app',
        });
    }
}
