import { Inject, Injectable } from '@angular/core';

import { GeojsonInfoTemplate, Indicator } from 'src/app/models/Indicator';

import { EventService } from 'src/app/services/event.service';
import { FilterDataService } from 'src/app/services/FilterDataService';
import { ProsperReseauxService } from 'src/app/services/prosper-reseaux/prosper-reseaux.service';
import { MapService } from 'src/app/services/map.service';
import { RestService } from 'src/app/services/RestService';
import { TerService } from 'src/app/services/TerService';

@Injectable({
    providedIn: 'root',
})
export class PlotProsperReseauxIndicatorService {
    public idKey = 'id';

    private geojsonInfoTemplate: GeojsonInfoTemplate = {
        id: null,
        geojson: null,
        territoryScale: null,
        territoryIds: [],
        granularity: null,
    };

    constructor(
        @Inject(EventService) private eventService: EventService,
        @Inject(FilterDataService) private filterDataService: FilterDataService,
        @Inject(MapService) private mapService: MapService,
        @Inject(ProsperReseauxService) private prosperReseauxService: ProsperReseauxService,
        @Inject(RestService) private restService: RestService,
        @Inject(TerService) private terService: TerService,
    ) {}

    handleClickOnFeature(e: any) {
        const layer = e.target;
        const feature = layer.feature;

        if (feature) {
            const vectorBase = feature.properties.vector_base;
            const energyId = vectorBase.substring(4, 6);
            const typeId = vectorBase.substring(7, 9);
            const isNew = !!feature.properties.isNew;

            this.eventService.emit('clickOnProsperReseauxElement', {
                id: feature.properties.id,
                label: feature.properties.label,
                energyId: energyId,
                typeId: typeId,
                isNew: isNew,
            });

            // this.mapService.panMapBy(layer);
        }
    }

    setGeojsonInfoId(indicatorPlot: any) {
        if (!indicatorPlot.geojsonInfoId) {
            const vectorBase = indicatorPlot.vector_base;
            const year = indicatorPlot.crrsdc_ter_year_geo;
            const form = indicatorPlot.form || 'poly';
            const territoryIds = this.terService.territories.map((t) => t.id);

            const idParts = [vectorBase, year, form, ...territoryIds.sort()];
            const geojsonInfoId = idParts.join('_');

            indicatorPlot.geojsonInfoId = geojsonInfoId;
        }

        return indicatorPlot.geojsonInfoId;
    }

    completeGeojson(indicatorPlot: any, geojson: any) {
        const vectorBase = indicatorPlot.vector_base;
        const indicatorId = indicatorPlot.indicatorId;
        const geojsonDeepCopy = JSON.parse(JSON.stringify(geojson));
        const geojsonWithUserGrid = this.concatGeojsonWithUserGrid(
            geojsonDeepCopy,
            vectorBase,
            indicatorId,
        );
        indicatorPlot.geojson = geojsonWithUserGrid;
        return geojsonWithUserGrid;
    }

    async getGeojson(
        indicatorPlot: any,
        geojsonsInfo: Array<any>,
        saveInGeojsonInfo: boolean = true,
    ) {
        try {
            const indicatorId = indicatorPlot.indicatorId;
            const form = indicatorPlot.form;
            const vectorBase = indicatorPlot.vector_base;
            const year = indicatorPlot.crrsdc_ter_year_geo;
            const geojson = await this.requestGeojson(form, vectorBase, year);
            // Temporary: until back-end is still in french snake case,
            // we need to convert the fields in english camel case
            geojson.features = geojson.features.map((feature) => {
                feature.properties.id = feature.properties.id_res;
                delete feature.properties.id_res;
                feature.properties.label = feature.properties.lib_res;
                delete feature.properties.lib_res;
                feature.properties.gridEner = feature.properties.ener_res;
                delete feature.properties.ener_res;
                feature.properties.typeId = feature.properties.type_res;
                delete feature.properties.type_res;
                return feature;
            });
            this.concatGeojsonWithUserGrid(geojson, vectorBase, indicatorId);

            if (saveInGeojsonInfo) {
                this.setGeojsonInfo(indicatorPlot, geojsonsInfo, geojson);
            }
            indicatorPlot.geojson = geojson;

            return geojson;
        } catch (error) {
            console.error('Erorr getGeojson', error);
            throw error;
        }
    }

    concatGeojsonWithUserGrid(
        geojson: GeoJSON.FeatureCollection,
        vectorBase: string,
        indicatorId: number,
    ) {
        const typeId = vectorBase.split('_').slice(-1)[0];

        const featuresToAdd = this.prosperReseauxService.findFeaturesToAddByTypeId(typeId);
        const userFeatures = featuresToAdd.features;

        let newFeatures = userFeatures.filter((userFeature: GeoJSON.Feature) =>
            geojson.features.every(
                (geojsonFeature: GeoJSON.Feature) =>
                    geojsonFeature.properties.id !== userFeature.properties.id,
            ),
        );

        geojson.features.push(...newFeatures);

        return geojson;
    }

    async requestGeojson(shape: string, type_res: string, year: number) {
        try {
            const parameters = {
                shape: shape,
                type: type_res,
                year: year,
                typefilter: this.terService.territoryScale.type,
                idfilter: JSON.stringify(
                    this.terService.territories.map((territory) => territory.id),
                ),
            };
            const geojson = await this.restService.getPrGeojson(parameters);
            return geojson;
        } catch (error) {
            console.error('Error requestGeojson', error);
            throw error;
        }
    }

    setGeojsonInfo(indicatorPlot: any, geojsonsInfo: any, geojson: GeoJSON.FeatureCollection) {
        const id = this.setGeojsonInfoId(indicatorPlot);
        const isAlreadySet = geojsonsInfo.some((geojsonInfo: any) => geojsonInfo.id == id);

        if (!isAlreadySet) {
            const geojsonInfo = { ...this.geojsonInfoTemplate };
            const territoryScale = JSON.parse(JSON.stringify(this.terService.territoryScale));
            const territoryIds = this.terService.territories.map((t) => t.id);
            const granularity = indicatorPlot.granularity
                ? JSON.parse(JSON.stringify(indicatorPlot.granularity))
                : null;

            geojsonInfo.id = id;
            geojsonInfo.geojson = JSON.parse(JSON.stringify(geojson));
            geojsonInfo.territoryScale = territoryScale;
            geojsonInfo.territoryIds = territoryIds;
            geojsonInfo.granularity = granularity;
            geojsonsInfo.push(geojsonInfo);
        }
    }

    async getValues(indicatorPlot: Indicator) {
        const indicatorId = indicatorPlot.indicatorId;
        // const granularity = indicatorPlot.granularity;
        const hypothesisInputs = this.prosperReseauxService.getHypothesisInputs(indicatorId);

        indicatorPlot.isPrHypothesisApplied = hypothesisInputs.length > 0;

        const data = {
            scale_filter: this.terService.territories.map((t) => `'${t.id}'`).join(', '), // that one is for back auth check
            scale_int: this.terService.territoryScale.typeId, // that one is for back auth check
            // granularity_int: granularity.typeId, // that one is for back auth check
            year: indicatorPlot.crrsdc_ter_year_geo, // that one is for back auth check
            scaleTypeId: this.terService.territoryScale.typeId,
            territoryIds: this.terService.territories.map((t) => t.id),
            hypothesisInputs: hypothesisInputs,
            filters: this.filterDataService.createFiltersArray(indicatorId),
        };

        try {
            const valuesByElementId = await this.restService.getPrIndicatorValues(
                indicatorId,
                data,
            );

            const typeId = indicatorPlot.vector_base.split('_').slice(-1)[0];
            if (typeId == '10') {
                const prElements = this.prosperReseauxService.findPrElementByTypeId(typeId);
                const elements = prElements.elements;
                const elementIds = elements.map((element: any) => element.id);

                // existing
                Object.keys(valuesByElementId)
                    .filter((elementId) => elementIds.includes(elementId))
                    .forEach((elementId: string) => {
                        const element = elements.find((element: any) => element.id == elementId);
                        element.valueToAggregate = valuesByElementId[elementId].p_soutire;
                    });

                // new
                Object.keys(valuesByElementId)
                    .filter((elementId) => !elementIds.includes(elementId))
                    .forEach((elementId: string) => {
                        elements.push({
                            id: elementId,
                            valueToAggregate: valuesByElementId[elementId].p_soutire,
                        });
                    });
            }

            indicatorPlot.dataToPlot = valuesByElementId;
        } catch (error) {
            console.error('Error getValues', error);
            return Promise.reject(error);
        }
    }
}
