<stTab class="atac" label="Atlas de l'Autoconsommation Collective" [visible]="moduleService.isModuleActive.atac">
  <div class="atac-content p-4">
    <ul class="nav nav-pills nav-justified nav-custom mb-4" id="ex1" role="tablist">
      <li class="text-center {{tabActive === 'parameter' ? 'active' : ''}} nav-item" role="presentation">
        <a (click)="goToTab('parameter');" style="cursor: pointer"
          class="nav-link {{tabActive === 'parameter' ? 'active' : ''}}" data-bs-toggle="tab">Paramétrage / lancement
          simulation</a>
      </li>
      <li class="text-center {{tabActive === 'import' ? 'active' : ''}} nav-item" role="presentation">
        <a (click)="goToTab('import')" style="cursor: pointer"
          class="nav-link {{tabActive === 'import' ? 'active' : ''}}" data-bs-toggle="tab">Import données / Définition
          parc bâti</a>
      </li>
      <li class="text-center {{tabActive === 'list' ? 'active' : ''}} nav-item" role="presentation">
        <a (click)="goToTab('list')" style="cursor: pointer" class="nav-link {{tabActive === 'list' ? 'active' : ''}}"
          data-bs-toggle="tab">Liste groupements optimaux</a>
      </li>
    </ul>

    <atacList *ngIf="tabActive === 'list'">
    </atacList>

    <atacImport *ngIf="tabActive === 'import'"></atacImport>

    <atacParameter *ngIf="tabActive === 'parameter'"></atacParameter>

  </div>

</stTab>