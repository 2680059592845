import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { distinctUntilChanged, skip, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ChartConfiguration } from 'chart.js';

import { SolarService } from 'src/app/services/cadastre-solaire/cadastre-solaire.service';
import { SolarPvSurplusService } from 'src/app/services/cadastre-solaire/cadastre-solaire-pv-surplus.service';
import { ThemeService } from 'src/app/services/ThemeService';
import { UsefulService } from 'src/app/services/UsefulService';

@Component({
    selector: 'cadastreSolairePvSurplusTypicalDay',
    templateUrl: './cadastre-solaire-pv-surplus-typical-day.template.html',
    styleUrls: ['./cadastre-solaire-pv-surplus-typical-day.component.scss'],
})
export class CadastreSolairePvSurplusTypicalDayComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();

    public typicalDayDatasets: ChartConfiguration<'line'>['data']['datasets'];
    public typicalDayLabels: string[] = [
        '0:00',
        '1:00',
        '2:00',
        '3:00',
        '4:00',
        '5:00',
        '6:00',
        '7:00',
        '8:00',
        '9:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00',
    ];

    private productionColor: string = 'rgba(255, 192, 0, 1)';
    private electricitySoldColor: string = 'rgba(255, 229, 149, 0.5)';

    private consumptionColor: string = 'rgba(21, 96, 130, 1)';
    private electricityPurchasedColor: string = 'rgba(33, 95, 154, 0.5)';

    private selfconsumptionColor: string = 'rgba(199, 202, 176, 0.75)';

    public showLoader: boolean = false;

    constructor(
        @Inject(SolarService) public solarService: SolarService,
        @Inject(SolarPvSurplusService)
        public solarPvSurplusService: SolarPvSurplusService,
        @Inject(ThemeService) public themeService: ThemeService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    ngOnInit(): void {
        this.solarPvSurplusService.pvSurplusResultObs$
            .pipe(skip(1), takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((currentResult) => {
                if (currentResult) {
                    this._setTypicalDayDatasets();
                }
            });

        this._setTypicalDayDatasets();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private _setTypicalDayDatasets() {
        const currentResult = this.solarPvSurplusService.currentResult;
        const production = currentResult.typicalDayProduction;
        const consumption =
            this.solarPvSurplusService.pvSurplusConsumersResults.typicalDayConsumption;
        // const selfConsumption = production.map((production, index) =>
        //     this.usefulService.round(Math.min(consumption[index], production), 3),
        // );

        this.typicalDayDatasets = [
            // {
            //     data: selfConsumption,
            //     label: 'Autoconsommation (kWh)',
            //     fill: true,
            //     backgroundColor: 'rgba(208, 226, 243, 1)',
            //     borderColor: 'rgba(208, 226, 243, 1)',
            // },
            {
                data: production,
                label: 'Production (kWh)',
                fill: true,
                backgroundColor: this.electricitySoldColor,
                borderColor: this.productionColor,
            },
            {
                data: consumption,
                label: 'Consommation (kWh)',
                fill: true,
                backgroundColor: this.electricityPurchasedColor,
                borderColor: this.consumptionColor,
            },
        ];
    }

    // this function needs to be an arrow one
    typicalDayTooltipHandler = (context: any) => {
        const { chart, tooltip } = context;
        const tooltipEl =
            CadastreSolairePvSurplusTypicalDayComponent._createTypicalDayTooltip(chart);
        tooltipEl.style.width = '200px';

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        // Set Text
        if (tooltip.body) {
            const titleLines = tooltip.title || [];
            const bodyLines = tooltip.body.map((b) => b.lines);

            const tableHead = document.createElement('thead');

            titleLines.forEach((title: string) => {
                const tr = document.createElement('tr');
                tr.style.borderWidth = '0px';

                const th = document.createElement('th');
                th.style.borderWidth = '0px';
                th.style.color = this.themeService.theme.info.color['--primary'];
                th.style.fontSize = '1rem';
                const text = document.createTextNode(title);

                th.appendChild(text);
                tr.appendChild(th);
                tableHead.appendChild(tr);
            });

            const production = context.tooltip.dataPoints[0].raw;
            const consumption = context.tooltip.dataPoints[1].raw;
            const selfConsumption = this.usefulService.round(Math.min(consumption, production), 2);
            const electricityPurchased = this.usefulService.round(
                Math.max(0, consumption - production),
                2,
            );
            const electricitySold = this.usefulService.round(
                Math.max(0, production - consumption),
                2,
            );

            const tableBody = document.createElement('tbody');
            bodyLines.forEach((bodyLine: string[], i: number) => {
                const tr = document.createElement('tr');
                tr.classList.add('d-flex');
                tr.style.backgroundColor = 'inherit';
                tr.style.borderWidth = '0px';
                tableBody.appendChild(tr);

                const tdColor = document.createElement('td');
                tdColor.classList.add('d-flex');
                tdColor.classList.add('align-items-center');
                tdColor.style.borderWidth = '0px';
                tr.appendChild(tdColor);

                const colors = tooltip.labelColors[i];

                const span = document.createElement('span');
                span.style.background = colors.backgroundColor;
                span.style.borderColor = colors.borderColor;
                span.style.borderWidth = '2px';
                span.style.marginRight = '10px';
                span.style.height = '2px';
                span.style.width = '15px';
                span.style.display = 'inline-block';
                tdColor.appendChild(span);

                const tdLabel = document.createElement('td');
                tdLabel.classList.add('d-flex');
                tdLabel.classList.add('align-items-center');
                tdLabel.classList.add('flex-grow-1');
                tdLabel.style.borderWidth = '0px';
                tr.appendChild(tdLabel);

                const spanLabel = document.createElement('span');
                tdLabel.appendChild(spanLabel);

                const rawValue = context.tooltip.dataPoints[i].raw;
                const valueStringified = this.usefulService.stringifyNumber(
                    this.usefulService.round(rawValue, 2),
                );
                const bodyLineItems = bodyLine[0].split(': ');
                const labelSplited = bodyLineItems[0].split(' ');
                labelSplited.splice(-1);
                const textLabel = document.createTextNode(labelSplited.join(' '));
                spanLabel.appendChild(textLabel);

                const tdValue = document.createElement('td');
                tdValue.classList.add('d-flex');
                tdValue.classList.add('align-items-center');
                tdValue.style.borderWidth = '0px';
                tr.appendChild(tdValue);

                const spanValue = document.createElement('span');
                tdValue.appendChild(spanValue);

                const textValue = document.createTextNode(`${valueStringified} kWh`);
                spanValue.appendChild(textValue);
            });

            const table1 = tooltipEl.querySelector('#line-chart-tooltip-lomia-typical-day-1');

            // Remove old children
            while (table1.firstChild) {
                table1.firstChild.remove();
            }

            // Add new children
            table1.appendChild(tableHead);
            table1.appendChild(tableBody);

            // set another table below the one above
            const tBody = document.createElement('tbody');

            // self consumption
            const trSelfConsumption = document.createElement('tr');
            trSelfConsumption.classList.add('d-flex');
            trSelfConsumption.style.backgroundColor = 'inherit';
            trSelfConsumption.style.borderWidth = '0px';
            tBody.appendChild(trSelfConsumption);

            const tdSelfConsumptionColor = document.createElement('td');
            tdSelfConsumptionColor.classList.add('d-flex');
            tdSelfConsumptionColor.classList.add('align-items-center');
            trSelfConsumption.appendChild(tdSelfConsumptionColor);

            const spanSelfconsumptionColor = document.createElement('span');
            spanSelfconsumptionColor.style.background = this.selfconsumptionColor;
            spanSelfconsumptionColor.style.borderColor = this.selfconsumptionColor;
            spanSelfconsumptionColor.style.borderWidth = '2px';
            spanSelfconsumptionColor.style.marginRight = '10px';
            spanSelfconsumptionColor.style.height = '10px';
            spanSelfconsumptionColor.style.width = '15px';
            spanSelfconsumptionColor.style.display = 'inline-block';
            tdSelfConsumptionColor.appendChild(spanSelfconsumptionColor);

            const tdSelfConsumptionLabel = document.createElement('td');
            tdSelfConsumptionLabel.classList.add('d-flex');
            tdSelfConsumptionLabel.classList.add('align-items-center');
            tdSelfConsumptionLabel.classList.add('flex-grow-1');
            tdSelfConsumptionLabel.style.borderWidth = '0px';
            trSelfConsumption.appendChild(tdSelfConsumptionLabel);

            const spanSelfConsumptionLabel = document.createElement('span');
            tdSelfConsumptionLabel.appendChild(spanSelfConsumptionLabel);

            const textSelfConsumption = document.createTextNode('Autoconsommation');
            spanSelfConsumptionLabel.appendChild(textSelfConsumption);

            const tdSelfConsumptionValue = document.createElement('td');
            tdSelfConsumptionValue.classList.add('d-flex');
            tdSelfConsumptionValue.classList.add('align-items-center');
            tdSelfConsumptionValue.style.borderWidth = '0px';
            trSelfConsumption.appendChild(tdSelfConsumptionValue);

            const spanSelfConsumptionValue = document.createElement('span');
            tdSelfConsumptionValue.appendChild(spanSelfConsumptionValue);

            const electricityPurchasedStringified =
                this.usefulService.stringifyNumber(electricityPurchased);
            const electricitySoldStringified = this.usefulService.stringifyNumber(electricitySold);

            const selfConsumptionStringified = this.usefulService.stringifyNumber(selfConsumption);
            const selfConsumptionValue = document.createTextNode(
                `${selfConsumptionStringified} kWh`,
            );
            spanSelfConsumptionValue.appendChild(selfConsumptionValue);

            // electricity purchased
            const trElectricityPurchased = document.createElement('tr');
            trElectricityPurchased.classList.add('d-flex');
            trElectricityPurchased.style.backgroundColor = 'inherit';
            trElectricityPurchased.style.borderWidth = '0px';
            tBody.appendChild(trElectricityPurchased);

            const tdElectricityPurchasedColor = document.createElement('td');
            tdElectricityPurchasedColor.classList.add('d-flex');
            tdElectricityPurchasedColor.classList.add('align-items-center');
            trElectricityPurchased.appendChild(tdElectricityPurchasedColor);

            const spanElectricityPurchased = document.createElement('span');
            spanElectricityPurchased.style.background = this.electricityPurchasedColor;
            spanElectricityPurchased.style.borderColor = this.electricityPurchasedColor;
            spanElectricityPurchased.style.borderWidth = '2px';
            spanElectricityPurchased.style.marginRight = '10px';
            spanElectricityPurchased.style.height = '10px';
            spanElectricityPurchased.style.width = '15px';
            spanElectricityPurchased.style.display = 'inline-block';
            tdElectricityPurchasedColor.appendChild(spanElectricityPurchased);

            const tdElectricityPurchasedLabel = document.createElement('td');
            tdElectricityPurchasedLabel.classList.add('d-flex');
            tdElectricityPurchasedLabel.classList.add('align-items-center');
            tdElectricityPurchasedLabel.classList.add('flex-grow-1');
            tdElectricityPurchasedLabel.style.borderWidth = '0px';
            trElectricityPurchased.appendChild(tdElectricityPurchasedLabel);

            const spanElectricityPurchasedLabel = document.createElement('span');
            tdElectricityPurchasedLabel.appendChild(spanElectricityPurchasedLabel);

            const textElectricityPurchased = document.createTextNode(`Achat d'électricité`);
            spanElectricityPurchasedLabel.appendChild(textElectricityPurchased);

            const tdElectricityPurchasedValue = document.createElement('td');
            tdElectricityPurchasedValue.classList.add('d-flex');
            tdElectricityPurchasedValue.classList.add('align-items-center');
            tdElectricityPurchasedValue.style.borderWidth = '0px';
            trElectricityPurchased.appendChild(tdElectricityPurchasedValue);

            const spanElectricityPurchasedValue = document.createElement('span');
            tdElectricityPurchasedValue.appendChild(spanElectricityPurchasedValue);

            const textValueElectricityPurchased = document.createTextNode(
                `${electricityPurchasedStringified} kWh`,
            );
            tdElectricityPurchasedValue.appendChild(textValueElectricityPurchased);

            // electricity sold
            const trElectricitySold = document.createElement('tr');
            trElectricitySold.classList.add('d-flex');
            trElectricitySold.style.backgroundColor = 'inherit';
            trElectricitySold.style.borderWidth = '0px';
            tBody.appendChild(trElectricitySold);

            const tdElectricitySoldColor = document.createElement('td');
            tdElectricitySoldColor.classList.add('d-flex');
            tdElectricitySoldColor.classList.add('align-items-center');
            trElectricitySold.appendChild(tdElectricitySoldColor);

            const spanElectricitySold = document.createElement('span');
            spanElectricitySold.style.background = this.electricitySoldColor;
            spanElectricitySold.style.borderColor = this.electricitySoldColor;
            spanElectricitySold.style.borderWidth = '2px';
            spanElectricitySold.style.marginRight = '10px';
            spanElectricitySold.style.height = '10px';
            spanElectricitySold.style.width = '15px';
            spanElectricitySold.style.display = 'inline-block';
            tdElectricitySoldColor.appendChild(spanElectricitySold);

            const tdElectricitySoldLabel = document.createElement('td');
            tdElectricitySoldLabel.classList.add('d-flex');
            tdElectricitySoldLabel.classList.add('align-items-center');
            tdElectricitySoldLabel.classList.add('flex-grow-1');
            tdElectricitySoldLabel.style.borderWidth = '0px';
            trElectricitySold.appendChild(tdElectricitySoldLabel);

            const spanElectricitySoldLabel = document.createElement('span');
            tdElectricitySoldLabel.appendChild(spanElectricitySoldLabel);

            const textElectricitySold = document.createTextNode(`Vente sur le réseau`);
            spanElectricitySoldLabel.appendChild(textElectricitySold);

            const tdElectricitySoldValue = document.createElement('td');
            tdElectricitySoldValue.classList.add('d-flex');
            tdElectricitySoldValue.classList.add('align-items-center');
            tdElectricitySoldValue.style.borderWidth = '0px';
            trElectricitySold.appendChild(tdElectricitySoldValue);

            const spanElectricitySoldValue = document.createElement('span');
            tdElectricitySoldValue.appendChild(spanElectricitySoldValue);

            const textValueElectricitySold = document.createTextNode(
                `${electricitySoldStringified} kWh`,
            );
            tdElectricitySoldValue.appendChild(textValueElectricitySold);

            // Remove old children
            const table2 = tooltipEl.querySelector('#line-chart-tooltip-lomia-typical-day-2');
            while (table2.firstChild) {
                table2.firstChild.remove();
            }

            // Add new children
            table2.appendChild(tBody);
        }

        SolarPvSurplusService._setTooltipPosition(context, tooltipEl);
    };

    private static _createTypicalDayTooltip(chart: any) {
        let tooltipEl = chart.canvas.parentNode.querySelector('div');

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.style.padding = '5px';
            tooltipEl.style.fontSize = '0.75rem';
            tooltipEl.style.background = 'rgba(255, 255, 255, 0.9)';
            tooltipEl.style.borderRadius = '3px';
            tooltipEl.style.color = 'rgba(74, 74, 76, 1)';
            tooltipEl.style.opacity = 1;
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.transform = 'translate(-50%, 0)';
            tooltipEl.style.transition = 'all .1s ease';

            const table1 = document.createElement('table');
            tooltipEl.appendChild(table1);
            table1.setAttribute('id', 'line-chart-tooltip-lomia-typical-day-1');
            table1.classList.add('w-100');
            table1.style.margin = '0px';

            const hr = document.createElement('hr');
            hr.classList.add('my-2');
            tooltipEl.appendChild(hr);

            const table2 = document.createElement('table');
            tooltipEl.appendChild(table2);
            table2.setAttribute('id', 'line-chart-tooltip-lomia-typical-day-2');
            table2.classList.add('w-100');
            table2.style.margin = '0px';

            chart.canvas.parentNode.appendChild(tooltipEl);
        }

        return tooltipEl;
    }
}
