import { Component, Inject, OnInit } from '@angular/core';

import { EventService } from 'src/app/services/event.service';
import { SolarService } from 'src/app/services/cadastre-solaire/cadastre-solaire.service';
import { UsefulService } from 'src/app/services/UsefulService';

@Component({
    selector: 'cadastreSolaireTechnology',
    templateUrl: './cadastre-solaire-technology.template.html',
    styleUrls: ['./cadastre-solaire-technology.component.scss'],
})
export class CadastreSolaireTechnologyComponent implements OnInit {
    public activeTabId: number = 1;
    public ui: string = 'desktop';

    constructor(
        @Inject(EventService) private eventService: EventService,
        @Inject(SolarService) public solarService: SolarService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {
        this.activeTabId = this.solarService.technology == 'pv' ? 1 : 2;
    }

    ngOnInit(): void {
        this.ui = this.usefulService.showMobileUi ? 'mobile' : 'desktop';
    }

    setTechnology(technology: string) {
        this.solarService.technology = technology;
        this.activeTabId = technology == 'pv' ? 1 : 2;
    }

    validate() {
        if (this.solarService.technology == 'thermal') {
            this.solarService.updateMainStage('thermalStage');
        } else {
            this.solarService.updateMainStage('pvStage');
        }
    }

    cancel() {
        if (this.solarService.results.length) {
            this.solarService.updateMainStage('resultsStage');
            return;
        }

        const isNew = this.solarService.checkIfSelectedElementsIsNew();
        if (isNew) {
            this.solarService.updateMainStage('newRoofStage');
        } else {
            this.solarService.updateMainStage('initStage');
        }
        return;
    }
}
