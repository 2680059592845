'use strict';

import { Component, Inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AtacBuilding } from 'src/app/models/AtacTypes';
import { AtacService } from 'src/app/services/AtacService';

@UntilDestroy()
@Component({
    selector: 'atacImport',
    templateUrl: './atac-import.template.html',
})
export class AtacImportComponent implements OnInit {
    producersTooltip: { [key: string]: string } = {};
    consumersTooltip: { [key: string]: string } = {};
    selectedBuildings: AtacBuilding[];
    modalData: { terId: string; hasLoadCurve: boolean; loadCurveType: string } = {
        terId: '',
        hasLoadCurve: false,
        loadCurveType: '',
    };
    formModal: any;

    constructor(
        @Inject(AtacService) private atacService: AtacService,
    ) {}

    ngOnInit(): void {
        this.atacService.selectedBuildings$
            .pipe(untilDestroyed(this))
            .subscribe((selectedBuildings) => {
                this.selectedBuildings = selectedBuildings;
                selectedBuildings.forEach((building) => {
                    if (!this.consumersTooltip[building.id_ter]) {
                        this.consumersTooltip[building.id_ter] = 'Consommateur Potentiel';
                    }
                    if (!this.producersTooltip[building.id_ter]) {
                        this.producersTooltip[building.id_ter] = 'Producteur Potentiel';
                    }
                });
            });
        this.formModal = new window.bootstrap.Modal(
            document.getElementById('importLoadCurveModal')
            );
    }

    targetElementOnMap(buildingId: string, index?: number) {
        this.atacService.targetElementOnMap(buildingId, index);
    }

    /* Ouvrir modale Import Courbe */
    openModalAtacImportCourbe = (terId: string, hasLoadCurve: boolean, loadCurveType: string) => {
        const modalId = 'importLoadCurveModal';
        this.modalData = {
            terId: terId,
            hasLoadCurve: hasLoadCurve,
            loadCurveType: loadCurveType,
        };
        this.formModal.show();
    };

    setLoadCurve(data: { hasLoadCurve: boolean; loadCurveType: string; terId: string }) {
        const building = this.selectedBuildings.find((x) => x.id_ter === data.terId);
        const field = `has${data.loadCurveType}`;
        building[field] = data.hasLoadCurve;
    }

    /* Règles métier Affectation des bâtiments
 Fonctions vont régir toutes les spécifications sur l'affection d'un bâtiment producteur / consommateur */
    setProducer(terId: string) {
        // Si le producteur est obligatoire, on le set à exclu
        if (this.atacService.requiredProducersIds.has(terId)) {
            this._setProducerToExcluded(terId);
        }
        // Si le producteur est exclu, on le set à potentiel
        else if (this.atacService.excludedProducersIds.has(terId)) {
            this._setProducerToPotential(terId);
            // Si bâtiment est prod potentiel -> c'est un conso potentiel
            this._setConsumerToPotential(terId);
        }
        // Si le producteur est potentiel, on le set à obligatoire
        else if (this.atacService.potentialProducersIds.has(terId)) {
            this._setProducerToRequired(terId);
            this._setConsumerToRequired(terId);
        }
    }

    private _setProducerToRequired(terId: string) {
        this.atacService.requiredProducersIds.add(terId);
        this.producersTooltip[terId] = 'Forcément Producteur';
        this.atacService.setClass(terId, 'producer', 'affectationMandatory');
    }

    private _setConsumerToRequired(terId: string) {
        this.atacService.excludedConsumersIds.delete(terId);
        this.atacService.potentialConsumersIds.add(terId);
        this.atacService.requiredConsumersIds.add(terId);
        this.consumersTooltip[terId] = 'Forcément Consommateur';
        this.atacService.setClass(terId, 'consumer', 'affectationMandatory');
    }

    private _setProducerToPotential(terId: string) {
        this.atacService.excludedProducersIds.delete(terId);
        this.atacService.potentialProducersIds.add(terId);
        this.producersTooltip[terId] = 'Producteur Potentiel';
        this.atacService.setClass(terId, 'producer', 'affectationPotential');
    }
    private _setConsumerToPotential(terId: string) {
        this.atacService.excludedConsumersIds.delete(terId);
        this.atacService.potentialConsumersIds.add(terId);
        this.consumersTooltip[terId] = 'Consommateur Potentiel';
        this.atacService.setClass(terId, 'consumer', 'affectationPotential');
    }

    private _setProducerToExcluded(terId: string) {
        this.atacService.requiredProducersIds.delete(terId);
        this.atacService.potentialProducersIds.delete(terId);
        this.atacService.excludedProducersIds.add(terId);
        this.producersTooltip[terId] = 'Forcément Non Producteur';
        this.atacService.setClass(terId, 'producer', 'affectationExcluded');
    }

    private _setConsumerToExcluded(terId: string) {
        this.atacService.requiredConsumersIds.delete(terId);
        this.atacService.potentialConsumersIds.delete(terId);
        this.atacService.excludedConsumersIds.add(terId);
        this.consumersTooltip[terId] = 'Forcément Non Consommateur';
        this.atacService.setClass(terId, 'consumer', 'affectationExcluded');
    }

    /* Règles métier Modification Potentiel PV
 Fonction qui autorise ou non la modification du Potentiel PV */
    disabledPPV(terId: string) {
        //Si le bâtiment est à Forcément Producteur  : modifiable (Rajouter la condition "AND pas d'import de courbe de charge" plus tard)
        return this.atacService.requiredProducersIds.has(terId);
    }

    setConsumer(terId: string) {
        // Si le consommateur est obligatoire, on le set à exclu
        if (this.atacService.requiredConsumersIds.has(terId)) {
            this._setConsumerToExcluded;
            // on set le producteur à Exclu
            this._setProducerToExcluded(terId);
        }
        // Si le consommateur est exclu, on le set à potentiel (le producteur ne doit pas être exclu)
        else if (this.atacService.excludedConsumersIds.has(terId)) {
            this._setConsumerToPotential(terId);
        }
        // Si le consommateur est potentiel, on le set à obligatoire
        else if (this.atacService.potentialConsumersIds.has(terId)) {
            this._setConsumerToRequired(terId);
        }
    }

    // Fonction qui permet de supprimer une ligne de bâtiment
    deleteBatiment(terId: string) {
        const selectedBuildings = this.selectedBuildings.filter((building) => {
            return building.id_ter !== terId;
        });
        this.atacService.setSelectedBuildings(selectedBuildings);
        this.atacService.requiredProducersIds.delete(terId);
        this.atacService.requiredConsumersIds.delete(terId);
        this.atacService.potentialProducersIds.delete(terId);
        this.atacService.potentialConsumersIds.delete(terId);
        this.atacService.excludedProducersIds.delete(terId);
        this.atacService.excludedConsumersIds.delete(terId);
        delete this.producersTooltip[terId];
        delete this.consumersTooltip[terId];
        delete this.atacService.PPVBuildings[terId];
    }

    get PPVBuildings() {
        return this.atacService.PPVBuildings;
    }

    get selectedBuildingIndex() {
        return this.atacService.selectedBuildingIndex;
    }
}
