import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Group } from 'src/app/models/User';
import { RestService } from 'src/app/services/RestService';

@Injectable({ providedIn: 'root' })
export class GroupResolver implements Resolve<Group> {
    constructor(private restService: RestService) {}

    resolve(route: ActivatedRouteSnapshot): Promise<Group> {
        const groupId = parseInt(route.paramMap.get('groupId'));
        return this.restService.getGroup(groupId);
    }
}
