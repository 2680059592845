<div id="atacResultsModal" tabindex="-1" aria-labelledby="atacResultsModal">
  <div class="modal-header">
    <div class="col-11 text-center">
      <h4 class="modal-title">AtAC : Résultats détaillés</h4>
    </div>
    <div class="col-1 text-end" *ngIf="group">
      <a style="cursor: pointer" (click)="addGroupementFav(group)">
        <i class='fa fa-2x fa-star-o' ngbTooltip="Ajouter à mes groupements favoris" placement="start"
          container="body"></i>
      </a>
      <a style="cursor: pointer" (click)="exportPdf()" *ngIf="!isExportingPdf">
        <i class='fa fa-2x fa-download' ngbTooltip="Export PDF" placement="start" container="body"></i>
      </a>
      <i *ngIf="isExportingPdf" class='fa fa-2x fa-spin fa-spinner' ngbTooltip="Export PDF en cours" placement="start"
        container="body"></i>
      <a style="cursor: pointer" (click)="activeModal.close()">
        <i class='fa fa-2x fa-times'></i>
      </a>
    </div>
  </div>

  <div class="modal-body">
    <ul class="nav nav-pills nav-fill w-100 d-flex justify-content-between " style="margin-bottom:15px">
      <li class="text-center {{activeTab === 'groupInfo' ? 'active' : ''}} nav-item">
        <a style="cursor: pointer" (click)="activeTab = 'groupInfo'" class="nav-link">Point de vue groupement</a>
      </li>
      <li class="text-center  {{activeTab === 'producersInfo' ? 'active' : ''}} nav-item">
        <a style="cursor: pointer" (click)="activeTab = 'producersInfo'" class="nav-link">Point de vue producteurs</a>
      </li>
      <li class="text-center  {{activeTab === 'consumersInfo' ? 'active' : ''}} nav-item">
        <a style="cursor: pointer" (click)="activeTab = 'consumersInfo'" class="nav-link">Point de vue consommateurs</a>
      </li>
    </ul>

    <!-- Point de vue groupement -->
    <div class="row tab-content">
      <div id="mapatac" class="col-5" style="height: 350px">
      </div>
      <div class="col-7" [hidden]="activeTab !== 'groupInfo'" id="groupInfo">
        <div class="row">
          <div class="col-6">
            <p class="text-center"><strong>Production PV</strong></p>
            <p class="small text-center"> Taux autoconsommation : {{ autoConsommation }}%</p>
            <stDoughnut [labels]="prodLabels" [data]="prodData" [colors]="prodColors"></stDoughnut>
          </div>
          <div class="col-6">
            <p class="text-center"><strong>Consommation</strong></p>
            <p class="small text-center"> Taux autoproduction : {{ autoProduction }}%</p>
            <stDoughnut [labels]="consoLabels" [data]="consoData" [colors]="consoColors"></stDoughnut>
          </div>
        </div>
        <div class="row" style="margin-top:20px" *ngIf="group">
          <p><strong>TRI</strong> : {{ (group.TRI *100) | round:2 }} %</p>
          <p><strong>Temps de retour</strong> : {{ group.Temps_de_Retour }} ans</p>
          <p><strong>Investissement</strong> : {{ group.investissement | round:0 }} €</p>

          <p><strong>Économie globale</strong> : {{ group.Economie_globale | round:0 }} €/an
          </p>
          <p><strong>Consommation annuelle du groupement</strong> : {{ group.Consommation_annuelle | round:0
            }} kWh</p>
          <p><strong>Potentiel de production annuel du groupement</strong> : {{
            group.Potentiel_production_annuelle | round:0 }} kWh</p>
          <p><strong>Clé de répartition ACC</strong> : répartition dynamique en fonction de la consommation</p>
        </div>
      </div>

      <!-- Point de vue producteurs -->
      <div class="col-7" [hidden]="activeTab !== 'producersInfo'" id="producersInfo">
        <table class="table table-hover">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th *ngFor="let producer of producers[index] | keyvalue">
                <a style="cursor: pointer" (click)="highlightElementOnMap(producer.key)">
                  <i class='fa fa-2x fa-television' [ngbTooltip]="producer.key" placement="top" container="body"></i>
                </a>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="col-6">Puissance installée (kWc)</th>
              <td class="col-1"><i class='fa fa-info-circle'
                  ngbTooltip="Puissance installée par toiture productrice au sein du groupement." placement="top"
                  container="body"></i></td>
              <td *ngFor="let producer of producers[index] | keyvalue">
                {{producer.value.puissance_pv | round:0}}
              </td>
            </tr>
            <tr>
              <th class="col-6">Coût investissement (€)</th>
              <td class="col-1"><i class='fa fa-info-circle'
                  ngbTooltip="Le coût d’investissement est calculé individuellement pour chacun des bâtiments producteurs.<br/>Un bâtiment présentant une installation de grande taille a donc tendance à présenter des coûts unitaire du kWc plus faibles."
                  placement="top" container="body"></i></td>
              <td *ngFor="let producer of producers[index] | keyvalue">
                {{producer.value.investissement | round:0 }}
              </td>
            </tr>
            <tr>
              <th class="col-6">Production annuelle (kWh)</th>
              <td class="col-1"><i class='fa fa-info-circle' ngbTooltip="Production annuelle par bâtiment producteur."
                  placement="top" container="body
               "></i></td>
              <td *ngFor="let producer of producers[index] | keyvalue">
                {{producer.value.potentiel_prod | round:0 }}
              </td>
            </tr>
            <tr>
              <th class="col-6">Potentiel d'autoconsommation indiv. (%)</th>
              <td class="col-1"><i class='fa fa-info-circle'
                  ngbTooltip="Le potentiel d’autoconsommation individuelle présenté ici correspond à la part maximale de la production du bâtiment qui pourrait être consommée par ce même bâtiment en dehors de toute opération d’autoconsommation collective."
                  placement="top" container="body"></i></td>
              <td *ngFor="let producer of producers[index] | keyvalue">
                {{(producer.value.Taux_autoconsommation_individuel*100) | round:0}}
              </td>
            </tr>
            <tr>
              <th class="col-6">Potentiel d'autoproduction indiv. (%)</th>
              <td class="col-1"><i class='fa fa-info-circle'
                  ngbTooltip="Le potentiel d’autoproduction individuelle présenté ici correspond à la part maximale de la consommation totale du bâtiment qui pourrait être couverte par la production PV de ce même bâtiment en dehors de toute opération d’autoconsommation collective. Le taux d’autoproduction effectif pour ce bâtiment dans le cadre de l’opération d’ACC peut être inférieur au potentiel du fait de l’application de la clé de répartition."
                  placement="top" container="body"></i></td>
              <td *ngFor="let producer of producers[index] | keyvalue">
                {{(producer.value.Taux_autoproduction_individuel*100) | round:2}}
              </td>
            </tr>
            <tr>
              <th class="col-6">Taux autoconsommation avec ACC. (%)</th>
              <td class="col-1"><i class='fa fa-info-circle'
                  ngbTooltip="Le taux d’autoconsommation présenté ici correspond à la part de la production du bâtiment consommée par l’ensemble des consommateurs du groupement dans le cadre de l’opération d’autoconsommation."
                  placement="top" container="body"></i></td>
              <td *ngFor="let producer of producers[index] | keyvalue">
                {{(producer.value.Taux_autoproduction_resp*100) | round:2}}
              </td>
            </tr>
            <tr>
              <th class="col-6">TRI avec Tarif actuel garanti (%)</th>
              <td class="col-1"><i class='fa fa-info-circle'
                  ngbTooltip="Le taux de Rentabilité interne présenté ici reflète la rentabilité du projet pour le producteur, avec une tarification de l'énergie au sein du groupement égale au tarif actuel sur le réseau garanti sur toute la durée de l'opération."
                  placement="top" container="body"></i></td>
              <td *ngFor="let producer of producers[index] | keyvalue">
                {{(producer.value.TRI_tarif_1*100) | round:2}}
              </td>
            </tr>
            <tr>
              <th class="col-6">TRI avec Tarif réseau minoré (%)</th>
              <td class="col-1"><i class='fa fa-info-circle'
                  ngbTooltip="Le Taux de Rentabilité interne présenté ici reflète la rentabilité du projet pour le producteur, avec une tarification de l'énergie au sein du groupement qui suit l'évolution du tarif réseau, minorée de 10%."
                  placement="top" container="body"></i></td>
              <td *ngFor="let producer of producers[index] | keyvalue">
                {{(producer.value.TRI_tarif_2*100) | round:2}}
              </td>
            </tr>
            <tr>
              <th class="col-6">TRI avec revente en totalité (%)</th>
              <td class="col-1"><i class='fa fa-info-circle'
                  ngbTooltip="Taux de Rentabilité Interne dans le cas d’une revente de la totalité de la production au tarif d’achat fixé par la CRE."
                  placement="top" container="body"></i></td>
              <td *ngFor="let producer of producers[index] | keyvalue">
                {{(producer.value.TRI_revente_totalite*100) | round:2}}
              </td>
            </tr>
            <tr>
              <th class="col-6">TRI avec autoconsommation individuelle revente surplus (%)</th>
              <td class="col-1"><i class='fa fa-info-circle'
                  ngbTooltip="Taux de Rentabilité Interne dans le cas d’une autoconsommation individuelle avec revente du surplus au tarif d’achat spécifique autoconsommation fixé par la CRE."
                  placement="top" container="body"></i></td>
              <td *ngFor="let producer of producers[index] | keyvalue">
                {{(producer.value.TRI_autoconsommation_individuelle*100) | round:2}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Point de vue consommateurs -->
      <div class="col-7" [hidden]="activeTab !== 'consumersInfo'" id="consumersInfo">
        <div class="row">
          <table class="table table-hover">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th *ngFor="let consumer of consumers[index] | keyvalue">
                  <a style="cursor: pointer" (click)="highlightElementOnMap(consumer.key)">
                    <i class='fa fa-2x fa-building-o' [ngbTooltip]="consumer.key" placement='top' container="body"></i>
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="col-3">Nombre de logements du parc privé</th>
                <td class="col-1"><i class='fa fa-info-circle'
                    ngbTooltip="La maille d’analyse de l’outil <b>AtAC</b> est le bâtiment. L’estimation du nombre de logements dans le bâtiment permet d'une part d'estimer la facture globale du bâtiment, et d'autre part de rendre compte de la difficulté potentielle d’inclusion du bâtiment à un groupement d’autoconsommation collective."
                    placement="top" container="body"></i></td>
                <td *ngFor="let consumer of consumers[index] | keyvalue">
                  {{consumer.value.n_log_prive | round:0 }}
                </td>
              </tr>
              <tr>
                <th class="col-3">Nombre de logements sociaux</th>
                <td class="col-1"><i class='fa fa-info-circle'
                    ngbTooltip="La maille d’analyse de l’outil <b>AtAC</b> est le bâtiment. L’estimation du nombre de logements dans le bâtiment permet d'une part d'estimer la facture globale du bâtiment, et d'autre part de rendre compte de la difficulté potentielle d’inclusion du bâtiment à un groupement d’autoconsommation collective."
                    placement="top" container="body"></i></td>
                <td *ngFor="let consumer of consumers[index] | keyvalue">
                  {{consumer.value.n_log_hlm | round:0 }}
                </td>
              </tr>
              <tr>
                <th class="col-3">Nombre de locaux tertiaires publics</th>
                <td class="col-1"><i class='fa fa-info-circle'
                    ngbTooltip="La maille d’analyse de l’outil <b>AtAC</b> est le bâtiment. L’estimation du nombre de locaux tertiaires dans le bâtiment permet d'une part d'estimer la facture globale du bâtiment, et d'autre part de rendre compte de la difficulté potentielle d’inclusion du bâtiment à un groupement d’autoconsommation collective."
                    placement="top" container="body"></i></td>
                <td *ngFor="let consumer of consumers[index] | keyvalue">
                  {{consumer.value.n_loc_pub | round:0 }}
                </td>
              </tr>
              <tr>
                <th class="col-3">Nombre de locaux tertiaires privés</th>
                <td class="col-1"><i class='fa fa-info-circle'
                    ngbTooltip="La maille d’analyse de l’outil <b>AtAC</b> est le bâtiment. L’estimation du nombre de locaux tertiaires dans le bâtiment permet d'une part d'estimer la facture globale du bâtiment, et d'autre part de rendre compte de la difficulté potentielle d’inclusion du bâtiment à un groupement d’autoconsommation collective."
                    placement="top" container="body"></i></td>
                <td *ngFor="let consumer of consumers[index] | keyvalue">
                  {{consumer.value.n_loc_prive | round:0 }}
                </td>
              </tr>
              <tr>
                <th class="col-3">Nombre de locaux tertiaires indeterminés</th>
                <td class="col-1"><i class='fa fa-info-circle'
                    ngbTooltip="La maille d’analyse de l’outil <b>AtAC</b> est le bâtiment. L’estimation du nombre de locaux tertiaires dans le bâtiment permet d'une part d'estimer la facture globale du bâtiment, et d'autre part de rendre compte de la difficulté potentielle d’inclusion du bâtiment à un groupement d’autoconsommation collective."
                    placement="top" container="body"></i></td>
                <td *ngFor="let consumer of consumers[index] | keyvalue">
                  {{consumer.value.n_loc_indeter | round:0 }}
                </td>
              </tr>
              <tr>
                <th class="col-3">Consommation résidentielle du parc privé (kWh)</th>
                <td class="col-1"><i class='fa fa-info-circle'
                    ngbTooltip="Consommation annuelle des logements du parc privé par bâtiment." placement="top"
                    container="body
                 "></i></td>
                <td *ngFor="let consumer of consumers[index] | keyvalue">
                  {{consumer.value.conso_residentiel_prive | round:0 }}</td>
              </tr>
              <tr>
                <th class="col-3">Consommation résidentielle du parc social (kWh)</th>
                <td class="col-1"><i class='fa fa-info-circle'
                    ngbTooltip="Consommation annuelle des logements du parc social par bâtiment." placement="top"
                    container="body"></i></td>
                <td *ngFor="let consumer of consumers[index] | keyvalue">
                  {{consumer.value.conso_residentiel_hlm | round:0 }}
                </td>
              </tr>
              <tr>
                <th class="col-3">Consommation tertiaire secteur public (kWh)</th>
                <td class="col-1"><i class='fa fa-info-circle'
                    ngbTooltip="Consommation annuelle des locaux tertiaires par bâtiment." placement="top" container="body
                 "></i></td>
                <td *ngFor="let consumer of consumers[index] | keyvalue">
                  {{consumer.value.conso_ter_pub | round:0 }}
                </td>
              </tr>
              <tr>
                <th class="col-3">Consommation tertiaire secteur privé (kWh)</th>
                <td class="col-1"><i class='fa fa-info-circle'
                    ngbTooltip="Consommation annuelle des locaux tertiaires par bâtiment." placement="top" container="body
                 "></i></td>
                <td *ngFor="let consumer of consumers[index] | keyvalue">
                  {{consumer.value.conso_ter_prive | round:0 }}</td>
              </tr>
              <tr>
                <th class="col-3">Consommation tertiaire secteur indéterminé (kWh)</th>
                <td class="col-1"><i class='fa fa-info-circle'
                    ngbTooltip="Consommation annuelle des locaux tertiaires par bâtiment." placement="top"
                    container="body"></i></td>
                <td *ngFor="let consumer of consumers[index] | keyvalue">
                  {{consumer.value.conso_ter_indeter | round:0 }}</td>
              </tr>
              <tr>
                <th class="col-3">Facture sans autoconsommation collective (€)</th>
                <td class="col-1"><i class='fa fa-info-circle'
                    ngbTooltip="Facture d’électricité cumulée sur la période d’amortissement sans participation à une opération d’autoconsommation et en prenant en compte le scénario d’évolution des tarifs de l’électricité."
                    placement="top" container="body"></i></td>
                <td *ngFor="let consumer of consumers[index] | keyvalue">
                  {{consumer.value.Facture_totale | round:0 }}</td>
              </tr>
              <tr>
                <th class="col-3">Économie avec tarif actuel garanti (€)</th>
                <td class="col-1"><i class='fa fa-info-circle'
                    ngbTooltip="Economies cumulées sur la période d'ammortissement réalisées par le consommateur avec une tarification de l'énergie au sein du groupement égale au tarif actuel sur le réseau garanti sur toute la durée de l'opération."
                    placement="top" container="body"></i></td>
                <td *ngFor="let consumer of consumers[index] | keyvalue">
                  {{consumer.value.Economie_cumulee_tarif_1 | round:0 }}</td>
              </tr>
              <tr>
                <th class="col-3">Économie avec tarif 2 (€)</th>
                <td class="col-1"><i class='fa fa-info-circle'
                    ngbTooltip="Economies cumulées sur la période d'ammortissement réalisées par le consommateur avec une tarification de l'énergie au sein du groupement qui suit l'évolution du tarif réseau, minorée de 10%."
                    placement="top" container="body"></i></td>
                <td *ngFor="let consumer of consumers[index] | keyvalue">
                  {{consumer.value.Economie_cumulee_tarif_2 | round:0 }}</td>
              </tr>
              <tr>
                <th class="col-3">Taux autoproduction (%)</th>
                <td class="col-1"><i class='fa fa-info-circle'
                    ngbTooltip="Le taux d’autoproduction présenté ici correspond à la part de la consommation totale du bâtiment couverte par la production PV issue de l’opération collective. "
                    placement="top" container="body"></i></td>
                <td *ngFor="let consumer of consumers[index] | keyvalue">
                  {{(consumer.value.Taux_autoproduction*100) | round:2}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>