import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { DataService } from './DataService';
import { EventService } from './event.service';
import { ModuleService } from 'src/app/services/module.service';
import { PlotIndicatorService } from './plotIndicator/plot-indicator.service';
import { TerService } from './TerService';
import { RestService } from './RestService';

import { AtacBuilding, AtacGroup, SimulationParameters } from '../models/AtacTypes';

('use strict');

@Injectable({
    providedIn: 'root',
})
export class AtacService {
    groups: AtacGroup[] = [];
    requiredProducersIds = new Set<string>();
    requiredConsumersIds = new Set<string>();
    excludedProducersIds = new Set<string>();
    excludedConsumersIds = new Set<string>();
    potentialProducersIds = new Set<string>();
    potentialConsumersIds = new Set<string>();
    PPVBuildings: any = {};
    private tab$ = new BehaviorSubject<string>('parameter');
    tabActive$ = this.tab$.asObservable();
    private _consumers$ = new BehaviorSubject<any[]>([]);
    consumers$ = this._consumers$.asObservable();
    private _producers$ = new BehaviorSubject<any[]>([]);
    producers$ = this._producers$.asObservable();
    private _selectedBuildings$ = new BehaviorSubject<AtacBuilding[]>([]);
    selectedBuildings$ = this._selectedBuildings$.asObservable();
    isSuccess: boolean;
    selectedBuildingIndex: number;

    constructor(
        @Inject(RestService) private restService: RestService,
        @Inject(TerService) private terService: TerService,
        @Inject(DataService) private dataService: DataService,
        @Inject(EventService) private eventService: EventService,
        @Inject(PlotIndicatorService) private plotIndicatorService: PlotIndicatorService,
        @Inject(ModuleService) private moduleService: ModuleService,
    ) {
        this.eventService.clickOnAtacElement.subscribe(async (data: any) => {
            const indicatorId = data.indicatorId;

            const indicatorPlot = this.plotIndicatorService.plotedIndicators[indicatorId];
            const plugin = indicatorPlot.plugin;
            if (plugin !== 'atac') return;

            const selectedBuilding: any = { ...data };
            const buildingId = selectedBuilding.id_ter;
            const selectedBuildings = this._selectedBuildings$.getValue();

            const isAlreadySelected = selectedBuildings.some(
                (building: any) => building.id_ter === buildingId,
            );
            if (isAlreadySelected) return;

            selectedBuilding.consumerClass = 'affectationPotential';
            selectedBuilding.producerClass = 'affectationPotential';
            selectedBuildings.push(selectedBuilding);
            this._selectedBuildings$.next(selectedBuildings);

            //On l'ajoute automatiquement aux producteurs / consommateurs potentiels
            this.potentialProducersIds.add(buildingId);
            this.potentialConsumersIds.add(buildingId);

            this.setTab('import');

            //On ouvre la page donnée
            try {
                const data = await this.getInfoOnTer(buildingId);
                this.PPVBuildings[buildingId] = parseFloat(data.PPV);
                Object.assign(selectedBuilding, data);

                this.targetElementOnMap(buildingId, selectedBuildings.length - 1);
                this.moduleService.openModule('atac');
            } catch (error: any) {
                console.error('Error handle clickOnAtacElement', error);
            }
        });
    }

    setTab(tab: string) {
        this.tab$.next(tab);
    }

    setProducers(producers: any[]) {
        this._producers$.next(producers);
    }

    setConsumers(consumers: any[]) {
        this._consumers$.next(consumers);
    }

    setSelectedBuildings(selectedBuilding: AtacBuilding[]) {
        this._selectedBuildings$.next(selectedBuilding);
    }

    setClass(terId: string, type: string, className: string) {
        const selectedBuildings = this._selectedBuildings$.getValue();
        const building = selectedBuildings.find((building) => building.id_ter === terId);
        const field = `${type}Class`;
        building[field] = className;
    }

    async launchSimulation(parameters: SimulationParameters[], selectedCase) {
        const indicatorPlot: any = Object.values(this.plotIndicatorService.plotedIndicators).find(
            (indicatorPlot: any) => indicatorPlot.plugin === 'atac',
        );
        indicatorPlot.geojson.features.forEach((feature) => (feature.properties.value = 0));
        this.plotIndicatorService.updateParameters(indicatorPlot);
        const toSend = {
            selectedCas: selectedCase,
            scale_type_ter_int: this.terService.territoryScale.typeId,
            scale_id_ter: this.terService.territories.map((t) => `'${t.id}'`).join(', '),
            producteurs_potentiel_id: Array.from(this.potentialProducersIds),
            consommateurs_potentiel_id: Array.from(this.potentialConsumersIds),
            producteurs_obligatoire_id: Array.from(this.requiredProducersIds),
            consommateurs_obligatoire_id: Array.from(this.requiredConsumersIds),
            producteurs_exclu_id: Array.from(this.excludedProducersIds),
            consommateurs_exclu_id: Array.from(this.excludedConsumersIds),
            batimentsPPV: this.PPVBuildings,
        };

        parameters.forEach((parameter) => {
            if (parameter.value) {
                toSend[parameter.id] = parameter.value;
            } else if (parameter.options) {
                parameter.options.forEach((option) => {
                    toSend[option.id] = option.value;
                });
            } else if (parameter.item) {
                parameter.item.forEach((element) => {
                    toSend[element.id] = element.value;
                });
            }
        });
        try {
            const data = await this.sendParamsToAPI(toSend);
            return data;
        } catch (error) {
            throw error;
        }
    }

    //Display groupement on map
    showGroupementOnMap(selectedGroup) {
        //TODO : revoir cette partie si l'on ajoute des indicateurs autre que "Composition de l'assemblage..."
        const selectedIndex = selectedGroup.key;
        const indicatorPlot: any = Object.values(this.plotIndicatorService.plotedIndicators).find(
            (indicator: any) => indicator.plugin === 'atac',
        );
        const producers = this._producers$.getValue();
        const consumers = this._consumers$.getValue();

        // Consommateur : id_class=1
        // Conso + prod : id_class=2
        // Non inclus : id_class=0
        indicatorPlot.geojson.features.map((bati) => {
            const id = bati.properties.id_ter;
            const isProducer = Object.keys(producers[selectedIndex]).indexOf(id) >= 0;
            const isConsumer = Object.keys(producers[selectedIndex]).indexOf(id) >= 0;
            if (isProducer) {
                bati.properties.value = 2;
                producers[selectedIndex][id].geometry = bati.geometry;
            } else if (isConsumer) {
                bati.properties.value = 1;
                consumers[selectedIndex][id].geometry = bati.geometry;
            } else {
                bati.properties.value = 0;
            }
            return bati;
        });
        this.plotIndicatorService.updateParameters(indicatorPlot);
        this.plotIndicatorService.refreshLayer(indicatorPlot);
    }

    sendParamsToAPI(data: any) {
        return this.restService.getAtacValues(data);
    }

    getInfoOnTer(terId: string) {
        return this.restService.getAtacInfoOnMap(terId);
    }

    uploadCourbe(terId: string, fileContent: Array<Array<string>>, cdcType: string) {
        return this.restService.uploadCourbeAtac(terId, fileContent, cdcType);
    }

    deleteCourbe(terId: string, cdcType: string) {
        return this.restService.deleteCourbeAtac(terId, cdcType);
    }

    targetElementOnMap(buildingId: string, index: number) {
        const indicatorPlot: any = Object.values(this.plotIndicatorService.plotedIndicators).find(
            (indicatorPlot: any) => indicatorPlot.plugin === 'atac',
        );

        indicatorPlot.targetElement(buildingId);
        this.selectedBuildingIndex = index;
    }
}
