<stHeader data-is-admin-page="true"></stHeader>
<stNavbar page="groups-manage"></stNavbar>

<div class="container py-4">
  <h2 class="text-st-primary mb-4">
    <span class="fa fa-stack me-3">
      <i class="fa fa-circle-thin fa-stack-2x"></i>
      <i class="fa fa-users fa-stack-1x"></i>
    </span>
    Gestion des groupes utilisateur
  </h2>

  <div class="row mb-4 d-flex align-items-center">
    <div class="col-lg mb-5">
      <div class="text-justify">
        <p>On rappelera qu'un groupe défini pour leur utilisateurs : </p>
        <ul>
          <li>Les droits d'accès aux différents indicateurs.</li>
          <li>Le périmètre géographique d'utilisation de l'application ainsi que la granularité des
            informations accessibles.</li>
        </ul>
        <div style="padding-top: 10px">Le tableau ci-dessous vous permet de gérer les groupes sur lesquels vous
          disposez des droits d'administration.</div>
      </div>
    </div>


    <div class="col-lg-4">
      <div class="card">
        <div class="card-body p-4">
          <h4 class="modal-title">Dashboard administation des groupes</h4>
          <div class="mb-3" *ngIf="groups.length">
            <div class="p-1">
              <b>Nombre de groupes gérés</b> : {{ groups.length }} groupes
            </div>
            <div class="p-1">
              <b>Nombre total d'utilisateurs</b> : {{ totalUserCount }} utilisateurs
            </div>
            <div class="p-1">
              <b>Nombre d'utilisateurs moyen par groupe</b> : {{ meanUserCount }} utilisateurs
            </div>
          </div>
          <stButton btnLabel="Créer un groupe" btnClass="btn-st-primary" (click)="goToNewGroup()"></stButton>
        </div>
      </div>
    </div>
  </div>


  <form *ngIf="groups.length">
    <div class="row mb-2">
      <div class="col-sm-auto">
        <div class="row">
          <div class="col px-0 position-relative">
            <input id="table-complete-search" type="text" class="form-control px-5" name="searchTerm"
              [(ngModel)]="stTableService.searchTerm" placeholder="Rechercher" />
            <i class="fa fa-search position-absolute top-50 translate-middle-y"></i>
          </div>
        </div>
      </div>
      <span class="col col-form-label" *ngIf="stTableService.loading$ | async">Chargement...</span>
    </div>

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col" sortable="id" (sort)="onSort($event)" class="id-col">ID</th>
          <th scope="col" sortable="name" (sort)="onSort($event)" class="name-col">Nom du groupe</th>
          <th scope="col" class="territory-col">Territoires</th>
          <th scope="col" class="granularity-col">Granularité</th>
          <th scope="col" class="numberUsers-col">Nombre d'utilisateurs</th>
          <th scope="col" sortable="endOfRightsDateTime" (sort)="onSort($event)" class="endOfRights-col">
            Fin des droits
          </th>
          <th scope="col" sortable="referent" (sort)="onSort($event)" class="referent-col">Référent interne</th>
          <th scope="col" class="handling-col">Gestion</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let group of groups$ | async">
          <td scope="row">
            <ngb-highlight [result]="group.id" [term]="stTableService.searchTerm">
            </ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="group.name" [term]="stTableService.searchTerm">
            </ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="setTerritoryLabel(group.territory.territories)" [term]="stTableService.searchTerm">
            </ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="group.territory.granularity.lib_granularity_long"
              [term]="stTableService.searchTerm">
            </ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="group.users.length"
              [ngClass]="{'text-st-danger': group.users.length == 0 || !group.users}"
              [term]="stTableService.searchTerm">
            </ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="group.endOfRightsDate" [term]="stTableService.searchTerm">
            </ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="group.group.referent" [term]="stTableService.searchTerm">
            </ngb-highlight>
          </td>

          <td>
            <ng-template #tooltipContent>
              <div>Voir / modifier</div>
            </ng-template>
            <span (click)="goToUpdateGroup(group)" class="fa fa-pencil-square-o cursor-pointer" style="padding:5px"
              [ngbTooltip]="tooltipContent"></span>
            <ng-template #tooltipContentDel>
              <div>Supprimer</div>
            </ng-template>
            <span class="fa fa-trash-o cursor-pointer" style="padding:5px" (click)="deleteGroup(group)"
              [ngbTooltip]="tooltipContentDel"></span>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="d-flex justify-content-between p-2">
      <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="stTableService.page"
        [pageSize]="stTableService.pageSize" [maxSize]="5" [rotate]="true" [boundaryLinks]="true">
      </ngb-pagination>

      <select class="form-select selectPageSize" name="pageSize" [(ngModel)]="stTableService.pageSize">
        <option [ngValue]="value" *ngFor="let value of stTableService.pageSizeOptions">{{ value }}</option>
      </select>
    </div>
  </form>
</div>