import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReversePipe } from 'ngx-pipes';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { Indicator } from 'src/app/models/Indicator';
import { SubTheme, Theme } from 'src/app/models/Theme';
import { TerritoryLabel, TerritoryScale, Territory } from 'src/app/models/TerritoryTypes';

import { DataService } from 'src/app/services/DataService';
import { RestService } from 'src/app/services/RestService';
import { TerService } from 'src/app/services/TerService';

import { ConfirmationModalComponent } from '../../main/modals/confirmation/confirmation-modal.component';

('use strict');

@Component({
    selector: 'updateGroup',
    templateUrl: './group-update.template.html',
    providers: [ReversePipe],
})
export class UpdateGroupComponent implements OnInit {
    public groupId = null; //recup via param router
    public groupTmp: {
        logo: any;
        endingValidityDate: any;
        formatedDate: string;
        nom: string;
        name_site: string;
    };
    public groupT: { logo: any };
    public group: {
        indicators: any;
        logo: any;
        group: any;
        id: any;
        territory: any;
    };
    public selectedTerritoriesTmp = [];
    public selectedTerritories = [];
    public selectedTerritoryScaleTmp: { id: any; id_terr: any; ordre: any; libelle_short: any };
    public selectedTerritoryScale: { id: any; id_terr: any; ordre: any; libelle_short: any };
    // public territoryToAdd: { id_ter: any; lib_ter: any };
    public selectedGranularity: { libelle_short: any };
    public selectedGranularityTmp: { libelle_short: any };
    public territoryLabel = {};
    public indicatorsByTheme: Theme[];
    public selectedIndicator = [];
    public territories = [];
    public granularityOptions = [];
    public _nomFG = null;

    public generalInfo = {
        isEditing: false,
        isSaving: false,
    };

    public territoryInfo = {
        isEditing: false,
        isSaving: false,
    };

    public indicatorRight = {
        isEditing: false,
        isSaving: false,
    };

    constructor(
        private activatedRoute: ActivatedRoute,
        private notification: ToastrService,
        private ref: ChangeDetectorRef,
        private router: Router,
        private modalService: NgbModal,
        @Inject(DataService) private dataService: DataService,
        @Inject(RestService) private restService: RestService,
        @Inject(TerService) public terService: TerService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.activatedRoute.data.subscribe(({ group }) => {
            this.group = group;
            this.groupId = group.id;
        });
        this.initialization();
    }

    async initialization() {
        this.cleanIndicators();

        const territories = this.group.territory.territories;
        this.selectedTerritories = [...territories];
        this.selectedTerritoriesTmp = [...territories];
        this.territoryLabel = territories.map((t) => t.label).join(', ');

        this.groupTmp = { ...this.group.group };
        this.groupTmp.endingValidityDate = new Date(this.group.group.endingValidityDate);
        this.convertDateToDateString();

        try {
            await this.terService.initTerritory(false, false);
            this.initTerritoryRight();
            const type = this.group.territory.territoryScale.type;
            await this.updateTerritories(type);
            await this.initIndicatorRight();
        } catch (error) {
            console.error('Error initialization', error);
        }
    }

    initTerritoryRight() {
        const territoryScale = this.setTerritoryScale();
        this.selectedTerritoryScale = territoryScale;
        this.selectedTerritoryScaleTmp = territoryScale;

        const granularity = this.setSelectedGranularity();
        this.selectedGranularity = granularity;
        this.selectedGranularityTmp = granularity;

        this.updateAvailableGranularities();
    }

    setTerritoryScale() {
        return this.terService.territoryScales.find(
            (territoryScale: TerritoryScale) =>
                !!territoryScale.geom_poly &&
                territoryScale.id_terr == this.group.territory.territoryScale.type,
        );
    }

    setSelectedGranularity() {
        return this.terService.territoryScales.find(
            (territoryScale: TerritoryScale) =>
                territoryScale.id_terr == this.group.territory.granularity.type,
        );
    }

    updateAvailableGranularities() {
        if (this.selectedTerritoryScaleTmp) {
            this.granularityOptions = this.terService.territoryScales.filter(
                (territoryScale) =>
                    territoryScale.ordre < this.selectedTerritoryScaleTmp.ordre &&
                    territoryScale.geom_poly,
            );
        } else {
            this.granularityOptions = this.terService.territoryScales.filter(
                (territoryScale) => territoryScale.geom_poly,
            );
        }

        if (!this.granularityOptions.includes(this.selectedGranularityTmp)) {
            this.selectedGranularityTmp = this.granularityOptions[0];
        }
    }

    cleanIndicators = () => {
        this.selectedIndicator = this.group.indicators.filter((indicator) => !!indicator);
    };

    convertDateToDateString() {
        const date = this.groupTmp.endingValidityDate;
        const year = date.getFullYear();
        const monthNumber = date.getMonth() + 1;
        const month = monthNumber < 10 ? `0${monthNumber + 1}` : monthNumber + 1;

        const day = date.getDate();
        this.groupTmp.formatedDate = `${year}-${month}-${day}`;
    }

    convertDateStringToDate() {
        this.groupTmp.endingValidityDate = new Date(this.groupTmp.formatedDate);
    }

    getImagebase64 = (event: any) => {
        const f = event.target.files[0];
        const r = new FileReader();

        r.onloadend = (e) => {
            this.groupTmp.logo = e.target.result;
            this.ref.detectChanges();
        };

        r.readAsDataURL(f);
    };

    cancelGeneralInfo() {
        this.generalInfo.isEditing = false;
        this.generalInfo.isSaving = false;
        this.groupTmp = { ...this.group.group };
    }

    async validateGeneralInfo() {
        this.generalInfo.isSaving = true;

        this.convertDateStringToDate();
        const groupId = this.group.id;
        const data = {
            group: this.groupTmp,
        };
        try {
            await this.restService.updateGroup(groupId, data);
            this.generalInfo.isEditing = false;
            this.generalInfo.isSaving = false;
            this.group.group = this.groupTmp;
            this.notification.success(`Le groupe "${this.group.group.nom}" a été mis à jour.`);
        } catch (error) {
            console.error('Error validateGeneralInfo', error);
            this.notification.error('Une erreur est survenue, veuillez rééssayer.');
            this.generalInfo.isSaving = false;
        }
    }

    selectTerritoryScale() {
        if (this.selectedTerritoryScaleTmp.id != this.selectedTerritoryScale.id) {
            this.selectedTerritoriesTmp = [];
        } else {
            this.selectedTerritoriesTmp = { ...this.selectedTerritories };
        }

        this.updateTerritories(this.selectedTerritoryScaleTmp.id_terr);
        this.updateAvailableGranularities();
    }

    removeTerritory = (index: number) => {
        this.selectedTerritoriesTmp.splice(index, 1);
    };

    addTerritory(territoryToAdd: TerritoryLabel) {
        const alreadyAddedTerritory = this.selectedTerritoriesTmp.find(
            (territory: any) => territory.id == territoryToAdd.id,
        );

        if (!alreadyAddedTerritory) {
            const id = territoryToAdd.id;
            const label = territoryToAdd.label;

            const newSelectedTerritory = {
                id: id,
                label: label,
                labelId: `${label} (${id})`,
            };
            this.selectedTerritoriesTmp.push(newSelectedTerritory);
        }
    }

    cancelTerritory() {
        this.territoryInfo.isEditing = false;
        this.territoryInfo.isSaving = false;

        this.selectedTerritoriesTmp = [...this.selectedTerritories];
        this.selectedTerritoryScaleTmp = { ...this.selectedTerritoryScale };
        this.selectedGranularityTmp = { ...this.selectedGranularity };

        this.updateTerritories(this.selectedTerritoryScaleTmp.id_terr);
    }

    async validateTerritory() {
        this.territoryInfo.isSaving = true;

        const territoryScale = { ...this.selectedTerritoryScaleTmp };
        const territories = [...this.selectedTerritoriesTmp];
        const granularity = { ...this.selectedGranularityTmp };

        const data = {
            territory: {
                territoryScale: territoryScale,
                territories: territories,
                granularity: granularity,
            },
        };
        try {
            await this.restService.updateGroup(this.groupId, data);
            this.territoryLabel = territories.map((t) => t.label).join(', ');

            this.selectedTerritoryScale = { ...territoryScale };
            this.selectedTerritories = [...territories];
            this.selectedGranularity = { ...granularity };

            this.territoryInfo.isEditing = false;
            this.territoryInfo.isSaving = false;
            this.notification.success('Le groupe a été mis à jour.');
        } catch (error) {
            console.error('validateTerritory', error);
            this.notification.error('Une erreur est survenue, veuillez rééssayer.');
            this.territoryInfo.isSaving = false;
        }
    }

    selectAllIndicatorFromTheme = (theme: Theme) => {
        if (this.indicatorRight.isEditing) {
            theme.ss_theme.forEach((subTheme: SubTheme) =>
                subTheme.indicators.forEach(
                    (indicator: Indicator) => (indicator.active = theme.full < 2),
                ),
            );
            this.updateSelectionState();
        }
    };

    selectAllIndicatorFromSubTheme = (subTheme: SubTheme) => {
        if (this.indicatorRight.isEditing) {
            subTheme.indicators.forEach(
                (indicator: Indicator) => (indicator.active = subTheme.full < 2),
            );
            this.updateSelectionState();
        }
    };

    cancelIndicator() {
        this.indicatorRight.isEditing = false;
        this.indicatorRight.isSaving = false;
        this.updateIndicatorState();
        this.updateSelectionState();
    }

    async validateIndicator() {
        this.indicatorRight.isSaving = true;

        this.selectedIndicator = [];
        this.indicatorsByTheme.forEach((theme: Theme) =>
            theme.ss_theme.forEach((subTheme: SubTheme) => {
                subTheme.indicators
                    .filter((indicator: Indicator) => indicator.active)
                    .forEach((indicator: Indicator) =>
                        this.selectedIndicator.push(indicator.id_indicateur),
                    );
            }),
        );
        this.updateSelectionState();

        const data = {
            indicators: this.selectedIndicator,
        };
        try {
            await this.restService.updateGroup(this.groupId, data);
            this.indicatorRight.isEditing = false;
            this.indicatorRight.isSaving = false;
            this.updateIndicatorState();
            this.updateSelectionState();
            this.notification.success('Le groupe a été mis à jour.');
        } catch (error) {
            console.error('Error validateIndicator', error);
            this.notification.error('Une erreur est survenue, veuillez rééssayer.');
            this.indicatorRight.isSaving = false;
        }
    }

    assignToAllUsers() {
        const body = [
            `Vous êtes sur le point d'affecter à tous les utilisateurs du groupe
            <span class="text-st-active">
                ${this.group.group.nom}
            </span>
            les indicateurs sélectionnés.
            `,
            'Voulez-vous continuer ?',
        ].join('<br/>');

        const modalRef = this.modalService.open(ConfirmationModalComponent);
        modalRef.componentInstance.body = body;
        modalRef.result.then(
            (result) => {
                this.confirmAssignToAllUsers();
            },
            () => {},
        );
    }

    async confirmAssignToAllUsers() {
        try {
            await this.restService.assignIndicatorsToGroupUsers(this.groupId);
            this.notification.success(
                "L'accès aux indicateurs sélectionnés ont été attribués à tous les utilisateurs du groupe.",
            );
        } catch (error) {
            console.error('Error confirmAssignToAllUsers', error);
            this.notification.error('Une erreur est survenue, veuillez rééssayer.');
        }
    }

    redirectToAdminGroups() {
        this.router.navigate(['administration/groups/manage/']);
    }

    async updateTerritories(type: string) {
        try {
            this.territories = await this.terService.getTerritoryLabelByYear(
                this.terService.geoYear,
                type,
            );
        } catch (error) {
            console.error('Error updateTerritories', error);
            throw error;
        }
    }

    async initIndicatorRight() {
        try {
            this.indicatorsByTheme = await this.dataService.getIndicatorLibraryByTheme();

            this.indicatorsByTheme.forEach((theme: Theme) => {
                theme.open = false;
                theme.ss_theme.forEach((subTheme: SubTheme) => {
                    subTheme.open = false;
                    subTheme.indicators.forEach(
                        (indicator: Indicator) => (indicator.active = false),
                    );
                });
            });

            this.updateIndicatorState();
            this.updateSelectionState();
        } catch (error) {
            console.error('Error initIndicatorRight', error);
            throw error;
        }
    }

    updateIndicatorState() {
        this.indicatorsByTheme.forEach((theme: Theme) =>
            theme.ss_theme.forEach((subTheme: SubTheme) => {
                subTheme.indicators.forEach((indicator: Indicator) => (indicator.active = false));
                subTheme.indicators
                    .filter((indicator) => this.selectedIndicator.includes(indicator.id_indicateur))
                    .forEach((indicator) => (indicator.active = true));
            }),
        );
    }

    updateSelectionState() {
        let p = 0;
        let d = 0;

        this.indicatorsByTheme.forEach((theme: Theme) => {
            d = 0;
            theme.ss_theme.forEach((subTheme: SubTheme) => {
                p = 0;
                p += subTheme.indicators.filter((indicator: Indicator) => indicator.active).length;

                if (p == subTheme.indicators.length) {
                    subTheme.full = 2;
                    d += 1;
                } else if (p > 0) {
                    subTheme.full = 1;
                    d += 0.5;
                } else {
                    subTheme.full = 0;
                }
            });

            if (d == theme.ss_theme.length) {
                theme.full = 2;
            } else if (d > 0) {
                theme.full = 1;
            } else {
                theme.full = 0;
            }
        });
    }
}
