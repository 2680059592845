'use strict';

import { Component, Inject, Input, OnInit } from '@angular/core';

import { RestService } from 'src/app/services/RestService';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'forgotPasswordModal',
    templateUrl: './forgot-password.template.html',
})
export class ForgotPasswordModal implements OnInit {
    @Input() login: string;

    public isValidated = false;

    constructor(
        public activeModal: NgbActiveModal,
        @Inject(RestService) private restService: RestService,
    ) {}

    ngOnInit(): void {
        this.isValidated = false;
    }

    validate() {
        const data = { login: this.login };
        this.restService.resetPassword(data);
        this.isValidated = true;
    }

    close() {
        this.isValidated = false;
        this.activeModal.close();
    }

    dismiss() {
        this.isValidated = false;
        this.activeModal.dismiss();
    }
}
