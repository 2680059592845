'use strict';
import * as L from 'leaflet';

import { MapService } from '../services/map.service';
import { PlotFactory } from './PlotFactory';

export class PlotLineFactory extends PlotFactory {
    private dashArray: number[];
    public dashArray_list = [[1], [5, 5], [5, 10], [10, 5], [10, 5, 10], [5, 10, 5]];

    constructor(indicatorId: number, mapService: MapService) {
        super(indicatorId, mapService);
        this.form = 'line';
        this.default_radius_weight = 2;
        this.dashArray = [1];
    }

    getStyle(feature: GeoJSON.Feature) {
        return {
            weight: this.getWeight(feature.properties.value),
            color: this.getColor(feature.properties.value),
            opacity: this.getFillOpacity(feature.properties.value),
            // fillColor: this.getColor(feature.properties.value),
            // fillOpacity: this.getFillOpacity(feature.properties.value),
            dashArray: this.getDashArray(feature.properties.value),
        };
    }

    getDashArray(d) {
        if (this.type === 'class' && this.class_label) {
            const classe = this.class_label.find((c) => c.id_class === d) || {};
            if (classe.dashArray) {
                return classe.dashArray.toString();
            }
        }
        return this.dashArray.toString();
    }

    highlightFeature(layer: any) {
        const feature = layer.feature;
        layer.setStyle({ weight: 3 * this.getWeight(feature.properties.value) });
    }

    getTotDashArray() {
        return this.dashArray.reduce((t, a) => t + a, 0);
    }

    getOverlayPosition(layer: L.Layer) {
        const latLng = this.getPointOnFeature(layer);
        return latLng;
    }

    // fonction de récupération du point le plus au nord d'un polygone
    getPopupPositionX(feature) {
        let latitude = 0;
        let longitude = 0;

        let nbPoints = feature.geometry.coordinates.length;

        if (nbPoints == 2) {
            latitude =
                (feature.geometry.coordinates[0][1] + feature.geometry.coordinates[1][1]) / 2;
            longitude =
                (feature.geometry.coordinates[0][0] + feature.geometry.coordinates[1][0]) / 2;
        } else if (nbPoints > 2) {
            nbPoints = Math.floor(nbPoints / 2);

            try {
                let coord = feature.geometry.coordinates[nbPoints];
                let iteration = 0;

                while (Array.isArray(coord) && Array.isArray(coord[0]) && iteration < 10) {
                    iteration = iteration + 1;
                    coord = coord[0];
                }

                latitude = coord[1];
                longitude = coord[0];
            } catch (err) {
                latitude = null;
                longitude = null;
            }
        } else {
            latitude = null;
            longitude = null;
        }

        return [latitude, longitude];
    }
}
