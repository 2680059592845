<stTab
    class="mapParameters"
    label="Fond de carte & contours"
    [visible]="moduleService.isModuleActive.mapParameters"
>
    <div id="body" class="container-fluid p-4">
        <div class="themes">
            <h4>Thèmes</h4>

            <div class="container-fluid">
                <stRadio
                    label="Thème clair"
                    innerId="light-theme"
                    name="theme"
                    value="light"
                    [(ngModel)]="theme.id"
                    (change)="updateTheme()"
                >
                </stRadio>
                <stRadio
                    label="Thème sombre"
                    innerId="dark-theme"
                    name="theme"
                    value="dark"
                    [(ngModel)]="theme.id"
                    (change)="updateTheme()"
                >
                </stRadio>
            </div>
        </div>

        <hr class="split my-3" />

        <div class="map-background">
            <h4>Fonds de carte</h4>

            <div class="container-fluid background-map-list">
                <div
                    *ngFor="let raster of rasters"
                    [ngClass]="{'raster-seleted': raster.id == currentRaster.id}"
                >
                    <span class="cursor-pointer" (click)="updateRaster(raster.id)">
                        <img
                            class="me-3"
                            height="15"
                            width="15"
                            [src]="'../../../../../assets/img/map/' + raster.logo"
                        />
                        {{ raster.libelle }}
                    </span>
                </div>
            </div>
        </div>

        <hr class="split my-3" />

        <div class="zoning">
            <h4>Zonages</h4>

            <div class="container-fluid" *ngIf="territoryScales">
                <div *ngFor="let territoryScale of territoryScales | sortBy: 'ordre'">
                    <ng-container *ngIf="territoryScale.geom_line">
                        <div class="layer-zone">
                            <div class="territory-boundaries">
                                <span class="loading">
                                    <!-- truth table results 0 1 1 1-->
                                    <i
                                        class="fa fa-plus-circle"
                                        [hidden]="territoryScale.isPloted || territoryScale.isLoading"
                                        (click)="addBoundaries(territoryScale)"
                                    ></i>
                                    <!-- truth table results 1 0 1 1-->
                                    <i
                                        class="fa fa-spinner fa-pulse"
                                        [hidden]="territoryScale.isPloted || !territoryScale.isLoading"
                                    ></i>
                                </span>

                                <span class="unloading">
                                    <!-- truth table results 1 1 0 1-->
                                    <i
                                        class="fa fa-minus-circle"
                                        [hidden]="!territoryScale.isPloted || territoryScale.isLoading"
                                        (click)="removeBoundaries(territoryScale)"
                                    ></i>
                                    <!-- truth table results 1 1 1 0-->
                                    <i
                                        class="fa fa-spinner fa-pulse"
                                        [hidden]="!territoryScale.isPloted || !territoryScale.isLoading"
                                    ></i>
                                </span>
                            </div>

                            <div class="territory-label">
                                <span class="loading">
                                    <!-- truth table results 0 1 1 1-->
                                    <i
                                        class="fa fa-font"
                                        [hidden]="territoryScale.isLabelPloted || territoryScale.isLabelLoading"
                                        (click)="addLabeledBoundaries(territoryScale)"
                                    ></i>
                                    <!-- truth table results 1 0 1 1-->
                                    <i
                                        class="fa fa-spinner fa-pulse"
                                        [hidden]="territoryScale.isLabelPloted || !territoryScale.isLabelLoading"
                                    ></i>
                                </span>

                                <span class="unloading">
                                    <!-- truth table results 1 1 0 1-->
                                    <i
                                        class="fa fa-minus-circle"
                                        [hidden]="!territoryScale.isLabelPloted || territoryScale.isLabelLoading"
                                        (click)="removeLabeledBoundaries(territoryScale)"
                                    ></i>
                                    <!-- truth table results 1 1 1 0-->
                                    <i
                                        class="fa fa-spinner fa-pulse"
                                        [hidden]="!territoryScale.isLabelPloted || !territoryScale.isLabelLoading"
                                    ></i>
                                </span>
                            </div>

                            <span> {{ territoryScale.libelle_long }} </span>
                        </div>

                        <div class="container-fluid pseudo-collapse">
                            <div
                                class="row d-flex align-items-center mb-3"
                                *ngIf="territoryScale.isPloted || territoryScale.isLabelPloted"
                            >
                                <label for="boundaries-color" class="col-sm-5 control-label py-0">
                                    Couleur
                                </label>
                                <div class="col-sm-7">
                                    <stColor
                                        innerId="boundaries-color"
                                        name="boundaries-color"
                                        [(ngModel)]="territoryScale.style.color"
                                        (change)="updateStyle(territoryScale)"
                                    ></stColor>
                                </div>
                            </div>

                            <div
                                class="row d-flex align-items-center mb-3"
                                *ngIf="territoryScale.isPloted || territoryScale.isLabelPloted"
                            >
                                <label for="boundaries-weight" class="col-sm-5 control-label py-0">
                                    Epaisseur
                                </label>
                                <stRange
                                    innerId="boundaries-weight"
                                    name="boundaries-weight"
                                    class="col-sm-7"
                                    [min]="0"
                                    [max]="5"
                                    [step]="0.1"
                                    [value]="territoryScale.style.weight"
                                    [(ngModel)]="territoryScale.style.weight"
                                    (change)="updateStyle(territoryScale)"
                                >
                                </stRange>
                            </div>

                            <div
                                class="row d-flex align-items-center mb-3"
                                *ngIf="territoryScale.isPloted || territoryScale.isLabelPloted"
                            >
                                <label for="boundaries-opacity" class="col-sm-5 control-label py-0">
                                    Opacité
                                </label>
                                <stRange
                                    innerId="boundaries-opacity"
                                    name="boundaries-opacity"
                                    class="col-sm-7"
                                    [min]="0"
                                    [max]="1"
                                    [step]="0.1"
                                    [value]="territoryScale.style.opacity"
                                    [(ngModel)]="territoryScale.style.opacity"
                                    (change)="updateStyle(territoryScale)"
                                >
                                </stRange>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</stTab>
