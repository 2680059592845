import { Inject, Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './services/local-storage.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(@Inject(LocalStorageService) private localStorageService: LocalStorageService) {}

    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.localStorageService.get('token');
        const isGeoUrl = httpRequest.url.includes(environment.geoBackEndUrl);
        const isBackUrl = httpRequest.url.includes(environment.backEndUrl);
        if (token && (isGeoUrl || isBackUrl)) {
            httpRequest = httpRequest.clone({
                setHeaders: {
                    'x-auth-token': token,
                },
            });
        }
        return next.handle(httpRequest);
    }
}
