import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { DecimalPipe } from '@angular/common';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { SortDirection } from './sortable.directive';

interface SearchResult {
    rows: any[];
    total: number;
}

interface State {
    page: number;
    pageSize: number;
    searchTerm: string;
    sortColumn: any;
    sortDirection: SortDirection;
}

const compare = (v1: string | number, v2: string | number) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);

function sort(rows: any[], column: any, direction: string): any[] {
    if (direction === '' || column === '') {
        return rows;
    } else {
        return [...rows].sort((a, b) => {
            const res = compare(a[column], b[column]);
            return direction === 'asc' ? res : -res;
        });
    }
}

@Injectable({ providedIn: 'root' })
export class StTableService {
    private _loading$ = new BehaviorSubject<boolean>(true);
    private _search$ = new Subject<void>();
    private _rows$ = new BehaviorSubject<any[]>([]);
    private _total$ = new BehaviorSubject<number>(0);

    public pageSizeOptions = [10, 25, 50, 100];
    private _state: State = {
        page: 1,
        pageSize: this.pageSizeOptions[0],
        searchTerm: '',
        sortColumn: '',
        sortDirection: '',
    };

    public rows: any[] = [];
    public matches: any;
    public isPaginationDefined: boolean = true;

    constructor(private pipe: DecimalPipe) {}

    private _handleEvent() {
        this._search$
            .pipe(
                tap(() => this._loading$.next(true)),
                debounceTime(200),
                switchMap(() => this._search()),
                tap(() => this._loading$.next(false)),
            )
            .subscribe((result) => {
                this._rows$.next(result.rows);
                this._total$.next(result.total);
            });
        this._search$.next();
    }

    setRows(rows: any[]) {
        this.rows = rows;
        this._handleEvent();
    }

    get rows$() {
        return this._rows$.asObservable();
    }
    get total$() {
        return this._total$.asObservable();
    }
    get loading$() {
        return this._loading$.asObservable();
    }
    get page() {
        return this._state.page;
    }
    get pageSize() {
        return this._state.pageSize;
    }
    get searchTerm() {
        return this._state.searchTerm;
    }

    set page(page: number) {
        this._set({ page });
    }
    set pageSize(pageSize: number) {
        this._set({ pageSize });
    }
    set searchTerm(searchTerm: string) {
        this._set({ searchTerm });
    }
    set sortColumn(sortColumn: any) {
        this._set({ sortColumn });
    }
    set sortDirection(sortDirection: SortDirection) {
        this._set({ sortDirection });
    }

    private _set(patch: Partial<State>) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    private _search(): Observable<SearchResult> {
        const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

        // 1. sort
        let rows = sort(this.rows, sortColumn, sortDirection);

        // 2. filter
        rows = rows.filter((row) => this.matches(row, searchTerm, this.pipe));
        const total = rows.length;

        // 3. paginate
        if (this.isPaginationDefined) {
            rows = rows.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
        }
        return of({ rows, total });
    }
}
