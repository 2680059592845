'use strict';

import * as L from 'leaflet';
import { MapService } from '../services/map.service';
import { PlotFactory } from './PlotFactory';

export class PlotIconFactory extends PlotFactory {
    private default_icon: string;
    private weightMultiplier = 5;
    public icon_list = [
        '500px',
        'adjust',
        'adn',
        'align-center',
        'align-justify',
        'align-left',
        'align-right',
        'amazon',
        'ambulance',
        'american-sign-language-interpreting',
        'anchor',
        'android',
        'angellist',
        'angle-double-down',
        'angle-double-left',
        'angle-double-right',
        'angle-double-up',
        'angle-down',
        'angle-left',
        'angle-right',
        'angle-up',
        'apple',
        'archive',
        'area-chart',
        'arrow-circle-down',
        'arrow-circle-left',
        'arrow-circle-o-down',
        'arrow-circle-o-left',
        'arrow-circle-o-right',
        'arrow-circle-o-up',
        'arrow-circle-right',
        'arrow-circle-up',
        'arrow-down',
        'arrow-left',
        'arrow-right',
        'arrow-up',
        'arrows',
        'arrows-alt',
        'arrows-h',
        'arrows-v',
        'asl-interpreting',
        'assistive-listening-systems',
        'asterisk',
        'at',
        'audio-description',
        'automobile',
        'backward',
        'balance-scale',
        'ban',
        'bank',
        'bar-chart',
        'bar-chart-o',
        'barcode',
        'bars',
        'battery-0',
        'battery-1',
        'battery-2',
        'battery-3',
        'battery-4',
        'battery-empty',
        'battery-full',
        'battery-half',
        'battery-quarter',
        'battery-three-quarters',
        'bed',
        'beer',
        'behance',
        'behance-square',
        'bell',
        'bell-o',
        'bell-slash',
        'bell-slash-o',
        'bicycle',
        'binoculars',
        'birthday-cake',
        'bitbucket',
        'bitbucket-square',
        'bitcoin',
        'black-tie',
        'blind',
        'bluetooth',
        'bluetooth-b',
        'bold',
        'bolt',
        'bomb',
        'book',
        'bookmark',
        'bookmark-o',
        'braille',
        'briefcase',
        'btc',
        'bug',
        'building',
        'building-o',
        'bullhorn',
        'bullseye',
        'bus',
        'buysellads',
        'cab',
        'calculator',
        'calendar',
        'calendar-check-o',
        'calendar-minus-o',
        'calendar-o',
        'calendar-plus-o',
        'calendar-times-o',
        'camera',
        'camera-retro',
        'car',
        'caret-down',
        'caret-left',
        'caret-right',
        'caret-square-o-down',
        'caret-square-o-left',
        'caret-square-o-right',
        'caret-square-o-up',
        'caret-up',
        'cart-arrow-down',
        'cart-plus',
        'cc',
        'cc-amex',
        'cc-diners-club',
        'cc-discover',
        'cc-jcb',
        'cc-mastercard',
        'cc-paypal',
        'cc-stripe',
        'cc-visa',
        'certificate',
        'chain',
        'chain-broken',
        'check',
        'check-circle',
        'check-circle-o',
        'check-square',
        'check-square-o',
        'chevron-circle-down',
        'chevron-circle-left',
        'chevron-circle-right',
        'chevron-circle-up',
        'chevron-down',
        'chevron-left',
        'chevron-right',
        'chevron-up',
        'child',
        'chrome',
        'circle',
        'circle-o',
        'circle-o-notch',
        'circle-thin',
        'clipboard',
        'clock-o',
        'clone',
        'close',
        'cloud',
        'cloud-download',
        'cloud-upload',
        'cny',
        'code',
        'code-fork',
        'codepen',
        'codiepie',
        'coffee',
        'cog',
        'cogs',
        'columns',
        'comment',
        'comment-o',
        'commenting',
        'commenting-o',
        'comments',
        'comments-o',
        'compass',
        'compress',
        'connectdevelop',
        'contao',
        'copy',
        'copyright',
        'creative-commons',
        'credit-card',
        'credit-card-alt',
        'crop',
        'crosshairs',
        'css3',
        'cube',
        'cubes',
        'cut',
        'cutlery',
        'dashboard',
        'dashcube',
        'database',
        'deaf',
        'deafness',
        'dedent',
        'delicious',
        'desktop',
        'deviantart',
        'diamond',
        'digg',
        'dollar',
        'dot-circle-o',
        'download',
        'dribbble',
        'dropbox',
        'drupal',
        'edge',
        'edit',
        'eject',
        'ellipsis-h',
        'ellipsis-v',
        'empire',
        'envelope',
        'envelope-o',
        'envelope-square',
        'envira',
        'eraser',
        'eur',
        'euro',
        'exchange',
        'exclamation',
        'exclamation-circle',
        'exclamation-triangle',
        'expand',
        'expeditedssl',
        'external-link',
        'external-link-square',
        'eye',
        'eye-slash',
        'eyedropper',
        'fa',
        'facebook',
        'facebook-f',
        'facebook-official',
        'facebook-square',
        'fast-backward',
        'fast-forward',
        'fax',
        'feed',
        'female',
        'fighter-jet',
        'file',
        'file-archive-o',
        'file-audio-o',
        'file-code-o',
        'file-excel-o',
        'file-image-o',
        'file-movie-o',
        'file-o',
        'file-pdf-o',
        'file-photo-o',
        'file-picture-o',
        'file-powerpoint-o',
        'file-sound-o',
        'file-text',
        'file-text-o',
        'file-video-o',
        'file-word-o',
        'file-zip-o',
        'files-o',
        'film',
        'filter',
        'fire',
        'fire-extinguisher',
        'firefox',
        'first-order',
        'flag',
        'flag-checkered',
        'flag-o',
        'flash',
        'flask',
        'flickr',
        'floppy-o',
        'folder',
        'folder-o',
        'folder-open',
        'folder-open-o',
        'font',
        'font-awesome',
        'fonticons',
        'fort-awesome',
        'forumbee',
        'forward',
        'foursquare',
        'frown-o',
        'futbol-o',
        'gamepad',
        'gavel',
        'gbp',
        'ge',
        'gear',
        'gears',
        'genderless',
        'get-pocket',
        'gg',
        'gg-circle',
        'gift',
        'git',
        'git-square',
        'github',
        'github-alt',
        'github-square',
        'gitlab',
        'gittip',
        'glass',
        'glide',
        'glide-g',
        'globe',
        'google',
        'google-plus',
        'google-plus-circle',
        'google-plus-official',
        'google-plus-square',
        'google-wallet',
        'graduation-cap',
        'gratipay',
        'group',
        'h-square',
        'hacker-news',
        'hand-grab-o',
        'hand-lizard-o',
        'hand-o-down',
        'hand-o-left',
        'hand-o-right',
        'hand-o-up',
        'hand-paper-o',
        'hand-peace-o',
        'hand-pointer-o',
        'hand-rock-o',
        'hand-scissors-o',
        'hand-spock-o',
        'hand-stop-o',
        'hard-of-hearing',
        'hashtag',
        'hdd-o',
        'header',
        'headphones',
        'heart',
        'heart-o',
        'heartbeat',
        'history',
        'home',
        'hospital-o',
        'hotel',
        'hourglass',
        'hourglass-1',
        'hourglass-2',
        'hourglass-3',
        'hourglass-end',
        'hourglass-half',
        'hourglass-o',
        'hourglass-start',
        'houzz',
        'html5',
        'i-cursor',
        'ils',
        'image',
        'inbox',
        'indent',
        'industry',
        'info',
        'info-circle',
        'inr',
        'instagram',
        'institution',
        'internet-explorer',
        'intersex',
        'ioxhost',
        'italic',
        'joomla',
        'jpy',
        'jsfiddle',
        'key',
        'keyboard-o',
        'krw',
        'language',
        'laptop',
        'lastfm',
        'lastfm-square',
        'leaf',
        'leanpub',
        'legal',
        'lemon-o',
        'level-down',
        'level-up',
        'life-bouy',
        'life-buoy',
        'life-ring',
        'life-saver',
        'lightbulb-o',
        'line-chart',
        'link',
        'linkedin',
        'linkedin-square',
        'linux',
        'list',
        'list-alt',
        'list-ol',
        'list-ul',
        'location-arrow',
        'lock',
        'long-arrow-down',
        'long-arrow-left',
        'long-arrow-right',
        'long-arrow-up',
        'low-vision',
        'magic',
        'magnet',
        'mail-forward',
        'mail-reply',
        'mail-reply-all',
        'male',
        'map',
        'map-marker',
        'map-o',
        'map-pin',
        'map-signs',
        'mars',
        'mars-double',
        'mars-stroke',
        'mars-stroke-h',
        'mars-stroke-v',
        'maxcdn',
        'meanpath',
        'medium',
        'medkit',
        'meh-o',
        'mercury',
        'microphone',
        'microphone-slash',
        'minus',
        'minus-circle',
        'minus-square',
        'minus-square-o',
        'mixcloud',
        'mobile',
        'mobile-phone',
        'modx',
        'money',
        'moon-o',
        'mortar-board',
        'motorcycle',
        'mouse-pointer',
        'music',
        'navicon',
        'neuter',
        'newspaper-o',
        'object-group',
        'object-ungroup',
        'odnoklassniki',
        'odnoklassniki-square',
        'opencart',
        'openid',
        'opera',
        'optin-monster',
        'outdent',
        'pagelines',
        'paint-brush',
        'paper-plane',
        'paper-plane-o',
        'paperclip',
        'paragraph',
        'paste',
        'pause',
        'pause-circle',
        'pause-circle-o',
        'paw',
        'paypal',
        'pencil',
        'pencil-square',
        'pencil-square-o',
        'percent',
        'phone',
        'phone-square',
        'photo',
        'picture-o',
        'pie-chart',
        'pied-piper',
        'pied-piper-alt',
        'pied-piper-pp',
        'pinterest',
        'pinterest-p',
        'pinterest-square',
        'plane',
        'play',
        'play-circle',
        'play-circle-o',
        'plug',
        'plus',
        'plus-circle',
        'plus-square',
        'plus-square-o',
        'power-off',
        'print',
        'product-hunt',
        'puzzle-piece',
        'qq',
        'qrcode',
        'question',
        'question-circle',
        'question-circle-o',
        'quote-left',
        'quote-right',
        'ra',
        'random',
        'rebel',
        'recycle',
        'reddit',
        'reddit-alien',
        'reddit-square',
        'refresh',
        'registered',
        'remove',
        'renren',
        'reorder',
        'repeat',
        'reply',
        'reply-all',
        'resistance',
        'retweet',
        'rmb',
        'road',
        'rocket',
        'rotate-left',
        'rotate-right',
        'rouble',
        'rss',
        'rss-square',
        'rub',
        'ruble',
        'rupee',
        'safari',
        'save',
        'scissors',
        'scribd',
        'search',
        'search-minus',
        'search-plus',
        'sellsy',
        'send',
        'send-o',
        'server',
        'share',
        'share-alt',
        'share-alt-square',
        'share-square',
        'share-square-o',
        'shekel',
        'sheqel',
        'shield',
        'ship',
        'shirtsinbulk',
        'shopping-bag',
        'shopping-basket',
        'shopping-cart',
        'sign-in',
        'sign-language',
        'sign-out',
        'signal',
        'signing',
        'simplybuilt',
        'sitemap',
        'skyatlas',
        'skype',
        'slack',
        'sliders',
        'slideshare',
        'smile-o',
        'snapchat',
        'snapchat-ghost',
        'snapchat-square',
        'soccer-ball-o',
        'sort',
        'sort-alpha-asc',
        'sort-alpha-desc',
        'sort-amount-asc',
        'sort-amount-desc',
        'sort-asc',
        'sort-desc',
        'sort-down',
        'sort-numeric-asc',
        'sort-numeric-desc',
        'sort-up',
        'soundcloud',
        'space-shuttle',
        'spinner',
        'spoon',
        'spotify',
        'square',
        'square-o',
        'stack-exchange',
        'stack-overflow',
        'star',
        'star-half',
        'star-half-empty',
        'star-half-full',
        'star-half-o',
        'star-o',
        'steam',
        'steam-square',
        'step-backward',
        'step-forward',
        'stethoscope',
        'sticky-note',
        'sticky-note-o',
        'stop',
        'stop-circle',
        'stop-circle-o',
        'street-view',
        'strikethrough',
        'stumbleupon',
        'stumbleupon-circle',
        'subscript',
        'subway',
        'suitcase',
        'sun-o',
        'superscript',
        'support',
        'table',
        'tablet',
        'tachometer',
        'tag',
        'tags',
        'tasks',
        'taxi',
        'television',
        'tencent-weibo',
        'terminal',
        'text-height',
        'text-width',
        'th',
        'th-large',
        'th-list',
        'themeisle',
        'thumb-tack',
        'thumbs-down',
        'thumbs-o-down',
        'thumbs-o-up',
        'thumbs-up',
        'ticket',
        'times',
        'times-circle',
        'times-circle-o',
        'tint',
        'toggle-down',
        'toggle-left',
        'toggle-off',
        'toggle-on',
        'toggle-right',
        'toggle-up',
        'trademark',
        'train',
        'transgender',
        'transgender-alt',
        'trash',
        'trash-o',
        'tree',
        'trello',
        'tripadvisor',
        'trophy',
        'truck',
        'try',
        'tty',
        'tumblr',
        'tumblr-square',
        'turkish-lira',
        'tv',
        'twitch',
        'twitter',
        'twitter-square',
        'umbrella',
        'underline',
        'undo',
        'universal-access',
        'university',
        'unlink',
        'unlock',
        'unlock-alt',
        'unsorted',
        'upload',
        'usb',
        'usd',
        'user',
        'user-md',
        'user-plus',
        'user-secret',
        'user-times',
        'users',
        'venus',
        'venus-double',
        'venus-mars',
        'viacoin',
        'viadeo',
        'viadeo-square',
        'video-camera',
        'vimeo',
        'vimeo-square',
        'vine',
        'vk',
        'volume-control-phone',
        'volume-down',
        'volume-off',
        'volume-up',
        'warning',
        'wechat',
        'weibo',
        'weixin',
        'whatsapp',
        'wheelchair',
        'wheelchair-alt',
        'wifi',
        'wikipedia-w',
        'windows',
        'won',
        'wordpress',
        'wpbeginner',
        'wpforms',
        'wrench',
        'xing',
        'xing-square',
        'y-combinator',
        'y-combinator-square',
        'yahoo',
        'yc',
        'yc-square',
        'yelp',
        'yen',
        'yoast',
        'youtube',
        'youtube-play',
        'youtube-square',
    ];

    constructor(indicatorId: number, mapService: MapService) {
        super(indicatorId, mapService);
        this.form = 'icon';
        this.default_icon = 'map-marker';
        this.indicatorcontourcolor = '#000000';
        this.indicatorcontouropacity = 1;
    }

    addLayer(map: L.Map) {
        if (this.geojsonLayer != null) {
            this.removeLayer(map);
        }

        this.geojsonLayer = L.geoJson(this.geojson, {
            pointToLayer: this.getStyle.bind(this),
            onEachFeature: this.onEachFeature.bind(this),
        });

        map.addLayer(this.geojsonLayer);
    }

    getIcon(d) {
        if (this.type === 'class' && this.class_label) {
            const classe = this.class_label.find((c) => c.id_class === d) || {};
            return classe.default_icon || this.default_icon;
        }
        return this.default_icon;
    }

    getStyle(feature: GeoJSON.Feature, latLng?: { lat: number; lng: number }) {
        const size = this.weightMultiplier * this.getWeight(feature.properties.value);
        const iconName = this.getIcon(feature.properties.value);
        const color = this.getColor(feature.properties.value);
        const opacity = this.getFillOpacity(feature.properties.value);
        const contourColor = this.indicatorcontourcolor;
        const contourWeight = this.indicatorcontourweight;
        const textShadow = `
            -${contourWeight}px 0 ${contourColor},
            0 ${contourWeight}px ${contourColor},
            ${contourWeight}px 0 ${contourColor},
            0 -${contourWeight}px ${contourColor}
        `;

        const icon = L.divIcon({
            html: ` <i class="fa fa-${iconName}" style="color: ${color}; opacity : ${opacity}; font-size : ${size}px !important; text-shadow: ${textShadow};"></i>`,
            iconSize: [size, size],
            className: 'leafletIconFactory',
        });

        return L.marker(latLng, { icon: icon });
    }
}
