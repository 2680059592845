'use strict';

import { Inject, Injectable } from '@angular/core';

import { Indicator } from 'src/app/models/Indicator';

import { UsefulService } from 'src/app/services/UsefulService';

@Injectable({
    providedIn: 'root',
})
export class ManualDistribution {
    public values: Array<number>;
    public valueScale: any;
    public intervals: Array<number> = [];
    public legendBoundaries: number[][] = [];

    public id = 'manuel';
    public label = 'Seuils manuels';

    constructor(@Inject(UsefulService) private usefulService: UsefulService) {}

    setValues(values: Array<number>) {
        this.values = values;
    }

    setClassCount(indicatorPlot: Indicator) {
        if (indicatorPlot.form == 'chart') {
            indicatorPlot.classCount = Object.keys(JSON.parse(indicatorPlot.chart_divider)).length;
        }
        // else {
        //     indicatorPlot.classCount = Math.min(this.values.length, indicatorPlot.classCount);
        // }
        indicatorPlot.classCountsAvailable = [2, 3, 4, 5, 6, 7, 8, 9];
    }

    setScale(indicatorPlot: Indicator) {
        this.valueScale = indicatorPlot.getLegendIndex;
    }

    setIntervals(indicatorPlot: Indicator) {
        const legendBoundaries = indicatorPlot.legendBoundaries;
        this.legendBoundaries = legendBoundaries;

        return this.legendBoundaries;
    }

    convertTableBornesToLegendBoundaries(tableBornes: number[], decimalCount: number) {
        const step = 1 / Math.pow(10, decimalCount);

        const legendBoundaries = tableBornes
            .slice(0, -1)
            .reduce((legendBoundaries: number[][], value: number, index: number) => {
                const boundaryInf = value;

                const valueSup = tableBornes[index + 1] - step;
                const boundarySup = this.usefulService.round(valueSup, decimalCount);

                legendBoundaries.push([boundaryInf, boundarySup]);
                return legendBoundaries;
            }, []);

        return legendBoundaries;
    }

    convertLegendBoundariesToTableBornes(legendBoundaries: number[][]) {
        const tableBornes = legendBoundaries.map((boundary: number[]) => boundary[0]);
        tableBornes.push(legendBoundaries[legendBoundaries.length - 1][1]);
        return tableBornes;
    }
}
