('use strict');

import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Indicator } from 'src/app/models/Indicator';

import { DataService } from 'src/app/services/DataService';
import { FilterDataService } from './FilterDataService';
import { MapService } from 'src/app/services/map.service';
import { PlotIndicatorService } from 'src/app/services/plotIndicator/plot-indicator.service';
import { RestService } from './RestService';
import { TerService } from './TerService';
import { UsefulService } from './UsefulService';

@Injectable({
    providedIn: 'root',
})
export class DashboardService {
    public dashboards = new BehaviorSubject<any[]>([]);
    dashboards$: Observable<any[]> = this.dashboards.asObservable();

    public addedDashboard = new BehaviorSubject<any>(null);
    addedDashboard$: Observable<any> = this.addedDashboard.asObservable();

    public removedDashboard = new BehaviorSubject<any>(null);
    removedDashboard$: Observable<any> = this.removedDashboard.asObservable();

    public updatedDashboard = new BehaviorSubject<any>(null);
    updatedDashboard$: Observable<any> = this.updatedDashboard.asObservable();

    public updatedTableRepartition = new BehaviorSubject<any>(null);
    updatedTableRepartition$: Observable<any> = this.updatedTableRepartition.asObservable();

    public territoryDashboard = new BehaviorSubject<any>(null);
    territoryDashboard$: Observable<any> = this.territoryDashboard.asObservable();

    public moduleDashboard = {
        isOpen: false,
        isAvailable: false,
    };

    public isPlotAvailable: boolean;
    public tablePlot = 'table';

    constructor(
        @Inject(DataService) private dataService: DataService,
        @Inject(FilterDataService) private filterDataService: FilterDataService,
        @Inject(MapService) private mapService: MapService,
        @Inject(PlotIndicatorService) private plotIndicatorService: PlotIndicatorService,
        @Inject(RestService) private restService: RestService,
        @Inject(TerService) private terService: TerService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {
        this._init();
    }

    clear() {
        this._init();
        this._clearObservables();
    }

    private _init() {
        this.moduleDashboard = {
            isOpen: false,
            isAvailable: false,
        };
    }

    private _clearObservables() {
        this.dashboards.next([]);
        this.addedDashboard.next(null);
        this.removedDashboard.next(null);
        this.updatedDashboard.next(null);
        this.territoryDashboard.next(null);
    }

    addDashboard(dashboard: any) {
        const dashboards = this.dashboards.value;
        dashboards.push(dashboard);

        this.dashboards.next(dashboards);
        this.addedDashboard.next(dashboard);
    }

    removeDashboard(dashboard: any) {
        const dashboards = this.dashboards.value;
        const filteredDashboards = dashboards.filter(
            (obj) => obj.indicatorId !== dashboard.indicatorId,
        );

        this.dashboards.next(filteredDashboards);
        this.removedDashboard.next(dashboard);
    }

    updateDashboard(dashboard: any) {
        const currentArray = this.dashboards.value;
        const index = currentArray.findIndex((obj) => obj.indicatorId === dashboard.indicatorId);

        if (index !== -1) {
            currentArray[index] = dashboard;
            this.dashboards.next(currentArray);
            this.updatedDashboard.next(dashboard);
        }
    }

    updateDashboardTableRepartition(indicatorId: number) {
        const currentArray = this.dashboards.value;
        const index = currentArray.findIndex((obj) => obj.indicatorId === indicatorId);

        if (index !== -1) {
            const dashboard = currentArray[index];
            if (!this.isTerritory(dashboard)) {
                this.updatedTableRepartition.next(dashboard);
            }
        }
    }

    setIsPlotAvailable() {
        const indicatorIds = Object.keys(this.plotIndicatorService.plotedIndicators).map((x) =>
            Number(x),
        );
        this.isPlotAvailable = indicatorIds.some((indicatorId: number) =>
            this.isIndicatorPlotAvailable(indicatorId),
        );
        this.mapService.invalidateSize();
    }

    isIndicatorPlotAvailable(indicatorId: number) {
        const indicatorData = this.dataService.activeIndicators[indicatorId];
        const isFilterable = indicatorData.static_dynamic == 'dyn';
        const dashboardPreferences = indicatorData.dashboardPreferences;
        const isPlotsAvailable = !!dashboardPreferences
            ? dashboardPreferences.available_plots.length > 0
            : true;
        return isFilterable && isPlotsAvailable;
    }

    open(view: string = this.tablePlot) {
        this.moduleDashboard.isAvailable = true;
        this.moduleDashboard.isOpen = true;
        this.tablePlot = view;
        this.mapService.invalidateSize();
    }

    close() {
        this.moduleDashboard.isOpen = false;
    }

    toggleDashboardState(view: string) {
        if (view == this.tablePlot) {
            this.moduleDashboard.isOpen = !this.moduleDashboard.isOpen;
        } else {
            this.moduleDashboard.isOpen = true;
        }
        this.tablePlot = view;

        this.mapService.invalidateSize();
    }

    updateAvailable() {
        const indicatorPlots = Object.values(this.plotIndicatorService.plotedIndicators);

        const isAvailable = indicatorPlots.some(
            (indicatorPlot: Indicator) =>
                !indicatorPlot.isImported &&
                (['territories', 'none'].includes(indicatorPlot.vector_base) ||
                    indicatorPlot.plugin == 'atac'),
        );
        this.moduleDashboard.isAvailable = isAvailable;

        if (!isAvailable) {
            this.moduleDashboard.isOpen = false;
        } else {
            this.setIsPlotAvailable();
            if (!this.isPlotAvailable) {
                this.tablePlot = 'table';
            }
        }
    }

    async getAggregation(indicatorId: number) {
        const indicatorData = this.dataService.activeIndicators[indicatorId];
        const indicatorPlot = this.plotIndicatorService.plotedIndicators[indicatorId];

        const data: any = {
            scale_int: this.terService.territoryScale.typeId, // that one is for back auth checkout
            scale_filter: this.terService.territories.map((t) => `'${t.id}'`).join(', '), // that one is for back auth checkout
            scale: this.terService.territoryScale.tbl_data,
            scaleTypeId: this.terService.territoryScale.typeId,
            year: indicatorData.crrsdc_ter_year_geo,
            territoryIds: this.terService.territories.map((t) => t.id),
        };
        if (!indicatorData.isCustomTerritory) {
            data.granularityTypeId = indicatorPlot.granularity.typeId;
        }
        const isFilterable = indicatorData.static_dynamic == 'dyn';
        if (isFilterable) {
            data.filters = this.filterDataService.createFiltersArray(indicatorId);
        }

        const aggregationInfo = await this.restService.getCurrentIndicatorAggregation(
            indicatorId,
            data,
        );

        const aggregation = this.formatAggregation(aggregationInfo);
        return aggregation;
    }

    private formatAggregation(aggregationInfo: any) {
        const label = aggregationInfo.label;
        const value = aggregationInfo.value;
        const decimalCount = aggregationInfo.decimalCount || 0;
        const unit = aggregationInfo.unit || '';
        const count = aggregationInfo.count;
        const method = aggregationInfo.method;
        const type = aggregationInfo.type;

        const stringifiedCount = this.usefulService.stringifyNumber(count);
        const countLabel = `Nombre d'éléments : ${stringifiedCount}`;

        if (type == 'categorical') {
            return {
                aggregationLabel: label,
                countLabel: countLabel,
            };
        }

        const labelMethod = method == 'mean' ? 'moyenne' : 'somme';
        const stringifiedValue = this.usefulService.stringifyNumber(
            this.usefulService.round(value, decimalCount),
        );
        let aggregationLabel = `${label} (${labelMethod}) : ${stringifiedValue}`;
        if (unit) {
            aggregationLabel += ` ${unit}`;
        }

        return {
            aggregationLabel: aggregationLabel,
            countLabel: countLabel,
        };
    }

    public isTerritory(dashboard: any) {
        const indicator = dashboard.indicator;
        const vectorBase = indicator.vector_base;
        const isTerritory = vectorBase == 'territories';
        const isCustomTerritory = vectorBase == 'none';
        const granularityTypeId = isTerritory ? indicator.granularity.typeId : null;
        const isBuilding = granularityTypeId == 1;
        return !((isTerritory && isBuilding) || isCustomTerritory);
    }

    public updateTerritoryTable(dashboard: any) {
        this.territoryDashboard.next(dashboard);
    }
}
