'use strict';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'stringifyNumber' })
export class SeparatorPipe implements PipeTransform {
    transform(value: number) {
        if (value != null) {
            value = Math.round(value);

            // Splitting the value in parts using a dot as decimal separator
            const parts = value.toString().split('.');

            const length = parts[0].length;
            const nbSeparatorsToAdd = Math.floor((length - 1) / 3);

            // Add a space every three caracters
            const numberArray = parts[0].split('');
            for (let i = 0; i < nbSeparatorsToAdd; i++) {
                numberArray.splice(length - 3 * (i + 1), 0, ' ');
            }
            return numberArray.join('');
        } else {
            return '-';
        }
    }
}

@Pipe({
    name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
    transform(array: any[], key: string, order: string = 'asc'): any[] {
        if (order == 'asc') {
            return array.sort((a, b) => a[key] - b[key]);
        } else if (order == 'desc') {
            return array.sort((a, b) => b[key] - a[key]);
        } else {
            return array;
        }
    }
}

@Pipe({
    name: 'alphabeticallySortBy',
})
export class AlphabeticallySortByPipe implements PipeTransform {
    transform(array: any, field: string): any[] {
        if (!Array.isArray(array)) {
            return;
        }
        if (field[0] == '-') {
            field = field.slice(1);
            array.sort((a: any, b: any) => {
                if (a[field] < b[field]) {
                    return 1;
                } else if (a[field] > b[field]) {
                    return -1;
                } else {
                    return 0;
                }
            });
        } else {
            array.sort((a: any, b: any) => {
                if (a[field] < b[field]) {
                    return -1;
                } else if (a[field] > b[field]) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }

        return array;
    }
}
