import { Inject, Injectable } from '@angular/core';

import { GeojsonInfoTemplate, Indicator } from 'src/app/models/Indicator';

import { FilterDataService } from 'src/app/services/FilterDataService';
import { MapService } from 'src/app/services/map.service';
import { RestService } from 'src/app/services/RestService';
import { SolarService } from '../cadastre-solaire/cadastre-solaire.service';
import { TerService } from 'src/app/services/TerService';

@Injectable({
    providedIn: 'root',
})
export class PlotCadastreSolaireIndicatorRoofSectionService {
    public idKey = 'id_ter';

    private geojsonInfoTemplate: GeojsonInfoTemplate = {
        id: null,
        geojson: null,
        territoryScale: null,
        territoryIds: [],
        granularity: null,
    };

    constructor(
        @Inject(FilterDataService) private filterDataService: FilterDataService,
        @Inject(MapService) private mapService: MapService,
        @Inject(RestService) private restService: RestService,
        @Inject(SolarService) public solarService: SolarService,
        @Inject(TerService) private terService: TerService,
    ) {}

    completeGeojson(indicatorPlot: any, geojson: any) {
        const geojsonDeepCopy = JSON.parse(JSON.stringify(geojson));
        return geojsonDeepCopy;
    }

    setGeojsonInfoId(indicatorPlot: any) {
        if (!indicatorPlot.geojsonInfoId) {
            const vectorBase = indicatorPlot.vector_base;
            const year = indicatorPlot.crrsdc_ter_year_geo;
            const indicatorId = indicatorPlot.indicatorId;

            const mapCenter = this.mapService.map.getCenter();
            const lat = mapCenter.lat;
            const lng = mapCenter.lng;
            const idParts = [vectorBase, year, indicatorId, lat, lng];
            const geojsonInfoId = idParts.join('_');

            indicatorPlot.geojsonInfoId = geojsonInfoId;
        }

        return indicatorPlot.geojsonInfoId;
    }

    async getGeojson(
        indicatorPlot: any,
        geojsonsInfo: Array<any>,
        saveInGeojsonInfo: boolean = true,
        rejectAlreadyLoadedElement: boolean = false,
    ) {
        try {
            const geojson = await this.requestGeojson(indicatorPlot, rejectAlreadyLoadedElement);
            if (saveInGeojsonInfo) {
                this.setGeojsonInfo(indicatorPlot, geojson, geojsonsInfo);
            }
            return geojson;
        } catch (error) {
            console.error('Erorr getGeojson', error);
            throw error;
        }
    }

    async requestGeojson(indicatorPlot: Indicator, rejectAlreadyLoadedElement: boolean = false) {
        const indicatorId = indicatorPlot.indicatorId;
        // const centerLatLng = this.getCenter();
        // const radius = this.getRadius();

        const bounds = this.mapService.map.getBounds();
        const minLng = bounds.getWest();
        const minLat = bounds.getSouth();
        const maxLng = bounds.getEast();
        const maxLat = bounds.getNorth();

        const data = {
            year: this.terService.geoYear,
            scaleTypeId: this.terService.territoryScale.typeId,
            territoryIds: this.terService.territories.map((t) => t.id),
            // latitude: centerLatLng.lat,
            // longitude: centerLatLng.lng,
            // radius: radius,
            minLng: minLng,
            minLat: minLat,
            maxLng: maxLng,
            maxLat: maxLat,
        };

        if (rejectAlreadyLoadedElement) {
            this.sendAlreadyLoadedElementIds(indicatorPlot, data);
        }

        try {
            const geojson = await this.restService.getSolarGeojson(indicatorId, data);
            return geojson;
        } catch (error) {
            console.error('Error requestGeojson', error);
            throw error;
        }
    }

    setGeojsonInfo(
        indicatorPlot: Indicator,
        geojson: GeoJSON.FeatureCollection,
        geojsonsInfo: any,
    ) {
        const territoryScale = JSON.parse(JSON.stringify(this.terService.territoryScale));
        const territoryIds = this.terService.territories.map((t) => t.id);
        const granularity = indicatorPlot.granularity
            ? JSON.parse(JSON.stringify(indicatorPlot.granularity))
            : null;

        const geojsonInfo = { ...this.geojsonInfoTemplate };
        geojsonInfo.id = this.setGeojsonInfoId(indicatorPlot);
        geojsonInfo.geojson = JSON.parse(JSON.stringify(geojson));
        geojsonInfo.territoryScale = territoryScale;
        geojsonInfo.territoryIds = territoryIds;
        geojsonInfo.granularity = granularity;
        geojsonsInfo.push(geojsonInfo);
    }

    async getValues(indicatorPlot: any, rejectAlreadyLoadedElement: boolean = false) {
        try {
            // const centerLatLng = this.getCenter();
            // const latitude = centerLatLng.lat;
            // const longitude = centerLatLng.lng;
            // const radius = this.getRadius();

            const bounds = this.mapService.map.getBounds();
            const minLng = bounds.getWest();
            const minLat = bounds.getSouth();
            const maxLng = bounds.getEast();
            const maxLat = bounds.getNorth();

            const indicatorId = indicatorPlot.indicatorId;

            const data: any = {
                year: indicatorPlot.crrsdc_ter_year_geo,
                scaleTypeId: this.terService.territoryScale.typeId,
                territoryIds: this.terService.territories.map((t) => t.id),
                filters: this.filterDataService.createFiltersArray(indicatorId),
                // latitude: latitude,
                // longitude: longitude,
                // radius: radius,
                minLng: minLng,
                minLat: minLat,
                maxLng: maxLng,
                maxLat: maxLat,
            };
            if (rejectAlreadyLoadedElement) {
                this.sendAlreadyLoadedElementIds(indicatorPlot, data);
            }

            indicatorPlot.dataToPlot = await this.restService.getSolarIndicatorValue(
                indicatorId,
                data,
            );
        } catch (error) {
            console.error('Error getValues', error);
            return Promise.reject(error);
        }
    }

    sendAlreadyLoadedElementIds(indicatorPlot: any, data: any) {
        const geojson = indicatorPlot.geojson;
        if (geojson) {
            const rejectedElementIds = geojson.features
                .filter((feature: GeoJSON.Feature) => feature.properties.value != null)
                .map((feature: GeoJSON.Feature) => feature.properties.id_ter);
            data.rejectedElementIds = Array.from(new Set(rejectedElementIds));
        }
    }

    getCenter() {
        // const width = $('#left-panel').width();
        this.mapService.invalidateSize();
        const map = this.mapService.map;
        // const shiftPoint = L.point(width / 2, 0);
        // const centerLatLng = this.mapService.getShiftedLatLng(map.getCenter(), shiftPoint);
        const centerLatLng = map.getCenter();
        return centerLatLng;
    }

    getRadius() {
        // const width = $('#left-panel').width();
        const map = this.mapService.map;
        const mapBounds = map.getBounds();
        const northWestLatLng = mapBounds.getNorthWest();
        const southEstLatLng = mapBounds.getSouthEast();

        // const shiftPointRadius = L.point(width, 0);
        // const shiftedSouthEstLatLng = this.mapService.getShiftedLatLng(
        //     southEstLatLng,
        //     shiftPointRadius,
        // );
        // const radius = northWestLatLng.distanceTo(shiftedSouthEstLatLng) / 2;
        const radius = northWestLatLng.distanceTo(southEstLatLng) / 2;
        return radius;
    }
}
