<div class="modal-header">
    <h4 class="modal-title text-st-active">{{ header }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
    <div [innerHtml]="body"></div>
</div>
<div class="modal-footer">
    <stButton
        [btnLabel]="cancelLabel"
        btnClass="btn-st-default me-2 px-5"
        (onClick)="close()"
    ></stButton>
    <stButton
        [btnLabel]="validateLabel"
        btnClass="btn-st-primary ms-2 px-5"
        (onClick)="validate()"
    ></stButton>
</div>
