('use strict');

import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'stInput',
    templateUrl: './st-input.template.html',
    styleUrls: ['/st-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => StInputComponent),
            multi: true,
        },
    ],
})
export class StInputComponent implements ControlValueAccessor {
    @Input() name: string;
    @Input() innerId: string;
    @Input() type: string;
    @Input() class: string;
    @Input() style: string;
    @Input() placeholder = '';
    @Input() autocomplete: string;
    @Input() min: number;
    @Input() max: number;
    @Input() step: number;
    @Input() isRequired: boolean = false;
    @Input() isDisabled: boolean = false;
    @Input() isInvalid: boolean = false;
    @Output() ngBlur = new EventEmitter();

    constructor() {}
    onChange: any = () => {};
    onTouch: any = () => {};
    val = '';

    onBlur() {
        this.ngBlur.emit();
    }

    set value(val: string) {
        if (val !== undefined && this.val !== val) {
            this.val = val;
            this.onChange(val);
            this.onTouch(val);
        }
    }

    writeValue(value: string) {
        this.value = value;
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }

    get aggregatedClass(): string {
        let _class = 'form-control';
        const isInvalid = this.isInvalid || (this.isRequired && !this.val);
        if (isInvalid) _class += ' isInvalid';
        if (this.class) _class += ` ${this.class}`;
        return _class;
    }
}
