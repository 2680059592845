<div class="btn-toolbar p-2" role="toolbar">
    <div class="btn-group flex-grow-1" *ngIf="!isCustomTerritory && indicatorPlot.granularity">
        <div ngbDropdown container="body" class="d-inline-block w-100">
            <button ngbDropdownToggle type="button" [disabled]="availableGranularities.length == 1"
                class="btn btn-st-default d-flex align-items-center justify-content-center">
                {{ indicatorPlot.granularity.labelLong }}
            </button>
            <div ngbDropdownMenu>
                <ng-container *ngFor="let granularity of availableGranularities">
                    <button ngbDropdownItem (click)="selectGranularity(granularity)">
                        {{ granularity.labelLong }}
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="btn-group" *ngIf="isParametersAvailable">
        <stButton btnClass="btn-st-default p-0" iconFaLeft="fa fa-cog" (onClick)="toggleIndicatorParams()"
            title="Paramétrer la forme de l'indicateur">
        </stButton>
    </div>
    <div class="btn-group" *ngIf="isFiltersAvailable && isFilterable">
        <stButton btnClass="btn-st-default p-0" iconFaLeft="fa fa-filter" (onClick)="toggleIndicatorFilters()"
            title="Filtrer l'indicateur">
        </stButton>
    </div>
</div>