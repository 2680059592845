('use strict');

import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'stRadio',
    templateUrl: './st-radio.template.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => StRadioComponent),
            multi: true,
        },
    ],
})
export class StRadioComponent implements ControlValueAccessor {
    @Input() label: string;
    @Input() name: string;
    @Input() innerId: string;
    @Input() value: any;

    constructor() {}
    onChange: any = () => {};
    onTouch: any = () => {};
    innerModel = false;
    private _val;

    set val(val: any) {
        if (val !== undefined && this.val !== val) {
            this._val = val;
            this.onChange(val);
            this.onTouch(val);
        }
    }

    get val() {
        return this._val;
    }

    writeValue(value: any) {
        this.val = value;
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }
}
