<div class="container-fluid p-3 pt-0">
    <stAccordion innerId="{{ dashboard.indicatorId }}-territory-table" [items]="[dashboard.table]">
        <ng-template #contentTemplate let-table>
            <table class="table table-striped table-sm territory-table">
                <thead>
                    <tr>
                        <th scope="col" sortable="label" (sort)="onSort($event)" class="label-col">
                            Territoire
                        </th>
                        <th scope="col" sortable="value" (sort)="onSort($event)" class="value-col">
                            Valeur
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr id="db-{{ row.territoryId }}-{{ dashboard.indicatorId }}"
                        *ngFor="let row of territoryRows$ | async" [ngClass]="{'is-selected': row.isSelected}">
                        <th scope="row">
                            {{ row.label }}
                        </th>
                        <td scope="row">
                            {{ row.stringifiedValue }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>
    </stAccordion>
</div>