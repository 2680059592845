'use strict';

import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AtacService } from 'src/app/services/AtacService';
import { FilesService } from 'src/app/services/FilesService';

@Component({
    selector: 'importLoadCurveModal',
    templateUrl: './import-load-curve.template.html',
})
export class ImportLoadCurveModal {
    @Input() terId: string;
    @Input() hasLoadCurve: boolean;
    @Input() loadCurveType: string;
    @Output() onUpload = new EventEmitter();

    fileContent: any;
    isUploadingFile = false;
    hasUploadError = false;
    isUploadReady = false;
    isUploadingCurve = false;

    constructor(
        public activeModal: NgbActiveModal,
        @Inject(AtacService) private atacService: AtacService,
        @Inject(FilesService) private filesService: FilesService,
    ) {}

    async uploadFile(file) {
        this.isUploadReady = false;
        this.isUploadingFile = true;
        this.hasUploadError = false;

        try {
            this.fileContent = await this.filesService.readCsv(file);
            const data = {
                hasLoadCurve: this.hasLoadCurve,
                loadCurveType: this.loadCurveType,
                terId: this.terId,
            };
            this.onUpload.emit(data);
        } catch (error) {
            console.log(error);
            this.hasUploadError = true;
        } finally {
            this.isUploadReady = true;
            this.isUploadingFile = false;
        }
    }

    async delete() {
        try {
            await this.atacService.deleteCourbe(this.terId, this.loadCurveType);
            this.hasLoadCurve = false;
        } catch (error) {
            console.log(error);
        }
    }

    async upload() {
        this.isUploadingCurve = true;

        try {
            await this.atacService.uploadCourbe(this.terId, this.fileContent, this.loadCurveType);
            this.hasLoadCurve = true;
            this.close();
        } catch (error) {
            console.log(error);
            this.isUploadReady = false;
            this.hasUploadError = true;
        } finally {
            this.isUploadingFile = false;
            this.isUploadingCurve = false;
        }
    }

    close() {
        this.activeModal.close();
    }
}
