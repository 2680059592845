<!-- Simulation not launched -->
<div class="row center-block" *ngIf="!isSuccess">
  <p class="text-center"><em>Aucun résultat disponible : veuillez paramétrer puis lancez une simulation pour que les
      groupements
      d'autoconsommation collective optimaux aparaissent dans cet onglet.
    </em></p>
</div>

<!-- Simulation launched -->
<div class="row center-block" *ngIf="isSuccess">
  <p><strong>Groupements optimaux suivant paramètres de la simulation </strong></p>
  <p class="small text-center"> TRI Groupement (%)</p>
  <div class="row" *ngFor="let group of groups | orderBy:'-TRI'; index as index">
    <div class="col-1">
      <a style="cursor: pointer" (click)="showGroupementOnMap(group, index)"><i class="fa fa-2x fa-bullseye"
          [ngClass]="{ 'selectedLine' : index == selectedGroupIndex }" ngbTooltip="Afficher sur la carte"
          placement="top" container="body"></i></a>
    </div>
    <div class="col-1">
      <a style="cursor: pointer" (click)="openModalAtacResultats(group, index)"><i class="fa fa-2x fa-file-text-o"
          [ngClass]="{ 'selectedLine' : index == selectedGroupIndex }" ngbTooltip="Rapport détaillé" placement="top"
          container="body"></i></a>
    </div>
    <div class="col-5" style="margin-top:5px;" [ngClass]="{ 'selectedLine' : index == selectedGroupIndex }">
      Groupement n°{{group.key}}
    </div>
    <div class="col-5" style="margin-top:5px;" [ngClass]="{ 'selectedLine' : index == selectedGroupIndex }">
      {{(group.TRI *100) | round:2}}%
    </div>
  </div>
</div>
