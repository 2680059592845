('use strict');

import { Inject, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as shp from 'shpjs';

import { ClassLabel } from 'src/app/models/Indicator';

import { CatalogService } from 'src/app/services/catalog.service';
import { DataService } from 'src/app/services/DataService';
import { ImportedDataService } from 'src/app/services/ImportedDataService';
import { LoaderService } from 'src/app/services/LoaderService';
import { MapService } from 'src/app/services/map.service';
import { ModuleService } from 'src/app/services/module.service';

@Injectable({
    providedIn: 'root',
})
export class ShapeFileService {
    constructor(
        private notification: ToastrService,
        @Inject(CatalogService) private catalogService: CatalogService,
        @Inject(DataService) private dataService: DataService,
        @Inject(ImportedDataService) private importedDataService: ImportedDataService,
        @Inject(LoaderService) private loaderService: LoaderService,
        @Inject(MapService) private mapService: MapService,
        @Inject(ModuleService) private moduleService: ModuleService,
    ) {}

    async handleZipFile(file: File) {
        try {
            this.loaderService.start('handleZipFile');

            const data = await this.unzipShapeFile(file);
            const isArrayOfGeoJson = !data.hasOwnProperty('features');

            if (isArrayOfGeoJson) {
                const geojsons = data as shp.FeatureCollectionWithFilename[];
                geojsons
                    .filter((geojson) => geojson.features.length)
                    .forEach((geojson) => this.addIndicatorsFromGeojson(geojson));
            } else {
                const geojson = data as shp.FeatureCollectionWithFilename;
                if (geojson.features.length) {
                    this.addIndicatorsFromGeojson(geojson);
                }
            }
        } catch (error) {
            console.error('Error handleZipFile', error);
        } finally {
            this.loaderService.stop('handleZipFile');
        }
    }

    unzipShapeFile = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = async () => {
                try {
                    const geojson = await shp(reader.result);
                    resolve(geojson);
                } catch (error) {
                    reject(error);
                }
            };

            reader.onerror = (error) => {
                console.error('Error unzipShapeFile', error);
                reject(error);
            };

            reader.readAsArrayBuffer(file);
        });
    };

    handleGeojsonFile(file: File) {
        const reader = new FileReader();
        reader.onload = ((f) => {
            return (e) => {
                const geojson = JSON.parse(e.target.result.toString());
                geojson.fileName = file.name;
                if (geojson.features.length) {
                    this.addIndicatorsFromGeojson(geojson);
                }
            };
        })(file);

        reader.readAsText(file);
    }

    addIndicatorsFromGeojson(geojson: shp.FeatureCollectionWithFilename) {
        const name = geojson.fileName.replace('siterre/', '');

        const propertiesInfo = [];
        const propertiesToFilterOn = [];
        const popoverInfo = {};

        const feature = geojson.features[0];
        Object.keys(feature.properties).forEach((key) => {
            const value = feature.properties[key];

            if (typeof value == 'number') {
                propertiesInfo.push({ propertyName: key, type: 'valeur' });
            } else {
                propertiesInfo.push({ propertyName: key, type: 'class' });
                propertiesToFilterOn.push(key);
            }
        });

        if (!propertiesInfo.length) {
            geojson.features.forEach(
                (feature: GeoJSON.Feature, index: number) => (feature.properties.siterreId = index),
            );
            propertiesInfo.push({ propertyName: 'siterreId', type: 'valeur' });
        }

        const indicators = propertiesInfo.map((propertyInfo) =>
            this._addNewIndicator(propertyInfo, geojson, propertiesToFilterOn, popoverInfo, name),
        );

        this.catalogService.addImportedIndicators(indicators, name);
        this.moduleService.openModule('catalog');
    }

    private _addNewIndicator(
        propertyInfo: { propertyName: string; type: string },
        geojson: GeoJSON.FeatureCollection,
        propertiesToFilterOn: Array<string>,
        popoverInfo: { [prop: string]: string },
        name: string,
    ) {
        const data = this._setDataToPlot(geojson, propertyInfo);
        const dataToPlot = data.dataToPlot;
        const classLabels = data.classLabels;

        const propertyName = propertyInfo.propertyName;
        const type = propertyInfo.type;

        const newIndicator = this.dataService.createIndicator();
        newIndicator.type = type;
        newIndicator.vector_base = 'imported';
        newIndicator.champ_p_associe = propertyName;

        if (type == 'class') {
            classLabels.push({
                lib_class: 'Autres',
                id_class: -9999,
                ordre: -9999,
                default_radius_weight: 1,
                default_fillopacity: 1,
                default_contouropacity: 1,
            });
            newIndicator.class_label = classLabels;
            newIndicator.classParameters = {
                sort_legend_by: 'default',
                max_class_count: 0,
                aggregated_class_label: 'Autres',
                aggregated_class_id: -9999,
            };
        }

        if (propertiesToFilterOn.length > 0) {
            newIndicator.static_dynamic = 'dyn';
            newIndicator.isFilterable = true;
            newIndicator.id_indic_for_filter_defaut = propertyName;
        }

        const geometryType = geojson.features[0].geometry.type;
        const isLine = geometryType.toLowerCase().indexOf('line') >= 0;
        const isPoint = geometryType.toLowerCase().indexOf('point') >= 0;
        const isPolygon = geometryType.toLowerCase().indexOf('polygon') >= 0;
        if (isLine) {
            newIndicator.form = 'line';
        } else if (isPoint) {
            newIndicator.form = 'point';
        } else if (isPolygon) {
            newIndicator.form = 'poly';
        } else {
            throw `Imported data form is not understood: ${geometryType}.`;
        }

        const popoverInfoStringified = JSON.stringify(popoverInfo);
        newIndicator.champ_info = popoverInfoStringified;
        newIndicator.info_nom = popoverInfoStringified;
        newIndicator.libelle_indic_complet = propertyName;
        newIndicator.libelle_indic_short = propertyName;
        newIndicator.secondary_unit_short = propertyName;
        newIndicator.tbl = name;
        newIndicator.id_archi_critdter = propertyName;
        newIndicator.source = name;

        this.importedDataService.create(
            newIndicator.id_indicateur,
            dataToPlot,
            newIndicator,
            geojson,
            propertiesToFilterOn,
        );
        return newIndicator;
    }

    private _setDataToPlot(
        geojson: shp.FeatureCollectionWithFilename,
        propertyInfo: { propertyName: string; type: string },
    ) {
        const propertyName = propertyInfo.propertyName;
        const type = propertyInfo.type;

        const classLabels: ClassLabel[] = [];
        let dataToPlot: any;

        if (type === 'class') {
            let classLabelId: number;
            dataToPlot = geojson.features.reduce(
                (dataToPlot: any, feature: GeoJSON.Feature, elementId: number) => {
                    feature.properties.id_ter = elementId;
                    const label = feature.properties[propertyName];

                    const classLabel = classLabels.find(
                        (classLabel) => classLabel.lib_class == label,
                    );

                    if (classLabel) {
                        classLabelId = classLabel.id_class;
                    } else {
                        classLabelId = classLabels.length + 1;
                        classLabels.push({
                            id_class: classLabelId,
                            lib_class: label,
                            ordre: classLabelId,
                        });
                    }
                    dataToPlot[elementId] = { value: classLabelId };
                    return dataToPlot;
                },
                {},
            );
        } else {
            dataToPlot = geojson.features.reduce(
                (dataToPlot: any, feature: GeoJSON.Feature, elementId: number) => {
                    feature.properties.id_ter = elementId;
                    const value = feature.properties[propertyName];
                    dataToPlot[elementId] = { value: value };
                    return dataToPlot;
                },
                {},
            );
        }

        return {
            dataToPlot: dataToPlot,
            classLabels: classLabels,
        };
    }

    setDropEvent() {
        this.mapService.activateDropEvent(this.handleDragAndDrop);
    }

    handleDragAndDrop = (e: any) => {
        e.stopPropagation();
        e.preventDefault();

        const dt = e.dataTransfer;
        const files = dt.files;

        if (!files.length) {
            return;
        }

        let file: any;
        let i = 0;
        while (i < files.length) {
            file = files[i];
            const extension = file.name.split('.').pop();

            if (extension === 'zip') {
                this.handleZipFile(file);
            } else if (extension === 'geojson') {
                this.handleGeojsonFile(file);
            } else {
                this.notification.warning(`Impossible d'ouvrir le fichier ${file.name}.`);
            }
            i++;
        }
    };
}
