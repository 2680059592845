<div class="container-fluid mb-3">
    <div class="row d-flex align-items-center solar-card">
        <div class="col-auto px-0 text-center">
            <span class="fa-stack">
                <i class="fa fa-circle-thin fa-stack-2x"></i>
                <i class="fa fa-info fa-stack-1x"></i>
            </span>
        </div>
        <div class="col">
            <p class="mb-0">
                Ce graphique représente une année type de production de votre installation solaire
                et de consommation du patrimoine de votre périmètre de simulation.
            </p>
            <p class="mb-0">
                Pour chaque mois, la consommation et la production sont divisées en deux
                histogrammes empilés. Celui de gauche représente la consommation (autoconsommation
                et achat sur le réseau) et celui de droite la production (autoconsommation et vente
                sur le réseau).
            </p>
        </div>
    </div>
</div>
<div id="monthly-plot" class="mb-3" *ngIf="!!monthlyDatasets">
    <div class="mb-3">
        <stStackedBarChart
            [datasets]="monthlyDatasets"
            [labels]="monthlyLabels"
            [externalTooltipHandler]="monthlyTooltipHandler"
            [displayLegend]="false"
            xAxisLabel="Mois"
            yAxisLabel="kWh"
        ></stStackedBarChart>
    </div>

    <div id="cs-self-consumption-monthly-legend" class="container-fluid">
        <div class="row gx-2">
            <div class="col-xl-4 col-lg-6 col-md-12">
                <span class="color area self-consumption"></span>
                <small>Autoconsommation&nbsp;(kWh)</small>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12">
                <span class="color area electricity-purchased"></span>
                <small>Achat&nbsp;d'électricité&nbsp;(kWh)</small>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12">
                <span class="color area electricity-sold"></span>
                <small>Vente&nbsp;sur&nbsp;le&nbsp;réseau&nbsp;(kWh)</small>
            </div>
        </div>
    </div>
</div>
