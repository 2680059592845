'use strict';

import { Component, Inject } from '@angular/core';
import { AtacGroup } from 'src/app/models/AtacTypes';
import { AtacService } from 'src/app/services/AtacService';
import { AtacResultsModal } from '../../../components/module-atac/modals/atac-results.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'atacList',
    templateUrl: './atac-list.template.html',
})
export class AtacListComponent {
    selectedGroupIndex: number;
    selectedGroup: AtacGroup;

    constructor(
        private modalService: NgbModal,
        @Inject(AtacService) private atacService: AtacService,
    ) {}

    //Display groupement on map
    showGroupementOnMap(selectedGroup: AtacGroup, index?: number) {
        this.atacService.showGroupementOnMap(selectedGroup);
        if (index) this.selectedGroupIndex = index;
    }

    /* Ouvrir modale Groupement / Résultats détaillés */
    openModalAtacResultats(selectedGroup: AtacGroup, index: number) {
        this.selectedGroup = selectedGroup;
        this.selectedGroupIndex = index;
        this.showGroupementOnMap(selectedGroup);
        const modalRef = this.modalService.open(AtacResultsModal, { size: 'xl' });

         modalRef.componentInstance.group = this.selectedGroup;
         modalRef.componentInstance.index = this.selectedGroupIndex;
    }

    get groups() {
        return this.atacService.groups;
    }

    get isSuccess() {
        return this.atacService.isSuccess;
    }
}
