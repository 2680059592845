<stTab
    class="solar"
    id="cadastre-solaire"
    [label]="title"
    [subLabel]="subTitle"
    [visible]="moduleService.isModuleActive.solar"
>
    <div class="d-flex flex-column h-100">
        <ng-container *ngIf="!mainStage">
            <div class="w-100 p-3">
                <div class="solar-card text-center">
                    <h4 class="st-text-body mb-0">
                        <span> Pour simuler un projet solaire, </span>
                        <span class="text-st-active"> sélectionner une toiture sur la carte. </span>
                    </h4>
                </div>
            </div>
        </ng-container>

        <div class="animate-switch-container h-100" [ngSwitch]="mainStage">
            <div class="animate-switch d-flex flex-column h-100" *ngSwitchCase="'newRoofStage'">
                <cadastreSolaireNewRoof class="flex-grow-1 overflow-auto"></cadastreSolaireNewRoof>
            </div>

            <div class="animate-switch d-flex flex-column h-100" *ngSwitchCase="'initStage'">
                <cadastreSolaireInit class="flex-grow-1 overflow-auto"> </cadastreSolaireInit>
            </div>

            <div class="animate-switch d-flex flex-column h-100" *ngSwitchCase="'parametersStage'">
                <cadastreSolaireParameters class="flex-grow-1 overflow-auto">
                </cadastreSolaireParameters>
            </div>

            <div class="animate-switch d-flex flex-column h-100" *ngSwitchCase="'technologyStage'">
                <cadastreSolaireTechnology
                    class="flex-grow-1 overflow-auto"
                ></cadastreSolaireTechnology>
            </div>

            <div class="animate-switch d-flex flex-column h-100" *ngSwitchCase="'thermalStage'">
                <cadastreSolaireThermal class="flex-grow-1 overflow-auto"> </cadastreSolaireThermal>
            </div>

            <div class="animate-switch d-flex flex-column h-100" *ngSwitchCase="'pvStage'">
                <cadastreSolairePv class="flex-grow-1 overflow-auto"> </cadastreSolairePv>
            </div>

            <div class="animate-switch d-flex flex-column h-100" *ngSwitchCase="'resultsStage'">
                <cadastreSolaireResults
                    *ngIf="modularity.simulations"
                    class="flex-grow-1 overflow-auto"
                >
                </cadastreSolaireResults>
            </div>
        </div>
    </div>
</stTab>
