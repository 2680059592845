import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { HousingDefaultConsumption } from 'src/app/models/Solar';

import { SolarPvSurplusService } from 'src/app/services/cadastre-solaire/cadastre-solaire-pv-surplus.service';
import { SolarService } from 'src/app/services/cadastre-solaire/cadastre-solaire.service';
import { UsefulService } from 'src/app/services/UsefulService';

@Component({
    selector: 'cadastreSolairePvSurplusConsumption',
    templateUrl: './cadastre-solaire-pv-surplus-consumption.template.html',
    styleUrls: ['./cadastre-solaire-pv-surplus-consumption.component.scss'],
})
export class CadastreSolairePvSurplusConsumptionComponent implements OnInit {
    public customElectricityConsumptionInfo: string;
    public isImportedElectricLoadDefined: boolean = false;

    public consumptionFormGroup: FormGroup;
    public isConsumptionFormValid: boolean = false;

    constructor(
        private readonly formBuilder: FormBuilder,
        private notification: ToastrService,
        @Inject(SolarPvSurplusService)
        public solarPvSurplusService: SolarPvSurplusService,
        @Inject(SolarService) public solarService: SolarService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.solarPvSurplusService.consumptionData.active = false;

        this.consumptionFormGroup = this.formBuilder.group({
            energy: [undefined, [Validators.required, Validators.min(1)]],
            subscribedPower: [undefined, [Validators.required, Validators.min(1)]],
        });

        this.isImportedElectricLoadDefined =
            this.solarPvSurplusService.importedElectricLoad.load.length > 0;

        if (this.isImportedElectricLoadDefined) {
            this._initConsumptionForImportedElectricLoad();
        } else {
            await this._initConsumptionForEquipments();
        }

        this.checkConsumptionFormValidity();
        this.consumptionFormGroup.valueChanges.subscribe((values) =>
            this.checkConsumptionFormValidity(),
        );
    }

    private _initConsumptionForImportedElectricLoad() {
        const energyCtrl = this.consumptionFormGroup.get('energy');
        energyCtrl.setValue(this.solarPvSurplusService.importedElectricLoad.energy);

        const subscribedPowerCtrl = this.consumptionFormGroup.get('subscribedPower');
        subscribedPowerCtrl.setValue(
            this.solarPvSurplusService.importedElectricLoad.subscribedPower,
        );
    }

    private async _initConsumptionForEquipments() {
        const equipmentsData = this.solarPvSurplusService.equipmentsData;
        const consumptionData = this.solarPvSurplusService.consumptionData;

        const isDifferentOccupantCount =
            equipmentsData.occupantCount != consumptionData.occupantCount;

        const isDifferentUsableFloorArea =
            equipmentsData.usableFloorArea != consumptionData.usableFloorArea;

        const newEquipments = equipmentsData.uses.filter((use) => use.active).sort();
        const previousEquipments = equipmentsData.uses.filter((use) => use.active).sort();
        const isDifferentEquipment =
            JSON.stringify(newEquipments) !== JSON.stringify(previousEquipments);

        if (isDifferentOccupantCount || isDifferentUsableFloorArea || isDifferentEquipment) {
            this.solarPvSurplusService.initConsumptionData();
            const defaultHousingConsumption = await this._getHousingElectricityParameters();

            const electricEnergyConsumed = this.usefulService.round(
                defaultHousingConsumption.annualConsumption,
            );
            const subscribedPower = this.usefulService.ceil(
                defaultHousingConsumption.subscribedPower,
            );

            const energyCtrl = this.consumptionFormGroup.get('energy');
            energyCtrl.setValue(electricEnergyConsumed);

            const subscribedPowerCtrl = this.consumptionFormGroup.get('subscribedPower');
            subscribedPowerCtrl.setValue(subscribedPower);
        } else {
            const energyCtrl = this.consumptionFormGroup.get('energy');
            energyCtrl.setValue(this.solarPvSurplusService.consumptionData.energyConsumed);

            const subscribedPowerCtrl = this.consumptionFormGroup.get('subscribedPower');
            subscribedPowerCtrl.setValue(
                this.solarPvSurplusService.consumptionData.subscribedPower,
            );
        }
    }

    cancel() {
        if (this.isImportedElectricLoadDefined) {
            this.solarPvSurplusService.updateStage('importStage');
        } else {
            this.solarPvSurplusService.updateStage('equipmentsStage');
        }
    }

    validate() {
        const energyCtrl = this.consumptionFormGroup.get('energy');
        const energy = Number(energyCtrl.value);

        const subscribedPowerCtrl = this.consumptionFormGroup.get('subscribedPower');
        const subscribedPower = Number(subscribedPowerCtrl.value);

        this.solarPvSurplusService.consumptionData.energyConsumed = energy;
        this.solarPvSurplusService.consumptionData.subscribedPower = subscribedPower;

        if (this.isImportedElectricLoadDefined) {
            this.solarPvSurplusService.consumptionData.occupantCount = null;
            this.solarPvSurplusService.consumptionData.usableFloorArea = null;
            this.solarPvSurplusService.consumptionData.uses = null;
        } else {
            const equipmentsData = this.solarPvSurplusService.equipmentsData;
            this.solarPvSurplusService.consumptionData.occupantCount = equipmentsData.occupantCount;
            this.solarPvSurplusService.consumptionData.usableFloorArea =
                equipmentsData.usableFloorArea;
            this.solarPvSurplusService.consumptionData.uses = equipmentsData.uses;
        }

        this.solarPvSurplusService.updateStage('simulationStage');
    }

    public checkConsumptionFormValidity() {
        this.isConsumptionFormValid = this.consumptionFormGroup.status === 'VALID';
    }

    private async _getHousingElectricityParameters() {
        try {
            const defaultHousingConsumption: HousingDefaultConsumption =
                await this.solarPvSurplusService.getHousingElectricityConsumption();
            return defaultHousingConsumption;
        } catch (error) {
            console.error('Error _getHousingElectricityParameters', error);
            this.notification.error(
                "Une erreur est survenue. Impossible d'initialiser les paramètres.",
            );
        }
    }

    public isSubscribedPowerDisable(): boolean {
        if (this.isImportedElectricLoadDefined) {
            return false;
        }
        return !this.solarPvSurplusService.consumptionData.active;
    }
}
