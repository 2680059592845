'use strict';

import { Component, Inject, Input, OnInit } from '@angular/core';

import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SolarService } from 'src/app/services/cadastre-solaire/cadastre-solaire.service';
import { UsefulService } from 'src/app/services/UsefulService';

@Component({
    selector: 'cadastreSolairePvValorization',
    templateUrl: './cadastre-solaire-pv-valorization.template.html',
})
export class CadastreSolairePvValorizationComponent implements OnInit {
    @Input() show: any;

    public showMobileUi: boolean = false;
    public modularity: any;
    public activeTabId: number = 1;

    constructor(
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(SolarService) public solarService: SolarService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    ngOnInit(): void {
        this.showMobileUi = this.usefulService.showMobileUi;
        this.modularity = this.localStorageService.get('preferences').modularity.solar;
        this.activeTabId = this.solarService.valorization == 'Totality' ? 1 : 2;
    }

    setValorization(valorization: string) {
        this.solarService.valorization = valorization;
        this.activeTabId = valorization == 'Totality' ? 1 : 2;
    }

    cancel() {
        this.solarService.updateMainStage('technologyStage');
    }

    validate() {
        if (this.solarService.valorization == 'Totality') {
            this.solarService.updatePvStage('pvTotalityStage');
        } else {
            this.solarService.updatePvStage('pvSurplusStage');
        }
    }
}
