<div class="row center-block">
  <p>
    <strong>Bâtiments faisant l'objet d'un paramétrage spécifique utilisateur</strong>
  </p>
  <p class="small"><em>(Cliquer sur un bâtiment sur la carte pour l'ajouter à la liste et le modifier)</em></p>
</div>
<div class="row" [hidden]="!selectedBuildings.length">
  <div class="col-1">
  </div>
  <div class="col-3">
  </div>
  <div class="col-3">
    <i class="fa fa-info-circle"
      ngbTooltip="Pour appuyer les simulations sur des données réelles, l’utilisateur a la possibilité d’importer manuellement des données réelles de courbe de charge, de courbe de production et de grille tarifaire. Ces données viennent remplacer les données modélisées dans la base. Le format est précisé dans la fenêtre de dialogue d’import des données. Veuillez à importer des séries de données complètes. Si l’utilisateur importe une série de données sur une période supérieure à 1 an, AtAC prendra en compte automatiquement la dernière année complète de données."
      container="body" placement="start"></i>
    <small>Import</small>
  </div>
  <div class="col-2">
    <i class="fa fa-info-circle"
      ngbTooltip="En fonction de ses besoins et de la réalité du parc bâti, l’utilisateur a la possibilité de forcer les rôles de chaque bâtiment au sein de l’opération d’autoconsommation collective. Le paramétrage porte sur les rôles de producteur et de consommateur. Chacun de ses deux rôles peut ainsi être défini comme forcément oui, forcément non ou potentiellement oui. Cette option permet notamment d’imposer des bâtiments producteurs connus autour desquels trouver des consommateurs ou d’exclure des bâtiments dont on sait que les toitures ne sont pas appropriées. Attention : l’algorithme considère qu’un bâtiment producteur est forcément consommateur et qu’un bâtiment dont le rôle de consommateur est exclu ne peut pas être producteur."
      placement="start" container="body"></i>
    <small>Rôle</small>
  </div>
  <div class="col-3">
    <i class="fa fa-info-circle"
      ngbTooltip="Puissance crête installée sur la toiture. Paramètre modifiable par l’utilisateur si le bâtiment est défini comme « producteur obligé ». Par défaut ce paramètre est initialisé avec la puissance crête maximale installable sur la toiture plafonnée à 250kWc. Au-delà de 250 kWc les raccordements se font en HTA et on sort du périmètre réglementaire de l’autoconsommation collective étendue."
      tooltip-placement="start" tooltip-append-to-body="true" tooltip-trigger="mouseenter"></i>
    <small>PPV (kWc)</small>
  </div>
</div>
<div class="row" *ngFor="let building of selectedBuildings; let index = index" style="margin-top:10px;">
  <div class="col-1">
    <a style="cursor: pointer" (click)="targetElementOnMap(building.id_ter)">
      <i class="fa fa-2x fa-bullseye" [ngClass]="{'selectedLine' : index == selectedBuildingIndex}"
        ngbTooltip="Afficher sur la carte" placement="top" container="body"></i>
    </a>
  </div>
  <div class="col-3" style="margin-top:5px;" [ngClass]="{'selectedLine' : index == selectedBuildingIndex}">
    {{ building.lib_ter }}
  </div>
  <div class="col-3">
    <a style="cursor: pointer" (click)="openModalAtacImportCourbe(building.id_ter, building.hasCDC, 'CDC')">
      <i class="fa fa-2x fa-line-chart" [ngStyle]="{color: building.hasCDC ? 'blue' : 'gray'}"
        ngbTooltip="Importer courbe de charge format CSV" placement="top" container="body"></i>
    </a>
    <a style="cursor: pointer" (click)="openModalAtacImportCourbe(building.id_ter, building.hasCDP, 'CDP')">
      <i class="fa fa-2x fa-sun-o" [ngStyle]="{color: building.hasCDP ? 'blue' : 'gray'}"
        ngbTooltip="Importer courbe de production format CSV" placement="top" container="body
       "></i>
    </a>
    <a style="cursor: pointer">
      <i class="fa fa-2x fa-eur" [ngStyle]="{color: building.hasCDT ? 'blue' : 'lightgray'}"
        ngbTooltip="Importer une grille tarifaire. Fonctionnalité non disponibe." placement="top" container="body
       "></i>
    </a>
  </div>
  <div class="col-2">
    <a style="cursor: pointer" (click)="setProducer(building.id_ter)">
      <i class="fa fa-2x fa-television" [ngClass]="building.producerClass"
        [ngbTooltip]="producersTooltip[building.id_ter]" placement="top" container="body"></i>
    </a>
    <a style="cursor: pointer" (click)="setConsumer(building.id_ter)">
      <i class='fa fa-2x fa-building-o' [ngClass]="building.consumerClass"
        [ngbTooltip]="consumersTooltip[building.id_ter]" placement="top" container="body"></i>
    </a>
  </div>
  <div class="col-3">
    <span style="position:absolute; right:70px; width:70px;">
      <stInput type="number" [min]="0" [max]="building.PPV" [isDisabled]="disabledPPV(building.id_ter)"
        [(ngModel)]="PPVBuildings[building.id_ter]"></stInput>
    </span>

    <a style="cursor: pointer" (click)="deleteBatiment(building.id_ter)">
      <i class='fa fa-2x fa-times' style="position:absolute; right:30px; color:#FB8576;" ngbTooltip="Supprimer bâtiment"
        placement="start" container="body"></i>
    </a>
  </div>
</div>
<importLoadCurveModal [terId]="modalData.terId" [hasLoadCurve]="modalData.hasLoadCurve"
  [loadCurveType]="modalData.loadCurveType" (onUpload)="setLoadCurve($event)">
</importLoadCurveModal>