<div class="container-fluid p-3 pt-0">
    <stAccordion innerId="{{ dashboard.indicatorId }}-building-custom-table" [items]="[dashboard.table]">
        <ng-template #contentTemplate let-table>
            <table class="table table-striped table-sm">
                <thead>
                    <tr>
                        <th scope="col" sortable="label" (sort)="onSort($event)" class="label-col">
                            {{ table.column }}
                        </th>
                        <th scope="col" sortable="value" (sort)="onSort($event)" class="value-col">
                            {{ table.countLabel }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr id="db-{{ row.territoryId }}-{{ dashboard.indicatorId }}"
                        *ngFor="let row of buildingAndCustomTerritoryRows$ | async">
                        <th scope="row">
                            {{ row.label }}
                        </th>
                        <td scope="row">
                            {{ row.stringifiedValue }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>
    </stAccordion>
</div>