<div id="dashboard-control" class="leaflet-control-zoom leaflet-bar leaflet-control"
    [hidden]="!dashboardService.moduleDashboard.isAvailable">
    <a href="javascript:void(0)" title="Tableau de bord" role="button" (click)="toggleTableState()"
        [ngClass]="{'text-st-active': dashboardService.moduleDashboard.isOpen && dashboardService.tablePlot == 'table'}"
        *ngIf="isTableAvailable">
        <span class="fa fa-table fa-2x"></span>
    </a>
    <a href="javascript:void(0)" title="Graphes" role="button" (click)="togglePlotState()"
        [ngClass]="{'text-st-active': dashboardService.moduleDashboard.isOpen &&  dashboardService.tablePlot == 'plot'}"
        *ngIf="isPlotAvailable && dashboardService.isPlotAvailable">
        <span class="fa fa-bar-chart fa-2x"></span>
    </a>
</div>