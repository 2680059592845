<div class="d-flex align-items-center">
  <button class="btn btn-link p-0 flex-grow-1 section-title" type="button"
    (click)="category.isCollapsed = !category.isCollapsed" [attr.aria-expanded]="!category.isCollapsed">
    <i class="fa fa-caret-down me-2"></i>
    <span>{{ category.label }}</span>
  </button>

  <div class="filter-shortcut" [hidden]="category.isCollapsed" [ngClass]="{'d-flex': !category.isCollapsed}">
    <i class="fa fa-spinner fa-spin" [hidden]="!(isWaitingForData || isWaitingForAll)"></i>

    <span class="fa fa-filter ps-1 cursor-pointer" [hidden]="!showAddAllFilters()" (click)="addAllFilters()"
      ngbTooltip="Cocher tous les champs" placement="start" container="body">
    </span>

    <span class="fa fa-filter-slash ps-1 cursor-pointer" [hidden]="!showRemoveAllFilters()" (click)="removeAllFilters()"
      ngbTooltip="Décocher tous les champs" placement="start" container="body">
    </span>
  </div>
</div>

<div class="collapse" [(ngbCollapse)]="category.isCollapsed" id="collapse-{{ indicatorId }}-{{ category.id }}">
  <div class="container-fluid">
    <div class="d-flex align-items-center"
      *ngFor="let criteria of category.criteria.filter(keepAvailableFilter) | sortBy: 'order'">
      <stCheckbox class="flex-grow-1" innerId="{{ indicatorId }}-{{ category.id }}-{{ criteria.id }}" labelClass="w-100"
        [isDisabled]="isWorkInProgress" [(ngModel)]="criteria.active" (change)="updateFilter(criteria.id)">
        {{ criteria.label || 'Non renseigné' }}
      </stCheckbox>
      <div class="mx-3">
        <stProgressbar [percentageValue]="criteria.relativeValue" [absoluteValue]="criteria.absoluteValue" [unit]="unit"
          type="primary"></stProgressbar>
      </div>
      <stProgressbar [percentageValue]="criteria.initRelativeValue" [absoluteValue]="criteria.initAbsoluteValue"
        [unit]="unit" type="secondary"></stProgressbar>
    </div>
  </div>
</div>