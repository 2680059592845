('use strict');

import { Inject, Injectable } from '@angular/core';

import {
    PrElement,
    PrElectronPath,
    PrPath,
    PrParameter,
    PrHypothesisValue,
} from '../../models/PrTypes';
import { Indicator } from 'src/app/models/Indicator';

import { EventService } from 'src/app/services/event.service';
import { ModuleService } from 'src/app/services/module.service';
import { RestService } from '../RestService';
import { TerService } from '../TerService';
import { UsefulService } from '../UsefulService';

const BUILDING = '01';
const BT_LINE = '05';
const BT_START = '08';
const HTA_BT_TRANSFORMER = '09';
const HTA_BT_POST = '10';
const HTA_LINE = '15';
const HTA_START = '18';
const HTA_TRANSFORMER = '19';
const DEFAULT_HTA_LENGTH = 500;
const DEFAULT_BT_LENGTH = 30;

@Injectable({
    providedIn: 'root',
})
export class ProsperReseauxService {
    public prElements = [
        {
            id: '20',
            label: 'Poste Source',
            elements: [],
            parameters: [],
            order: 1,
        },
        {
            id: '18',
            label: 'Départ HTA',
            elements: [],
            parameters: [],
            order: 2,
        },
        {
            id: '15',
            label: 'Ligne HTA',
            elements: [],
            parameters: [],
            order: 3,
        },
        {
            id: '10',
            label: 'Poste HTA/BT',
            elements: [],
            parameters: [],
            order: 4,
        },
        {
            id: '09',
            label: 'Transformateur HTA/BT',
            elements: [],
            parameters: [],
            order: 5,
        },
        {
            id: '08',
            label: 'Départ BT',
            elements: [],
            parameters: [],
            order: 6,
        },
        {
            id: '05',
            label: 'Ligne BT',
            elements: [],
            parameters: [],
            order: 7,
        },
        {
            id: '01',
            label: 'Bâtiment',
            elements: [],
            parameters: [],
            order: 8,
        },
    ];

    public featuresToAddByType = [
        { id: '01', label: 'Bâtiment', features: [] },
        { id: '05', label: 'Ligne BT', features: [] },
        { id: '08', label: 'Départ BT', features: [] },
        { id: '09', label: 'Transformateur HTA/BT', features: [] },
        { id: '10', label: 'Poste HTA/BT', features: [] },
        { id: '15', label: 'Ligne HTA', features: [] },
        { id: '18', label: 'Départ HTA', features: [] },
        { id: '20', label: 'Poste Source', features: [] },
    ];

    public scenario: any;

    public elementsToModifyByType = [
        { id: '20', label: 'Poste Source', isActive: false, elements: [], newPaths: [] },
        { id: '18', label: 'Départ HTA', isActive: false, elements: [], newPaths: [] },
        { id: '15', label: 'Ligne HTA', isActive: false, elements: [], newPaths: [] },
        { id: '10', label: 'Poste HTA/BT', isActive: false, elements: [], newPaths: [] },
        { id: '09', label: 'Transformateur HTA/BT', isActive: false, elements: [], newPaths: [] },
        { id: '08', label: 'Départ BT', isActive: false, elements: [], newPaths: [] },
        { id: '05', label: 'Ligne BT', isActive: false, elements: [], newPaths: [] },
        { id: '01', label: 'Bâtiment', isActive: false, elements: [], newPaths: [] },
    ];

    public collapse = {
        prTypes: [],
        elements: [],
    };

    public miscInfo = {
        isInitialized: false,
        selectedElement: null,
    };

    constructor(
        @Inject(EventService) private eventService: EventService,
        @Inject(ModuleService) public moduleService: ModuleService,
        @Inject(TerService) private terService: TerService,
        @Inject(RestService) private restService: RestService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    public findPrElementByTypeId(typeId: string, prElements: any = this.prElements) {
        return prElements.find((element: any) => element.id == typeId);
    }

    public findElementById(id: string, prElements: any = this.prElements) {
        const prElement = prElements.find((prElement: any) =>
            prElement.elements.some((element: any) => element.id == id),
        );
        if (prElement) {
            return prElement.elements.find((element: any) => element.id == id);
        }
        return;
    }

    public findElementsToModifyByTypeId(typeId: string) {
        return this.elementsToModifyByType.find(
            (elementsToModify: any) => elementsToModify.id == typeId,
        );
    }

    public findFeaturesToAddByTypeId(typeId: string) {
        return this.featuresToAddByType.find((featuresToAdd: any) => featuresToAdd.id == typeId);
    }

    async getPrParameters(indicatorId: number) {
        try {
            const parameters = {
                indicatorId: indicatorId,
            };
            const prParameters = await this.restService.getPrHypothesisParameters(parameters);
            prParameters.forEach((parameter) => this._updateParameters(parameter, indicatorId));
        } catch (error) {
            console.error('Error getPrParameters', error);
        }
    }

    private _updateParameters(parameter: PrParameter, indicatorId: number) {
        const typeId = parameter.typeId;
        const prElement = this.findPrElementByTypeId(typeId);
        const existingParameters: PrParameter[] = prElement.parameters;
        const parametersToUpdate = existingParameters.find(
            (parametersToUpdate) => parametersToUpdate.id === parameter.id,
        );

        if (parametersToUpdate) {
            const isAlreadyIncludes = parametersToUpdate.indicatorIds.includes(indicatorId);
            if (!isAlreadyIncludes) {
                parametersToUpdate.indicatorIds.push(indicatorId);
            }
        } else {
            parameter.indicatorIds = [indicatorId];
            existingParameters.push(parameter);
        }
    }

    updateHypothesisWithParameters() {
        this.prElements.forEach((prElement) =>
            prElement.parameters.forEach((parameter) => this._updateHypothesis(parameter)),
        );

        // update empty hypothesis for new element
        this.elementsToModifyByType.forEach((elementsToModify) =>
            elementsToModify.elements
                .filter((element) => element.isNew && !element.hypothesis.length)
                .forEach((element) => this._setHypothesis(element, false)),
        );
    }

    private _updateHypothesis(parameter: PrParameter) {
        const typeId = parameter.typeId;

        const prElementToModify = this.findElementsToModifyByTypeId(typeId);
        const elementsToModify = prElementToModify.elements;

        if (elementsToModify.length) {
            elementsToModify
                .filter((element: any) =>
                    element.hypothesis.some((hypothesis: any) => hypothesis.id == parameter.id),
                )
                .forEach((element: any) => {
                    element.hypothesis
                        .filter((hypothesis: any) => hypothesis.id == parameter.id)
                        .forEach((hypothesis: any) => {
                            this._enableHypothesis(hypothesis, parameter);
                            this.setIsHypothesisAvailable(element);
                        });
                });
        }
    }

    private _enableHypothesis(hypothesis: any, parameter: any) {
        const value = hypothesis.value;

        Object.assign(hypothesis, JSON.parse(JSON.stringify(parameter)));
        hypothesis.isAvailable = true;
        hypothesis.value = value; // when importing scenario, override value assigned from parameter

        const isNumerical = !hypothesis.options;
        const isCategorical = !!hypothesis.options;

        if (isNumerical) {
            const decimalCount = -Math.log10(hypothesis.step);
            const defaultValue = hypothesis.defaultValue;
            hypothesis.defaultValue = this.usefulService.round(defaultValue, decimalCount);
            if (!!value) {
                hypothesis.value = this.usefulService.round(value, decimalCount);
            }
        }
        if (isCategorical) {
            hypothesis.referenceLabel = hypothesis.options.find(
                (option: any) => option.value == hypothesis.reference,
            ).label;
        }
    }

    setIsHypothesisAvailable(element: PrElement) {
        element.isHypothesisAvailable = element.hypothesis.some(
            (hypothesis: any) => hypothesis.isAvailable,
        );
    }

    async getPrElementLabels(typeId: string, energyId: string = '01'): Promise<any[]> {
        const prElement = this.findPrElementByTypeId(typeId);
        const elements = prElement.elements;

        if (elements.length > 0) {
            return elements;
        }

        const parameters = {
            year: 2015,
            energyId: energyId,
            typeId: typeId,
            territoryScaleTypeId: this.terService.territoryScale.typeId,
            filterIds: JSON.stringify(this.terService.territories.map((t) => t.id)),
        };
        try {
            const response = await this.restService.getGeoPrLabels(parameters);
            prElement.elements = response;

            return response;
        } catch (error) {
            console.error('Error getPrElementLabels', error);
            throw error;
        }
    }

    getHypothesisInputs(indicatorId: number) {
        const elementsToModify = this.elementsToModifyByType.some((elementsToModify) =>
            elementsToModify.elements.some((element) =>
                element.hypothesis.some((hypothesis: any) =>
                    hypothesis.indicatorIds.includes(indicatorId),
                ),
            ),
        );

        if (!elementsToModify) {
            return [];
        }

        const elementsToModifyByType = JSON.parse(JSON.stringify(this.elementsToModifyByType));

        // convert value to float for numerical parameters
        elementsToModifyByType
            .filter((elementsToModify: any) => elementsToModify.elements.length)
            .forEach((elementsToModify: any) =>
                elementsToModify.elements
                    .filter((element: PrElement) => element.hypothesis.length)
                    .forEach((element: PrElement) =>
                        element.hypothesis
                            .filter(
                                (hypothesis: PrHypothesisValue) =>
                                    !hypothesis.options && hypothesis.value != '',
                            )
                            .forEach(
                                (hypothesis) => (hypothesis.value = parseFloat(hypothesis.value)),
                            ),
                    ),
            );

        return elementsToModifyByType;
    }

    getValuesToAggregate(typeId: string) {
        const prElements = this.findPrElementByTypeId(typeId);
        const elements = prElements.elements;
        const valuesToAggregate = elements.map((element: any) => ({
            id: element.id,
            value: element.valueToAggregate,
        }));
        return valuesToAggregate;
    }

    async getPrHypothesisValues(id: string, typeId: string) {
        const parameters = {
            id: id,
            typeId: typeId,
        };
        const prHypothesisValues = await this.restService.getPrHypothesisValues(parameters);

        return prHypothesisValues;
    }

    setHypothesisValues(prHypothesisValues: any, typeId: string) {
        const prElement = this.findPrElementByTypeId(typeId);
        const hypothesisParameters = prElement.parameters;

        const hypothesisValues = prHypothesisValues.map((hypothesis: any) => {
            const parameter = hypothesisParameters.find(
                (parameter: any) => parameter.id == hypothesis.id,
            );
            hypothesis.isAvailable = !!parameter;
            hypothesis.indicatorIds = [];
            if (parameter) {
                this._enableHypothesis(hypothesis, parameter);
            }
            return hypothesis;
        });

        return hypothesisValues;
    }

    deleteFromFeaturesToAdd(typeId: string, element: PrElement) {
        const featuresToAdd = this.findFeaturesToAddByTypeId(typeId);
        featuresToAdd.features = featuresToAdd.features.filter(
            (feature: any) => feature.properties.id !== element.id,
        );
    }

    deletePathFromElementsToModify(typeId: string, element: PrElement, elementsToDelete: any) {
        this.elementsToModifyByType.forEach((elementToModify: any) => {
            const newPaths = elementToModify.newPaths;
            const elements = elementToModify.elements;

            const pathsToDelete = newPaths.reduce((pathsToDelete: PrPath[], newPath: PrPath) => {
                const isElementInPath =
                    newPath.id === element.id || newPath.path.some((x) => x[typeId] === element.id);

                if (isElementInPath) {
                    this._deleteDownstreamElements(
                        elementsToDelete,
                        elements,
                        element,
                        newPath,
                        typeId,
                    );
                    if (typeId !== BUILDING) {
                        newPath.path = newPath.path.filter((x) => x[typeId] !== element.id);
                    }

                    if (!(newPath.path.length > 0 && newPath.id !== element.id)) {
                        pathsToDelete.push(newPath);
                    }
                }
                return pathsToDelete;
            }, []);

            pathsToDelete.forEach((path: PrPath) => {
                const index = newPaths.indexOf(path);
                newPaths.splice(index, 1);
            });
        });
    }

    private _deleteDownstreamElements(
        elementsToDelete: any,
        elementsToModify: any,
        element: any,
        newPath: PrPath,
        typeId: string,
    ) {
        //TODO : pour un troncon BT / HTA a supprimer, il faut ajoute rles troncons avals
        //Ici, devrait fonctionner pour un poste & départ
        const pathsToDelete = this._findPathsToDelete(element, newPath, typeId);

        pathsToDelete.forEach((path) => {
            for (const key in path) {
                if (key === 'aggCost') continue;
                const pathTypeId = key.split('_')[1];

                // Si l'élément est aval & que l'element existe toujours dans elementsToModify,
                // on le tag à supprimer
                const elements = elementsToModify.find(
                    (elements: any) => elements.id === path[key],
                );
                if (elements) {
                    if (parseInt(pathTypeId) <= parseInt(typeId) || element.id === newPath.id) {
                        if (!elementsToDelete[typeId].includes(elements))
                            elementsToDelete[typeId].push(elements);
                    }
                }
            }
        });
    }

    private _findPathsToDelete(element: PrElement, newPath: PrPath, typeId: string) {
        const isElementInPath = (path: PrElectronPath) => {
            return path[typeId] === element.id || element.id === newPath.id;
        };
        let pathToDelete = newPath.path.filter(isElementInPath);

        if (typeId === BT_LINE) {
            const firstEl = pathToDelete.sort((a, b) => a.aggCost - b.aggCost)[0];
            pathToDelete = pathToDelete.filter(
                (x) => x.aggCost >= firstEl.aggCost || element.id === newPath.id,
            );
        } else if (typeId === HTA_LINE) {
            const firstEl = pathToDelete.sort((a, b) => b.aggCost - a.aggCost)[0];
            pathToDelete = pathToDelete.filter(
                (x) => x.aggCost <= firstEl.aggCost || element.id === newPath.id,
            );
        }
        return pathToDelete;
    }

    async updateElementType(typeId: string) {
        const prElement = this.findPrElementByTypeId(typeId);
        const prElementToModify = this.findElementsToModifyByTypeId(typeId);

        if (prElement.elements.length > 0) {
            prElement.elements = [];
            try {
                const elementsLabel = await this.getPrElementLabels(typeId);

                // On supprime les hypothèses qui n'existent plus
                const elements = prElementToModify.elements;
                prElementToModify.elements = this.cleanArray(elements, elementsLabel).cleanedArray;

                const newPaths = prElementToModify.newPaths;
                const cleanPaths = this.cleanArray(newPaths, elements);
                prElementToModify.newPaths = cleanPaths.cleanedArray;

                cleanPaths.removedElements.forEach((newPath: PrPath) => {
                    // Si on a supprimé l'élément de départ de notre nouveau réseau, il faut le supprimer aussi
                    newPath.path.forEach((path: PrElectronPath) => {
                        const typeIds = Object.keys(path).filter((key) => !isNaN(parseFloat(key)));
                        typeIds.forEach((typeId) => {
                            const featuresToAdd = this.findFeaturesToAddByTypeId(typeId);
                            featuresToAdd.features = featuresToAdd.features.filter(
                                (feature: any) => feature.properites.id !== path[typeId],
                            );
                        });
                    });
                });
            } catch (error) {
                console.error('Error updateElementType', error);
            }
        }
    }

    private cleanArray(arrayToClean: any[], referenceArray: any[]) {
        const cleanedArray = arrayToClean.filter((element) =>
            referenceArray.some((ref) => ref.id === element.id),
        );
        const removedElements = arrayToClean.filter((element) =>
            referenceArray.every((ref) => ref.id !== element.id),
        );

        const result = {
            cleanedArray: cleanedArray,
            removedElements: removedElements,
        };
        return result;
    }

    deleteIndicatorIdFromHypothesis(indicatorId: number, indicatorPlots: Indicator[]) {
        this.prElements.forEach(
            (prElement: any) =>
                (prElement.parameters = prElement.parameters
                    .map((parameter: PrParameter) => {
                        parameter.indicatorIds = parameter.indicatorIds.filter(
                            (id: number) => id !== indicatorId,
                        );
                        return parameter;
                    })
                    .filter((parameter: PrParameter) => parameter.indicatorIds.length > 0)),
        );

        this.elementsToModifyByType
            // .filter((elementsToModify) =>
            //     elementsToModify.elements.some((element) =>
            //         element.hypothesis.some((hypothesis: any) =>
            //             hypothesis.indicatorIds.includes(indicatorId),
            //         ),
            //     ),
            // )
            .forEach((elementsToModify) => {
                elementsToModify.elements.forEach((element) => {
                    element.hypothesis
                        .filter((hypothesis: any) => hypothesis.indicatorIds.includes(indicatorId))
                        .forEach((hypothesis: any) => {
                            const index = hypothesis.indicatorIds.indexOf(indicatorId);
                            hypothesis.indicatorIds.splice(index, 1);
                            hypothesis.isAvailable = hypothesis.indicatorIds.length > 0;
                        });
                    this.setIsHypothesisAvailable(element);
                    this.setIsDrawingAvailable(element, indicatorPlots);
                });
            });
    }

    async addElement(element: PrElement, indicatorPlots: Indicator[]) {
        const id = element.id;
        const typeId = element.typeId;

        this.elementsToModifyByType.forEach(
            (elementsToModify) => (elementsToModify.isActive = false),
        );

        const prElementToModify = this.findElementsToModifyByTypeId(typeId);
        prElementToModify.isActive = true;

        const elementsToModify = prElementToModify.elements;

        const isAlreadySelected = elementsToModify.some((element: PrElement) => element.id === id);
        if (isAlreadySelected) {
            return;
        }

        this.setIsDrawingAvailable(element, indicatorPlots);
        await this._setHypothesis(element);
    }

    setIsDrawingAvailable(element: PrElement, indicatorPlots: Indicator[]) {
        const typeId = element.typeId;

        element.isDrawingAvailable = indicatorPlots
            .filter((indicatorPlot) => indicatorPlot.plugin == 'prosper_reseaux')
            .some((indicatorPlot) => indicatorPlot.vector_base == `res_01_${typeId}`);

        switch (typeId) {
            case '05':
                element.drawingTooltip =
                    'Pour ajouter une extension, afficher un indicateur \u00ab\u00a0Ligne\u00a0BT\u00a0\u00bb.';
                break;
            case '15':
                element.drawingTooltip =
                    'Pour ajouter une extension, afficher un indicateur \u00ab\u00a0Ligne\u00a0HTA\u00a0\u00bb.';
                break;
            case '09':
                element.drawingTooltip =
                    'Pour ajouter un nouveau départ BT, afficher un indicateur \u00ab\u00a0Transformateur\u00a0HTA/BT\u00a0\u00bb.';
                break;
            case '10':
                element.drawingTooltip =
                    'Pour ajouter un nouveau transformateur et départ BT, afficher un indicateur \u00ab\u00a0Poste\u00a0HTA/BT\u00a0\u00bb.';
                break;
            case '20':
                element.drawingTooltip =
                    'Pour ajouter un nouveau départ HTA, afficher un indicateur \u00ab\u00a0Poste\u00a0source\u00a0\u00bb.';
                break;
        }
    }

    private async _setHypothesis(element: PrElement, addToElementsToModify: boolean = true) {
        const id = element.id;
        const typeId = element.typeId;

        try {
            const prHypothesisValues = await this.getPrHypothesisValues(id, typeId);
            const hypothesisValues = this.setHypothesisValues(prHypothesisValues, typeId);
            element.hypothesis = hypothesisValues;
            this.setIsHypothesisAvailable(element);

            if (['15', '05'].includes(typeId)) {
                element.applyChanges = {
                    label: 'À tout le départ',
                    value: 'allFromStart',
                };
            }

            if (addToElementsToModify) {
                const prElementToModify = this.findElementsToModifyByTypeId(typeId);
                const elementsToModify = prElementToModify.elements;
                elementsToModify.push(element);
            }
        } catch (error) {
            console.error('Error _setHypothesis', error);
        }
    }

    openPrTypeCollapse(panelId: string) {
        if (!this.collapse.prTypes.includes(panelId)) {
            this.collapse.prTypes.push(panelId);
        }
    }

    closePrTypeCollapse(panelId: string) {
        const index = this.collapse.prTypes.indexOf(panelId);
        if (index >= 0) {
            this.collapse.prTypes.splice(index, 1);
        }
    }

    openElementCollapse(panelId: string) {
        if (!this.collapse.elements.includes(panelId)) {
            this.collapse.elements.push(panelId);
        }
    }

    closeElementCollapse(panelId: string) {
        const index = this.collapse.elements.indexOf(panelId);
        if (index >= 0) {
            this.collapse.elements.splice(index, 1);
        }
    }

    setCollapsePrTypeIds() {
        this.collapse.prTypes = this.elementsToModifyByType
            .filter((elementType) => elementType.elements.length)
            .map((elementType) => `panel-${elementType.id}`);
    }

    setCollapseElementIds() {
        const elements = this.elementsToModifyByType
            .filter((elementType) => elementType.elements.length)
            .map((elementType) => elementType.elements);
        const flattenElements = elements.flat();
        this.collapse.elements = flattenElements.map((elements) => `panel-${elements.id}`);
    }

    getScenarioToSave() {
        const prDataToSave = {
            name: 'prosper_reseaux',
            data: {
                prElements: this.prElements,
                elementsToModifyByType: this.elementsToModifyByType,
                featuresToAddByType: this.featuresToAddByType,
                collapse: this.collapse,
                miscInfo: this.miscInfo,
            },
        };
        return prDataToSave;
    }

    loadSavedScenario(data: any) {
        this.prElements = data.prElements;
        this.elementsToModifyByType = data.elementsToModifyByType;
        this.featuresToAddByType = data.featuresToAddByType;
        this.collapse = data.collapse;
        this.miscInfo = data.miscInfo;
        this.moduleService.openModule('prosperReseaux');

        this.eventService.emit('prosperReseauxHypothesisUpdated');
    }
}
