'use strict';

import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, filter } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'stInputSearch',
    templateUrl: './st-input-search.template.html',
    styleUrls: ['./st-input-search.component.scss'],
})
export class StInputSearchComponent {
    @ViewChild('instance', { static: true }) instance: NgbTypeahead;
    @Input() set initValue(value: string) {
        this.setInitLabel(value);
    }
    @Input() popupClass: string;
    @Input() placement: string = 'bottom-start';
    @Input() container: string;
    @Input() isDisabled: boolean = false;
    @Input() innerId: string;
    @Input() innerClass: string;
    @Input() label: string;
    @Input() style: string;
    @Input() placeholder: string;
    @Input() limit: number = 50;
    @Input() clear: boolean = true;
    @Input() set options(value: any[]) {
        this._options = value;
        this.formatOptions();
    }

    get options(): Array<any> {
        return this._options;
    }
    @Output() onSelect = new EventEmitter();
    @Output() onBlur = new EventEmitter();

    val = '';
    private _options: any[];
    private _formatedOptions: { label: string; noCaseLabel: string; ordre: number }[];
    focus$ = new Subject<string>();
    click$ = new Subject<string>();

    initLabel: string = '';

    handleOnSelect($event: any, input: any) {
        $event.preventDefault();
        const formatedItem = $event.item;
        const item: any = this._options.find((option) => option[this.label] === formatedItem.label);
        this.onSelect.emit(item);

        input.value = this.clear ? '' : item.label;
    }

    handleOnBlur($event: any) {
        $event.preventDefault();

        const label = $event.target.value;
        const item: any = this._options.find((option) => option[this.label] === label);
        this.onBlur.emit(item);
    }

    toNoCase(item: string) {
        if (!item) {
            return ' ';
        }

        let temp = String(item).toLowerCase();

        const nonAsciis = {
            a: '[àáâãäå]',
            ae: 'æ',
            c: 'ç',
            e: '[èéêë]',
            i: '[ìíîï]',
            n: 'ñ',
            o: '[òóôõö]',
            oe: 'œ',
            u: '[ùúûűü]',
            y: '[ýÿ]',
        };

        for (let i in nonAsciis) {
            temp = temp.replace(new RegExp(nonAsciis[i], 'g'), i);
        }
        // temp = temp.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, '\\$&');
        temp = temp.replace(/[a\341\301\340\300\342\302\344\304]/gi, 'a');
        temp = temp.replace(/[e\351\311\350\310\352\312\353\313]/gi, 'e');
        temp = temp.replace(/[i\355\315\354\314\356\316\357\317]/gi, 'i');
        temp = temp.replace(/[o\363\323\362\322\364\324\366\326]/gi, 'o');
        temp = temp.replace(/[u\372\332\371\331\373\333\374\334]/gi, 'u');
        temp = temp.replace(/[c\347\307]/gi, 'c');
        temp = temp.replace(/['+\-]/gi, ' ');
        temp = temp.replace(/[(\)\.\,]/gi, '');
        return temp;
    }

    formatOptions() {
        this._formatedOptions = this._options.map((option) => {
            return {
                label: option[this.label],
                noCaseLabel: this.toNoCase(option[this.label]),
                ordre: option.ordre,
            };
        });
    }

    search: OperatorFunction<
        string,
        readonly { label: string; noCaseLabel: string; ordre: number }[]
    > = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
        const inputFocus$ = this.focus$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            map((term: string) =>
                (term === ''
                    ? this._formatedOptions
                    : this._formatedOptions.filter(
                          (option) => this.toNoCase(option.label).indexOf(this.toNoCase(term)) > -1,
                      )
                ).slice(0, this.limit),
            ),
        );
    };

    formatter(x: any) {
        return x.label;
    }

    setInitLabel(initValue: string) {
        const initOption = this._options.find((option) => option.value === initValue);
        if (initOption) {
            this.initLabel = initOption[this.label];
        }
    }
}
