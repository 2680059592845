'use strict';

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PrParameter, PrHypothesisValue } from 'src/app/models/PrTypes';

@Component({
    selector: 'prosperReseauxNewElement',
    templateUrl: './prosper-reseaux-new-element.template.html',
})
export class ProsperReseauxNewElementModal {
    @Input() set elementsInfo(elementsInfo: any) {
        this._elementsInfo = elementsInfo;
    }

    @Output() valueSubmitted = new EventEmitter();

    private _elementsInfo = {};

    constructor(
        public activeModal: NgbActiveModal,
        ) {}

    cancel() {
        this.valueSubmitted.emit({
            isConfirmed: false,
        });
        this.close();
    }

    submit() {
        const hypothesis: PrHypothesisValue = this.elementsInfo.elements.map((element: any) => {
            const newHypothesis = JSON.parse(JSON.stringify(element.parameters));

            // numerical
            newHypothesis
                .filter((hypothesis: any) => !hypothesis.options)
                .forEach((hypothesis: any) => {
                    const value = parseFloat(hypothesis.value);
                    const defaultValue = hypothesis.defaultValue;
                    hypothesis.reference = defaultValue;
                    hypothesis.referenceLabel = defaultValue;
                    hypothesis.value = value == defaultValue ? '' : value;
                });

            // categorical
            newHypothesis
                .filter((hypothesis: any) => !!hypothesis.options)
                .forEach((hypothesis: any) => {
                    const value = hypothesis.value;
                    const defaultValue = hypothesis.defaultValue;
                    hypothesis.reference = defaultValue;
                    hypothesis.value = value == defaultValue ? '' : value;
                });

            newHypothesis.sort((a: any, b: any) => a.id - b.id);

            let maxLineLength: number;
            if (element.maxLineLength) {
                maxLineLength = parseFloat(element.maxLineLength);
            }
            return {
                id: element.id,
                hypothesis: newHypothesis,
                maxLineLength: maxLineLength,
            };
        }, []);

        this.valueSubmitted.emit({
            isConfirmed: true,
            data: hypothesis,
        });
        this.close();
    }

    close() {
        this.activeModal.close();
    }

    get elementsInfo(): any {
        return this._elementsInfo;
    }
}
