('use strict');

import { Inject, Injectable, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Meta, Title } from '@angular/platform-browser';

import { RestService } from 'src/app/services/RestService';

@Injectable({
    providedIn: 'root',
})
export class MetaTagService implements OnInit {
    public metaInfo: any;

    constructor(
        private window: Window,
        private titleService: Title,
        private metaService: Meta,
        @Inject(RestService) private restService: RestService,
    ) {}

    async ngOnInit() {

        this.metaInfo = environment.metaData;

        this.setMetaTags();
        this.setTitle();
        this.setFavicon();
    }

    setMetaTags() {
        this.metaService.addTag({ name: 'description', content: this.metaInfo.description });
    }

    createMetaTag(name: string, content: string) {
        const metaElement = this.window.document.createElement('meta');
        metaElement.name = name;
        metaElement.content = content;

        const headElement = this.window.document.getElementsByTagName('head')[0];
        const titleElement = this.window.document.getElementsByTagName('title')[0];
        headElement.insertBefore(metaElement, titleElement);
    }

    setTitle() {
        this.titleService.setTitle(this.metaInfo.title);
    }

    setFavicon() {
        const iconElement: any = this.window.document.getElementById('favicon');
        iconElement.setAttribute('href', `assets/img/favicon/${this.metaInfo.favicon}`);
    }
}
