'use strict';

import { Component, Inject, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { TerritoryScale } from 'src/app/models/TerritoryTypes';
import { Indicator } from 'src/app/models/Indicator';

import { CatalogService } from 'src/app/services/catalog.service';
import { LoaderService } from 'src/app/services/LoaderService';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ModuleService } from 'src/app/services/module.service';
import { PlotIndicatorService } from 'src/app/services/plotIndicator/plot-indicator.service';

@Component({
    selector: 'legend-toolbar',
    templateUrl: './legend-toolbar.template.html',
    styleUrls: ['./legend-toolbar.component.css'],
})
export class LegendToolbarComponent implements OnInit {
    @Input() indicatorPlot: Indicator;

    public availableGranularities: TerritoryScale[];
    public isFilterable: boolean;
    public isCustomTerritory: boolean;

    public isParametersAvailable: boolean;
    public isFiltersAvailable: boolean;

    private indicator: Indicator;

    constructor(
        private notification: ToastrService,
        @Inject(CatalogService) private catalogService: CatalogService,
        @Inject(LoaderService) private loaderService: LoaderService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(ModuleService) private moduleService: ModuleService,
        @Inject(PlotIndicatorService) private plotIndicatorService: PlotIndicatorService,
    ) {}

    ngOnInit(): void {
        this.indicator = this.catalogService.findIndicator(this.indicatorPlot.indicatorId);
        this.availableGranularities = this.indicator.availableGranularities;
        this.isFilterable = this.indicator.isFilterable;
        this.isCustomTerritory = this.indicator.isCustomTerritory;

        const preferences = this.localStorageService.get('preferences');
        const modulesAccess = preferences.modules;

        this.isParametersAvailable = modulesAccess.indicatorParameters;
        this.isFiltersAvailable = modulesAccess.filters;
    }

    toggleIndicatorParams() {
        this.moduleService.setNewIndicatorId(this.indicatorPlot.indicatorId);
        this.moduleService.openModule('indicatorParameters');
    }

    toggleIndicatorFilters() {
        this.moduleService.setNewIndicatorId(this.indicatorPlot.indicatorId);
        this.moduleService.openModule('filters');
    }

    async selectGranularity(granularity: TerritoryScale) {
        const isSameGranularity = granularity.id == this.indicatorPlot.granularity.id;
        if (isSameGranularity) {
            return;
        }

        const indicatorId = this.indicatorPlot.indicatorId;
        this.indicatorPlot.granularity = granularity;
        this.indicator.granularity = granularity;

        const loaderId = `${indicatorId}_${granularity.type}`;
        this.loaderService.start(loaderId);
        try {
            await this.plotIndicatorService.updateIndicatorGranularity(indicatorId, granularity);
        } catch (error) {
            console.error('Error selectNewGranularity', error);
            this.notification.error("Une erreur est survenue. Impossible de charger l'indicateur.");
        } finally {
            this.loaderService.stop(loaderId);
        }
    }
}
