import { Component, Input, Inject } from '@angular/core';

import { DashboardService } from 'src/app/services/dashboard.service';
import { MapService } from 'src/app/services/map.service';
import { ModuleService } from 'src/app/services/module.service';

@Component({
    selector: 'stTab',
    templateUrl: './st-tab.template.html',
    styleUrls: ['./st-tab.component.scss'],
})
export class StTab {
    @Input() label: string;
    @Input() subLabel: string;
    @Input() class: string;
    @Input() closePlacement: string = 'right';
    @Input() visible: boolean = false;

    constructor(
        @Inject(DashboardService) private dashboardService: DashboardService,
        @Inject(ModuleService) private moduleService: ModuleService,
        @Inject(MapService) private mapService: MapService,
    ) {}

    close() {
        if (this.class == 'dashboard') {
            this.dashboardService.close();
        } else {
            this.moduleService.closeModule(this.class);
        }
        this.mapService.invalidateSize();
    }
}
