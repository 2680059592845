'use strict';

import 'leaflet.minichart';
import * as L from 'leaflet';

import { PlotFactory } from './PlotFactory';
import { MapService } from '../services/map.service';

export class PlotMiniChartFactory extends PlotFactory {
    constructor(indicatorId: number, mapService: MapService) {
        super(indicatorId, mapService);
        this.form = 'chart';
        this.indicatorcontourweight = 2;
    }

    addLayer(map: L.Map) {
        if (this.geojsonLayer != null) {
            this.removeLayer(map);
        }

        this.geojsonLayer = L.geoJson(this.geojson, {
            pointToLayer: this.getStyle.bind(this),
            onEachFeature: this.onEachFeature.bind(this),
        });

        this.legende = [];
        const categories = JSON.parse(this.chart_divider);
        let counter = 0;
        for (const cat in categories) {
            const label = categories[cat];
            this.legende.push({
                id: counter,
                lib: label,
                color: this.tableCouleurs[counter],
            });
            counter = counter + 1;
        }

        map.addLayer(this.geojsonLayer);
    }

    getStyle(feature: GeoJSON.Feature, latLng?: { lat: number; lng: number }) {
        const data = [];
        for (const key in feature.properties) {
            if (key.includes('value_')) {
                data.push(feature.properties[key]);
            }
        }

        return (L as any).minichart(latLng, {
            data: data,
            type: 'pie', //this.form.split("_")[1],
            colors: this.tableCouleurs,
            width: 10 * this.getWeight(feature.properties.value),
            heigth: 10 * this.getWeight(feature.properties.value),
            opacity: this.getFillOpacity(feature.properties.value),
        });
    }

    getRadiusForPoints(value: any) {
        const baseX = 20;
        const maxY = 60;

        if (this.default_radius_weight > 0) {
            return this.default_radius_weight;
        } else if (typeof value === 'number' && !isNaN(value) && this.type == 'valeur') {
            return (
                baseX + ((maxY - baseX) * this.getLegendIndex(value)) / this.legendBoundaries.length
            );
        } else {
            return 20;
        }
    }

    highlightFeature(layer: any) {
        const feature = layer.feature;
        const weight = this.getWeight(feature.properties.value);

        layer.setOptions({
            width: 1.3 * 10 * weight,
            heigth: 1.3 * 10 * weight,
            transitionTime: 0,
        });
    }

    unhighlightFeature(layer: any) {
        const feature = layer.feature;
        const weight = this.getWeight(feature.properties.value);
        layer.setOptions({
            width: 10 * weight,
            heigth: 10 * weight,
        });
    }
}
