'use strict';

import { Component, Inject, Input } from '@angular/core';

import {
    PrElement,
    PrFeature,
    PrLine,
    PrPath,
    PrParameter,
    PrHypothesisValue,
    ProsperScenario,
} from 'src/app/models/PrTypes';
import { Indicator } from 'src/app/models/Indicator';

import { EventService } from 'src/app/services/event.service';
import { ProsperReseauxService } from 'src/app/services/prosper-reseaux/prosper-reseaux.service';
import { PlotIndicatorService } from 'src/app/services/plotIndicator/plot-indicator.service';

@Component({
    selector: 'prosperReseauxSearchElement',
    templateUrl: './search-element.template.html',
})
export class ProsperReseauxSearchElementComponent {
    @Input() results;
    @Input() show: {
        main: boolean;
        searchElement: boolean;
    };

    active = 1;
    searchInfo = null;
    optionsElements = [];
    isOptionsLoading: boolean = false;
    isAnyElementSelected: boolean = false;
    selectedElements = [
        {
            id: '20',
            label: 'Poste Source',
            elements: [],
            isActive: false,
            order: 1,
        },
        {
            id: '18',
            label: 'Départ HTA',
            elements: [],
            isActive: false,
            order: 2,
        },
        {
            id: '15',
            label: 'Ligne HTA',
            elements: [],
            isActive: false,
            order: 3,
        },
        {
            id: '10',
            label: 'Poste HTA/BT',
            elements: [],
            isActive: false,
            order: 4,
        },
        {
            id: '09',
            label: 'Transformateur HTA/BT',
            elements: [],
            isActive: false,
            order: 5,
        },
        {
            id: '08',
            label: 'Départ BT',
            elements: [],
            isActive: false,
            order: 6,
        },
        {
            id: '05',
            label: 'Ligne BT',
            elements: [],
            isActive: false,
            order: 7,
        },
        {
            id: '01',
            label: 'Bâtiment',
            elements: [],
            isActive: false,
            order: 8,
        },
    ];

    constructor(
        @Inject(EventService) private eventService: EventService,
        @Inject(ProsperReseauxService) public prService: ProsperReseauxService,
        @Inject(PlotIndicatorService) private plotIndicatorService: PlotIndicatorService,
    ) {
        const options = this.prService.prElements.map((prElement) => {
            return {
                label: prElement.label,
                value: prElement.id,
            };
        });
        const initOptions = this.prService.prElements.find(
            (prElement) => prElement.elements.length,
        );
        const initTypeValue = initOptions ? initOptions.id : null;

        this.searchInfo = {
            type: {
                options: options,
                value: initTypeValue,
            },
            name: {
                value: null,
            },
        };
        this.updateElementType();
    }

    async updateElementType() {
        const typeId = this.searchInfo.type.value;
        let prElements = this.prService.findPrElementByTypeId(typeId);
        if (!prElements.elements.length) {
            await this.getPrElementLabels(typeId);
            prElements = this.prService.findPrElementByTypeId(typeId);
        }
        this.optionsElements = prElements.elements.map((element: any) => {
            return {
                id: element.id,
                label: element.label,
                typeId: typeId,
            };
        });
        this.optionsElements.sort((a, b) => a.label - b.label);
    }

    async getPrElementLabels(typeId: string) {
        try {
            this.isOptionsLoading = true;
            await this.prService.getPrElementLabels(typeId);
        } catch (error) {
            console.error('Error getPrElementLabels', error);
        } finally {
            this.isOptionsLoading = false;
        }
    }

    async selectElement(elementInfo: any) {
        const typeId = elementInfo.typeId;
        const selectedType = this.selectedElements.find(
            (selectedType) => selectedType.id == typeId,
        );
        selectedType.elements.push(elementInfo);
        selectedType.isActive = true;

        this.isAnyElementSelected = true;
    }

    removeElement(elementInfo: any) {
        const typeId = elementInfo.typeId;
        const selectedType = this.selectedElements.find(
            (selectedType) => selectedType.id == typeId,
        );
        const index = selectedType.elements.indexOf(elementInfo);
        selectedType.elements.splice(index, 1);
        selectedType.isActive = selectedType.elements.length > 0;

        this.isAnyElementSelected = this.selectedElements.some(
            (selectedType) => selectedType.isActive,
        );
    }

    async addElements() {
        const indicatorPlots: Indicator[] = Object.values(
            this.plotIndicatorService.plotedIndicators,
        );
        const promises = [];
        this.selectedElements
            .filter((selectedType) => selectedType.elements.length)
            .forEach((selectedType) => {
                const elements = selectedType.elements;
                elements.forEach((elementInfo) => {
                    const id = elementInfo.id;
                    const element = JSON.parse(JSON.stringify(this.prService.findElementById(id)));
                    promises.push(this.prService.addElement(element, indicatorPlots));
                });
            });

        await Promise.all(promises);
        this.prService.setCollapsePrTypeIds();
        this.prService.setCollapseElementIds();

        if (!this.prService.miscInfo.selectedElement) {
            const selectedType = this.selectedElements.find(
                (selectedType) => selectedType.elements.length,
            );
            this.prService.miscInfo.selectedElement = {
                id: selectedType.elements[0].id,
                label: selectedType.elements[0].label,
                typeId: selectedType.id,
                typeLabel: selectedType.label,
            };
            this.prService.miscInfo.isInitialized = true;
        }
        this.back();
    }

    back() {
        this.show.main = true;
        this.show.searchElement = false;
    }
}
