<stTab class="informations" label="Informations" [visible]="moduleService.isModuleActive.informations">
  <div class="container-fluid p-4 body">
    <div>
      <h4>Adaptabilité</h4>

      <div class="container-fluid">
        De la maille infra-communale (IRIS ou cadastre) à la maille nationale, SITERRE propose une visualisation
        fine et détaillée à votre échelle d’intérêt pour répondre à vos besoins spécifiques et personnalisés.
        Avec un catalogue proposant plus de 100 indicateurs et plus de 120 filtres, vous pouvez préciser des
        analyses successives nécessaires à l’élaboration de votre diagnostic territorial.
      </div>
    </div>

    <hr class="split my-3">

    <div>
      <h4>Simplicité</h4>

      <div class="container-fluid">
        SITERRE a été conçu pour être utilisé en toute indépendance : sa plateforme intuitive et ergonomique,
        entièrement accessible depuis internet, vous permet un emploi facile et illimité de l'outil alors
        mobilisable à chaque étape de votre diagnostic. Energies Demain reste à votre disposition pour vous
        proposer assistance technique et formation afin de garantir votre autonomie.
      </div>
    </div>

    <hr class="split my-3">

    <div>
      <h4>Fiabilité</h4>

      <div class="container-fluid">
        Fondé sur les bases statistiques publiques, SITERRE fait l'objet d’une actualisation continue réalisée
        par Energies Demain. Cette mise à disposition de données issues d'une expérience reconnue en
        modélisation et d'une expertise pointue vous garantit un support fiable de contextualisation de votre
        situation sans qu’aucune donnée spécifique ou pose de capteur de mesures ne soit nécessaire.
      </div>
    </div>

    <hr class="split my-3">

    <div>
      <h4>Innovation</h4>

      <div class="container-fluid">
        Expertes dans la création d’outils informatiques appliqués au domaine de la transition énergétique, les
        équipes d’Energies Demain s’emploient à concevoir des modèles toujours plus innovants. Les méthodologies
        "Bottom Up - Distributions Aléatoires Contraintes - Machine Learning" permettent ainsi d’éviter
        plusieurs failles liées aux usages courants de la donnée et donnent un éclairage opérationnel à vos
        analyses.
      </div>
    </div>

    <hr class="split my-3">

    <ng-container *ngIf="!!customInfo">
      <div [innerHtml]="customInfo"></div>

      <hr class="split my-3">
    </ng-container>

    <div class="cursor-pointer p-2">
      <span (click)="open()">
        <span class="fa fa-envelope-o fa-lg me-2"></span>
        <span>Contact</span>
      </span>
    </div>

    <hr class="split my-3">

    <div class="cursor-pointer p-2">
      <span class="fa fa-gavel fa-lg me-2"></span>
      <span>Mentions légales</span>
    </div>
  </div>
</stTab>