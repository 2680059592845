('use strict');

import { Inject, Injectable } from '@angular/core';

import {
    PrBaseElement,
    PrElement,
    PrElectronPath,
    PrPath,
    PrParameter,
    PrHypothesisValue,
} from '../../models/PrTypes';
import { Indicator } from 'src/app/models/Indicator';

import { ProsperReseauxService } from './prosper-reseaux.service';
import { RestService } from '../RestService';
import { TerService } from '../TerService';

@Injectable({
    providedIn: 'root',
})
export class ProsperReseauxProsperActionsScenarioService {
    constructor(
        @Inject(ProsperReseauxService) private prService: ProsperReseauxService,
        @Inject(TerService) private terService: TerService,
        @Inject(RestService) private restService: RestService,
    ) {}

    async getPrProsperActionsScenarios() {
        const scenarios = await this.restService.getPrProsperActionsScenarios();
        const cleanedScenarios = scenarios.map(
            (scenario: { idScenario: number; scenarioName: string }) => {
                return {
                    id: scenario.idScenario,
                    label: scenario.scenarioName,
                };
            },
        );
        return cleanedScenarios;
    }

    getScenarioByYear(scenarioId: number) {
        const data = {
            scaleTypeId: this.terService.territoryScale.typeId,
            territoryIds: this.terService.territories.map((t) => t.id),
            scenarioId: scenarioId,
        };

        return this.restService.getPrProsperScenarioByYear(data);
    }

    loadProsperActionsScenario(scenario: any, indicatorPlots: Indicator[]) {
        const typeId = scenario.typeId;
        const scenarioElements = scenario.elements;

        // add to prElements
        const elementsToAdd = scenarioElements.map((element) => {
            return {
                id: element.id,
                label: element.label,
                typeId: typeId,
                indicatorIds: [],
            };
        });
        const prElement = this.prService.findPrElementByTypeId(typeId);
        prElement.elements.push(...elementsToAdd);

        // add to elementToModify
        scenarioElements.forEach((scenarioElement: any) => {
            const id = scenarioElement.id;
            const element = JSON.parse(JSON.stringify(this.prService.findElementById(id)));

            const prHypothesisValues = scenarioElement.hypothesis;
            element.hypothesis = this.prService.setHypothesisValues(prHypothesisValues, typeId);
            this.prService.setIsHypothesisAvailable(element);
            this.prService.setIsDrawingAvailable(element, indicatorPlots);

            const prElementToModify = this.prService.findElementsToModifyByTypeId(typeId);
            const elementsToModify = prElementToModify.elements;
            elementsToModify.push(element);
        });

        // console.log('elementsToModifyByType', this.elementsToModifyByType);
        // console.log('prElements', this.prElements);
    }
}
