import { Inject, Injectable } from '@angular/core';
import { Position } from 'geojson';
import {
    PrBuilding,
    PrElectronPath,
    PrElement,
    PrFeature,
    PrHtaBtPost,
    PrLine,
    PrPath,
    PrStart,
    PrTransformer,
    PrHypothesisValue,
} from '../../models/PrTypes';

import { DataService } from '../DataService';
import { ProsperReseauxService } from './prosper-reseaux.service';
import { MapService } from '../map.service';
import { PlotIndicatorService } from '../plotIndicator/plot-indicator.service';

('use strict');
@Injectable({
    providedIn: 'root',
})
export class ProsperReseauxElementService {
    private nextNetworkId = -1;

    constructor(
        @Inject(DataService) private dataService: DataService,
        @Inject(MapService) private mapService: MapService,
        @Inject(PlotIndicatorService) private plotIndicatorService: PlotIndicatorService,
        @Inject(ProsperReseauxService) private prService: ProsperReseauxService,
    ) {}

    getGeoJsonElement(id: string, typeId: string, energyId: string = '01') {
        const indicator = this.dataService.activeIndicatorsList.find(
            (indicator) => indicator.tbl === `bd_res_${energyId}_${typeId}`,
        );
        const indicatorId = indicator.id_indicateur;
        const features: any[] =
            this.plotIndicatorService.plotedIndicators[indicatorId].geojson.features;
        const feature = features.find((feature) => feature.properties.id === id);
        feature.properties.energyId = energyId;
        feature.properties.typeId = typeId;
        return feature;
    }

    createTransformer(
        id: string,
        coordinates: number[],
        typeId: string,
        hypothesis: PrHypothesisValue[],
    ) {
        const label = 'Nouveau transformateur';
        const properties = this.setElementProperties(id, label, typeId, hypothesis);
        const newTransformerFeature: PrTransformer = {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: coordinates,
            },
            properties: properties,
        };
        this.addElementToTheGrid(newTransformerFeature, typeId);

        return newTransformerFeature;
    }

    createStart(
        id: string,
        coordinates: number[],
        typeId: string,
        hypothesis: PrHypothesisValue[],
    ) {
        const label = 'Nouveau départ';
        const properties = this.setElementProperties(id, label, typeId, hypothesis);
        const newStartFeature: PrStart = {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: coordinates,
            },
            properties: properties,
        };
        this.addElementToTheGrid(newStartFeature, typeId);
        return newStartFeature;
    }

    createLines(
        id: string,
        line: any,
        typeId: string,
        hypothesis: PrHypothesisValue[],
        maxLineLength: number,
    ) {
        // First we split the lines in sections of <maxLineLength>
        const coordinates = this.splitLineInSections(line.geometry.coordinates, maxLineLength);

        // Then we create each section and add it to the grid
        const newNetwork: PrLine[] = [];
        for (let i = 1; i < coordinates.length; i++) {
            const lineSectionCoordinates = [coordinates[i - 1], coordinates[i]];
            const line = this.createLine(id, lineSectionCoordinates, typeId, hypothesis);
            newNetwork.push(line);
        }

        return newNetwork;
    }

    private createLine(
        id: string,
        coordinates: Position[],
        typeId: string,
        hypothesis: PrHypothesisValue[],
    ) {
        const label = 'Nouveau tronçon';
        const properties: any = this.setElementProperties(id, label, typeId, hypothesis);
        properties.longueur_e = this.mapService.distance(coordinates[0], coordinates[1]);
        const lineFeature: PrLine = {
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: coordinates,
            },
            properties: properties,
        };

        this.addElementToTheGrid(lineFeature, typeId);
        return lineFeature;
    }

    createClient(
        id: string,
        coordinates: number[],
        typeId: string,
        hypothesis: PrHypothesisValue[],
    ) {
        let newClientFeature: PrFeature;
        const label = 'Nouveau client';
        const properties: any = this.setElementProperties(id, label, typeId, hypothesis);
        const isBuilding = typeId === '01';

        if (isBuilding) {
            properties.height = 3;
            newClientFeature = {
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates: this.mapService.convertPointToPolygon(coordinates),
                },
                properties: properties,
            } as PrBuilding;
        } else {
            newClientFeature = {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: coordinates,
                },
                properties: properties,
            } as PrHtaBtPost;
        }

        this.addElementToTheGrid(newClientFeature, typeId);
        return newClientFeature;
    }

    private setElementProperties(
        sourceGridId: string,
        label: string,
        typeId: string,
        hypothesis?: PrHypothesisValue[],
    ) {
        const id = `${sourceGridId}_${this._createUniqueId()}`;
        const newLabel = `${label} (${id})`;
        const vectorBase = `res_01_${typeId}`;

        return {
            id: id,
            label: newLabel,
            vector_base: vectorBase,
            hypothesis: JSON.parse(JSON.stringify(hypothesis)),
            typeId: typeId,
            isNew: true,
        };
    }

    private _createUniqueId() {
        this.nextNetworkId++;
        return this.nextNetworkId;
    }

    private addElementToTheGrid(feature: PrFeature, typeId: string) {
        const element = feature.properties;
        if (['05', '15'].includes(typeId)) {
            element.applyChanges = {
                label: 'À tout le départ',
                value: 'allFromStart',
            };
        }
        const gridElementsToModify = this.prService.findElementsToModifyByTypeId(typeId);
        gridElementsToModify.elements.push(element);

        const featuresToAdd = this.prService.findFeaturesToAddByTypeId(typeId);
        featuresToAdd.features.push(feature);
    }

    private splitLineInSections(geojsonCoordinates: number[][], maxLineLength: number) {
        // Split a line in sections of <maxLineLength> and returns the coordinates
        const coordinates = geojsonCoordinates.splice(0, 1);

        const distanceLeft = (coord: number[]) => {
            return this.mapService.distance(coordinates[coordinates.length - 1], coord);
        };

        geojsonCoordinates.forEach((coordinate) => {
            while (maxLineLength && distanceLeft(coordinate) > maxLineLength) {
                const lineCoord = [coordinates[coordinates.length - 1], coordinate];
                coordinates.push(this.mapService.lineSliceAlong(lineCoord, maxLineLength));
            }
            coordinates.push(coordinate);
        });
        return coordinates;
    }
}
