('use strict');

import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import { FilterCategory, FilterCriteria } from '../../../models/FilterCategory';
import { RestService } from 'src/app/services/RestService';

@Component({
    templateUrl: './filter-add.template.html',
})
export class AddFilterComponent implements OnInit {
    filter = {
        id: null,
        label: null,
        tbl: null,
        categories: [],
        new: true,
    };
    filterId: any;

    constructor(
        private notification: ToastrService,
        private router: Router,
        @Inject(RestService) private restService: RestService,
    ) {}

    ngOnInit() {
        this.addCategory();
    }

    goToAdminFilter() {
        this.router.navigate(['administration/filters/manage/']);
    }

    deleteCategory(categoryToDelete: FilterCategory) {
        this.filter.categories = this.filter.categories.filter(
            (category) => category.id !== categoryToDelete.id,
        );
    }

    deleteCriteria(criteriaToDelete: FilterCriteria, categoryId: number) {
        const category = this.filter.categories.find((category) => category.id === categoryId);
        category.criteria = category.criteria.filter(
            (criteria: FilterCriteria) => criteria.id === criteriaToDelete.id,
        );
    }

    addCategory() {
        const categoryIds = this.filter.categories.map((category) => category.id);
        const maxId = categoryIds.length ? Math.max(...categoryIds) : 0;

        const newCategory = {
            id: maxId + 1,
            label: null,
            criteria: [],
            filterId: this.filterId,
            champ_associe: null,
            modify: true,
            new: true,
            collapse: false,
        };
        this.filter.categories.push(newCategory);
        this.addCriteria(newCategory.id);
    }

    addCriteria(categoryId: number) {
        const category = this.filter.categories.find((category) => category.id === categoryId);
        const criteriaIds = category.criteria.map((criteria: FilterCriteria) => criteria.id);
        const maxId = criteriaIds.length ? Math.max(...criteriaIds) : 0;

        const newCriteria = {
            id: maxId + 1,
            label: null,
            filterId: this.filterId,
            categoryId: category.id,
            order: category.criteria.length + 1,
            modify: true,
            new: true,
        };

        category.criteria.push(newCriteria);
    }

    async saveFilter() {
        if (!this.filter.tbl || !this.filter.label) {
            this.notification.error(
                "Vous n'avez pas rempli les informations sur la famille de filtre.",
            );
            return;
        }

        for (const category of this.filter.categories) {
            const critereInfo = `${category.label} (${category.champ_associe})`;
            if (!category.label || !category.champ_associe) {
                this.notification.error(
                    `Vous n'avez pas rempli les informations sur le critère : ${critereInfo}`,
                );
                return;
            }
            const hasEmptyCriteria = category.criteria.some(
                (criteria: FilterCriteria) => !criteria.label || !criteria.id,
            );
            if (hasEmptyCriteria) {
                this.notification.error(
                    `Vous n'avez pas rempli les informations d'un critère :${critereInfo}`,
                );
                return;
            }
        }

        try {
            // well it sucks but we must reconvert to dirty convetion name :
            const critere = this.filter.categories.map((category) => {
                const dter = category.criteria.map((criteria) => ({
                    id_dter: criteria.id,
                    lib_dter: criteria.label,
                    id_archi_critdter: criteria.filterId,
                    id_critere: criteria.categoryId,
                    ordre: criteria.order,
                }));
                return {
                    id_critere: category.id,
                    lib_critere: category.label,
                    id_archi_critdter: category.filterId,
                    champ_associe: category.champ_associe,
                    dter: dter,
                };
            });
            const newFilter = {
                id_archi_critdter: this.filter.id,
                nom_archi_cridter: this.filter.label,
                tbl: this.filter.tbl,
                critere: critere,
            };

            await this.restService.createFilter(newFilter);
            this.notification.success('La famille de filtre a bien été enregistrée');
            this.goToAdminFilter();
        } catch (error) {
            this.notification.success("Une erreur s'est produite");
        }
    }
}
