<div class="d-flex flex-column h-100">
    <div class="flex-grow-1 overflow-auto">
        <div class="w-100 p-3">
            <div class="header mb-3">
                <div class="container-fluid">
                    <div class="row d-flex align-items-center solar-card">
                        <div class="col-auto px-0 text-center">
                            <span class="fa-stack">
                                <i class="fa fa-circle-thin fa-stack-2x"></i>
                                <i class="fa fa-info fa-stack-1x"></i>
                            </span>
                        </div>
                        <div class="col">
                            <p>
                                Pour un projet photovoltaïque en vente totale, vous êtes libre de
                                choisir la taille de votre installation. Les coûts d'installation et
                                le tarif d'achat de l'énergie sont dégressifs selon la puissance
                                installée.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="body">
                <div class="my-2 solar-loader-icon" [hidden]="!showLoader">
                    <div>
                        <i class="fa fa-spinner fa-pulse fa-4x fa-fw"></i>
                    </div>
                    <div>
                        Simulation de votre <br />
                        projet en cours
                    </div>
                </div>

                <div [hidden]="showLoader">
                    <h5 class="text-st-primary">{{ adjustInstallationSizeLabel }}</h5>

                    <div
                        class="container-fluid position-relative solar-card mb-3 p-2"
                        *ngIf="slider"
                    >
                        <div class="row d-flex justify-content-center my-3">
                            <div class="col-11">
                                <stRange
                                    [min]="1"
                                    [max]="slider.maxModuleCount"
                                    [step]="1"
                                    [value]="slider.moduleCount"
                                    [(ngModel)]="slider.moduleCount"
                                    (change)="updateModuleCountForSell()"
                                >
                                </stRange>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center my-2">
                            <div class="col-11">
                                <div class="row">
                                    <div class="col-4 panel-count">
                                        {{ slider.moduleCount }} {{ slider.moduleCount == 1 ?
                                        'panneau' : 'panneaux' }}
                                    </div>
                                    <div class="col-4 text-center panel-area">
                                        {{ slider.moduleArea }}&nbsp;m²
                                    </div>
                                    <div class="col-4 d-flex justify-content-end peak-power">
                                        {{ slider.stringifiedPeakPower }}&nbsp;kWc
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="possible-installation" *ngIf="slider.maxPeakPower >= 3">
                            <i>
                                <small>
                                    <span class="text-st-primary">
                                        Sélectionner une puissance selon les seuils de tarif de
                                        vente&nbsp;:
                                    </span>
                                    <ul>
                                        <li *ngIf="slider.maxPeakPower >= 3">
                                            <a
                                                _target="_blank"
                                                class="text-st-active"
                                                (click)="clickOnPossibleInstallation(3)"
                                            >
                                                3&nbsp;kWc
                                            </a>
                                        </li>
                                        <li *ngIf="slider.maxPeakPower >= 9">
                                            <span>,</span>
                                            <a
                                                _target="_blank"
                                                class="text-st-active"
                                                (click)="clickOnPossibleInstallation(9)"
                                            >
                                                9&nbsp;kWc
                                            </a>
                                        </li>
                                        <li *ngIf="slider.maxPeakPower >= 36">
                                            <span>,</span>
                                            <a
                                                _target="_blank"
                                                class="text-st-active"
                                                (click)="clickOnPossibleInstallation(36)"
                                            >
                                                36&nbsp;kWc
                                            </a>
                                        </li>
                                    </ul>
                                    <span>.</span>
                                </small>
                            </i>
                        </div>

                        <div [(ngbCollapse)]="!activeCollapseWarningPower" class="px-3">
                            <div class="container-fluid px-0 pt-2">
                                <hr class="m-0" />
                                <div class="row d-flex align-items-center pt-3 pb-2">
                                    <div class="col-auto text-center">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="28"
                                            height="28"
                                            fill="currentColor"
                                            class="bi bi-exclamation-triangle"
                                            style="color: var(--active) !important"
                                            viewBox="0 0 16 16"
                                        >
                                            <path
                                                d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z"
                                            />
                                            <path
                                                d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"
                                            />
                                        </svg>
                                    </div>
                                    <div class="col">
                                        <div [innerHtml]="htmlPvWarningSubcribedPower"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container-fluid solar-card mb-3 investment">
                        <div class="row g-2 d-flex align-items-center justify-content-between">
                            <div class="col-sm-1 d-flex justify-content-center">
                                <i class="fa fa-tasks fa-2x" [title]="tooltip.investment"></i>
                            </div>
                            <div class="col-sm">
                                <div class="row gy-2 gx-4 d-flex align-items-center">
                                    <div class="col-sm" *ngIf="!!connectionCost">
                                        <ng-container
                                            *ngIf="connectionCost.isAvailable && connectionCost.isActive && solarService.connectionCostInfo.isEnedisAvailable"
                                        >
                                            <div class="d-flex">
                                                <div>
                                                    <div [innerHtml]="htmlPvInvestment"></div>
                                                    <stButton
                                                        btnLabel="Estimez le coût de raccordement grâce à Enedis."
                                                        btnClass="text-st-link text-start p-0"
                                                        (onClick)="getConnectionCost()"
                                                        *ngIf="!connectionCost.isLoading"
                                                    >
                                                    </stButton>
                                                    <div
                                                        class="text-st-primary"
                                                        *ngIf="connectionCost.isLoading"
                                                    >
                                                        Connexion à l'API Raccordement d'Enedis en
                                                        cours...
                                                    </div>
                                                </div>
                                                <div
                                                    class="flex-grow-1 d-flex align-items-center justify-content-center ms-3"
                                                    *ngIf="connectionCost.isLoading"
                                                >
                                                    <span
                                                        class="fa fa-spinner fa-2x fa-pulse text-st-active"
                                                    ></span>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <ng-container
                                            *ngIf="!connectionCost.isAvailable || !connectionCost.isActive || !solarService.connectionCostInfo.isEnedisAvailable"
                                        >
                                            <div [innerHtml]="htmlPvInvestment"></div>
                                        </ng-container>
                                    </div>
                                    <div class="col-sm-auto question-mark-container">
                                        <stButton
                                            btnLabel="+&nbsp;d'infos"
                                            btnClass="btn-st-default w-100"
                                            (onClick)="openInfoModal(tooltipPvInvestmentDetail, titleInvestment)"
                                        >
                                        </stButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container-fluid solar-card mb-3 production">
                        <div class="row g-2 d-flex align-items-center justify-content-between">
                            <div class="col-sm-1 d-flex justify-content-center">
                                <i class="fa fa-bolt fa-2x" [title]="tooltip.production"> </i>
                            </div>
                            <div class="col-sm">
                                <div class="row gy-2 gx-4 d-flex align-items-center">
                                    <div class="col-sm" [innerHtml]="htmlPvProduction"></div>
                                    <div class="col-sm-auto question-mark-container">
                                        <stButton
                                            btnLabel="+&nbsp;d'infos"
                                            btnClass="btn-st-default w-100"
                                            (onClick)="openInfoModal(tooltipPvProductionDetail, titleProduction)"
                                        >
                                        </stButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container-fluid solar-card selling-gain">
                        <div class="row g-2 d-flex align-items-center justify-content-between">
                            <div class="col-sm-1 d-flex justify-content-center">
                                <i class="fa fa-eur fa-2x" [title]="tooltip.economy"></i>
                            </div>
                            <div class="col-sm">
                                <div class="row gy-2 gx-4 d-flex align-items-center">
                                    <div class="col-sm" [innerHtml]="htmlPvEconomy"></div>
                                    <div class="col-sm-auto question-mark-container">
                                        <stButton
                                            btnLabel="+&nbsp;d'infos"
                                            btnClass="btn-st-default w-100"
                                            (onClick)="openInfoModal(tooltipPvSellingDetail, titleEconomy)"
                                        >
                                        </stButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="!modularity.simulations">
                        <ng-container *ngIf="!!htmlPvWarning">
                            <div class="mt-3" [innerHtml]="htmlPvWarning"></div>
                        </ng-container>
                        <ng-container *ngIf="!htmlPvWarning">
                            <div class="text-center mt-3">
                                <small>
                                    Ces données sont issues d'un traitement automatisé et sont un
                                    premier ordre d'idée. Cela ne remplace pas la visite d'un
                                    technicien agréé.
                                </small>
                            </div>
                        </ng-container>
                    </ng-container>

                    <div class="mt-3" *ngIf="modularity.downloadResults && !modularity.simulations">
                        <button
                            type="button"
                            class="btn btn-st-primary solar-pdf"
                            (click)="downloadPdf()"
                            [disabled]="isResultsDownloading"
                        >
                            <span *ngIf="!isResultsDownloading">Télécharger le récapitulatif</span>
                            <i class="fa fa-spinner fa-pulse" *ngIf="isResultsDownloading"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer border-top" [hidden]="showLoader">
        <div class="container-fluid py-3">
            <div class="row">
                <div class="col-5">
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-default w-100 h-100"
                        btnLabel="Retour"
                        (click)="cancel()"
                    >
                    </stButton>
                </div>
                <div class="col-7">
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-primary w-100"
                        btnLabel="Valider ma simulation"
                        (click)="validate()"
                        *ngIf="modularity.simulations"
                    >
                    </stButton>
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-primary w-100"
                        [btnLabel]="contactButtonLabel"
                        (click)="openContactModal()"
                        *ngIf="!modularity.simulations"
                    >
                    </stButton>
                </div>
            </div>
        </div>
    </div>
</div>
