'use strict';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';

import { TerritoryScale } from 'src/app/models/TerritoryTypes';
import { MapService } from 'src/app/services/map.service';
import { ModuleService } from 'src/app/services/module.service';
import { ThemeService } from 'src/app/services/ThemeService';
import { TerService } from 'src/app/services/TerService';
import { EventService } from 'src/app/services/event.service';
import { Component, Inject, OnInit } from '@angular/core';

@UntilDestroy()
@Component({
    selector: 'mapBoundaries',
    templateUrl: './map-boundaries.template.html',
})
export class MapBoundariesComponent implements OnInit {
    bordure = {
        indicatorcontourcolor: 0,
        indicatorcontourweight: 0,
        indicatorcontouropacity: 0,
    };
    currentRaster: any;
    theme: any;
    rasters: any;
    territoryScales: TerritoryScale[];

    constructor(
        private notification: ToastrService,
        @Inject(MapService) private mapService: MapService,
        @Inject(ModuleService) public moduleService: ModuleService,
        @Inject(ThemeService) private themeService: ThemeService,
        @Inject(TerService) private terService: TerService,
        @Inject(EventService) private eventService: EventService,
    ) {}

    ngOnInit(): void {
        this._init();
        this.eventService.mainInitialized.pipe(untilDestroyed(this)).subscribe(() => this._init());

        this.eventService.territoryPloted.pipe(untilDestroyed(this)).subscribe(() => this._init());

        this.eventService.selectionTerritoryUpdated
            .pipe(untilDestroyed(this))
            .subscribe(() => this._init());
    }

    updateRaster(rasterId: number) {
        this.mapService.updateRaster(rasterId);
        this.currentRaster = this.mapService.currentRaster;
    }

    async addBoundaries(territoryScale: TerritoryScale) {
        territoryScale.isLoading = true;
        try {
            await this.terService.addTerritoryLayer(territoryScale);
            territoryScale.isLoading = false;
        } catch (error) {
            console.error('Error addBoundaries', error);
            this.notification.error(
                "Une erreur s'est produite. Impossible de mettre à jour la carte.",
            );
        } finally {
            territoryScale.isLoading = false;
        }
    }

    removeBoundaries(territoryScale: TerritoryScale) {
        this.terService.removeTerritoryScaleLayers(territoryScale);
    }

    async addLabeledBoundaries(territoryScale: TerritoryScale) {
        territoryScale.isLabelLoading = true;
        try {
            await this.terService.addTerritoryLabeledLayer(territoryScale);
        } catch (error) {
            console.error('Error addLabeledBoundaries', error);
            this.notification.error(
                "Une erreur s'est produite. Impossible de mettre à jour la carte.",
            );
        } finally {
            territoryScale.isLabelLoading = false;
        }
    }

    removeLabeledBoundaries(territoryScale: TerritoryScale) {
        this.terService.removeTerritoryLabeledLayer(territoryScale);
    }

    updateStyle(territoryScale: TerritoryScale) {
        this.terService.updateLayerStyle(territoryScale);
    }

    updateTheme() {
        this.themeService.updateTheme(this.theme.id);
        this.themeService.save();
        this.themeService.apply();
    }

    private _init() {
        this.currentRaster = this.mapService.currentRaster;
        this.rasters = this.mapService.rasters;
        this.territoryScales = this.terService.territoryScales;

        this.theme = {
            id: this.themeService.themeId,
        };
    }
}
