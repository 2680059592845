('use strict');

import { ActivatedRoute, Router } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { FilterCategory, FilterCriteria } from 'src/app/models/FilterCategory';
import { RestService } from 'src/app/services/RestService';
import { ConfirmationModalComponent } from '../../main/modals/confirmation/confirmation-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    templateUrl: './filter-update.template.html',
})
export class UpdateFilterComponent implements OnInit {
    filter: any;
    categoryToDelete: FilterCategory;
    filterId: number;
    elementName: string;
    criteriaToDelete: FilterCriteria;

    categoryIdFromCriteriaToDelete: number;

    constructor(
        private notification: ToastrService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private modalService: NgbModal,
        @Inject(RestService) private restService: RestService,
    ) {}

    ngOnInit(): void {
        this.activatedRoute.data.subscribe(({ filter }) => {
            this.filter = filter;
            this.filterId = filter.id;
        });
        this._init();
    }

    goToAdminFilter() {
        this.router.navigate(['administration/filters/manage/']);
    }

    async saveFilter() {
        const data = {
            id_archi_critdter: this.filterId,
            nom_archi_critdter: this.filter.label,
            tbl: this.filter.tbl,
        };

        try {
            await this.restService.updateFilter(this.filterId, data);
            this.notification.success('Les données ont bien été enregistrés.');
            this._init();
        } catch (error) {
            this.notification.error("Une erreur s'est produite : l'enregistrement a échoué.");
            console.error(error);
        }
    }

    private _findCategory(categoryId: number) {
        return this.filter.category.find((category: FilterCategory) => category.id === categoryId);
    }

    addCategory() {
        const categoryIds = this.filter.categories.map((category) => category.id);
        const maxId = categoryIds.length ? Math.max(...categoryIds) : 0;

        const newCategory = {
            id: maxId + 1,
            label: 'Nouveau critère n°' + this.filter.categories.length,
            filterId: this.filterId,
            champ_associe: ' ',
            modify: true,
            new: true,
            collapse: false,
            criteria: [],
        };
        this.filter.critere.push(newCategory);
        this.addCriteria(newCategory.id);
    }

    async saveCategory(category: FilterCategory) {
        try {
            // well it sucks but we must reconvert to dirty convention name :
            const dter = category.criteria.map((criteria) => ({
                id_dter: criteria.id,
                lib_dter: criteria.label,
                id_archi_critdter: criteria.filterId,
                id_critere: criteria.categoryId,
                ordre: criteria.order,
            }));

            const critere = {
                id_critere: category.id,
                lib_critere: category.label,
                id_archi_critdter: category.filterId,
                champ_associe: category.champ_associe,
                dter: dter,
            };

            await this.restService.updateFilterCategory(category.id, critere);
            const updatedCategory = this._findCategory(category.id);
            updatedCategory.modify = false;
            updatedCategory.new = false;
            this.notification.success('Ce critère a bien été enregistré.');
        } catch (error) {
            this.notification.error(
                "Une erreur s'est produite : Ce critère a bien été enregistré.",
            );
            console.error(error);
        }
    }

    deleteCategory(category: FilterCategory) {
        this.elementName = 'critère';

        const body = [
            `Voulez-vous vraiment supprimer ce ${this.elementName} ?`,
            'Cette action est irréversible.',
        ].join('<br/>');

        const modalRef = this.modalService.open(ConfirmationModalComponent);
        modalRef.componentInstance.body = body;
        modalRef.result.then(
            (result) => {
                this.confirmDelete();
            },
            () => {},
        );
        this.categoryToDelete = category;
    }

    addCriteria(categoryId: number) {
        const category = this._findCategory(categoryId);
        const criteriaIds = category.criteria.map((criteria: FilterCriteria) => criteria.id);
        const maxId = criteriaIds.length ? Math.max(...criteriaIds) : 0;

        const newCriteria = {
            filterId: this.filterId,
            categoryId: category.id,
            id: maxId + 1,
            label: null,
            order: category.criteria.length + 1,
            modify: true,
            new: true,
        };

        category.criteria.push(newCriteria);
    }

    async saveCriteria(criteriaToSave: FilterCriteria) {
        const category = this._findCategory(criteriaToSave.categoryId);

        try {
            if (category.new) {
                await this.saveCategory(category);
            }

            // well it sucks but we must reconvert to dirty convention name :
            const dter = {
                id_dter: criteriaToSave.id,
                lib_dter: criteriaToSave.label,
                id_archi_critdter: criteriaToSave.filterId,
                id_critere: criteriaToSave.categoryId,
                ordre: criteriaToSave.order,
            };

            await this.restService.updateFilterCriteria(criteriaToSave.id, dter);
            const updatedCriteria = category.criteria.find(
                (criteria: FilterCriteria) => criteria.id === criteriaToSave.id,
            );
            updatedCriteria.modify = false;
            updatedCriteria.new = false;
            this.notification.success('Ce déterminant a bien été enregistré.');
        } catch (error) {
            this.notification.error(
                "Une erreur s'est produite : Ce déterminant n'a pas été enregistré.",
            );
            console.error(error);
        }
    }

    deleteCriteria(criteria: FilterCriteria) {
        this.elementName = 'déterminant';

        const body = [
            `Voulez-vous vraiment supprimer ce ${this.elementName} ?`,
            'Cette action est irréversible.',
        ].join('<br/>');

        const modalRef = this.modalService.open(ConfirmationModalComponent);
        modalRef.componentInstance.body = body;
        modalRef.result.then(
            (result) => {
                this.confirmDelete();
            },
            () => {},
        );

        this.criteriaToDelete = criteria;
    }

    confirmDelete() {
        if (this.elementName === 'critère') {
            this._confirmDeleteCategory();
        } else {
            this._confirmDeleteCriteria();
        }
    }

    private async _confirmDeleteCategory() {
        try {
            if (!this.categoryToDelete.new) {
                const parameters = { filterId: this.filterId };
                await this.restService.deleteFilterCategory(this.categoryToDelete.id, parameters);
            }
            this.filter.category = this.filter.category.filter(
                (category: FilterCategory) => category.id != this.categoryToDelete.id,
            );
            this.notification.success('Ce critère a bien été supprimé.');
        } catch (error) {
            this.notification.error("Une erreur s'est produite : Ce critère n'a pas été supprimé.");
            console.error(error);
        }
    }

    private async _confirmDeleteCriteria() {
        try {
            if (!this.criteriaToDelete.new) {
                await this.restService.deleteFilterCriteria(this.criteriaToDelete.id, {
                    filterId: this.filterId,
                    categoryId: this.criteriaToDelete.id_critere,
                });
            }

            const category = this._findCategory(this.criteriaToDelete.categoryId);
            category.criteria = category.criteria.filter(
                (criteria: FilterCriteria) => criteria.id !== this.criteriaToDelete.id,
            );
            this.notification.success('Ce déterminant a bien été supprimé.');
        } catch (error) {
            this.notification.error(
                "Une erreur s'est produite : Ce déterminant n'a pas été supprimé.",
            );
            console.error(error);
        }
    }

    private async _init() {
        try {
            const categories = this.filter.critere.map((critere: any) => {
                const criteria = critere.dter.map((dter: any) => ({
                    id: dter.id_dter,
                    label: dter.lib_dter,
                    filterId: dter.id_archi_critdter,
                    categoryId: dter.id_critere,
                    order: dter.ordre,
                }));
                return {
                    id: critere.id_critere,
                    label: critere.lib_critere,
                    filterId: critere.id_archi_critdter,
                    champ_associe: critere.champ_associe,
                    criteria: criteria,
                    modify: false,
                    collapse: true,
                };
            });

            this.filter = {
                id: this.filter.id_archi_critdter,
                label: this.filter.nom_archi_critdter,
                tbl: this.filter.tbl,
                categories: categories,
            };
        } catch (error) {
            console.error(error);
        }
    }
}
