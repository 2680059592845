import { Component, Inject, OnInit, PipeTransform, QueryList, ViewChildren } from '@angular/core';
import { ReversePipe } from 'ngx-pipes';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/AuthService';
import { RestService } from 'src/app/services/RestService';

import { ConfirmationModalComponent } from '../../main/modals/confirmation/confirmation-modal.component';
import { NgbdSortableHeader, SortEvent } from '../../../components/st-table/sortable.directive';
import { StTableService } from '../../../components/st-table/st-table.service';

function matches(user: any, term: string, pipe: PipeTransform) {
    const lowerCaseTerm = term.toLowerCase();
    return (
        pipe.transform(user.id).includes(term) ||
        user.name.toLowerCase().includes(lowerCaseTerm) ||
        user.surname.toLowerCase().includes(lowerCaseTerm) ||
        user.login.toLowerCase().includes(lowerCaseTerm) ||
        user.mail.toLowerCase().includes(lowerCaseTerm) ||
        pipe.transform(user.role).includes(term) ||
        user.group_name.toLowerCase().includes(lowerCaseTerm) ||
        user.lastConnectionDate.includes(lowerCaseTerm) ||
        user.roleName.toLowerCase().includes(lowerCaseTerm)
    );
}

@Component({
    selector: 'manageUser',
    templateUrl: './user-manage.template.html',
    styleUrls: [
        '../../../components/st-table/st-table.component.scss',
        './user-manage.controller.scss',
    ],
    providers: [ReversePipe],
})
export class UserManageComponent implements OnInit {
    users$: Observable<any[]>;
    total$: Observable<number>;
    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

    private userInfo: User;
    public isAdmin: boolean = false;
    public users: User[] = [];
    public user: any;

    public userCount: number;
    public localAdminCount: number;
    public superAdminCount: number;

    private roleByRoleId = {
        1: 'Super admin',
        2: 'Admin local',
        3: 'Utilisateur',
    };

    constructor(
        private router: Router,
        private modalService: NgbModal,
        private notification: ToastrService,
        @Inject(AuthService) private authService: AuthService,
        @Inject(RestService) private restService: RestService,
        @Inject(StTableService) public stTableService: StTableService,
    ) {
        this.users$ = this.stTableService.rows$;
        this.total$ = this.stTableService.total$;
    }

    async ngOnInit(): Promise<void> {
        this.stTableService.matches = matches;

        this.userInfo = this.authService.user;
        this.isAdmin = this.userInfo.role == 1;

        if (this.userInfo.group) {
            this.userInfo.group_id = this.userInfo.group.id;
        }

        try {
            const users = await this.restService.getAllUsers();
            this.setUsers(users);
            this.users = users;
            this.stTableService.setRows(users);
        } catch (error) {
            console.error('Error initialization', error);
            this.notification.error(
                'Une erreur est survenue. Impossible de charger la liste des utilisateurs.',
            );
        }
    }

    setUsers(users: any[]) {
        this.users = users;
        this._setLastConnectionDate();
        this._setRole();
        this._countUsers();
    }

    private _countUsers() {
        this.userCount = this.users.length;
        this.superAdminCount = this.users.filter((user) => user.role == 1).length;
        this.localAdminCount = this.users.filter((user) => user.role == 2).length;
    }

    private _setLastConnectionDate() {
        this.users.forEach((user) => {
            const lastConnectionDate = new Date(user.lastcon);
            user.lastConnectionDate = lastConnectionDate.toLocaleDateString();
            user.lastConnectionDateTime = lastConnectionDate.getTime();
        });
    }

    private _setRole() {
        this.users.forEach((user) => (user.roleName = this.roleByRoleId[user.role]));
    }

    openDeleteUser(user: User) {
        this.user = user;
        const modalHeader = 'Supprimer un utilisateur';
        const modalBody = `
            <div>Voulez-vous vraiment supprimer l'utilisateur <span class="text-st-primary">${user.surname} ${user.name}</span> ?</div>
            <div>Cette action est irréversible !</div>
        `;

        const modalRef = this.modalService.open(ConfirmationModalComponent);
        modalRef.componentInstance.header = modalHeader;
        modalRef.componentInstance.body = modalBody;
        modalRef.result.then(
            (result) => this.validateDelete(),
            () => {},
        );
    }

    async validateDelete() {
        try {
            const users = await this.restService.deleteUser(this.user.id);
            this.setUsers(users);
            this.users = users;
            this.stTableService.setRows(users);
        } catch (error) {
            console.error('Error delete User', error);
            this.notification.error(
                `Une erreur est survenue. Impossible de supprimer l'utilisateur.`,
            );
        }
    }

    goToUpdateUser(user: any) {
        this.router.navigate(['administration/users/update/' + user.id + '/']);
    }

    goToNewUser() {
        this.router.navigate(['administration/users/add/']);
    }

    onSort({ column, direction }: SortEvent) {
        // resetting other headers
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        this.stTableService.sortColumn = column;
        this.stTableService.sortDirection = direction;
    }
}
