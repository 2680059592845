<ng-container *ngIf="visible">
    <div class="tab-container {{ class }} d-flex flex-column">
        <div class="module-title">
            <h4 class="text-center">{{ label }}</h4>
            <h6 *ngIf="!!subLabel" class="text-center">{{ subLabel }}</h6>

            <button class="btn" title="Replier" (click)="close()" [ngClass]="closePlacement">
                <ng-container *ngIf="closePlacement == 'right'">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-chevron-left"
                        viewBox="0 0 16 16"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                        />
                    </svg>
                </ng-container>
                <ng-container *ngIf="closePlacement == 'left'">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-chevron-right"
                        viewBox="0 0 16 16"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                        />
                    </svg>
                </ng-container>
            </button>
        </div>

        <div class="position-relative module-body flex-grow-1 overflow-hidden">
            <ng-content></ng-content>
        </div>
    </div>
</ng-container>
