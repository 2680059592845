('use strict');

import { Component, Input } from '@angular/core';

@Component({
    selector: 'stProgressbar',
    templateUrl: './st-progressbar.template.html',
})
export class StProgressBarComponent {
    @Input() type: string;
    @Input() unit: string;
    @Input() percentageValue: number;
    @Input() absoluteValue: number;

    constructor() {}
}
