<div class="modal fade importLoadCurveModal" id="importLoadCurveModal" tabindex="-1" aria-labelledby="importLoadCurveModal" aria-hidden="true" data-bs-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="importLoadCurveModal">Importer une courbe de charge</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" style="width: 100%;">
        <div class="row">
          <div class="col-12 col-xs-offset-1 text-center" *ngIf="hasLoadCurve">
            <stButton btnLabel="Supprimer la courbe actuelle" btnClass="btn-md btn-danger" (click)="delete()">
            </stButton>
          </div>
    
          <div class="col-12 col-xs-offset-1 text-center" *ngIf="!hasLoadCurve && !isUploadingFile">
            <br />
            <i class="fa fa-5x fa-upload"></i>
            <br />
            <button *ngIf="!isUploadReady" class="btn btn-st-default" type="file" ngf-max-size="500MB"
              ngf-select="uploadFile($file, $invalidFiles)" accept=".csv"> <span>Importer un fichier</span>
            </button>
            <br />
            <br />
            <table *ngIf="isUploadReady">
              <thead>
                <tr>
                  <th>date</th>
                  <th>valeur</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data fo fileContent | limitTo :5">
                  <td>{{data[0]}}</td>
                  <td>{{data[1]}}</td>
                </tr>
                <tr>
                  <td>...</td>
                  <td>...</td>
                </tr>
              </tbody>
            </table>
            <stButton btnLabel="Enregistrer la courbe" btnClass="btn-st-primary btn-md" (click)="upload()"
              [isDisabled]="isUploadingCurve" *ngIf="isUploadReady"></stButton>
            <br />
            <br />
            <i *ngIf="!isUploadReady">Le fichier doit être au format CSV, séparé par un point-virgule et sans en-tête.
              <br /> La Première colonne correspond à la date (<a href="https://fr.wikipedia.org/wiki/ISO_8601">format
                ISO 8601: 2019-12-31T01:00:00+02:00</a>), la seconde la puissance appelée sur la période en W</i>
            <i *ngIf="hasLoadCurve">Vous écraserez définitivement les données actuellement existentes</i>
            <div *ngIf="hasUploadError">
              <br />
              <i class="fa fa-5x fa-meh-o"></i>
              <h4> Une erreur est survenue, veuillez verifier vos données </h4>
            </div>
          </div>
          <div class="col-10 col-xs-offset-1 text-center" *ngIf="!hasLoadCurve && !hasUploadError && isUploadingFile">
            <i class="fa fa-spin fa-spinner fa-4x"></i>
          </div>
          <div class="col-12 col-xs-offset-1 text-center">
            <br />
            <br />
            <stButton btnLabel="Annuler" btnClass="btn-st-default btn-md"  data-bs-dismiss="modal"></stButton>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>