<div class="animate-switch-container h-100" [ngSwitch]="pvStage">
    <div class="animate-switch d-flex flex-column h-100" *ngSwitchCase="'pvValorizationStage'">
        <cadastreSolairePvValorization
            class="flex-grow-1 overflow-auto"
        ></cadastreSolairePvValorization>
    </div>
    <div class="animate-switch d-flex flex-column h-100" *ngSwitchCase="'pvTotalityStage'">
        <cadastreSolairePvTotality class="flex-grow-1 overflow-auto"></cadastreSolairePvTotality>
    </div>
    <div class="animate-switch d-flex flex-column h-100" *ngSwitchCase="'pvSurplusStage'">
        <cadastreSolairePvSurplus class="flex-grow-1 overflow-auto"></cadastreSolairePvSurplus>
    </div>
</div>
