('use strict');

import { Router } from '@angular/router';
import { Component, Inject, OnInit, PipeTransform, QueryList, ViewChildren } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Indicator } from 'src/app/models/Indicator';

import { TerritoryScale } from 'src/app/models/TerritoryTypes';
import { PaginationService } from 'src/app/services/pagination.service';
import { RestService } from 'src/app/services/RestService';
import { TerService } from 'src/app/services/TerService';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from 'src/app/controllers/main/modals/confirmation/confirmation-modal.component';
import { StTableService } from '../../../components/st-table/st-table.service';
import { Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from '../../../components/st-table/sortable.directive';

function matches(indicator: any, term: string, pipe: PipeTransform) {
    const lowerCaseTerm = term.toLowerCase();
    return (
        pipe.transform(indicator.id_indicateur).includes(term) ||
        (indicator.libelle_indic_complet &&
            indicator.libelle_indic_complet.toLowerCase().includes(lowerCaseTerm)) ||
        (indicator.unit && indicator.unit.includes(lowerCaseTerm)) ||
        (indicator.tbl && indicator.tbl.toLowerCase().includes(lowerCaseTerm)) ||
        (indicator.granularity_min &&
            indicator.granularity_min.toLowerCase().includes(lowerCaseTerm)) ||
        (indicator.form && indicator.form.toLowerCase().includes(lowerCaseTerm))
    );
}
@Component({
    templateUrl: './indicator-manage.template.html',
    styleUrls: [
        '../../../components/st-table/st-table.component.scss',
        './indicator-manage.controller.scss',
    ],
})
export class ManageIndicatorsComponent implements OnInit {
    indicators$: Observable<any[]>;
    total$: Observable<number>;
    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

    bases: any;
    indicatorIdToDelete: number;
    constructor(
        private notification: ToastrService,
        private router: Router,
        @Inject(RestService) private restService: RestService,
        @Inject(TerService) private terService: TerService,
        @Inject(PaginationService) private paginationService: PaginationService,
        @Inject(StTableService) public stTableService: StTableService,
        private modalService: NgbModal,
    ) {
        this.indicators$ = this.stTableService.rows$;
        this.total$ = this.stTableService.total$;
    }
    granularity = [];
    indicators = [];
    totalIndicators = 0;
    reference = {};
    sortValue = '';

    selectedPage = 1;
    form = [
        { id: 'poly', label: 'polygone' },
        { id: 'poly3D', label: 'polygone 3D' },
        { id: 'point', label: 'point' },
        { id: 'line', label: 'ligne' },
    ];

    async ngOnInit(): Promise<void> {
        this.stTableService.matches = matches;

        try {
            this.bases = await this.restService.getBases();
            await this._setTerritoryScales();
            const indicators = await this.restService.getIndicators();
            this._initIndicators(indicators);
            this.setIndicators();
            this.stTableService.setRows(indicators);
        } catch (error: any) {
            console.error('Error initialization', error);
        }
    }

    getLabelFromData(value, ref) {
        if (!ref) {
            return value;
        } else {
            for (var i = 0; i < ref.length; i++) {
                if (ref[i].id == value) {
                    return ref[i].label;
                }
            }
        }
        return value;
    }

    //==================================================================================================================
    // fonction de route

    goToNewIndicator() {
        this.router.navigate(['administration/indicators/add/']);
    }

    goToUpdateIndicator(indicatorId: number) {
        this.router.navigate([`administration/indicators/update/${indicatorId}/`]);
    }

    _initIndicators = (indicators: Array<Indicator>) => {
        this.indicators = indicators;
        this.totalIndicators = this.indicators.length;

        this.granularity = this.terService.territoryScales.map((territoryScale: TerritoryScale) => {
            return {
                id: territoryScale.id_terr,
                label: territoryScale.libelle_long,
            };
        });
    };

    deleteIndicator(indicatorId: number) {
        this.indicatorIdToDelete = indicatorId;
        const modalHeader = 'Supprimer un indicateur';

        const body = [
            'Voulez-vous vraiment supprimer cet indicateur ?',
            'Cette action est irréversible ! Les scénarios associés à cet indicateur seront également supprimés.',
        ].join('<br/>');

        const modalRef = this.modalService.open(ConfirmationModalComponent);
        modalRef.componentInstance.body = body;
        modalRef.componentInstance.header = modalHeader;
        modalRef.result.then(
            (result) => {
                this.confirmDelete();
            },
            () => {},
        );
        modalRef.componentInstance.name = 'confirmationModal';
    }

    async confirmDelete() {
        try {
            await this.restService.deleteIndicator(this.indicatorIdToDelete);
            this.notification.success("L'indicateur a bien été supprimé.");
            const indicators = await this.restService.getIndicators();
            this._initIndicators(indicators);
            this.setIndicators();
            this.stTableService.setRows(indicators);
        } catch (error: any) {
            this.notification.success(
                "Une erreur s'est produite: L'indicateur n'a pas été supprimé.",
            );
            console.log(error);
        }
    }
    private _setTerritoryScales = () => {
        if (!this.terService.territoryScales) {
            return this.terService.initTerritory(false, false);
        }
        return;
    };

    onSort({ column, direction }: SortEvent) {
        // resetting other headers
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        this.stTableService.sortColumn = column;
        this.stTableService.sortDirection = direction;
    }

    setIndicators() {
        this.indicators.forEach((indicator) => {
            indicator.database = this.getLabelFromData(indicator.tbl, this.bases);
            indicator.shape = this.getLabelFromData(indicator.form,this.form);
            indicator.granularity = this.getLabelFromData(
                indicator.granularity_min,
                this.granularity,
            );
        });
    }
}
