'use strict';
import * as L from 'leaflet';

import { MapService } from '../services/map.service';
import { PlotFactory } from './PlotFactory';

export class PlotPolyFactory extends PlotFactory {
    constructor(indicatorId: number, mapService: MapService) {
        super(indicatorId, mapService);
        this.form = 'poly';
        if (this.type === 'value') {
            this.indicatorcontourcolor = '#000000';
            this.indicatorcontouropacity = 1;
        }
    }

    getStyle(feature: GeoJSON.Feature) {
        return {
            fillColor: this.getColor(feature.properties.value),
            weight: this.indicatorcontourweight,
            color: this.indicatorcontourcolor,
            opacity: this.getContourOpacity(feature.properties.value),
            fillOpacity: this.getFillOpacity(feature.properties.value),
        };
    }

    highlightFeature(layer: any) {
        layer.setStyle({
            weight: this.indicatorcontourweight * 2,
        });
    }

    unhighlightFeature(layer: any) {
        layer.setStyle({
            weight: this.indicatorcontourweight,
            color: this.indicatorcontourcolor,
        });
    }
}
