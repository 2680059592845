import { Inject, Injectable } from '@angular/core';

import { Indicator } from 'src/app/models/Indicator';

import { ColorService } from 'src/app/services/ColorService';
import { UsefulService } from 'src/app/services/UsefulService';
import { LinearDistribution } from 'src/app/services/plotIndicator/distributions/methods/Linear';
import { ManualDistribution } from 'src/app/services/plotIndicator/distributions/methods/Manual';
import { MeanDistribution } from 'src/app/services/plotIndicator/distributions/methods/Mean';
import { NaturalBreaksDistribution } from 'src/app/services/plotIndicator/distributions/methods/NaturalBreaks';
import { QuantileDistribution } from 'src/app/services/plotIndicator/distributions/methods/Quantile';
import { StandardDeviationDistribution } from 'src/app/services/plotIndicator/distributions/methods/StandardDeviation';

@Injectable({
    providedIn: 'root',
})
export class DistributionValueService {
    public values: Array<number> = [];
    public intervals: Array<number>;
    public legendBoundaries: number[][];

    public methodsInfo = [
        {
            id: 'linéaire',
            label: 'Linéaire',
        },
        {
            id: 'quantile',
            label: 'Quantile',
        },
        {
            id: 'moyenne',
            label: 'Moyennes emboitées',
        },
        {
            id: 'standardDeviation',
            label: 'Ecarts types',
        },
        {
            id: 'naturalBreaks',
            label: 'Seuils naturels',
        },
        {
            id: 'manuel',
            label: 'Seuils manuels',
        },
    ];

    public method: any;

    constructor(
        @Inject(ColorService) private colorService: ColorService,
        @Inject(UsefulService) private usefulService: UsefulService,
        @Inject(LinearDistribution) private linearDistribution: LinearDistribution,
        @Inject(ManualDistribution) private manualDistribution: ManualDistribution,
        @Inject(MeanDistribution) private meanDistribution: MeanDistribution,
        @Inject(NaturalBreaksDistribution)
        private naturalBreaksDistribution: NaturalBreaksDistribution,
        @Inject(QuantileDistribution) private quantileDistribution: QuantileDistribution,
        @Inject(StandardDeviationDistribution)
        private standardDeviationDistribution: StandardDeviationDistribution,
    ) {}

    run(indicatorPlot: any, refreshIntervals?: boolean) {
        this.getValues(indicatorPlot);

        const methodService = this.setMethodService(indicatorPlot);

        if (refreshIntervals) {
            methodService.setClassCount(indicatorPlot);
            methodService.setScale(indicatorPlot);

            this.legendBoundaries = methodService.setIntervals(indicatorPlot);
        }

        if (this.legendBoundaries.length) {
            this.setMin(indicatorPlot);
            this.setMax(indicatorPlot);
        }

        return this.legendBoundaries;
    }

    init(indicatorPlot: Indicator) {
        this.cleanLineForm(indicatorPlot);

        let method: { id: string; label: string };
        try {
            method = this.methodsInfo.find((method) => method.id == 'manuel');
            const tableBornes = JSON.parse(indicatorPlot.distributionMethodId);
            indicatorPlot.tableBornes = tableBornes.sort((a: number, b: number) => a - b);
            const decimalCount = indicatorPlot.decimalCount;

            indicatorPlot.legendBoundaries =
                this.manualDistribution.convertTableBornesToLegendBoundaries(
                    tableBornes,
                    decimalCount,
                );
        } catch (error) {
            method = this.methodsInfo.find(
                (method) => method.id == indicatorPlot.distributionMethodId,
            );
        }
        this.method = method;

        indicatorPlot.distribution = {
            type: 'value',
            method: this.method,
        };
    }

    getValues(indicatorPlot: Indicator) {
        this.values = [];
        for (let param in indicatorPlot.dataToPlot) {
            let value = indicatorPlot.dataToPlot[param].value;

            if (
                typeof value === 'number' &&
                (value !== 0 || !indicatorPlot.separate_zero_in_lgd) &&
                ((value !== undefined && value !== null) || !indicatorPlot.exclude_null)
            ) {
                this.values.push(value);
            }
        }
        this.values.sort((a, b) => a - b);
    }

    setMethodService(indicatorPlot: Indicator) {
        const methodId = indicatorPlot.distribution.method.id;
        let methodService: any;
        if (methodId == 'linéaire') {
            methodService = this.linearDistribution;
        } else if (methodId == 'manuel') {
            methodService = this.manualDistribution;
        } else if (methodId == 'moyenne') {
            methodService = this.meanDistribution;
        } else if (methodId == 'naturalBreaks') {
            methodService = this.naturalBreaksDistribution;
        } else if (methodId == 'quantile') {
            methodService = this.quantileDistribution;
        } else if (methodId == 'standardDeviation') {
            methodService = this.standardDeviationDistribution;
        } else {
            const message = `Distribution method is not understood: ${methodId}`;
            console.error(message);
            throw Error(message);
        }
        methodService.setValues(this.values);

        return methodService;
    }

    setMin(indicatorPlot: Indicator) {
        const features = indicatorPlot.geojson.features.filter(
            (feature: GeoJSON.Feature) =>
                feature.properties.value !== undefined && feature.properties.value !== null,
        );
        const minimum = features.reduce(
            (min: number, feature: GeoJSON.Feature) => Math.min(min, feature.properties.value),
            this.legendBoundaries[0][0],
        );
        this.legendBoundaries[0][0] = this.usefulService.floor(minimum, indicatorPlot.decimalCount);
    }

    setMax(indicatorPlot: Indicator) {
        const features = indicatorPlot.geojson.features.filter(
            (feature: GeoJSON.Feature) =>
                feature.properties.value !== undefined && feature.properties.value !== null,
        );
        const lastIndex = this.legendBoundaries.length - 1;
        const maximum = features.reduce(
            (max: number, feature: GeoJSON.Feature) => Math.max(max, feature.properties.value),
            this.legendBoundaries[lastIndex][1],
        );
        this.legendBoundaries[lastIndex][1] = this.usefulService.ceil(
            maximum,
            indicatorPlot.decimalCount,
        );
    }

    setColors(indicatorPlot: Indicator) {
        const degrade = indicatorPlot.newColorGradient
            ? indicatorPlot.newColorGradient
            : indicatorPlot.degrade;

        // override degrade with its new values (eventually) then remove newColorGradient
        indicatorPlot.degrade = degrade;
        delete indicatorPlot.newColorGradient;

        let colorPalet = this.colorService.getColorPalette(degrade, indicatorPlot.classCount);

        // if (indicatorPlot.form === 'point_proportional') {
        //     colorTable = colorTable.map((c: string) => colorTable[0]);
        // }

        const colorTable = [];

        for (let i = 0; i < indicatorPlot.classCount; i++) {
            colorTable[i] = colorPalet[i];
        }

        indicatorPlot.tableCouleurs = colorTable;
        return colorTable;
    }

    setLegend(indicatorPlot: Indicator, refreshIntervals?: boolean) {
        const colorTable = this.setColors(indicatorPlot);
        const legendBoundaries = indicatorPlot.legendBoundaries;
        const dashArray = indicatorPlot.dashArray;

        const values = indicatorPlot.geojson.features
            .filter((feature: GeoJSON.Feature) => !isNaN(feature.properties.value))
            .map((feature: GeoJSON.Feature) => feature.properties.value);
        const distinctValues = Array.from(new Set(values));

        if (indicatorPlot.distribution.method.id != 'manuel') {
            const classCount = Math.min(indicatorPlot.classCount, distinctValues.length);
            indicatorPlot.classCount = classCount;
        }

        let legends = [];
        for (let i = 0; i < indicatorPlot.classCount; i++) {
            const min = legendBoundaries[i][0];
            const minStringified = this.usefulService.stringifyNumber(min);

            const max = legendBoundaries[i][1];
            const maxStringified = this.usefulService.stringifyNumber(max);

            const label = minStringified + ' à ' + maxStringified;

            legends.push({
                id: i,
                min: min,
                max: max,
                lib: label,
                color: colorTable[i],
                default_radius_weight: 1,
                default_contouropacity: 1,
                default_fillopacity: 1,
                dashArray: dashArray,
            });
        }

        if (indicatorPlot.form === 'point_proportional') {
            const defaultRadius = indicatorPlot.default_radius_weight || 1;
            const weight = indicatorPlot.weightMultiplier || 1;
            legends.forEach(
                (legend, index) =>
                    (legend.default_radius_weight = (index + 1) * defaultRadius * weight),
            );
        }

        indicatorPlot.legende = legends;
    }

    cleanLineForm(indicatorPlot: any) {
        if (indicatorPlot.form === 'line' && typeof indicatorPlot.dashArray === 'string') {
            indicatorPlot.dashArray = indicatorPlot.dashArray
                .split(',')
                .map((x: string) => Number(x));
        }
    }

    getLegendBoundaryIndex(indicatorPlot: Indicator, value: number) {
        const legendBoundaries = indicatorPlot.legendBoundaries;
        const decimalCount = indicatorPlot.decimalCount;
        const roundedValue = this.usefulService.round(value, decimalCount);
        const boundaries = legendBoundaries.find(
            (boundaries) => roundedValue >= boundaries[0] && roundedValue <= boundaries[1],
        );
        const index = legendBoundaries.indexOf(boundaries);
        return index;
    }
}
