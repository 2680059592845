<div class="modal-header">
  <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()"><span
        aria-hidden="true">&times;</span></button> -->
  <h4 class="modal-title text-st-active">Rétablir la valeur par défaut</h4>
</div>
<div class="modal-body">
  <ng-container *ngFor="let option of manageResetLevel.options">
    <stRadio *ngIf="option.show" [label]="option.label" name="optionsReset" [innerId]="option.level"
      [value]="option.level" [(ngModel)]="manageResetLevel.level">
    </stRadio>
  </ng-container>
</div>
<div class="modal-footer">
  <stButton btnLabel="Annuler" btnClass="btn-st-default me-3 px-5" (onClick)="close()"></stButton>
  <stButton btnLabel="Valider" btnClass="btn-st-primary ms-3 px-5" (onClick)="validate()">
  </stButton>
</div>
