import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BaseManageComponent } from './controllers/admin/base/base-manage.controller';
import { AddFilterComponent } from './controllers/admin/filter/filter-add.controller';
import { ManageFilterComponent } from './controllers/admin/filter/filter-manage.controller';
import { UpdateFilterComponent } from './controllers/admin/filter/filter-update.controller';
import { GroupAddComponent } from './controllers/admin/group/group-add.controller';
import { ManageGroupComponent } from './controllers/admin/group/group-manage.controller';
import { UpdateGroupComponent } from './controllers/admin/group/group-update.controller';
import { AddIndicatorComponent } from './controllers/admin/indicator/indicator-add.controller';
import { ManageIndicatorsComponent } from './controllers/admin/indicator/indicator-manage.controller';
import { UpdateIndicatorComponent } from './controllers/admin/indicator/indicator-update.controller';
import { FilterResolver } from './controllers/admin/resolvers/filter.resolver';
import { GroupResolver } from './controllers/admin/resolvers/group.resolver';
import { IndicatorResolver } from './controllers/admin/resolvers/indicator.resolver';
import { UserResolver } from './controllers/admin/resolvers/user.resolver';
import { AddUserComponent } from './controllers/admin/user/user-add.controller';
import { UserManageComponent } from './controllers/admin/user/user-manage.controller';
import { UpdateUserComponent } from './controllers/admin/user/user-update.controller';
import { LoginComponent } from './controllers/login/login.controller';
import { MainComponent } from './controllers/main/main.controller';
import { AuthGuardService } from './services/auth-guard.service';
import { ExternalLoginComponent } from './controllers/external-login/external-login.component';

const routes: Routes = [
    { path: '', component: LoginComponent },
    { path: 'external/:group/:token', component: ExternalLoginComponent },
    { path: 'main', component: MainComponent, canActivate: [AuthGuardService], data: { role: 3 } },
    {
        path: 'administration/bases/manage',
        component: BaseManageComponent,
        canActivate: [AuthGuardService],
        data: { role: 1 },
    },
    {
        path: 'administration/users/manage',
        component: UserManageComponent,
        canActivate: [AuthGuardService],
        data: { role: 2 },
    },
    {
        path: 'administration/users/add',
        component: AddUserComponent,
        canActivate: [AuthGuardService],
        data: { role: 2 },
    },
    {
        path: 'administration/users/update/:userId',
        component: UpdateUserComponent,
        resolve: { user: UserResolver },
        canActivate: [AuthGuardService],
        data: { role: 2 },
    },
    {
        path: 'administration/indicators/manage',
        component: ManageIndicatorsComponent,
        canActivate: [AuthGuardService],
        data: { role: 1 },
    },
    {
        path: 'administration/indicators/add',
        component: AddIndicatorComponent,
        canActivate: [AuthGuardService],
        data: { role: 1 },
    },
    {
        path: 'administration/indicators/update/:indicatorId',
        component: UpdateIndicatorComponent,
        resolve: { indicator: IndicatorResolver },
        canActivate: [AuthGuardService],
        data: { role: 1 },
    },
    {
        path: 'administration/filters/manage',
        component: ManageFilterComponent,
        canActivate: [AuthGuardService],
        data: { role: 1 },
    },
    {
        path: 'administration/filters/add',
        component: AddFilterComponent,
        canActivate: [AuthGuardService],
        data: { role: 1 },
    },
    {
        path: 'administration/filters/update/:archiCritdterId',
        component: UpdateFilterComponent,
        resolve: { filter: FilterResolver },
        canActivate: [AuthGuardService],
        data: { role: 1 },
    },
    {
        path: 'administration/groups/manage',
        component: ManageGroupComponent,
        canActivate: [AuthGuardService],
        data: { role: 1 },
    },
    {
        path: 'administration/groups/add',
        component: GroupAddComponent,
        canActivate: [AuthGuardService],
        data: { role: 1 },
    },
    {
        path: 'administration/groups/update/:groupId',
        component: UpdateGroupComponent,
        resolve: { group: GroupResolver },
        canActivate: [AuthGuardService],
        data: { role: 1 },
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
