<div class="d-flex align-items-center mb-3">
  <div class="me-4">{{ text }}</div>
  <stSelect [data]="values" [(ngModel)]="nbLinesPerPage" labelField="fullObject" valueField="fullObject"
    (change)="pageCount()" type="primary">
  </stSelect>
</div>

<ng-content></ng-content>

<div class="d-flex justify-content-center" *ngIf="pagesNb">
  <div class="cursor-pointer iconeActiveBleu" (click)="goToFirstPage()" title="Aller à la première page">
    <span class="fa fa-angle-double-left"></span>
  </div>
  <div class="cursor-pointer iconeActiveBleu mx-5" (click)="tabPreviousPage()" title="Aller à la page précédente">
    <span class="fa fa-angle-left"></span>
  </div>
  <div>
    Page {{ pageSelected }} / {{ pagesNb }}
  </div>
  <div class="cursor-pointer iconeActiveBleu mx-5" (click)="tabNextPage()" title="Aller à la page suivante">
    <span class="fa fa-angle-right"></span>
  </div>
  <div class="cursor-pointer iconeActiveBleu" (click)="goToLastPage()" title="Aller à la dernière page">
    <span class="fa fa-angle-double-right"></span>
  </div>
</div>