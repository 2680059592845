'use strict';

import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ReversePipe } from 'ngx-pipes';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ngxCsv } from 'ngx-csv/ngx-csv';

import { Indicator } from 'src/app/models/Indicator';
import { FilterCategory } from 'src/app/models/FilterCategory';

import { CatalogService } from 'src/app/services/catalog.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { DataService } from 'src/app/services/DataService';
import { FilterDataService } from 'src/app/services/FilterDataService';
import { ModuleService } from 'src/app/services/module.service';
import { TerService } from 'src/app/services/TerService';

@UntilDestroy()
@Component({
    selector: 'dashboard-plot',
    templateUrl: './dashboard-plot.template.html',
    styleUrls: ['./dashboard-plot.component.scss'],
    providers: [ReversePipe],
})
export class DashboardPlotComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();

    public plots: {
        indicatorId: number;
        indicator: Indicator;
        selectedTerritory: any;
        chart: any;
        isLoading: boolean;
    }[] = [];
    public filtersByIndicatorId = {};
    private filtersValueByIndicatorId = {};

    constructor(
        private notification: ToastrService,
        @Inject(CatalogService) public catalogService: CatalogService,
        @Inject(DashboardService) public dashboardService: DashboardService,
        @Inject(DataService) private dataService: DataService,
        @Inject(FilterDataService) private filterDataService: FilterDataService,
        @Inject(ModuleService) public moduleService: ModuleService,
        @Inject(TerService) private terService: TerService,
    ) {}

    ngOnInit(): void {
        this.dashboardService.addedDashboard$
            .pipe(takeUntil(this.destroy$))
            .subscribe(async (dashboard) => {
                if (dashboard) {
                    const indicatorId = dashboard.indicatorId;
                    const indicatorPlot = dashboard.indicator;
                    const isPlotAvailable =
                        this.dashboardService.isIndicatorPlotAvailable(indicatorId);
                    if (!isPlotAvailable) return;

                    const isAlreadyDefined = this.plots.some(
                        (plot) => plot.indicatorId == indicatorId,
                    );
                    if (isAlreadyDefined) return;

                    this.plots.push({
                        indicatorId: indicatorId,
                        indicator: indicatorPlot,
                        selectedTerritory: {
                            id_ter: null,
                            label: null,
                        },
                        chart: null,
                        isLoading: true,
                    });
                    this._setFiltersByIndicatorId();
                }
            });

        this.dashboardService.removedDashboard$
            .pipe(takeUntil(this.destroy$))
            .subscribe(async (removedDashboard) => {
                if (removedDashboard) {
                    const indicatorId = removedDashboard.indicatorId;
                    this.plots = this.plots.filter((plot) => plot.indicatorId != indicatorId);
                    this._setFiltersByIndicatorId();
                }
            });

        this.dashboardService.updatedDashboard$
            .pipe(takeUntil(this.destroy$))
            .subscribe(async (dashboard) => {
                if (dashboard) {
                    const indicatorId = dashboard.indicatorId;
                    const plot = this.plots.find((plot) => plot.indicatorId == indicatorId);
                    if (plot) {
                        plot.selectedTerritory = dashboard.selectedTerritory;
                        const chart = plot.chart;
                        if (chart) {
                            await this.setChart(indicatorId, chart.categoryId);
                        }
                    }
                }
            });

        this.filterDataService.filtersByIndicator$
            .pipe(takeUntil(this.destroy$))
            .subscribe(async (filters) => {
                if (filters) {
                    const promises = [];
                    this.plots.forEach((plot) => {
                        const indicatorId = plot.indicatorId;
                        if (!!filters[indicatorId]) {
                            if (plot.chart == null) {
                                const indicatorId = plot.indicatorId;
                                const indicatorData =
                                    this.dataService.activeIndicators[indicatorId];
                                const dashboardPreferences = indicatorData.dashboardPreferences;

                                if (dashboardPreferences) {
                                    const categoryId = dashboardPreferences.default_plot;
                                    promises.push(this.setChart(indicatorId, categoryId));
                                } else {
                                    plot.isLoading = false;
                                }
                            } else {
                                plot.isLoading = false;
                            }
                        }
                    });
                    await Promise.all(promises);
                    this._setFiltersByIndicatorId();
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private _setFiltersByIndicatorId() {
        const filtersByIndicatorId = JSON.parse(
            JSON.stringify(this.filterDataService.filtersByIndicator),
        );
        const indicatorIds = Object.keys(filtersByIndicatorId);

        indicatorIds.forEach((indicatorId) => {
            const filtersInfo = filtersByIndicatorId[indicatorId];
            const indicatorData = this.dataService.activeIndicators[indicatorId];
            if (indicatorData) {
                const dashboardPreferences = indicatorData.dashboardPreferences;

                if (dashboardPreferences) {
                    const availablePlots = dashboardPreferences.available_plots;
                    filtersByIndicatorId[indicatorId] = filtersInfo.filter(
                        (category: FilterCategory) => availablePlots.includes(category.id),
                    );
                }
            }
        });

        this.filtersByIndicatorId = filtersByIndicatorId;
    }

    async setChart(indicatorId: number, categoryId: number) {
        const plot = this.plots.find((plot) => plot.indicatorId == indicatorId);
        plot.isLoading = true;

        const indicatorData = this.dataService.activeIndicators[indicatorId];
        const filterIndicatorId = indicatorData.id_indic_for_filter_defaut;
        let filterIndicatorData = this.catalogService.findIndicator(filterIndicatorId);
        if (!filterIndicatorData) {
            filterIndicatorData = await this.dataService.getIndicator(filterIndicatorId, false);
        }

        const label = filterIndicatorData.libelle_indic_short;
        const unit = filterIndicatorData.unit_short;
        const yAxisLabel = unit ? `${label} - ${unit}` : label;

        const series = ['Ensemble'];
        const globalTerritoryPlot = this._setGlobalTerritoryPlot(indicatorId, categoryId);
        const datasets = [globalTerritoryPlot.data];

        if (plot.selectedTerritory.id_ter) {
            const territoryId = plot.selectedTerritory.id_ter;

            await this._getSelectedTerritoryFiltersValue(plot);

            // check if user didn't select another territory in the meantime
            if (plot.selectedTerritory.id_ter != territoryId) return;

            series.push(`Sélection - ${plot.selectedTerritory.label}`);
            const selectedTerritoryPlot = this._setSelectedTerritoryPlot(indicatorId, categoryId);
            datasets.push(selectedTerritoryPlot.data);
        }

        plot.chart = {
            indicatorId: indicatorId,
            categoryId: categoryId,
            categoryLabel: globalTerritoryPlot.label,
            xAxislabels: globalTerritoryPlot.legend,
            yAxisLabel: yAxisLabel,
            unit: unit,
            series: series,
            datasets: datasets,
        };
        plot.isLoading = false;
    }

    private _setGlobalTerritoryPlot(indicatorId: number, categoryId: number) {
        const category = this.filterDataService.filtersByIndicator[indicatorId].find(
            (category: FilterCategory) => category.id == categoryId,
        );
        category.criteria = category.criteria.sort((a: any, b: any) => a.order - b.order);

        const globalTerritoryPlot: any = category.criteria
            .filter((criteria) => !(criteria.id === null && criteria.absoluteValue === 0))
            .reduce(
                (chart, criteria) => {
                    const value = Math.round(criteria.absoluteValue);
                    const xLabel = criteria.label;

                    chart.data.push(value);
                    chart.legend.push(xLabel);
                    return chart;
                },
                { data: [], legend: [], label: category.label },
            );

        return globalTerritoryPlot;
    }

    private async _getSelectedTerritoryFiltersValue(plot: any) {
        const indicatorId = plot.indicatorId;
        const isCustomTerritory = plot.indicator.vector_base == 'none';

        this.filtersValueByIndicatorId[indicatorId] = isCustomTerritory
            ? await this._geCustomTerritoryFiltersValue(plot)
            : await this._geTerritoryFiltersValue(plot);
    }

    private async _geCustomTerritoryFiltersValue(plot: any) {
        try {
            const indicatorId = plot.indicatorId;
            const territoryId = plot.selectedTerritory.id_ter;
            const territoryScale = this.terService.territoryScale;

            const data = {
                scale_int: territoryScale.typeId,
                scale_filter: `'${territoryId}'`,
                scale: territoryScale.tbl_data,
                scaleTypeId: territoryScale.typeId,
                year: plot.indicator.crrsdc_ter_year_geo,
                territoryIds: [territoryId],
                filters: this.filterDataService.createFiltersArray(indicatorId),
            };

            const filtersValue =
                await this.filterDataService.geCustomTerritoryIndicatorFiltersValue(
                    indicatorId,
                    data,
                );
            return filtersValue;
        } catch (error) {
            console.error('Error _geCustomTerritoryFiltersValue', error);
            this.notification.error(
                'Une erreur est survenue. Impossible de mettre à jour les indicateurs.',
            );
        }
    }

    private async _geTerritoryFiltersValue(plot: any) {
        try {
            const indicatorId = plot.indicatorId;
            const territoryId = plot.selectedTerritory.id_ter;
            const granularity = plot.indicator.granularity;

            const data = {
                scale_int: granularity.typeId,
                scale_filter: `'${territoryId}'`,
                scale: granularity.tbl_data,
                scaleTypeId: granularity.typeId,
                year: plot.indicator.crrsdc_ter_year_geo,
                territoryIds: [territoryId],
                filters: this.filterDataService.createFiltersArray(indicatorId),
            };
            const filtersValue = await this.filterDataService.geTerritoryIndicatorFiltersValue(
                indicatorId,
                data,
            );
            return filtersValue;
        } catch (error) {
            console.error('Error _geTerritoryFiltersValue', error);
            this.notification.error(
                'Une erreur est survenue. Impossible de mettre à jour les indicateurs.',
            );
        }
    }

    private _setSelectedTerritoryPlot(indicatorId: number, categoryId: number) {
        const category: FilterCategory = this.filtersValueByIndicatorId[indicatorId].find(
            (category: FilterCategory) => category.id == categoryId,
        );
        category.criteria = category.criteria.sort((a, b) => a.ordre - b.ordre);

        const selectedTerritoryPlot = category.criteria
            .filter((criteria) => !(criteria.id === null && criteria.absoluteValue === 0))
            .reduce(
                (chart, criteria) => {
                    const value = Math.round(criteria.absoluteValue);
                    chart.data.push(value);
                    return chart;
                },
                { data: [] },
            );

        return selectedTerritoryPlot;
    }

    downloadChartCsv(plot: any) {
        const headers = this._getCsvHeaders(plot);
        const body = this._getCsvBody(plot);
        const yAxisLabel = plot.chart.yAxisLabel;
        const filterLabel = plot.chart.categoryLabel;
        const title = `${yAxisLabel} par ${filterLabel}`;

        const options = {
            fieldSeparator: ';',
            decimalseparator: ',',
            headers: headers,
        };
        return new ngxCsv(body, title, options);
    }

    private _getCsvHeaders(plot: any) {
        const filterLabel = plot.chart.categoryLabel;
        const selectedTerritory = plot.selectedTerritory;
        const isTerritorySelected = !!selectedTerritory.id_ter;

        const territories = this.terService.territories.map((t) => `${t.label} (${t.id})`);
        const globalTerritoryLabel = territories.join(', ');

        const headers = [filterLabel, globalTerritoryLabel, 'Unité'];
        if (isTerritorySelected) {
            headers.splice(2, 0, selectedTerritory.label);
        }
        return headers;
    }

    private _getCsvBody(plot: any) {
        const datasets = plot.chart.datasets;
        const xAxislabels = plot.chart.xAxislabels;
        const unit = plot.chart.unit || '';

        const body = datasets.reduce((rows: number[][], dataset: number[]) => {
            dataset.forEach((value: number, index: number) =>
                !rows[index]
                    ? (rows[index] = [xAxislabels[index], value])
                    : rows[index].push(value),
            );
            return rows;
        }, []);

        body.forEach((row: number[]) => row.push(unit));

        return body;
    }
}
