import { Component, Input, OnInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import { BaseChartDirective, ThemeService as NgChartsThemeService } from 'ng2-charts';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ThemeService } from 'src/app/services/ThemeService';

@Component({
    selector: 'stBarChart',
    templateUrl: './st-bar-chart.template.html',
})
export class StBarChartComponent implements OnInit, OnDestroy {
    @Input() labels: any;
    @Input() maxwidth: number;
    @Input() data: any[];
    @Input() series: string[];
    @Input() yAxisLabel: string = '';

    @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
    public barChartOptions: ChartConfiguration['options'];
    public barChartData: ChartData<'bar'>;
    private destroy$ = new Subject<void>();

    private colors: { backgroundColor: string; borderColor?: string }[];

    constructor(
        private ngChartsThemeService: NgChartsThemeService,
        @Inject(ThemeService) private themeService: ThemeService,
    ) {}

    ngOnInit(): void {
        this.barChartOptions = {
            responsive: true,
            scales: {
                x: {
                    display: true,
                    ticks: {
                        autoSkip: true,
                    },
                },
                y: {
                    display: true,
                    grid: {
                        display: false,
                    },
                    ticks: {
                        autoSkip: true,
                    },
                    title: {
                        display: true,
                        text: this.yAxisLabel,
                    },
                },
            },
            plugins: {
                legend: {
                    display: true,
                },
            },
        };

        this.themeService.themeObs$.pipe(takeUntil(this.destroy$)).subscribe((theme) => {
            if (theme) {
                this._setColors();
                this._updateChart();
            }
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnChanges() {
        if (this.maxwidth) {
            this.labels = this.labels.map((label: string) => this.formatLabel(label));
        }
        this._setColors();
        this._updateChart();
    }

    private _setColors() {
        const theme = this.themeService.theme;

        let overrides: ChartConfiguration['options'];
        if (theme.id == 'dark') {
            overrides = {
                scales: {
                    x: {
                        border: {
                            color: 'rgba(102, 102, 102, 1)',
                        },
                        grid: { color: 'rgba(102, 102, 102, 0.5)' },
                    },
                    y: {
                        border: {
                            color: 'rgba(102, 102, 102, 1)',
                        },
                    },
                },
            };
        } else {
            overrides = {};
        }
        // Apply the custom theme
        this.ngChartsThemeService.setColorschemesOptions(overrides);

        this.colors = [
            {
                backgroundColor: theme.info.color['--primary'],
                borderColor: theme.info.color['--primary'],
            },
            {
                backgroundColor: theme.info.color['--active'],
                borderColor: theme.info.color['--active'],
            },
        ];
    }

    private _updateChart() {
        const datasets = this.data.map((data: number[], index: number) => ({
            data: data,
            label: this.series[index],
            backgroundColor: this.colors[index].backgroundColor,
            borderColor: this.colors[index].borderColor,
        }));
        this.barChartData = {
            labels: this.labels,
            datasets: datasets,
        };
        this.chart?.update();
    }

    formatLabel(label: string) {
        const sections = [];
        const words = label.split(' ');
        let temp = '';

        words.forEach((item: string, index: number) => {
            if (temp.length > 0) {
                var concat = temp + ' ' + item;

                if (concat.length > this.maxwidth) {
                    sections.push(temp);
                    temp = '';
                } else {
                    if (index == words.length - 1) {
                        sections.push(concat);
                        return;
                    } else {
                        temp = concat;
                        return;
                    }
                }
            }

            if (index == words.length - 1) {
                sections.push(item);
                return;
            }

            if (item.length < this.maxwidth) {
                temp = item;
            } else {
                sections.push(item);
            }
        });

        return sections;
    }
}
