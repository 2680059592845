<div class="d-flex flex-column h-100">
    <div class="flex-grow-1 overflow-auto">
        <div class="body">
            <div class="container-fluid mb-4 p-2">
                <h5 class="form-title">
                    Rechercher un élément réseau
                </h5>

                <div class="container-fluid pr-card">
                    <div class="row parameters-field mb-3">
                        <label for="element-type" class="col-5 control-label text-start fw-normal">
                            Type
                        </label>
                        <div class="col-7">
                            <stSelect innerId="element-type" (change)="updateElementType()"
                                [data]="searchInfo.type.options" [(ngModel)]="searchInfo.type.value" labelField="label"
                                valueField="value" type="primary">
                            </stSelect>
                        </div>
                    </div>

                    <div class="row parameters-field">
                        <label for="element-name" class="col-3 control-label text-start fw-normal">
                            Nom
                        </label>
                        <div class="col-2 text-right">
                            <span class="fa fa-spinner fa-2x fa-pulse text-st-active" *ngIf="isOptionsLoading"></span>
                        </div>
                        <div class="col-7">
                            <stInputSearch class="dropdown w-100" innerId="element-name" innerClass="border-07"
                                [isDisabled]="isOptionsLoading" placeholder="Saissiser un nom d'élément réseau"
                                [options]="optionsElements" label="label" (onSelect)="selectElement($event)">
                            </stInputSearch>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngFor="let elementType of selectedElements">
                <div class="container-fluid mb-4" *ngIf="elementType.isActive">
                    <h5 class="text-st-primary">
                        {{ elementType.label }}
                    </h5>

                    <div class="container-fluid">
                        <ng-container *ngFor="let element of elementType.elements">
                            <stButton [btnLabel]="element.label" btnClass="btn-st-default me-3 mb-3"
                                iconFaRight="ps-2 fa fa-times" (click)="removeElement(element)">
                            </stButton>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="footer border-top">
        <div class="container-fluid text-center py-3">
            <div class="row">
                <div class="col-5">
                    <stButton btnClass="btn-st-default w-100" btnLabel="Retour" (click)="back()">
                    </stButton>
                </div>
                <div class="col-7">
                    <stButton btnClass="btn-st-primary w-100" btnLabel="Ajouter les éléments réseaux"
                        [isDisabled]="!isAnyElementSelected" (onClick)="addElements()"></stButton>
                </div>
            </div>
        </div>
    </div>
</div>