export const environment = {
    admin: true,
    backEndUrl: '/back',
    geoBackEndUrl: '/geo',
    header: './siterre/header-siterre.template.html',
    login: './siterre/login-siterre.template.html',
    manual: 'assets/ressource/manuel_stopexclusion.pdf',
    name: 'siterre',
    production: true,
    role: 3,
    token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpZCI6MzQwLCJyb2xlIjozLCJncm91cCI6MTMzLCJzY2FsZV90eXBlX3RlciI6InRlcl85OSIsInNjYWxlX3R5cGVfdGVyX2ludCI6OTksInNjYWxlX2lkX3RlciI6IicxJyIsImdyYW51bGFyaXR5IjoidGVyXzUwIiwiZ3Jhbl9pbnQiOjUwLCJpYXQiOjE2MjQ4OTkwODgsImV4cCI6MTc4MjY4NzA4OCwiaXNzIjoic2l0ZXJyZS5lbmVyZ2llcy1kZW1haW4uY29tIn0.CTcmVh0F2HYmS-PGLuDGEc4QdBHQHyii6R1G2WZC1NcFbbeiNclXecgPBbfE6jNv7YPrnipafq2bqr6yCQAQ8w',
    userId: 340,
    hasMobileUi: false,
    isPlausibleEnabled: false,
    domain: 'recette.siterre.fr',
    labelMap: 'Énergies Demain',
    linkMap: 'https://energies-demain.com',
    metaData: {
        description:
            "Système d'Information TERRitorial de l'Énergie - Développé par Énergies Demain",
        favicon: 'favicon-siterre.svg',
        title: "Siterre | Système d'information territorial de l'énergie",
    },
    isSolar: false,
    isLandingPageDefined: false,
};
