<stTab class="download" label="Export de données" [visible]="moduleService.isModuleActive.download">
  <div class="container-fluid p-4 body">
    <div class="mb-4" *ngFor="let indicator of getActiveIndicatorsList()">
      <h4>
        {{ indicator.libelle_indic_complet }}
      </h4>
      <div class="row d-flex justify-content-center">
        <div class="col-6">
          <stButton btnLabel="CSV" btnClass="btn btn-st-primary w-100" (onClick)="downloadCsv(indicator)"
            iconFaLeft="pe-2 fa fa-file-text"></stButton>
        </div>
        <div class="col-6">
          <stButton btnLabel="SHAPEFILE" btnClass="btn-st-primary w-100"
            [iconFaLeft]="isDownloading ? 'pe-2 fa fa-spinner fa-spin' : 'pe-2 fa fa-map'"
            (onClick)="downloadShapefile(indicator)">
          </stButton>
        </div>
      </div>
    </div>
  </div>
</stTab>