'use strict';

import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ReversePipe } from 'ngx-pipes';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ngxCsv } from 'ngx-csv/ngx-csv';

import { Row } from 'src/app/models/Dashboard';
import { ClassLabel } from 'src/app/models/Indicator';

import { DashboardService } from 'src/app/services/dashboard.service';
import { TerService } from 'src/app/services/TerService';
import { UsefulService } from 'src/app/services/UsefulService';
import { Legend } from 'src/app/models/Layer';

@UntilDestroy()
@Component({
    selector: 'dashboard-table',
    templateUrl: './dashboard-table.template.html',
    styleUrls: [
        './dashboard-table.component.scss',
        '../../../../../../components/st-table/st-table.component.scss',
    ],
    providers: [ReversePipe],
})
export class DashboardTableComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();

    public dashboards: any[] = [];
    public selectedDashboard: any = {
        indicatorId: null,
        indicator: null,
        table: null,
        selectedTerritory: {
            id_ter: null,
            label: null,
        },
    };

    public perimeterLabels: string[] = [];

    constructor(
        @Inject(DashboardService) public dashboardService: DashboardService,
        @Inject(TerService) public terService: TerService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    ngOnInit(): void {
        this.dashboardService.dashboards$
            .pipe(takeUntil(this.destroy$))
            .subscribe(async (dashboards) => {
                this.dashboards = dashboards;
            });

        this.dashboardService.addedDashboard$
            .pipe(takeUntil(this.destroy$))
            .subscribe(async (dashboard) => {
                if (dashboard) {
                    this.selectedDashboard = dashboard;
                    this._setPerimeterScaleLabels();
                    if (this.dashboardService.isTerritory(dashboard)) {
                        this._setTerritoryTable(dashboard);
                    } else {
                        this._setBuildingAndCustomTerritoryTable(dashboard);
                    }
                }
            });

        this.dashboardService.removedDashboard$
            .pipe(takeUntil(this.destroy$))
            .subscribe(async () => {
                const dashboards = this.dashboardService.dashboards.value;
                if (!dashboards.length) {
                    return;
                }
                const dashboard = dashboards[dashboards.length - 1];
                this.selectedDashboard = dashboard;
            });

        this.dashboardService.updatedDashboard$
            .pipe(takeUntil(this.destroy$))
            .subscribe(async (dashboard) => {
                if (dashboard) {
                    this._setPerimeterScaleLabels();

                    if (this.dashboardService.isTerritory(dashboard)) {
                        this._setTerritoryTable(dashboard);
                    } else {
                        this._setBuildingAndCustomTerritoryTable(dashboard);
                    }

                    const index = this.dashboards.indexOf(dashboard);
                    this.dashboards[index] = dashboard;
                    this.selectedDashboard = dashboard;
                    this.dashboardService.updateTerritoryTable(dashboard);
                }
            });

        this.dashboardService.updatedTableRepartition$
            .pipe(takeUntil(this.destroy$))
            .subscribe(async (dashboard) => {
                if (dashboard) {
                    if (!this.dashboardService.isTerritory(dashboard)) {
                        this._setBuildingAndCustomTerritoryTable(dashboard);
                    }
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    changeSelectedIndicator(indicatorId: number) {
        if (indicatorId == this.selectedDashboard.indicatorId) return;

        const dashboard = this.dashboards.find((dashboard) => dashboard.indicatorId == indicatorId);
        this.selectedDashboard = dashboard;
    }

    deselectTerritory() {
        this.selectedDashboard.selectedTerritory = {
            id_ter: null,
            label: null,
        };
        this.dashboardService.updateDashboard(this.selectedDashboard);
    }

    downloadTableCsv() {
        let headers: string[];
        let body: string[];

        if (this.dashboardService.isTerritory(this.selectedDashboard)) {
            headers = this._getTerritoryCsvHeaders();
            body = this._getTerritoryCsvBody();
        } else {
            headers = this._getBuildingAndCustomTerritoryCsvHeaders();
            body = this._getBuildingAndCustomTerritoryCsvBody();
        }

        const title = this.selectedDashboard.indicator.libelle_indic_complet.trim();
        const options = {
            fieldSeparator: ';',
            decimalseparator: ',',
            headers: headers,
        };
        return new ngxCsv(body, title, options);
    }

    private _setPerimeterScaleLabels() {
        this.perimeterLabels = this.terService.territories.map((t) => t.label);
        // this.perimeterLabel = this.terService.territories.map((t) => t.label).join(' - ');
        // if (this.terService.territories.length > 2) {
        //     this.perimeterLabel = `${this.terService.territories.length} territoires sélectionnés`;
        // } else {
        //     this.perimeterLabel = this.terService.territories.map((t) => t.label).join(' - ');
        // }
    }

    private _setTerritoryTable(dashboard: any) {
        const selectedTerritory = dashboard.selectedTerritory;
        const indicatorPlot = dashboard.indicator;
        const geojson = indicatorPlot.geojson;
        const isCategorical = indicatorPlot.type == 'class';
        const isNullInclude = indicatorPlot.hide_if_null != 1;
        const decimalCount = indicatorPlot.decimalCount;

        const rows: Row[] = geojson.features.map((feature: GeoJSON.Feature) => {
            const label = feature.properties.lib_ter;

            let value: string | number;
            let stringifiedValue: string;
            if (isCategorical) {
                const category = indicatorPlot.class_label.find(
                    (category: ClassLabel) => category.id_class == feature.properties.value,
                );
                if (category) {
                    stringifiedValue = category.lib_class;
                    value = category.lib_class;
                }
            } else {
                value = feature.properties.value;
                if (value == null) {
                    if (isNullInclude) {
                        value = null;
                        stringifiedValue = indicatorPlot.text_null;
                    } else {
                        // what do we do ?
                    }
                } else {
                    stringifiedValue = this.round(Number(value), decimalCount);
                    value = this.usefulService.round(Number(value), decimalCount);
                }
            }

            return {
                territoryId: feature.properties.id_ter,
                label: label,
                value: value,
                stringifiedValue: stringifiedValue,
                isSelected: selectedTerritory.id_ter == feature.properties.id_ter,
            };
        });

        dashboard.table.isTerritory = true;
        dashboard.table.unit = indicatorPlot.unit;
        dashboard.table.rows = rows;
    }

    private _getTerritoryCsvHeaders() {
        const headers = ['Code INSEE', 'Territoire', 'Valeur', 'Unité'];
        return headers;
    }

    private _getTerritoryCsvBody() {
        const body = this.selectedDashboard.table.rows.map((data: any) => [
            data.territoryId,
            data.label,
            data.value,
            this.selectedDashboard.table.unit,
        ]);
        return body;
    }

    private _setBuildingAndCustomTerritoryTable(dashboard: any) {
        const indicatorPlot = dashboard.indicator;
        const geojson = indicatorPlot.geojson;
        const legends = indicatorPlot.legende;
        let column = indicatorPlot.libelle_indic_complet;
        if (indicatorPlot.unit) {
            column += ` - ${indicatorPlot.unit}`;
        }
        const isCategorical = indicatorPlot.type == 'class';
        const isZeroSetApart = indicatorPlot.separate_zero_in_lgd == 1;
        const isNullInclude = indicatorPlot.hide_if_null != 1;
        const decimalCount = indicatorPlot.decimalCount;

        let values: number[];
        if (isCategorical) {
            values = geojson.features.map((feature: GeoJSON.Feature) => feature.properties.value);
        } else {
            const features = isZeroSetApart
                ? geojson.features.filter(
                      (feature: GeoJSON.Feature) => feature.properties.value != 0,
                  )
                : geojson.features;
            values = features.map((feature: GeoJSON.Feature) =>
                this.usefulService.round(feature.properties.value, decimalCount),
            );
        }

        const rows = legends.map((legend: Legend) => {
            let countValue: number;

            if (isCategorical) {
                countValue = values.filter((value: number) => value == legend.id_class).length;
            } else {
                countValue = values.filter(
                    (value: number) => value >= legend.min && value <= legend.max,
                ).length;
            }

            return {
                label: legend.lib,
                value: countValue,
                stringifiedValue: this.usefulService.stringifyNumber(countValue),
            };
        });

        if (!isCategorical && isZeroSetApart) {
            const countValue = geojson.features.filter(
                (feature: GeoJSON.Feature) => feature.properties.value == 0,
            ).length;
            rows.push({
                label: '0',
                value: countValue,
                stringifiedValue: this.usefulService.stringifyNumber(countValue),
            });
        }

        if (isNullInclude) {
            const countValue = geojson.features.filter(
                (feature: GeoJSON.Feature) => feature.properties.value == null,
            ).length;
            rows.push({
                label: indicatorPlot.text_null,
                value: countValue,
                stringifiedValue: this.usefulService.stringifyNumber(countValue),
            });
        }

        dashboard.table.isTerritory = false;
        dashboard.table.unit = indicatorPlot.unit;
        dashboard.table.column = column;
        dashboard.table.rows = rows;
    }

    private _getBuildingAndCustomTerritoryCsvHeaders() {
        const headers = ['Répartition', 'Valeur'];
        return headers;
    }

    private _getBuildingAndCustomTerritoryCsvBody() {
        const body = this.selectedDashboard.table.rows.map((data: any) => [data.label, data.value]);
        return body;
    }

    private round(value: number, decimalCount: number) {
        if (isNaN(value)) {
            return 'N/A';
        }
        return this.usefulService.stringifyNumber(this.usefulService.round(value, decimalCount));
    }
}
