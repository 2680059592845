<stHeader [isAdminPage]="true"></stHeader>
<stNavbar page="indicators-manage"></stNavbar>

<div class="container py-4">
  <h2 class="text-st-primary mb-4">
    <span class="fa fa-life-ring"></span>
    Gestion des indicateurs
  </h2>

  <div class="row mb-4 d-flex align-items-center">
    <div class="col-lg">
      <div class="text-justify">
        <p>Cette page est à déstination des super administrateurs du site. </p>
        <div style="padding-top: 10px">Le tableau ci-dessous vous permet de gérer les indicateurs disponibles et
          paramétrer leur affichage.
          <ul>
            <li> Survoler le nom d'un paramètre de l'indicateur pour afficher sa définition
            </li>
            <li> Vous pouvez Ajouter / modifier / supprimer un indicateur </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-lg-4">
      <div class="card">
        <div class="card-body p-4">
          <h4 class="modal-title">Dashboard administation des indicateurs</h4>
          <div class="mb-3" *ngIf="indicators.length">
            <div class="p-1">
              <b>Nombre total d'indicateurs</b> : {{totalIndicators}} indicateurs(s)
            </div>
            <stButton btnLabel="Créer un indicateur" btnClass="btn-st-primary" (click)="goToNewIndicator()"></stButton>
          </div>
        </div>
      </div>
    </div>
  </div>

  <form *ngIf="indicators.length">
    <div class="row mb-2">
      <div class="col-sm-auto">
        <div class="row">
          <div class="col px-0 position-relative">
            <input id="table-complete-search" type="text" class="form-control px-5" name="searchTerm"
              [(ngModel)]="stTableService.searchTerm" placeholder="Rechercher" />
            <i class="fa fa-search position-absolute top-50 translate-middle-y"></i>
          </div>
        </div>
      </div>
      <span class="col col-form-label" *ngIf="stTableService.loading$ | async">Chargement...</span>
    </div>

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col" sortable="id_indicateur" (sort)="onSort($event)" class="id-col">ID</th>
          <th scope="col" sortable="libelle_indic_complet" (sort)="onSort($event)" class="label-col">Libellé</th>
          <th scope="col" sortable="unit" (sort)="onSort($event)" class="unity-col">Unité</th>
          <th scope="col" sortable="database" (sort)="onSort($event)" class="database-col">Base</th>
          <th scope="col" sortable="granularity" (sort)="onSort($event)" class="granularity-col">Granularité</th>
          <th scope="col" sortable="shape" (sort)="onSort($event)" class="forme-col">Forme</th>
          <th scope="col" class="handling-col">Gestion</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let indic of indicators$ | async">
          <td scope="row">
            <ngb-highlight [result]="indic.id_indicateur" [term]="stTableService.searchTerm">
            </ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="indic.libelle_indic_complet" [term]="stTableService.searchTerm">
            </ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="indic.unit" [term]="stTableService.searchTerm">
            </ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="getLabelFromData(indic.tbl,bases)" [term]="stTableService.searchTerm">
            </ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="getLabelFromData(indic.granularity_min,granularity)"
              [term]="stTableService.searchTerm">
            </ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="getLabelFromData(indic.form,form)" [term]="stTableService.searchTerm">
            </ngb-highlight>
          </td>
          <td>
            <ng-template #tooltipContent>
              <div>Voir / modifier</div>
            </ng-template>
            <span (click)="goToUpdateIndicator(indic.id_indicateur)" class="fa fa-pencil-square-o cursor-pointer"
              style="padding:5px" [ngbTooltip]="tooltipContent"></span>
            <ng-template #tooltipContentDel>
              <div>Supprimer</div>
            </ng-template>
            <span class="fa fa-trash-o cursor-pointer" style="padding:5px"
              (click)="deleteIndicator(indic.id_indicateur)" [ngbTooltip]="tooltipContentDel"></span>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="d-flex justify-content-between p-2">
      <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="stTableService.page"
        [pageSize]="stTableService.pageSize" [maxSize]="5" [rotate]="true" [boundaryLinks]="true">
      </ngb-pagination>

      <select class="form-select selectPageSize" name="pageSize" [(ngModel)]="stTableService.pageSize">
        <option [ngValue]="value" *ngFor="let value of stTableService.pageSizeOptions">{{ value }}</option>
      </select>
    </div>
  </form>

</div>