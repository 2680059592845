<stHeader [isAdminPage]="true"></stHeader>
<stNavbar></stNavbar>

<div class="ligneFlexStart windowAdmin">
  <div class="flexComponentOne bordureExtContenair"></div>

  <div class="colFlexStartWrap" style="max-width: 1400px; width: 100%">
    <div class="adm_main_title ligneFlexStartCentered">
      <div style="padding-right: 15px; color: rgb(33,68,111);">
        <span class="fa fa-users fa-2x"></span>
      </div>
      <div>Nouvel indicateur </div>
    </div>

    <div class="colFlexStretch" style="width:100%" *ngFor="let cat of categories">
      <div style="flex:1; min-width: 450px; border-top: 1px solid #ddd ; margin-top: 20px">
      </div>
      <div style="padding: 0px 20px 30px 20px; ">
        <h4 style="color:#0067C6"><b>{{cat.definition}}</b></h4>
      </div>

      <div style="padding: 0px 20px 10px 40px">
        <div class="ligneFlexStart"
          *ngFor="let ref of reference | filterBy : ['categorie']: cat.id | orderByImpure: 'ordre'"
          style="margin-bottom: 10px">
          <div style="margin-top: 7px; padding-right: 15px; width: 200px">
            <span><b>{{ref.field_lib}}</b></span>
          </div>

          <div style="width: 200px; border-top: 1px solid #ddd; text-align: right">
            <span *ngIf="ref.data_type == 'checkbox'">
              <stCheckbox [(ngModel)]="indicator[ref.field]" [trueValue]="1" [falseValue]="0">
              </stCheckbox>
            </span>
            <span *ngIf="ref.data_type == 'textarea'">
              <textarea class="form-control bg-inherit" [(ngModel)]="indicator[ref.field]"
                style="font-size: 12px;"></textarea>
            </span>
            <span *ngIf="ref.data_type == 'select'">
              <stSelect [(ngModel)]="indicator[ref.field]" [data]="ref.data" [size]="1"></stSelect>
            </span>
            <span *ngIf="ref.data_type == 'select_filter'">
              <select [(ngModel)]="indicator[ref.field]" ng-options="" size="1" class="form-control">
                <option
                  *ngFor="let element in ref.data | filter : { filter : indicator[ref.filter] } : true | orderBy : 'id'"
                  [value]="element.id">
                  {{ element.label }}
                </option>
              </select>
            </span>
            <span *ngIf="ref.data_type == 'color' ">
              <div style=" margin-bottom: 10px">
                <div>
                  <span>une teinte</span>
                  <span class="fa fa-check-square-o" [hidden]="gradientType==='bi'"></span>
                  <span class="fa fa-square-o" [hidden]="gradientType==='mono'" (click)="gradientType='mono'"></span>
                </div>
                <div>
                  <span>deux teintes</span>
                  <span class="fa fa-check-square-o" [hidden]="gradientType=='mono'"></span>
                  <span class="fa fa-square-o" [hidden]="gradientType=='bi'" (click)="gradientType='bi'"></span>
                </div>
              </div>

              <div [hidden]="gradientType==='bi'">
                <div class="choixCouleur">
                  <div *ngFor="let colortype of colortypes.mono | keyvalue">
                    <div class="barreCouleur" (click)="indicator.degrade = colortype.value.name"
                      [ngStyle]="{ 'background-color': (indicator.degrade == colortype.value.name ) ? ' black' : ' ' }">
                      <div *ngFor="let color of colortype.value.colorsIndexed | orderBy:'-id'"
                        [ngStyle]="{'background-color': color.color, 'opacity': 0.9}" class="cubeStrech">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div [hidden]="gradientType==='mono'">
                <div class="choixCouleur">
                  <div *ngFor="let colortype of colortypes.bi | keyvalue">
                    <div class="barreCouleur" (click)="indicator.degrade = colortype.value.name"
                      [ngStyle]="{ 'background-color': (indicator.degrade == colortype.value.name ) ? ' black' : ' ' }">

                      <div *ngFor="let color of colortype.value.colorsIndexed | orderBy:'-id'"
                        [ngStyle]="{'background-color': color.color, 'opacity': 0.9}" class="cubeStrech">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>
            <span
              *ngIf="ref.data_type != 'checkbox' && ref.data_type != 'color' && ref.data_type != 'textarea' && ref.data_type != 'select' && ref.data_type != 'select_filter'">
              <input type="{{ref.data_type}}" style="font-size: 12px;" class="form-control"
                [(ngModel)]="indicator[ref.field]"
                [disabled]="(ref.field == 'text_null' && indicator['zero_if_null_dyn']==1)" />
            </span>
          </div>
          <div style="width: 700px; border-top: 1px solid #ddd ; margin-left: 10px"> {{ref.definition}} </div>
          <div style="width: 100px; border-top: 1px solid #ddd ; margin-left: 10px"> {{ref.field}} </div>
        </div>
      </div>
    </div>

    <div style="flex:1; min-width: 100%; border-top: 1px solid #ddd ; margin-top: 20px">
      <div style="padding: 20px 20px 15px 20px" class="ligneFlexStartCentered">
        <h4 style="color:#0067C6"><b>Affecter l'indicateur</b></h4>
      </div>
      <div style="padding: 0px 20px 10px 40px">
        <div class="ligneFlexStart" style="margin-bottom: 22px;">
          <div>
            <div class="adm_groupWraperTheme" *ngFor="let theme of listIndicatorsByTheme">
              <div class="ligneFlexStartCentered adm_groupTitleTheme">
                <div class="ligneFlexStartCentered" style="cursor: pointer" (click)="theme.open = !theme.open">
                  <div class="fa fa-caret-down" style="width: 20px" [hidden]="!theme.open"></div>
                  <div class="fa fa-caret-right" style="width: 20px" [hidden]="theme.open"></div>
                  <div class="text-st-secondary">
                    {{ theme.libelle }}
                  </div>
                </div>

                <div class="iconQuickSelect">
                  <span [hidden]="!theme.active" class="fa fa-star "></span>
                  <span [hidden]="theme.active" class="fa fa-star-o"></span>
                </div>
              </div>
              <div class="adm_groupWraperSSTheme toggleContenu" [hidden]="!theme.open"
                *ngFor="let subTheme of theme.ss_theme | orderBy: 'ordre'">
                <div class="ligneFlexStartCentered adm_groupTitleSSTheme">
                  <div class="ligneFlexStartCentered cursor-pointer">
                    <stCheckbox [(ngModel)]="subTheme.active" (ngChange)="updateStars()">
                      {{ subTheme.libelle }}
                    </stCheckbox>
                  </div>
                </div>
              </div>
              <div class="adm_groupWraperSSTheme toggleContenu" [hidden]="!theme.open">
                <div class="ligneFlexStartCentered adm_groupTitleSSTheme">

                  <div class="ligneFlexStartCentered cursor-pointer">
                    <!-- <input type="checkbox" style="font-size: 11px; margin-right: 10px"
                                            ng-disabled="true" /> -->

                    <div class="btn-group" ngbDropdown>
                      <button type="button" style="min-width: 230px; text-align: left;"
                        class="btn btn-st-default dropdown-toggle" ngbDropdownToggle aria-haspopup="true"
                        aria-expanded="false">
                        <div style="width:100%; font-size: inherit" class="ligneFlexStartCentered">
                          <div style="color:gray"><i>Ajouter un sous-thème </i></div>
                          <div class="flexComponentOne"></div>
                          <div style="padding-left: 3px; padding-right: 3px; text-align: right"
                            class="fa fa-caret-down"></div>
                        </div>
                      </button>
                      <ul class="dropdown-menu" ngbDropdownMenu>
                        <li ngbDropdownItem class="cursor-pointer"
                          *ngFor="let subTheme of subThemes | orderBy:'libelle'" (click)="addSubTheme(theme, subTheme)">
                          <a>{{ subTheme.libelle }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="adm_groupWraperSSTheme toggleContenu" [hidden]="!theme.open">
                <div class="ligneFlexStartCentered adm_groupTitleSSTheme">
                  <div class="ligneFlexStartCentered" style="cursor: pointer">
                    <stInput type="text" [(ngModel)]="theme.newSubTheme" class="mx-1"></stInput>
                    <stButton btnLabel="Créer un sous-thème" btnClass="btn-st-primary btn-xs"
                      (click)="addSubTheme(theme, null)"></stButton>
                  </div>
                </div>
              </div>
            </div>
            <div class="adm_groupWraperTheme">
              <div class="ligneFlexStartCentered adm_groupTitleTheme">
                <div class="ligneFlexStartCentered" style="cursor: pointer">
                  <div class="fa fa-caret-right" style="width: 20px"></div>
                  <stInput type="text" [(ngModel)]="newTheme" class="mx-1"></stInput>
                  <stButton btnLabel="Créer un nouveau thème" btnClass="btn-st-primary btn-md" (click)="addTheme()">
                  </stButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="flex:1; min-width: 450px; border-top: 1px solid #ddd ; margin-top: 20px"></div>
    <div class="colFlexStretch" style="width:100%">
      <div>
        <div style="padding: 20px 20px 15px 20px">
          <h4 style="color:#0067C6"><b>Terminer la création du nouvel indicateur</b></h4>
        </div>
        <div style="padding: 0px 20px 10px 40px">
          <div class="ligneFlexStart" style="width:100%; padding-top : 20px; padding-bottom : 20px;  flex-wrap: wrap">
            <stButton btnLabel="Annuler" btnClass="btn-st-default mx-3" (click)="goToAdminIndicator()">
            </stButton>
            <stButton btnLabel="Valider" btnClass="btn-st-primary mx-3" (click)="validate()"></stButton>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flexComponentOne bordureExtContenair"></div>
</div>