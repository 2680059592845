import { Inject, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { Indicator } from 'src/app/models/Indicator';
import { SubTheme, Theme } from 'src/app/models/Theme';

import { environment } from 'src/environments/environment';

import { EventService } from 'src/app/services/event.service';
import { GranularityService } from 'src/app/services/granularity.service';
import { RestService } from 'src/app/services/RestService';
import { TerService } from 'src/app/services/TerService';
import { TerritoryScale } from '../models/TerritoryTypes';

@Injectable({
    providedIn: 'root',
})
export class CatalogService {
    public indicatorsByTheme: Theme[] = [];
    public initTheme: Theme;
    public initIndicatorId: number;

    private indicatorDescription = {
        static_dynamic: {
            dyn: {
                description: 'Cet indicateur est dynamique',
                class: 'fa fa-filter',
            },
            stat: {
                description: 'Cet indicateur est statique',
                class: 'fa fa-filter-slash',
            },
        },
        type: {
            class: {
                description: 'Cet indicateur est discret',
                class: 'fa fa-bar-chart',
            },
            valeur: {
                description: 'Cet indicateur est continu',
                class: 'fa fa-area-chart',
            },
        },
        form: {
            chart: {
                description: 'Cet indicateur est de type camembert',
                class: 'fi-graph-pie',
            },
            cluster: {
                description: 'Cet indicateur est de type points groupés',
                class: 'fa fa-circle',
            },
            heatmap: {
                description: 'Cet indicateur est de type carte de chaleur',
                class: 'fa fa-sun',
            },
            icon: {
                description: 'Cet indicateur est de type symbole',
                class: 'default',
            },
            line: {
                description: 'Cet indicateur est de type ligne',
                class: 'fa fa-window-minimize',
            },
            point: {
                description: 'Cet indicateur est de type point',
                iconClass: 'fa fa-circle',
            },
            poly: {
                description: 'Cet indicateur est de type polygone',
                class: 'fa fa-square',
            },
        },
    };

    constructor(
        private notification: ToastrService,
        @Inject(EventService) private eventService: EventService,
        @Inject(GranularityService) private granularityService: GranularityService,
        @Inject(RestService) private restService: RestService,
        @Inject(TerService) private terService: TerService,
    ) {}

    clear() {
        this.indicatorsByTheme = [];
    }

    async initCatalog(initIndicatorId?: number) {
        try {
            await this.getIndicatorsByTheme();
            this.initIndicatorsByTheme();
            this.manageTheme(initIndicatorId);
            return;
        } catch (error) {
            console.error('Error initCatalog', error);
            throw error;
        }
    }

    async getIndicatorsByTheme() {
        try {
            if (this.indicatorsByTheme.length) {
                return this.indicatorsByTheme;
            }

            this.indicatorsByTheme = await this.restService.getIndicatorByModule();
            this.indicatorsByTheme.forEach((theme) => {
                theme.label = theme.libelle;
                theme.ss_theme.forEach((subTheme) => (subTheme.label = subTheme.libelle));
            });
        } catch (error) {
            console.error('Error getIndicatorsByTheme', error);
            throw error;
        }
    }

    initIndicatorsByTheme() {
        this.indicatorsByTheme.forEach((theme: Theme) => {
            theme.open = false;
            theme.ss_theme.forEach((subTheme: SubTheme) => {
                subTheme.open = true;
                subTheme.indicators.forEach((indicator: Indicator) => {
                    indicator.active = false;
                    indicator.popoverInfo = this.setPopoverInfo(indicator);
                    // indicator.availableShape = this.setAvailableShape(indicator);
                });

                subTheme.indicators.forEach((indicator) =>
                    this.manageIsAvailableAtThisScale(indicator),
                );
                subTheme.indicators.forEach(
                    this.granularityService.setIndicatorGranularity.bind(this.granularityService),
                );
            });
        });

        // this.setAvailableIndicator();
    }

    setPopoverInfo(indicator: Indicator) {
        const description = indicator.info_description;
        const unit = indicator.unit_short;
        const source = indicator.source;
        const dataType = indicator.type_donnee;
        const type = indicator.type;
        const form = indicator.form ? indicator.form.toLowerCase() : '';
        const vectorBase = indicator.vector_base;
        const staticDynamic = indicator.static_dynamic;
        const geoYear = indicator.crrsdc_ter_year_geo;

        let popoverInfo = '';

        const noDescriptionAvailable =
            "<p><b>Description : </b> Aucune description n'est disponible pour cet indicateur </p>";
        if (description) {
            const trimDescription = description.trim();

            if (trimDescription) {
                popoverInfo += '<p><b>Description : </b>' + trimDescription + '</p>';
            } else {
                popoverInfo += noDescriptionAvailable;
            }
        } else {
            popoverInfo += noDescriptionAvailable;
        }

        if (unit) {
            const trimUnit = unit.trim();

            if (trimUnit) {
                popoverInfo += '<p><b>Unité : </b>' + trimUnit + '</p>';
            }
        }

        if (source) {
            const trimSource = source.trim();

            if (trimSource) {
                popoverInfo += '<p><b>Source : </b>' + trimSource + '</p>';
            }
        }

        if (dataType === 1) {
            popoverInfo += '<p><b>Type de données : </b> Statistique </p>';
        } else if (dataType === 2) {
            popoverInfo += '<p><b>Type de données : </b> Modélisation </p>';
        } else if (dataType === 3) {
            popoverInfo += '<p><b>Type de données : </b> Donnée réelle </p>';
        }

        if (type === 'valeur') {
            popoverInfo += "<p><b>Type d'indicateur : </b> Continu </p>";
        } else {
            popoverInfo += "<p><b>Type d'indicateur : </b> Discret </p>";
        }

        if (form === 'poly') {
            popoverInfo += '<p><b>Forme : </b> Polygone </p>';
        } else if (form === 'poly3d') {
            popoverInfo += '<p><b>Forme : </b> Polygone 3D </p>';
        } else if (form.includes('point')) {
            popoverInfo += '<p><b>Forme : </b> Point </p>';
        } else if (form.includes('icon')) {
            popoverInfo += '<p><b>Forme : </b> Icône </p>';
        } else if (form.includes('line')) {
            popoverInfo += '<p><b>Forme : </b> Ligne </p>';
        }

        if (vectorBase) {
            if (vectorBase === 'territories') {
                popoverInfo += '<p><b>Echelle : </b> Territoire </p>';
            } else if (vectorBase === 'solaire') {
                popoverInfo += '<p><b>Echelle : </b> Territoire personnalisé </p>';
            } else if (vectorBase.includes('res')) {
                popoverInfo += '<p><b>Echelle : </b> Réseau </p>';
            } else if (vectorBase === 'none') {
                popoverInfo += '<p><b>Echelle : </b> Territoire personnalisé </p>';
            }
        }

        if (staticDynamic === 'dyn') {
            popoverInfo += '<p><b>Filtres : </b> Oui </p>';
        } else {
            popoverInfo += '<p><b>Filtres : </b> Non </p>';
        }

        popoverInfo += '<p><b>Année géographique : </b>' + geoYear + '</p>';

        return popoverInfo;
    }

    setAvailableShape(indicator: Indicator) {
        const profileName = environment.name;

        if (profileName == 'siterre') {
            const isAvailable = Object.keys(this.indicatorDescription.form).some((form) =>
                form.includes(indicator.form),
            );

            if (isAvailable) {
                const availableShapes = [];
                for (const [key, value] of Object.entries(this.indicatorDescription)) {
                    const iconTooltip = value[indicator[key]].description;
                    const iconClass = indicator.default_icon || value[indicator[key]].class;

                    availableShapes.push({
                        class: iconClass,
                        tooltip: iconTooltip,
                    });
                }
                return availableShapes;
            }
        }
    }

    setAvailableIndicator() {
        this.indicatorsByTheme.forEach((theme: Theme) =>
            theme.ss_theme.forEach((subTheme) =>
                subTheme.indicators.forEach((indicator) =>
                    this.manageIsAvailableAtThisScale(indicator),
                ),
            ),
        );
    }

    manageIsAvailableAtThisScale(indicator: Indicator, territoryScale?: TerritoryScale) {
        if (!territoryScale) {
            territoryScale = this.terService.territoryScale;
        }

        const available = this.isAvailableAtThisScale(indicator, territoryScale);
        const isAvailable = available.isAvailable;
        const tooltip = available.tooltip;

        indicator.isAvailable = isAvailable;
        if (!indicator.isAvailable) {
            // make sur indicator is deactivate if scale not compatible
            indicator.active = false;
        }

        if (tooltip) {
            indicator.availableTooltip = tooltip;
            return;
        }

        if (!indicator.isAvailable) {
            this.setDisabledMessage(indicator);
        }
    }

    isAvailableAtThisScale(indicator: Indicator, territoryScale: TerritoryScale) {
        const userMinScale = this.terService.getUserMinScale();
        const userMaxScale = this.terService.getUserMaxScale();

        const indicatorMinGranularityTypeId = Number(indicator.granularity_min.split('_')[1]);

        // is the indicator available at the current scale ?
        const isIndicatorAvailableAtCurrentScale =
            indicatorMinGranularityTypeId <= territoryScale.typeId &&
            userMinScale.order <= territoryScale.order &&
            userMaxScale.order >= territoryScale.order;

        let isAvailable = indicator.isImported || isIndicatorAvailableAtCurrentScale;
        let tooltip = '';

        // special condition for building indicator : not available beyond epci scale
        const isUniqueGranularity = indicator.granularity_min == indicator.granularity_max;
        const isBuilding = indicator.granularity_min == 'ter_01';
        if (isUniqueGranularity && isBuilding) {
            const epciTypeId = 40;
            const limitScale =
                userMaxScale.typeId < epciTypeId
                    ? userMaxScale
                    : this.terService.territoryScales.find((scale) => scale.typeId == epciTypeId);

            isAvailable &&= territoryScale.order <= limitScale.order;
            if (!isAvailable) {
                tooltip = `Cet indicateur n'est disponible qu'à partir de l'échelle ${limitScale.labelShort} et inférieure.`;
            }
        }

        return { isAvailable: isAvailable, tooltip: tooltip };
    }

    setDisabledMessage(indicator: Indicator) {
        const userMinScale = this.terService.getUserMinScale();
        const userMaxScale = this.terService.getUserMaxScale();

        const indicatorMinGranularityTypeId = Number(indicator.granularity_min.split('_')[1]);
        const indicatorMaxGranularityTypeId = Number(indicator.granularity_max.split('_')[1]);

        // is the indicator ever available ?
        const isIndicatorEverAvailable =
            userMaxScale.typeId >= indicatorMinGranularityTypeId &&
            userMinScale.typeId <= indicatorMaxGranularityTypeId;

        if (!isIndicatorEverAvailable) {
            indicator.availableTooltip =
                "Cet indicateur n'est pas disponible pour les échelles dont vous avez accès.";
            return;
        }
        const scale =
            indicatorMinGranularityTypeId >= userMinScale.typeId
                ? this.terService.territoryScales.find(
                      (scale) => scale.typeId == indicatorMinGranularityTypeId,
                  )
                : userMinScale;
        indicator.availableTooltip = `Cet indicateur n'est disponible qu'à partir de l'échelle ${scale.labelShort} et supérieure.`;
    }

    setGranularities() {
        this.indicatorsByTheme.forEach((theme: Theme) =>
            theme.ss_theme.forEach((subTheme) =>
                subTheme.indicators.forEach((indicator) =>
                    this.granularityService.setIndicatorGranularity(indicator),
                ),
            ),
        );
    }

    manageTheme(initIndicatorId?: number) {
        if (!initIndicatorId) {
            this.initTheme = this.indicatorsByTheme[0];
            return;
        }

        this.initIndicatorId = initIndicatorId;
        this.initTheme = this.indicatorsByTheme.find((theme) =>
            theme.ss_theme.some((subTheme) =>
                subTheme.indicators.some(
                    (indicator) => indicator.id_indicateur == this.initIndicatorId,
                ),
            ),
        );
        if (!this.initTheme) {
            this.notification.warning(
                `Aucun thème n'est disponible pour l'indicateur ${initIndicatorId}.`,
            );
            this.initTheme = this.indicatorsByTheme[0];
            return;
        }
        const initSubTheme = this.initTheme.ss_theme.find((subTheme) =>
            subTheme.indicators.some(
                (indicator) => indicator.id_indicateur == this.initIndicatorId,
            ),
        );
        if (!initSubTheme) {
            this.notification.warning(
                `Aucun sous-thème n'est disponible pour l'indicateur ${initIndicatorId} du thème ${this.initTheme.id_theme}.`,
            );
            this.initTheme = this.indicatorsByTheme[0];
            return;
        }

        const initIndicator = initSubTheme.indicators.find(
            (indicator) => indicator.id_indicateur == this.initIndicatorId,
        );
        this.initTheme.open = true;
        initSubTheme.open = true;
        initIndicator.active = true;
    }

    findIndicator(indicatorId: number) {
        for (let theme of this.indicatorsByTheme) {
            for (let subTheme of theme.ss_theme) {
                const indicator = subTheme.indicators.find(
                    (indicator: Indicator) => indicator.id_indicateur == indicatorId,
                );
                if (indicator) {
                    return indicator;
                }
            }
        }
        return null;
    }

    addImportedIndicators(indicators: Indicator[], subThemeLabel: string) {
        let theme: Theme = this.indicatorsByTheme.find((theme) => theme.id_theme === -1);

        if (!theme) {
            theme = {
                id_theme: -1,
                libelle: 'INDICATER importés',
                label: 'INDICATER importés',
                ss_theme: [],
                ordre: 99,
                open: false,
            };
        }

        const subThemeIndex = theme.ss_theme.length + 1;
        const subtheme = {
            id_ss_theme: -1 * subThemeIndex,
            ordre: subThemeIndex,
            indicators: indicators,
            libelle: subThemeLabel,
            label: subThemeLabel,
            open: true,
        };
        theme.ss_theme.push(subtheme);

        this.indicatorsByTheme = [...this.indicatorsByTheme, theme];

        this.eventService.emit('customIndicatorsAdded', {
            indicatorsByTheme: this.indicatorsByTheme,
            theme: theme,
        });
    }

    updateAfterTerritoryChange(indicatorPlot: Indicator) {
        const indicator = this.findIndicator(indicatorPlot.indicatorId);
        this.granularityService.updateAfterTerritoryChange(indicator, indicatorPlot);
    }
}
