'use strict';
import { Inject, Injectable } from '@angular/core';

import { LocalStorageService } from 'src/app/services/local-storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PlausibleAnalyticsService {
    constructor(@Inject(LocalStorageService) private localStorageService: LocalStorageService) {
        const isPlausibleEnabled = environment.isPlausibleEnabled;

        if (isPlausibleEnabled) {
            this._loadPlausibleScript();
        }
    }

    private _loadPlausibleScript() {
        const element = document.getElementById('plausible-script');
        if (!element) {
            const domain = environment.domain;
            const script = document.createElement('script');
            script.defer = true;
            script.setAttribute('id', 'plausible-script');
            script.setAttribute('data-api', '/api/event');
            script.setAttribute('data-domain', domain);
            script.src = '/js/script.js';

            document.head.appendChild(script);
        }
    }

    trackPageView(pageName: string) {
        if ((window as any).plausible) {
            (window as any).plausible('pageview', { path: `/${pageName}` });
        }
    }

    trackEvent(prop: string, data?: any) {
        if ((window as any).plausible) {
            if (data) {
                (window as any).plausible(prop, { props: data });
            } else {
                (window as any).plausible(prop);
            }
        }
    }
}
