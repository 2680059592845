<div class="modal-header" id="territory-selection-header">
    <h4 class="modal-title">Sélection du territoire</h4>
    <div ngbDropdown class="ms-auto">
        <button type="button" class="btn btn-st-default" id="year" ngbDropdownToggle>
            Millésime
        </button>
        <div ngbDropdownMenu aria-labelledby="year">
            <ng-container *ngFor="let year of yearOptions">
                <button
                    ngbDropdownItem
                    (click)="changeYear(year)"
                    [ngClass]="{active: year == selected.year}"
                >
                    {{ year }}
                </button>
            </ng-container>
        </div>
    </div>
</div>

<div class="modal-body" id="territory-selection-body">
    <h5>Sélectionner les territoires à afficher</h5>
    <div class="container-fluid">
        <div class="row">
            <div class="col-4 ps-0">
                <label
                    for="territory-scale"
                    class="form-label text-st-active d-flex align-items-center"
                >
                    <span>
                        Echelle de territoire
                        <!-- <i class="fa fa-question-circle text-st-dark2 ms-2" aria-hidden="true" ngbTooltip="Hell yeah !"
                            placement='top'></i> -->
                    </span>
                    <i
                        class="fa fa-spinner fa-pulse fa-fw ms-auto"
                        *ngIf="isDataLoading.territoryScales"
                    ></i>
                </label>
                <stSelect
                    innerId="territory-scale"
                    type="primary"
                    labelField="labelShort"
                    valueField="fullObject"
                    [data]="territoryScalesOptions"
                    [(ngModel)]="selected.territoryScale"
                    (change)="selectTerritoryScale()"
                    [isDisabled]="isLoading || isDataLoading.territoryScales"
                >
                </stSelect>
            </div>
            <div class="col-8 pe-0">
                <label
                    for="territory-input"
                    class="form-label text-st-active d-flex align-items-center"
                >
                    <span>Ajouter un territoire</span>
                    <i
                        class="fa fa-spinner fa-pulse fa-fw ms-auto"
                        *ngIf="isDataLoading.territoryScales || isDataLoading.territories"
                    ></i>
                </label>
                <stInputSearch
                    innerId="territory-input"
                    label="labelId"
                    innerClass="border-07"
                    [options]="territories"
                    placeholder="Saisir le nom ou code INSEE du territoire"
                    (onSelect)="addTerritory($event)"
                    [isDisabled]="!territories.length || isLoading || isDataLoading.territoryScales || isDataLoading.territories"
                >
                </stInputSearch>
                <div class="btn-toolbar choice-territory my-2">
                    <div
                        class="btn-group"
                        *ngFor="let territory of selected.territories; let index = index"
                    >
                        <stButton
                            btnLabel="{{ territory.label }}"
                            btnClass="btn-st-default"
                            [isDisabled]="isLoading"
                            iconFaRight="ps-2 fa fa-times"
                            (onClick)="removeTerritory(index)"
                        >
                        </stButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <stButton
        btnLabel="Annuler"
        btnClass="btn-st-default me-1"
        [isDisabled]="isLoading"
        (onClick)="close()"
    >
    </stButton>
    <stButton
        btnLabel="Afficher la carte"
        btnClass="btn-st-primary ms-1"
        [isDisabled]="isValidateDisabled"
        (onClick)="validate()"
    >
    </stButton>
</div>
