('use strict');

import { Inject, Injectable } from '@angular/core';

import {
    PrBaseElement,
    PrElement,
    PrPath,
    PrHypothesisValue,
    PrElectronPath,
} from '../../models/PrTypes';
import { Indicator } from 'src/app/models/Indicator';

import { ProsperReseauxService } from './prosper-reseaux.service';
import { RestService } from '../RestService';
import { TerService } from '../TerService';

const SOURCE_POST = '20';
const HTA_START = '18';
const HTA_LINE = '15';
const HTA_BT_TRANSFORMER = '09';
const BT_START = '08';
const BT_LINE = '05';

@Injectable({
    providedIn: 'root',
})
export class ProsperReseauxApplyChangesService {
    private lineElements: PrBaseElement[];
    private element: PrElement;
    private typeId: string;
    private elements: PrElement[];
    private existingElementIds: string[];

    private newPathsByType: PrPath[][];

    private upstreamElectronPath: Set<string>;
    private downstreamElectronPath: Set<string>;

    constructor(
        @Inject(ProsperReseauxService) private prService: ProsperReseauxService,
        @Inject(TerService) private terService: TerService,
        @Inject(RestService) private restService: RestService,
    ) {}

    init(element: PrElement) {
        this.upstreamElectronPath = new Set();
        this.downstreamElectronPath = new Set();

        this.existingElementIds = [];
        this.newPathsByType = [];
        this.lineElements = [];

        this.element = element;
        this.typeId = element.typeId;

        const elementsToModify = this.prService.findElementsToModifyByTypeId(this.typeId);
        this.elements = elementsToModify.elements;

        this._setNewPathsByType();
        this._setExistingElementIdsByType();
    }

    private _setNewPathsByType() {
        const elementsToModify = this.prService.findElementsToModifyByTypeId(this.typeId);
        if (elementsToModify.newPaths.length) {
            this.newPathsByType.push(elementsToModify.newPaths);
        }

        if (this.element.isNew) {
            // new hta line can be :
            //  - child of a hta line (existing or new)
            //  - grand child of a post source
            if (this.typeId == HTA_LINE) {
                const elementsToModify = this.prService.findElementsToModifyByTypeId(SOURCE_POST);
                const sourcePostNewPaths = elementsToModify.newPaths;

                // easier to manage with electron paths described from hta start to hta line
                if (sourcePostNewPaths.length) {
                    const htaStartNewPaths = sourcePostNewPaths.map((newPath) => {
                        return {
                            id: newPath.path[0][HTA_START],
                            path: newPath.path.map((path: PrElectronPath) => {
                                return {
                                    [HTA_LINE]: path[HTA_LINE],
                                    [HTA_START]: path[HTA_START],
                                };
                            }),
                        };
                    });
                    this.newPathsByType.push(htaStartNewPaths);
                }
            }

            // new bt line can be :
            //  - child of a bt line (existing or new)
            //  - grand child of a new hta bt transformer
            if (this.typeId == BT_LINE) {
                const elementsToModify =
                    this.prService.findElementsToModifyByTypeId(HTA_BT_TRANSFORMER);
                const htaBtTransformerNewPaths = elementsToModify.newPaths;

                // easier to manage with electron paths described from bt start to bt line
                if (htaBtTransformerNewPaths.length) {
                    const btStartNewPaths = htaBtTransformerNewPaths.map((newPath) => {
                        return {
                            id: newPath.path[0][BT_START],
                            path: newPath.path.map((path: PrElectronPath) => {
                                return {
                                    [BT_LINE]: path[BT_LINE],
                                    [BT_START]: path[BT_START],
                                };
                            }),
                        };
                    });
                    this.newPathsByType.push(btStartNewPaths);
                }
            }
        }
    }

    private _setExistingElementIdsByType() {
        if (this.typeId == HTA_LINE) {
            // new hta line can be :
            //  - child of a hta line
            //  - child of a post source
            const htaLineElements = this.prService.findElementsToModifyByTypeId(HTA_LINE);
            const existingHtaLineIds = htaLineElements.elements
                .filter((element) => !element.isNew)
                .map((element) => element.id);
            this.existingElementIds.push(...existingHtaLineIds);

            const htaStartElements = this.prService.findElementsToModifyByTypeId(HTA_START);
            const existingHtaStartIds = htaStartElements.elements.map((element) => element.id);
            this.existingElementIds.push(...existingHtaStartIds);
        }

        if (this.typeId == BT_LINE) {
            // new bt line can be :
            //  - child of a bt line
            //  - child of a new hta bt transformer
            const btLineElements = this.prService.findElementsToModifyByTypeId(BT_LINE);
            const existingBtLineIds = btLineElements.elements
                .filter((element) => !element.isNew)
                .map((element) => element.id);
            this.existingElementIds.push(...existingBtLineIds);

            const btStartElements = this.prService.findElementsToModifyByTypeId(BT_START);
            const existingBtStartIds = btStartElements.elements.map((element) => element.id);
            this.existingElementIds.push(...existingBtStartIds);
        }
    }

    async getElements(stream: string, indicatorId: number) {
        const isNew = !!this.element.isNew;

        if (isNew) {
            switch (stream) {
                case 'upstream':
                    await this._getUpstreamElectronPathFromNew(indicatorId);
                    break;
                case 'downstream':
                    this._getDownstreamElectronPathFromNew();
                    break;
                case 'allFromStart':
                    await this._getAllElectronPathFromNew(indicatorId);
                    break;
            }
        } else {
            const data = {
                elementId: this.element.id,
                typeId: this.typeId,
                stream: stream,
                indicatorId: indicatorId,
                scaleTypeId: this.terService.territoryScale.typeId,
                territoryIds: this.terService.territories.map((t) => t.id),
            };
            this.lineElements = await this.restService.getPrLineElements(data);

            if (['downstream', 'allFromStart'].includes(stream)) {
                this._getElectronPathAmongNew();
            }
        }
    }

    private async _getUpstreamElectronPathFromNew(indicatorId?: number) {
        const elementId = this.element.id;

        this.upstreamElectronPath.add(elementId);
        const originElementId = this._findUpstreamOriginElementId(elementId);

        this.elements
            .filter((element) => this.upstreamElectronPath.has(element.id))
            .forEach((element) => this._setNewLineElements(element));

        if (originElementId) {
            const data = {
                elementId: originElementId,
                typeId: this.typeId,
                stream: 'upstream',
                indicatorId: indicatorId,
                scaleTypeId: this.terService.territoryScale.typeId,
                territoryIds: this.terService.territories.map((t) => t.id),
            };
            const existingElements = await this.restService.getPrLineElements(data);
            this.lineElements.push(...existingElements);
        }
    }

    private _findUpstreamOriginElementId(elementId: string) {
        for (let newPaths of this.newPathsByType) {
            const newPathInfo = newPaths.find((newPath) =>
                newPath.path.find((path) => path[this.typeId] == elementId),
            );

            if (newPathInfo) {
                const paths = newPathInfo.path;
                const originElementId = newPathInfo.id;

                const pathWithElementId = paths.find((path) => path[this.typeId] == elementId);
                const indexPath = paths.indexOf(pathWithElementId);
                paths
                    .filter((path, index) => index < indexPath)
                    .forEach((path) => this.upstreamElectronPath.add(path[this.typeId]));

                const exists = this.existingElementIds.includes(originElementId);
                if (exists) {
                    return originElementId;
                }

                this.upstreamElectronPath.add(originElementId);
                const result = this._findUpstreamOriginElementId(originElementId);
                if (result) {
                    return result;
                }
            }
        }
    }

    private _getDownstreamElectronPathFromNew() {
        const elementId = this.element.id;

        this.downstreamElectronPath.add(elementId);
        this._findNewDownstreamElectronPath(elementId);

        this.elements
            .filter((element) => element.isNew && this.downstreamElectronPath.has(element.id))
            .forEach((element) => this._setNewLineElements(element));
    }

    private _findNewDownstreamElectronPath(elementId: string) {
        this.newPathsByType.forEach((newPaths) => {
            newPaths
                .filter((newPath) => newPath.id == elementId)
                .forEach((newPath) =>
                    newPath.path.forEach((path) => {
                        const elementId = path[this.typeId];
                        this.downstreamElectronPath.add(elementId);
                        this._findNewDownstreamElectronPath(elementId);
                    }),
                );

            const newPathInfo = newPaths.find((newPath) =>
                newPath.path.find((path) => path[this.typeId] == elementId),
            );
            if (newPathInfo) {
                const paths = newPathInfo.path;
                const pathWithElementId = paths.find((path) => path[this.typeId] == elementId);
                const indexPath = paths.indexOf(pathWithElementId);
                paths
                    .filter((path, index) => index > indexPath)
                    .forEach((path) => {
                        const elementId = path[this.typeId];
                        this.downstreamElectronPath.add(elementId);
                        this._findNewDownstreamElectronPath(elementId);
                    });
            }
        });
    }

    private async _getAllElectronPathFromNew(indicatorId: number) {
        const elementId = this.element.id;

        const originElementId = this._findUpstreamOriginElementId(elementId);
        if (originElementId) {
            this.downstreamElectronPath.add(originElementId);
            this._findNewDownstreamElectronPath(originElementId);

            this.elements
                .filter((element) => element.isNew && this.downstreamElectronPath.has(element.id))
                .forEach((element) => this._setNewLineElements(element));

            const data = {
                elementId: originElementId,
                typeId: this.typeId,
                stream: 'allFromStart',
                indicatorId: indicatorId,
                scaleTypeId: this.terService.territoryScale.typeId,
                territoryIds: this.terService.territories.map((t) => t.id),
            };
            const existingElements = await this.restService.getPrLineElements(data);
            this.lineElements.push(...existingElements);
        } else {
            // got to search for ht or bt start and get all elements beneath

            this._getUpstreamElectronPathFromNew();
            this._getDownstreamElectronPathFromNew();
        }
    }

    private _getElectronPathAmongNew() {
        const existingIds = this.lineElements.map((lineElement) => lineElement.id);

        const newElementIds: any = this.newPathsByType.reduce((newElementIds, newPath) => {
            const ids = newPath
                .filter((newPath) => existingIds.includes(newPath.id))
                .reduce((newElementIds, newPath) => {
                    this.downstreamElectronPath.add(newPath.id);
                    this._findNewDownstreamElectronPath(newPath.id);
                    return new Set([...newElementIds, ...this.downstreamElectronPath]);
                }, new Set());
            return new Set([...newElementIds, ...ids]);
        }, new Set());

        this.elements
            .filter((element) => element.isNew && newElementIds.has(element.id))
            .forEach((element) => this._setNewLineElements(element));
    }

    private _setNewLineElements(element: any) {
        const isAlreadySet = this.lineElements.some((lineElement) => lineElement.id == element.id);
        if (!isAlreadySet) {
            this.lineElements.push({
                id: element.id,
                label: element.label,
            });
        }
    }

    async addNewElements(indicatorPlots: Indicator[]) {
        const typeLabel = this.element.typeLabel;
        const elementIds = this.elements.map((element) => element.id);

        await Promise.all(
            this.lineElements
                .filter((lineElement) => !elementIds.includes(lineElement.id))
                .map(async (lineElement) => {
                    const data = {
                        id: lineElement.id,
                        label: lineElement.label,
                        typeId: this.typeId,
                        typeLabel: typeLabel,
                        isLineElementsLoading: false,
                    };
                    return this.prService.addElement(data, indicatorPlots);
                }),
        );
    }

    applyChangesToElements() {
        const elementIds = this.elements.map((element) => element.id);

        this.lineElements
            .filter((lineElement) => elementIds.includes(lineElement.id))
            .forEach((lineElement) => {
                const loadedElement = this.elements.find((element) => element.id == lineElement.id);

                this.element.hypothesis
                    .filter((hypothesis) => hypothesis.value != '')
                    .forEach((hypothesis) => {
                        const loadedHypothesis = loadedElement.hypothesis.find(
                            (loadedHypothesis: PrHypothesisValue) =>
                                loadedHypothesis.id == hypothesis.id,
                        );
                        loadedHypothesis.value = hypothesis.value;
                    });
            });
    }
}
