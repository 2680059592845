export enum TerritoryScaleId {
    BUILDING = 1,
    LOT = 2,
    IRIS = 10,
    DISTRICT = 20,
    CITY = 21,
    EPT = 39,
    EPCI = 40,
    DEPARTMENT = 50,
    OLD_REGION = 60,
    REGION = 61,
    COUNTRY = 99,
}
