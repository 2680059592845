<div class="modal-header">
  <h5 class="modal-title" id="forgetPassword">Demande de nouveau mot de passe</h5>
  <button type="button" class="btn-close" (click)="dismiss()"></button>
</div>
<div class="modal-body">
  <form novalidate class="container-fluid" [hidden]="isValidated">
    <div class="row d-flex align-items-center mb-3">
      <label for="login" class="col-4 text-end">
        Login :
      </label>
      <div class="col-8">
        <stInput name="login" type="text" innerId="login" [(ngModel)]="login"></stInput>
      </div>
    </div>
  </form>

  <div class="alert alert-info d-flex align-items-center p-3 my-0" role="alert" *ngIf=isValidated>
    <i class="fa fa-check-circle" aria-hidden="true"></i>
    <span class="ms-3">
      Si ce nom d'utilisateur existe, vous allez recevoir un nouveau mot de passe sur l'adresse mail associée.
    </span>
  </div>
</div>

<div class="modal-footer">
  <stButton btnLabel="{{ isValidated ? 'Fermer' : 'Annuler' }}" btnClass="btn-st-default me-1" (onClick)="close()">
  </stButton>
  <stButton btnLabel="Valider" btnClass="btn-st-primary ms-1" [isDisabled]="!login" [hidden]="isValidated"
    (onClick)="validate()"></stButton>
</div>