<div class="container-fluid mb-3">
    <div class="row d-flex align-items-center solar-card">
        <div class="col-auto px-0 text-center">
            <span class="fa-stack">
                <i class="fa fa-circle-thin fa-stack-2x"></i>
                <i class="fa fa-info fa-stack-1x"></i>
            </span>
        </div>
        <div class="col">
            <p>
                Ce graphique représente une journée de printemps type de production de votre
                installation solaire et de consommation du patrimoine de votre périmètre de
                simulation.
            </p>
        </div>
    </div>
</div>
<div id="typical-day-plot" class="mb-3" *ngIf="!!typicalDayDatasets">
    <div class="mb-3">
        <stLineChart
            [datasets]="typicalDayDatasets"
            [labels]="typicalDayLabels"
            [externalTooltipHandler]="typicalDayTooltipHandler"
            [displayLegend]="false"
            xAxisLabel="Heure"
            yAxisLabel="kWh"
        ></stLineChart>
    </div>

    <div id="cs-self-consumption-typical-day-legend" class="container-fluid">
        <div class="row gx-2">
            <div class="col-xl-4 col-lg-6 col-md-12">
                <span class="color area self-consumption"></span>
                <small>Autoconsommation&nbsp;(kWh)</small>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12">
                <div>
                    <span class="color area electricity-purchased"></span>
                    <small>Achat&nbsp;d'électricité&nbsp;(kWh)</small>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12">
                <span class="color area electricity-sold"></span>
                <small>Vente&nbsp;sur&nbsp;le&nbsp;réseau&nbsp;(kWh)</small>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12">
                <span class="color line consumption"></span>
                <small>Consommation&nbsp;(kWh)</small>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12">
                <span class="color line solar-production"></span>
                <small>Production&nbsp;solaire&nbsp;(kWh)</small>
            </div>
        </div>
    </div>
</div>
