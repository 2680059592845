<div class="row mb-2">
  <div class="col-1" style="margin-top:10px;">
    <ng-template #tooltipContent>
      <div [innerHTML]="popoverInfo"></div>
    </ng-template>
    <i class="fa fa-info-circle" [ngbTooltip]="tooltipContent" placement="start" container="body"></i>
  </div>
  <div class="col-8" style="margin-top:10px;">
    <span>{{ label }}</span>
  </div>
  <div class="col-3">
    <stInput type="number" [(ngModel)]="value" (ngModelChange)="onChange($event)"></stInput>
  </div>
</div>