'use strict';

import { Component, Inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReversePipe } from 'ngx-pipes';

import { DashboardService } from 'src/app/services/dashboard.service';
import { EventService } from 'src/app/services/event.service';
import { FilterDataService } from 'src/app/services/FilterDataService';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ModuleService } from 'src/app/services/module.service';
import { PlotIndicatorService } from 'src/app/services/plotIndicator/plot-indicator.service';

@UntilDestroy()
@Component({
    selector: 'dashboard-tab',
    templateUrl: './dashboard-tab.template.html',
    styleUrls: ['./dashboard-tab.component.scss'],
    providers: [ReversePipe],
})
export class DashboardTabComponent implements OnInit {
    public selectedDashboard: any = {
        indicatorId: null,
        indicator: null,
        selectedTerritory: {
            id_ter: null,
            label: null,
        },
        table: {
            label: null,
            countLabel: null,
            isTerritory: null,
            column: null,
            rows: [],
        },
    };

    public isTableAvailable: boolean;
    public isPlotAvailable: boolean;

    constructor(
        @Inject(DashboardService) public dashboardService: DashboardService,
        @Inject(EventService) private eventService: EventService,
        @Inject(FilterDataService) private filterDataService: FilterDataService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(ModuleService) public moduleService: ModuleService,
        @Inject(PlotIndicatorService) public plotIndicatorService: PlotIndicatorService,
    ) {}

    ngOnInit(): void {
        const preferences = this.localStorageService.get('preferences');
        this.isTableAvailable = preferences.modularity.dashboard.table;
        this.isPlotAvailable = preferences.modularity.dashboard.plot;
        this._listenToEvents();
    }

    private _listenToEvents() {
        this.filterDataService.filteredIndicatorId$
            .pipe(untilDestroyed(this))
            .subscribe(async (indicatorId) => {
                if (!indicatorId) {
                    return;
                }

                const dashboards = this.dashboardService.dashboards.value;
                const dashboard = dashboards.find(
                    (dashboard) => dashboard.indicatorId == indicatorId,
                );
                if (dashboard) {
                    const aggregation = await this.dashboardService.getAggregation(indicatorId);
                    dashboard.table.label = aggregation.aggregationLabel;
                    dashboard.table.countLabel = aggregation.countLabel;
                    this.dashboardService.updateDashboard(dashboard);
                }
            });

        this.eventService.indicatorPloted
            .pipe(untilDestroyed(this))
            .subscribe(async (indicatorId: any) => {
                const indicatorPlot = this.plotIndicatorService.plotedIndicators[indicatorId];
                const isDashboardShown = ['territories', 'none'].includes(
                    indicatorPlot.vector_base,
                );
                const isImported = indicatorPlot.isImported;

                if (isDashboardShown && !isImported) {
                    const dashboards = this.dashboardService.dashboards.value;
                    const dashboard = dashboards.find(
                        (dashboard) => dashboard.indicatorId == indicatorId,
                    );
                    if (dashboard) {
                        this.dashboardService.removeDashboard(dashboard);
                    }

                    const aggregation = await this.dashboardService.getAggregation(indicatorId);
                    this.selectedDashboard = {
                        indicatorId: indicatorId,
                        indicator: indicatorPlot,
                        selectedTerritory: {
                            id_ter: null,
                            label: null,
                        },
                        table: {
                            label: aggregation.aggregationLabel,
                            countLabel: aggregation.countLabel,
                        },
                    };
                    this.dashboardService.addDashboard(this.selectedDashboard);
                }
            });

        this.eventService.indicatorUnploted
            .pipe(untilDestroyed(this))
            .subscribe(async (indicatorId: any) => {
                const dashboards = this.dashboardService.dashboards.value;
                const dashboardToRemove = dashboards.find(
                    (dashboard) => dashboard.indicatorId == indicatorId,
                );
                if (dashboardToRemove) {
                    this.dashboardService.removeDashboard(dashboardToRemove);

                    const dashboards = this.dashboardService.dashboards.value;
                    if (!dashboards.length) {
                        return;
                    }
                    const dashboard = dashboards[dashboards.length - 1];
                    this.selectedDashboard = dashboard;
                }
            });

        this.eventService.clickOnTerritory.pipe(untilDestroyed(this)).subscribe((data: any) => {
            const indicatorId = data.indicatorId;
            const territoryId = data.id_ter;

            // const isDashboardAvailable = this.dataService.activeIndicatorsList.some(
            //     (indicator: Indicator) => ['territories', 'none'].includes(indicator.vector_base),
            // );
            // isDashboardAvailable ? this.dashboardService.open() : this.dashboardService.close();

            const isSameIndicator = this.selectedDashboard.indicatorId == indicatorId;
            const isSameTerritory = this.selectedDashboard.selectedTerritory.id_ter == territoryId;
            if (isSameIndicator && isSameTerritory) {
                return;
            }

            const dashboards = this.dashboardService.dashboards.value;
            const dashboard = dashboards.find((dashboard) => dashboard.indicatorId == indicatorId);
            if (dashboard) {
                dashboard.selectedTerritory = data;
                this.selectedDashboard = dashboard;
                this.dashboardService.updateDashboard(dashboard);
            }
        });
    }
}
