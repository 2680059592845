('use strict');

import { OnInit, Inject, Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { environment } from '../../../environments/environment';

import { CatalogService } from 'src/app/services/catalog.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { EventService } from 'src/app/services/event.service';
import { LoaderService } from 'src/app/services/LoaderService';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MapService } from 'src/app/services/map.service';
import { ModuleService } from 'src/app/services/module.service';
import { PlausibleAnalyticsService } from 'src/app/services/plausible-analytics.service';
import { PlotIndicatorService } from 'src/app/services/plotIndicator/plot-indicator.service';
import { ShapeFileService } from 'src/app/services/ShapeFileService';
import { TerService } from 'src/app/services/TerService';

import { ImportModalComponent } from './modals/import-file/import-modal.component';
import { UsefulService } from 'src/app/services/UsefulService';

@UntilDestroy()
@Component({
    templateUrl: './main.template.html',
    styleUrls: ['./main.controller.scss'],
})
export class MainComponent implements OnInit {
    isProsper: boolean;
    showLoaderCarte = false;
    initIndicatorId: number;
    initZoom: any;
    initZoomAnimation: any;
    initLat: number;
    initLng: number;
    address: any;
    modulesAccess: any;
    isAddressDefined: boolean;
    isLocationDefined: boolean;
    showRightPanel: boolean;
    manual = environment.manual;
    showMobileUi = false;

    constructor(
        private modalService: NgbModal,
        private notification: ToastrService,
        @Inject(CatalogService) private catalogService: CatalogService,
        @Inject(DashboardService) public dashboardService: DashboardService,
        @Inject(EventService) private eventService: EventService,
        @Inject(LoaderService) private loaderService: LoaderService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(MapService) private mapService: MapService,
        @Inject(ModuleService) public moduleService: ModuleService,
        @Inject(PlausibleAnalyticsService)
        private plausibleAnalyticsService: PlausibleAnalyticsService,
        @Inject(PlotIndicatorService) private plotIndicatorService: PlotIndicatorService,
        @Inject(ShapeFileService) private shapeFileService: ShapeFileService,
        @Inject(UsefulService) private usefulService: UsefulService,
        @Inject(TerService) private terService: TerService,
    ) {
        this.showMobileUi = this.usefulService.showMobileUi;
    }

    ngOnInit(): void {
        this.plausibleAnalyticsService.trackPageView('main');

        this.isProsper = !!this.localStorageService.get('prosperToken');
        const preferences = this.localStorageService.get('preferences');

        this.initIndicatorId = preferences.init.indicatorId;
        this.initZoom = preferences.init.zoomLevel;
        this.initZoomAnimation = preferences.init.zoomAnimation;

        this.initLat = preferences.territory.latitude;
        this.initLng = preferences.territory.longitude;

        this.address = this.localStorageService.get('address');

        this.isAddressDefined = !!this.address;
        this.isLocationDefined = !!this.initLat && !!this.initLng;

        this.modulesAccess = preferences.modules;

        this._listenToEvents();
        this._initialization();
    }

    private _listenToEvents() {
        this.eventService.loaderState
            .pipe(untilDestroyed(this))
            .subscribe((loaderState: any) => (this.showLoaderCarte = loaderState));

        this.eventService.indicatorToggled.pipe(untilDestroyed(this)).subscribe(() => {
            const indicatorPlots = Object.values(this.plotIndicatorService.plotedIndicators);
            this.moduleService.updateAvailableModule(indicatorPlots);
            this.dashboardService.updateAvailable();
        });
    }

    private async _initialization() {
        this.loaderService.start('initialization');

        // no need to wait until map is initialized to continue
        this._initMap();

        try {
            await this.terService.initTerritory(this.isAddressDefined);
            this._centerOn();
        } catch (error) {
            console.error('Error initialization', error);
            this.notification.error("Une erreur est survenue. Impossible de charger l'application");
        } finally {
            this.loaderService.stop('initialization');
        }
    }

    private async _initMap() {
        this.mapService.createMap();
        this.mapService.initMapComponents();
        this.shapeFileService.setDropEvent();
    }

    private _centerOn() {
        this.loaderService.start('initializationZoom');
        const zoomEventCallback = async () => {
            try {
                await this.catalogService.initCatalog(this.initIndicatorId);
                this.eventService.emit('mainInitialized');
                this.mapService.deactivateZoomEndEvent(zoomEventCallback);
                this.loaderService.stop('initializationZoom');
            } catch (error) {
                console.error('Error centerOn', error);
                this.loaderService.stop('initializationZoom');
            }
        };

        if (this.isAddressDefined) {
            this._centerOnAddress(zoomEventCallback);
        } else if (this.isLocationDefined) {
            this._centerOnInitLatLng(zoomEventCallback);
        } else {
            const year = this.terService.territoryScale.year;
            const scaleTypeId = this.terService.territoryScale.typeId;
            const territoryIds = this.terService.territories.map((t) => t.id);
            this.mapService.centerOnBbox(year, scaleTypeId, territoryIds, zoomEventCallback);
        }
    }

    private _centerOnInitLatLng(callback: any) {
        this.mapService.centerOn(
            this.initLat,
            this.initLng,
            this.initZoom,
            this.initZoomAnimation,
            callback,
        );
    }

    private _centerOnAddress(callback: any) {
        const lat = this.address.geometry.coordinates[1];
        const lng = this.address.geometry.coordinates[0];
        this.mapService.centerOn(lat, lng, 18, this.initZoomAnimation, callback);
        this.mapService.addMarkerAddress(lat, lng);
    }

    goToProsper() {
        const group = this.localStorageService.get('prosperGroup');
        const token = this.localStorageService.get('prosperToken');
        const url = `http://prosper-${group}.energiesdemain.com/#/login/${token}_blank`;
        const win = window.open(url);
        win.focus();
    }

    openImportModal() {
        this.moduleService.closeAllModules();
        this.moduleService.isUploadModalActive = true;

        const modal = this.modalService.open(ImportModalComponent, { size: 'xl' });
        modal.result.then(
            () => (this.moduleService.isUploadModalActive = false),
            () => (this.moduleService.isUploadModalActive = false),
        );
    }

    toggleModule(moduleName: string) {
        this.moduleService.toggleModule(moduleName);
    }
}
