('use strict');

import { Inject, Injectable } from '@angular/core';

import { PlotIndicatorService } from '../plotIndicator/plot-indicator.service';
import { ProsperReseauxService } from './prosper-reseaux.service';

@Injectable({
    providedIn: 'root',
})
export class ProsperReseauxClearService {
    constructor(
        @Inject(PlotIndicatorService) private plotIndicatorService: PlotIndicatorService,
        @Inject(ProsperReseauxService) private prService: ProsperReseauxService,
    ) {
        this.clear();
    }

    clear() {
        this.clearElementsToModify();
        this._clearElements();
    }

    clearElementsToModify() {
        this._clearElementsToModify();
        this._clearGeojsons();
        this._clearCollapses();

        this.prService.miscInfo.selectedElement = null;
        this.prService.miscInfo.isInitialized = false;
    }

    private _clearElements() {
        this.prService.prElements.forEach((prElement) => {
            prElement.elements = [];
            prElement.parameters = [];
        });
    }

    private _clearElementsToModify() {
        this.prService.elementsToModifyByType.forEach((byType) => {
            byType.elements = [];
            byType.newPaths = [];
        });

        this.prService.featuresToAddByType.forEach((byType) => {
            byType.features = [];
        });
    }

    private _clearGeojsons() {
        this.plotIndicatorService.geojsonsInfo
            .filter((geojsonInfo: any) => geojsonInfo.id.includes('res_'))
            .forEach((geojsonsInfo: any) => {
                const features = geojsonsInfo.geojson.features.filter(
                    (feature: GeoJSON.Feature) => !feature.properties.isNew,
                );
                geojsonsInfo.geojson.features = features;
            });
    }

    private _clearCollapses() {
        this.prService.collapse = {
            prTypes: [],
            elements: [],
        };
    }
}
