<div class="d-flex flex-column h-100" id="cs-new-element-panel">
    <div class="flex-grow-1 overflow-auto">
        <div class="w-100 p-3">
            <ng-container *ngIf="isZoneConstraint">
                <div class="solar-card text-st-active mb-3">
                    L'emplacement ciblé se trouve dans une zone sujette à des contraintes
                    architecturales. Rapprochez-vous de votre mairie afin d'étudier la faisabilité
                    de votre projet solaire.
                </div>
            </ng-container>

            <ng-container *ngIf="newRoofFormGroup">
                <form [formGroup]="newRoofFormGroup">
                    <div class="container-fluid solar-card">
                        <h5 class="text-st-primary mb-3">
                            Description de mon projet d'installation solaire
                        </h5>

                        <div id="installation-type-row" class="row d-flex align-items-center mb-3">
                            <div class="col-auto">
                                <svg
                                    width="26"
                                    height="22"
                                    viewBox="0 0 26 22"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clip-path="url(#clip0_5666_4030)">
                                        <g clip-path="url(#clip1_5666_4030)">
                                            <path
                                                d="M17.5493 18.8754L15.6005 18.8775V17.5625H10.4005V18.8836L8.45135 18.8857C8.09304 18.8861 7.80257 19.1793 7.80217 19.5415L7.80013 20.8429C7.79973 21.2059 8.09142 21.5004 8.45095 21.5L17.5493 21.4893C17.9076 21.4889 18.1981 21.1957 18.1985 20.8339L18.2001 19.5325C18.2005 19.1695 17.9089 18.875 17.5493 18.8754ZM23.7739 1.59676C23.6674 0.963887 23.1154 0.5 22.4682 0.5H3.53167C2.88451 0.5 2.33242 0.963887 2.22598 1.59676C-0.13474 15.6413 0.00175978 14.7767 0.00135353 14.9375C0.000134778 15.6479 0.581885 16.25 1.32573 16.25H24.6745C25.4151 16.25 25.9953 15.6528 25.9985 14.9461C25.9993 14.7788 26.1346 15.6413 23.7739 1.59676ZM10.5557 3.125H15.4441L15.841 7.0625H10.1588L10.5557 3.125ZM7.50195 13.625H2.88817L3.66045 9.03125H7.96548L7.50195 13.625ZM8.16373 7.0625H3.99113L4.65292 3.125H8.56063L8.16373 7.0625ZM9.49704 13.625L9.96017 9.03125H16.0397L16.5028 13.625H9.49704ZM17.4392 3.125H21.3469L22.0087 7.0625H17.8361L17.4392 3.125ZM18.4979 13.625L18.0348 9.03125H22.3398L23.1121 13.625H18.4979Z"
                                                fill="#4A4A4C"
                                            />
                                        </g>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5666_4030">
                                            <rect
                                                width="26"
                                                height="21"
                                                fill="white"
                                                transform="translate(0 0.5)"
                                            />
                                        </clipPath>
                                        <clipPath id="clip1_5666_4030">
                                            <rect
                                                width="26"
                                                height="21"
                                                fill="white"
                                                transform="translate(0 0.5)"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>

                            <div class="col">
                                <div class="row d-flex align-items-center">
                                    <div class="col-lg">
                                        <div class="row d-flex align-items-center">
                                            <label class="col" for="new-roof-installation"
                                                >Type d'installation</label
                                            >
                                            <!-- <div class="col-auto">
                                                <i class="fa fa-info-circle fs-body"></i>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="col-lg">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-10">
                                                <stSelect
                                                    formControlName="installationType"
                                                    innerId="new-roof-installation"
                                                    [data]="installationTypeOptions"
                                                    labelField="label"
                                                    valueField="value"
                                                    type="primary"
                                                >
                                                </stSelect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="orientation-row" class="row d-flex align-items-center mb-3">
                            <div class="col-auto">
                                <svg
                                    width="26"
                                    height="26"
                                    viewBox="0 0 26 26"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clip-path="url(#clip0_5664_3992)">
                                        <path
                                            d="M11.8523 11.8523C11.2176 12.4871 11.2176 13.5154 11.8523 14.1502C12.4871 14.785 13.5154 14.785 14.1502 14.1502C14.785 13.5154 14.785 12.4861 14.1502 11.8523C13.5129 11.2176 12.4871 11.2176 11.8523 11.8523ZM13 0C5.81953 0 0 5.81953 0 13C0 20.1805 5.81953 26 13 26C20.1805 26 26 20.1805 26 13C26 5.81953 20.1805 0 13 0ZM19.4035 7.92188L16.0535 15.2547C15.9154 15.5578 15.5569 15.9159 15.2537 16.055L7.92594 19.405C7.07992 19.7914 6.20902 18.9202 6.59598 18.0746L9.94602 10.7468C10.0841 10.443 10.4427 10.0841 10.7473 9.94551L18.0751 6.59547C18.9211 6.21055 19.7945 7.07891 19.4035 7.92188Z"
                                            fill="#4A4A4C"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5664_3992">
                                            <rect width="26" height="26" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>

                            <div class="col">
                                <div class="row d-flex align-items-center">
                                    <div class="col-lg">
                                        <div class="row d-flex align-items-center">
                                            <label class="col" for="new-roof-orientation"
                                                >Orientation</label
                                            >
                                            <!-- <div class="col-auto">
                                                <i class="fa fa-info-circle fs-body"></i>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="col-lg">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-10">
                                                <stSelect
                                                    formControlName="orientation"
                                                    innerId="new-roof-orientation"
                                                    [data]="orientationOptions"
                                                    labelField="label"
                                                    valueField="value"
                                                    type="primary"
                                                >
                                                </stSelect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="tilt-row" class="row d-flex align-items-center mb-3">
                            <div class="col-auto">
                                <svg
                                    width="26"
                                    height="26"
                                    viewBox="0 0 26 26"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M24.6445 1.375L1.00078 24.6103"
                                        stroke="#4A4A4C"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                    />
                                    <path
                                        d="M25 25.625C25.5523 25.625 26 25.1773 26 24.625C26 24.0727 25.5523 23.625 25 23.625V25.625ZM25 23.625L1.00017 23.625V25.625L25 25.625V23.625Z"
                                        fill="#4A4A4C"
                                    />
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M24.997 23.0261V3.5L13.5494 14.678C13.5555 14.7727 13.5347 14.8702 13.4839 14.9587C13.3837 15.1329 13.1947 15.224 13.0069 15.2077L5 23.0261L18.4604 23.0261C18.4595 23.0194 18.4586 23.0127 18.4577 23.006C18.4211 22.7323 18.6133 22.4807 18.887 22.4441C19.1607 22.4075 19.4123 22.5997 19.4489 22.8734C19.4557 22.924 19.4623 22.9749 19.4688 23.0261L24.997 23.0261ZM18.03 18.3835C17.8864 18.1476 17.5788 18.0728 17.3429 18.2164C17.1071 18.36 17.0323 18.6676 17.1758 18.9035C17.2918 19.094 17.3995 19.291 17.4995 19.4958C17.6206 19.7439 17.92 19.8468 18.1682 19.7257C18.4163 19.6045 18.5192 19.3051 18.3981 19.057C18.2848 18.825 18.1623 18.6009 18.03 18.3835ZM16.3041 17.7495C16.4909 17.9529 16.8072 17.9663 17.0106 17.7796C17.2139 17.5928 17.2274 17.2765 17.0406 17.0731C16.8715 16.889 16.6929 16.7097 16.5042 16.534C16.3021 16.3458 15.9857 16.3571 15.7975 16.5592C15.6093 16.7613 15.6206 17.0777 15.8227 17.2659C15.9934 17.4248 16.1536 17.5857 16.3041 17.7495ZM18.981 20.5783C18.9031 20.3134 18.6253 20.1617 18.3603 20.2395C18.0954 20.3173 17.9437 20.5952 18.0215 20.8602C18.0845 21.0745 18.1425 21.2972 18.1958 21.5293C18.2576 21.7985 18.5259 21.9666 18.795 21.9047C19.0641 21.8429 19.2322 21.5747 19.1704 21.3055C19.1129 21.0553 19.05 20.8131 18.981 20.5783ZM14.6722 16.3319C14.8964 16.4931 15.2088 16.4422 15.3701 16.218C15.5314 15.9939 15.4804 15.6814 15.2563 15.5202C15.0604 15.3792 14.8566 15.2393 14.6447 15.0997C14.4141 14.9478 14.104 15.0116 13.9521 15.2422C13.8002 15.4728 13.864 15.7829 14.0946 15.9348C14.2961 16.0675 14.4885 16.1997 14.6722 16.3319Z"
                                        fill="#4A4A4C"
                                    />
                                    <circle
                                        cx="21.217"
                                        cy="15.5265"
                                        r="1.52164"
                                        fill="#4A4A4C"
                                        stroke="white"
                                    />
                                </svg>
                            </div>

                            <div class="col">
                                <div class="row d-flex align-items-center">
                                    <div class="col-lg">
                                        <div class="row d-flex align-items-center">
                                            <label class="col" for="new-roof-tilt"
                                                >Inclinaison de la couverture</label
                                            >
                                            <!-- <div class="col-auto">
                                                <i class="fa fa-info-circle fs-body"></i>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="col-lg">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-10">
                                                <stInput
                                                    formControlName="tilt"
                                                    innerId="new-roof-tilt"
                                                    placeholder="Inclinaison"
                                                    class="primary"
                                                    type="number"
                                                    [min]="0"
                                                    [max]="90"
                                                ></stInput>
                                            </div>
                                            <div class="col-2">°</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="available-area-row" class="row d-flex align-items-center mb-3">
                            <div class="col-auto">
                                <svg
                                    width="26"
                                    height="26"
                                    viewBox="0 0 26 26"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        x="1"
                                        y="1"
                                        width="24"
                                        height="24"
                                        fill="#FBFBFB"
                                        stroke="#4A4A4C"
                                        stroke-width="2"
                                        stroke-dasharray="2 2"
                                    />
                                    <rect x="3" y="3.25" width="20" height="19.5" fill="#4A4A4C" />
                                    <path
                                        d="M15.556 13.385V17.417H14.226V13.539C14.226 12.769 14.086 11.747 13.008 11.747C11.944 11.747 11.412 12.769 11.412 13.959V17.417H10.068V13.539C10.068 12.559 9.83 11.747 8.864 11.747C7.8 11.747 7.268 12.769 7.268 13.959V17.417H5.924V10.767H7.268L7.254 11.817H7.296C7.604 11.201 8.332 10.641 9.214 10.641C10.194 10.641 10.88 11.089 11.188 11.957C11.636 11.117 12.364 10.641 13.302 10.641C15.08 10.641 15.556 11.943 15.556 13.385ZM21.2177 12.531V13.371H17.0737L17.0037 12.643L19.2157 10.487C19.7897 9.91299 20.1117 9.53499 20.1117 9.00299C20.1117 8.45699 19.6777 8.06499 18.9637 8.06499C18.2357 8.06499 17.8157 8.47099 17.5777 9.12899L16.9337 8.72299C17.0457 7.95299 17.8157 7.22499 19.0337 7.22499C20.2377 7.22499 21.1057 7.92499 21.1057 8.91899C21.1057 9.91299 20.3217 10.571 19.7757 11.103L18.3337 12.531H21.2177Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>

                            <div class="col">
                                <div class="row d-flex align-items-center">
                                    <div class="col-lg">
                                        <div class="row d-flex align-items-center">
                                            <label class="col" for="new-roof-usable-area"
                                                >Surface disponible</label
                                            >
                                            <!-- <div class="col-auto">
                                                <i class="fa fa-info-circle fs-body"></i>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="col-lg">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-10">
                                                <stInput
                                                    formControlName="usableRoofArea"
                                                    innerId="new-roof-usable-area"
                                                    placeholder="Surface"
                                                    class="primary"
                                                    type="number"
                                                    [min]="1"
                                                ></stInput>
                                            </div>
                                            <div class="col-2">m<sup>2</sup></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="height-row" class="row d-flex align-items-center">
                            <div class="col-auto">
                                <svg
                                    width="26"
                                    height="26"
                                    viewBox="0 0 26 26"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M4.64 0.5C4.0104 0.5 3.5 1.02467 3.5 1.67188C3.5 2.31908 4.0104 2.84375 4.64 2.84375H21.36C21.9896 2.84375 22.5 2.31908 22.5 1.67188C22.5 1.02467 21.9896 0.5 21.36 0.5H4.64ZM4.64 23.1562C4.0104 23.1562 3.5 23.6809 3.5 24.3281C3.5 24.9753 4.0104 25.5 4.64 25.5H21.36C21.9896 25.5 22.5 24.9753 22.5 24.3281C22.5 23.6809 21.9896 23.1562 21.36 23.1562H4.64ZM13.8061 20.4692L16.8461 17.3442C17.2913 16.8866 17.2913 16.1447 16.8461 15.687C16.4008 15.2294 15.6792 15.2294 15.2339 15.687L14.14 16.8114V9.18853L15.2339 10.313C15.6792 10.7707 16.4008 10.7707 16.8461 10.313C17.2913 9.85537 17.2913 9.11337 16.8461 8.65573L13.8061 5.53073C13.3609 5.07309 12.6391 5.07309 12.1939 5.53073L9.1539 8.65573C8.70871 9.11337 8.70871 9.85537 9.1539 10.313C9.59909 10.7707 10.3209 10.7707 10.7661 10.313L11.86 9.18853V16.8114L10.7661 15.687C10.3209 15.2294 9.59909 15.2294 9.1539 15.687C8.70871 16.1447 8.70871 16.8866 9.1539 17.3442L12.1939 20.4692C12.6391 20.9269 13.3609 20.9269 13.8061 20.4692Z"
                                        fill="#4A4A4C"
                                    />
                                </svg>
                            </div>

                            <div class="col">
                                <div class="row d-flex align-items-center">
                                    <div class="col-lg">
                                        <div class="row d-flex align-items-center">
                                            <label class="col" for="new-roof-height"
                                                >Hauteur de l'installation</label
                                            >
                                            <div class="col-auto px-0 text-center">
                                                <stButton
                                                    class="btn-info-modal"
                                                    btnClass="btn-link d-flex align-items-center no-text-decoration"
                                                    iconFaLeft="fa fa-info-circle fs-4"
                                                    (onClick)="openHeightModal()"
                                                ></stButton>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-10">
                                                <stInput
                                                    formControlName="height"
                                                    innerId="new-roof-height"
                                                    placeholder="Hauteur"
                                                    class="primary"
                                                    type="number"
                                                    [min]="1"
                                                ></stInput>
                                            </div>
                                            <div class="col-2">m</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </ng-container>
        </div>
    </div>

    <div class="footer border-top">
        <div class="container-fluid text-center py-3">
            <div class="row">
                <div class="col-12">
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-primary w-100"
                        btnLabel="Démarrer ma simulation de projet solaire"
                        (onClick)="startSimulation()"
                        [isDisabled]="!isFormValid"
                    >
                    </stButton>
                </div>
            </div>
        </div>
    </div>
</div>
