<ng-template #result let-r="result" let-t="term">
    <ngb-highlight [result]="r.label" [term]="t"></ngb-highlight>
</ng-template>

<div class="input-search-container position-relative">
    <label [for]="innerId">
        <i class="fa fa-search" aria-hidden="true"></i>
    </label>
    <input
        #input
        #instance="ngbTypeahead"
        [id]="innerId"
        type="text"
        class="form-control"
        [class]="innerClass"
        [ngClass]="{disabled: isDisabled}"
        [disabled]="isDisabled"
        [placeholder]="placeholder"
        [ngbTypeahead]="search"
        (focus)="focus$.next($any($event).target.value)"
        (selectItem)="handleOnSelect($event, input)"
        (blur)="handleOnBlur($event)"
        [value]="initLabel"
        (click)="click$.next($any($event).target.value)"
        [resultTemplate]="result"
        [inputFormatter]="formatter"
        [placement]="placement"
        popupClass="input-search-container-dropdown-menu {{ popupClass }}"
        [container]="container"
    />
</div>
