'use strict';

import { Component, Inject, Input, OnInit } from '@angular/core';

import { MapService } from 'src/app/services/map.service';
import { ModuleService } from 'src/app/services/module.service';
import { PlotCadastreSolaireIndicatorService } from 'src/app/services/plotIndicator/plot-cadastre-solaire.service';
import { SolarService } from 'src/app/services/cadastre-solaire/cadastre-solaire.service';
import { UsefulService } from 'src/app/services/UsefulService';

@Component({
    selector: 'cadastreSolaireParameters',
    templateUrl: './cadastre-solaire-parameters.template.html',
    styleUrls: ['./cadastre-solaire-parameters.component.scss'],
})
export class CadastreSolaireParametersComponent implements OnInit {
    showMobileUi: boolean = false;
    isDisabled = false;
    selectedElementsTmp: any[];
    showRange = {
        usableFloorArea: true,
    };
    userData: { selectedElement: any };

    constructor(
        @Inject(MapService) private mapService: MapService,
        @Inject(ModuleService) public moduleService: ModuleService,
        @Inject(PlotCadastreSolaireIndicatorService)
        private plotCadastreSolaireIndicatorService: PlotCadastreSolaireIndicatorService,
        @Inject(SolarService) private solarService: SolarService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    ngOnInit(): void {
        this.showMobileUi = this.usefulService.showMobileUi;
        this.selectedElementsTmp = JSON.parse(JSON.stringify(this.solarService.selectedElements));
    }

    findElementOnMap(elementId: string) {
        const layer = this.solarService.indicatorPlot.findLayerBySiterreId(elementId);
        const latLng = layer.getCenter();

        if (this.showMobileUi) {
            this.moduleService.closeModule('solar');
        }
        this._targetElement(elementId);
        this.mapService.invalidateSize();
        this.plotCadastreSolaireIndicatorService.centerMapOnLayer(latLng);
    }

    private _targetElement(elementId: string) {
        this.mapService.closeAllPopup();
        this.solarService.indicatorPlot.openPopup = true;
        this.solarService.indicatorPlot.targetElement(elementId, false);
        this.solarService.indicatorPlot.openPopup = false;
    }

    checkOnValue() {
        this.isDisabled = this.selectedElementsTmp.some(
            (selectedElement: any) => !selectedElement.usableRoofArea,
        );
    }

    cancel() {
        this.solarService.updateMainStage('initStage');
    }

    validate() {
        this.solarService.selectedElements = this.selectedElementsTmp;
        this.solarService.updateMainStage('initStage');
    }
}
