import { Component, Inject, OnInit } from '@angular/core';
import { distinctUntilChanged, skip, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SolarService } from 'src/app/services/cadastre-solaire/cadastre-solaire.service';

@Component({
    selector: 'cadastreSolairePv',
    templateUrl: './cadastre-solaire-pv.template.html',
})
export class CadastreSolairePvComponent implements OnInit {
    private destroy$ = new Subject<void>();

    private modularity: any;
    public pvStage: string;
    public activeTabId: number = 1;

    constructor(
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(SolarService) private solarService: SolarService,
    ) {}

    ngOnInit(): void {
        this.modularity = this.localStorageService.get('preferences').modularity.solar;
        this.activeTabId = this.solarService.valorization == 'Totality' ? 1 : 2;

        if (this.modularity.pvAutoconsumption) {
            this.pvStage = 'pvValorizationStage';
        } else {
            this.pvStage = 'pvTotalityStage';
        }

        this._listenToEvents();
    }

    private _listenToEvents() {
        this.solarService.pvStageObs$
            .pipe(skip(1), takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((pvStage) => {
                if (pvStage) {
                    this.pvStage = pvStage;
                }
            });
    }
}
