import { Inject, Injectable } from '@angular/core';

import { GeojsonInfoTemplate, Indicator } from 'src/app/models/Indicator';
import { Territory } from 'src/app/models/TerritoryTypes';

import { DataService } from 'src/app/services/DataService';
import { EventService } from 'src/app/services/event.service';
import { FilterDataService } from 'src/app/services/FilterDataService';
import { RestService } from 'src/app/services/RestService';
import { TerService } from 'src/app/services/TerService';

@Injectable({
    providedIn: 'root',
})
export class PlotCustomTerritoryIndicatorService {
    public idKey = 'id_ter';

    private geojsonInfoTemplate: GeojsonInfoTemplate = {
        id: null,
        geojson: null,
        territoryScale: null,
        territoryIds: [],
        granularity: null,
    };

    private plotedIndicators: any;

    constructor(
        @Inject(DataService) private dataService: DataService,
        @Inject(EventService) private eventService: EventService,
        @Inject(FilterDataService) private filterDataService: FilterDataService,
        @Inject(RestService) private restService: RestService,
        @Inject(TerService) private terService: TerService,
    ) {}

    eventSelectionWrapper(plotedIndicators: any) {
        this.plotedIndicators = plotedIndicators;
        return this.selectCustomTerritory.bind(this);
    }

    selectCustomTerritory(e: any) {
        const layer = e.target;
        const feature = layer.feature;
        const indicatorId = feature.properties.id_indicateur;
        const indicatorPlot = this.plotedIndicators[indicatorId];
        const plugin = indicatorPlot.plugin;

        // // what does resSelectOnMap doing here ?
        // if (form.includes('_poste_')) {
        //     this.dataService.resSelectedOnMap = {
        //         id: feature.properties.vector_id,
        //         label: feature.properties.vector_id,
        //         typeId: form.split('_')[2],
        //     };
        //     //this.modalElectricLoadCurveService.open();
        // }

        const data = {
            indicatorId: indicatorId,
            id_ter: null,
            lib_ter: null,
            label: null,
        };

        if (feature.properties.id_ter) {
            const id = feature.properties.id_ter;
            const label = feature.properties.lib_ter ? feature.properties.lib_ter : id;
            const labelId = label == id ? `${label}` : `${label} (${id})`;

            data.id_ter = id;
            data.lib_ter = label;
            data.label = labelId;
        }

        if (plugin == 'atac') {
            this.eventService.emit('clickOnAtacElement', data);
        } else if (!plugin) {
            this.eventService.emit('clickOnTerritory', data);
        }

        // this.mapService.panMapBy(layer);
    }

    setGeojsonInfoId(indicatorPlot: Indicator) {
        if (!indicatorPlot.geojsonInfoId) {
            const vectorBase = indicatorPlot.vector_base;
            const year = indicatorPlot.crrsdc_ter_year_geo;
            const indicatorId = indicatorPlot.indicatorId;
            const territoryIds = this.terService.territories.map((t) => t.id);

            const idParts = [vectorBase, year, indicatorId, ...territoryIds.sort()];
            const geojsonInfoId = idParts.join('_');

            indicatorPlot.geojsonInfoId = geojsonInfoId;
        }

        return indicatorPlot.geojsonInfoId;
    }

    completeGeojson(indicatorPlot: any, geojson: any) {
        const geojsonDeepCopy = JSON.parse(JSON.stringify(geojson));
        // indicatorPlot.geojson = geojsonDeepCopy;
        return geojsonDeepCopy;
    }

    async getGeojson(
        indicatorPlot: any,
        geojsonsInfo: Array<any>,
        saveInGeojsonInfo: boolean = true,
    ) {
        try {
            const indicatorId = indicatorPlot.indicatorId;
            const year = indicatorPlot.crrsdc_ter_year_geo;

            const geojson = await this.requestGeojson(indicatorId, year);
            if (saveInGeojsonInfo) {
                this.setGeojsonInfo(indicatorPlot, geojson, geojsonsInfo);
            }
            return geojson;
        } catch (error) {
            console.error('Erorr getGeojson:', error);
            throw error;
        }
    }

    async requestGeojson(indicatorId: number, year: number) {
        try {
            const data = {
                scale_int: this.terService.territoryScale.typeId, // that one is for back auth checkout
                scale_filter: this.terService.territories.map((t) => `'${t.id}'`).join(', '), // that one is for back auth checkout
                year: year,
                scaleTypeId: this.terService.territoryScale.typeId,
                territoryIds: this.terService.territories.map((t: Territory) => t.id),
            };
            const geojson = await this.restService.getCustomTerritoryIndicatorGeojson(
                indicatorId,
                data,
            );
            return geojson;
        } catch (error) {
            console.error('Error requestGeojson', error);
            throw error;
        }
    }

    setGeojsonInfo(indicatorPlot: any, geojson: GeoJSON.FeatureCollection, geojsonsInfo: any) {
        const territoryScale = JSON.parse(JSON.stringify(this.terService.territoryScale));
        const territoryIds = this.terService.territories.map((t) => t.id);
        const granularity = indicatorPlot.granularity
            ? JSON.parse(JSON.stringify(indicatorPlot.granularity))
            : null;

        const geojsonInfo = { ...this.geojsonInfoTemplate };
        geojsonInfo.id = this.setGeojsonInfoId(indicatorPlot);
        geojsonInfo.geojson = JSON.parse(JSON.stringify(geojson));
        geojsonInfo.territoryScale = territoryScale;
        geojsonInfo.territoryIds = territoryIds;
        geojsonInfo.granularity = granularity;
        geojsonsInfo.push(geojsonInfo);
    }

    async getValues(indicatorPlot: any) {
        try {
            const indicatorId = indicatorPlot.indicatorId;
            const data = {
                scale_int: this.terService.territoryScale.typeId, // that one is for back auth checkout
                scale_filter: this.terService.territories.map((t) => `'${t.id}'`).join(', '), // that one is for back auth checkout
                year: indicatorPlot.crrsdc_ter_year_geo,
                scaleTypeId: this.terService.territoryScale.typeId,
                territoryIds: this.terService.territories.map((territory) => territory.id),
                filters: this.filterDataService.createFiltersArray(indicatorId),
            };
            indicatorPlot.dataToPlot = await this.restService.getCustomTerritoryIndicatorValues(
                indicatorId,
                data,
            );
            return;
        } catch (error) {
            console.error('Error getValues', error);
            throw error;
        }
    }
}
