'use strict';

import { Inject, Injectable } from '@angular/core';
import * as d3 from 'd3';

import { Indicator } from 'src/app/models/Indicator';

import { UsefulService } from 'src/app/services/UsefulService';
import { StatsService } from 'src/app/services/StatsService';

@Injectable({
    providedIn: 'root',
})
export class NaturalBreaksDistribution {
    public values: Array<number>;
    public valueScale: any;
    public intervals: Array<number> = [];
    public legendBoundaries: number[][];

    public id = 'naturalBreaks';
    public label = 'Seuils naturels';

    constructor(
        @Inject(StatsService) private statsService: StatsService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    setValues(values: Array<number>) {
        this.values = values;
    }

    setClassCount(indicatorPlot: Indicator) {
        if (indicatorPlot.form == 'chart') {
            indicatorPlot.classCount = Object.keys(JSON.parse(indicatorPlot.chart_divider)).length;
        } else {
            indicatorPlot.classCount = Math.min(this.values.length, indicatorPlot.classCount);
        }
        indicatorPlot.classCountsAvailable = [2, 3, 4, 5, 6, 7, 8, 9];
    }

    setScale(indicatorPlot: Indicator) {
        // const decimalCount = indicatorPlot.decimalCount;
        // let values = this.values.map((value) => this.usefulService.round(value, decimalCount));
        let values = this.values;
        if (indicatorPlot.separate_zero_in_lgd) {
            values = values.filter((value) => value != 0);
        }

        const domain = this.statsService.jenks(values, indicatorPlot.classCount);
        const range = d3.range(indicatorPlot.classCount + 1);
        //  If the number of values in the scale’s range is n + 1, the number of values in the scale’s domain must be n
        // https://d3js.org/d3-scale/threshold
        this.valueScale = d3.scaleThreshold().domain(domain).range(range);
    }

    setIntervals(indicatorPlot: Indicator) {
        const decimalCount = indicatorPlot.decimalCount;

        this.legendBoundaries = [];
        const step = 1 / Math.pow(10, decimalCount);

        // first boundaries are set for -infinity to first value
        // last boundaries are set for last value to +infinity
        // so we skip those ones, the loop start to 1
        for (let i = 1; i < indicatorPlot.classCount + 1; i++) {
            const limits = [...this.valueScale.invertExtent(i)];

            let boundaryInf: number;
            let boundarySup: number;

            if (i == 1) {
                boundaryInf = this.usefulService.floor(limits[0], decimalCount);
                boundarySup = this.usefulService.round(limits[1], decimalCount);
            } else if (i == indicatorPlot.classCount) {
                const valueInf = this.usefulService.round(limits[0], decimalCount) + step;
                boundaryInf = this.usefulService.round(valueInf, decimalCount); // rounding to avoid precision error;
                boundarySup = this.usefulService.ceil(limits[1], decimalCount);
            } else {
                const valueInf = this.usefulService.round(limits[0], decimalCount) + step;
                boundaryInf = this.usefulService.round(valueInf, decimalCount); // rounding to avoid precision error;
                boundarySup = this.usefulService.round(limits[1], decimalCount);
            }
            const boundaries = [boundaryInf, boundarySup];
            this.legendBoundaries.push(boundaries);
        }
        return this.legendBoundaries;
    }
}
