'use strict';

import 'simpleheat';
import * as L from 'leaflet';
import { PlotFactory } from './PlotFactory';
import { MapService } from '../services/map.service';

export class PlotHeatMapFactory extends PlotFactory {
    constructor(indicatorId: number, mapService: MapService) {
        super(indicatorId, mapService);
        this.form = 'heatmap';
        this.indicatorfillopacity = 0.8;
        this.indicatorcontourweight = 1;
        this.indicatorcontouropacity = 0.8;
        this.default_radius_weight = 0.5;
    }

    addLayer(map: L.Map) {
        if (this.geojsonLayer != null) {
            this.removeLayer(map);
        }

        const heatMapData = this.geojson.features.map((feature: any) => {
            const isPolygon = feature.geometry.type == 'Polygon';

            let lat: number;
            let lng: number;
            if (isPolygon) {
                lat = feature.geometry.coordinates[0][1];
                lng = feature.geometry.coordinates[0][0];
            } else {
                lat = feature.geometry.coordinates[1];
                lng = feature.geometry.coordinates[0];
            }

            const altitude = feature.properties.value || 0;
            return L.latLng(lat, lng, altitude);
        });

        const max = this.geojson.features.reduce(
            (max: number, feature: GeoJSON.Feature) =>
                (max = Math.max(max, feature.properties.value)),
            1,
        );

        this.geojsonLayer = (L as any).heatLayer(heatMapData, {
            maxZoom: 12,
            max: max,
            radius: this.default_radius_weight * 15,
            minOpacity: this.indicatorfillopacity,
            gradient: this.getGradient(),
        });

        map.addLayer(this.geojsonLayer);
    }

    getStyle(_feature: GeoJSON.Feature) {}

    unhighlightFeature(layer: any) {
        layer.setStyle({
            weight: 1,
        });
    }

    getGradient() {
        const gradient = {};
        this.tableCouleurs.forEach((color, i) => {
            gradient[i / (this.tableCouleurs.length - 1)] = color;
        });
        return gradient;
    }
}
