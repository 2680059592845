('use strict');

import { Inject, Injectable } from '@angular/core';

import { PvTotalityResult } from 'src/app/models/Solar';

import { SolarService } from './cadastre-solaire.service';
import { RestService } from '../RestService';
import { TerService } from '../TerService';
import { UsefulService } from '../UsefulService';

@Injectable({
    providedIn: 'root',
})
export class SolarPvTotalityService {
    public selectedElements: any[];
    private moduleArea: number;
    private powerPeakByArea: number;
    private sellingTariffByPpeak: { [ppeak: string]: number };
    public housingConsumption: number;
    public totalGrossRoofArea: number;
    public totalModuleCount: number;
    public totalModuleArea: number;
    public totalPowerPeak: number;
    public investment: number;
    public totalProduction: number;
    public sellingGain: number;
    public roi: number;
    public connectionCost: any;
    public subscribedPower: number;

    public pvInfo: any;
    public pvTotalityResults: PvTotalityResult[];
    public currentResult: PvTotalityResult;

    constructor(
        @Inject(RestService) private restService: RestService,
        @Inject(SolarService) private solarService: SolarService,
        @Inject(UsefulService) private usefulService: UsefulService,
        @Inject(TerService) private terService: TerService,
    ) {}

    setPvInfo(pvInfo: any) {
        this.pvInfo = pvInfo;
        this.moduleArea = pvInfo.parameter.surface_module;
    }

    setResults(pvTotalityResults: PvTotalityResult[]) {
        this.pvTotalityResults = pvTotalityResults;
    }

    init(selectedElements: any[], pvModuleArea?: number) {
        this.selectedElements = selectedElements;
        this.moduleArea = pvModuleArea ? pvModuleArea : this.moduleArea;
    }

    setCurrentResult(result: PvTotalityResult) {
        this.currentResult = result;
        this.investment = result.investment;
        this.totalModuleCount = result.moduleCount;
        this.totalModuleArea = result.totalModuleArea;
        this.totalPowerPeak = result.peakPower;
        this.totalProduction = result.annualProduction;
        this.sellingGain = result.sellingGain;
        this.roi = result.roi;
        this.connectionCost = result.connectionCost;
    }

    getMaxModuleResult(): PvTotalityResult {
        return this.pvTotalityResults[this.pvTotalityResults.length - 1];
    }

    findResultByModuleCount(moduleCount: number) {
        return this.pvTotalityResults.find((result) => result.moduleCount == moduleCount);
    }

    setSubscribedPower() {
        // const availableSubscribedPowers = [3, 6, 9];
        // const subscribedPower = availableSubscribedPowers.find(
        //     (power) => power >= Math.min(this.totalPowerPeak, 9),
        // );
        this.subscribedPower = Math.min(this.totalPowerPeak, 9);
        return this.subscribedPower;
    }

    calcByElement(totalPanelCount?: number) {
        this.setModuleCountByElement(totalPanelCount);
        this.setModuleAreaByElement();
    }

    calcTotal(totalModuleCount?: number, totalModuleArea?: number, totalPowerPeak?: number) {
        this.setTotalGrossRoofArea();
        this.setTotalModuleCount(totalModuleCount);
        this.setTotalModuleArea(totalModuleArea);
        // this.setTotalPowerPeak(totalPowerPeak);
        // this.setTotalInvestment();
        // this.setTotalProduction();
        // this.setTotalSellingGain();
        // this.setTotalRoi();
    }

    setModuleCountByElement(totalPanelCount?: number) {
        if (totalPanelCount !== undefined) {
            this.selectedElements.reduce((totalPanelCount, element) => {
                const pvModuleCount = element.pvModuleCount;
                const moduleCount = Math.min(pvModuleCount, totalPanelCount);
                element.pvModuleCount = moduleCount;
                return Math.max(0, totalPanelCount - moduleCount);
            }, totalPanelCount);
        } else {
            this.selectedElements.forEach(
                (element) =>
                    (element.pvModuleCount = this.usefulService.floor(
                        element.usableRoofArea / this.moduleArea,
                    )),
            );
        }
    }

    setModuleAreaByElement() {
        this.selectedElements.forEach((element) => {
            element.pvRoofModuleArea = element.pvModuleCount * this.moduleArea;
        });
    }

    // setProductionByElement() {
    //     this.selectedElements.forEach((element) => {
    //         element.pvProduction = element.productible_pv_us * element.pvRoofModuleArea;
    //     });
    // }

    // setPpeakByElement() {
    //     this.selectedElements.forEach((element) => {
    //         element.pvPowerPeak = element.pvRoofModuleArea * this.powerPeakByArea;
    //     });
    // }

    setTotalGrossRoofArea() {
        this.totalGrossRoofArea = this.selectedElements.reduce(
            (prev, element) => prev + element.surface_disponible / (1 - element.coeff_encombr),
            0,
        );
    }

    setTotalModuleCount(totalModuleCount?: number) {
        if (totalModuleCount !== undefined) {
            this.totalModuleCount = totalModuleCount;
        } else {
            const moduleCountByElement = this.selectedElements.map(
                (element) => element.pvModuleCount,
            );
            this.totalModuleCount = moduleCountByElement.reduce((prev, curr) => prev + curr, 0);
        }
    }

    setTotalModuleArea(totalModuleArea?: number) {
        if (totalModuleArea !== undefined) {
            this.totalModuleArea = totalModuleArea;
        } else {
            this.totalModuleArea = this.selectedElements
                .map((element) => element.pvRoofModuleArea)
                .reduce((sum, moduleArea) => sum + moduleArea, 0);
        }
    }

    // setTotalPowerPeak(totalPowerPeak?: number) {
    //     if (totalPowerPeak !== undefined) {
    //         this.totalPowerPeak = totalPowerPeak;
    //     } else {
    //         this.totalPowerPeak = this.powerPeakByArea * this.totalModuleArea;
    //     }
    // }

    // setTotalInvestment() {
    //     const costByPpeak = this.solarService.getLinearInterpolatedValue(
    //         this.moduleCostByPpeak,
    //         this.totalPowerPeak,
    //     );
    //     this.investment = this.totalPowerPeak * costByPpeak;
    // }

    // setTotalProduction() {
    //     const productionByElement = this.selectedElements.map((element) => element.pvProduction);
    //     this.totalProduction = productionByElement.reduce((prev, curr) => prev + curr, 0);
    // }

    // setTotalHousingConsumptionRatio() {
    //     this.housingConsumptionRatio = this.totalProduction / this.housingConsumption;
    // }

    setTotalSellingGain() {
        const threshold = this.solarService.getFirstGreater(
            this.sellingTariffByPpeak,
            this.totalPowerPeak,
        );
        const sellingTariffByEnergy = this.sellingTariffByPpeak[threshold];
        this.sellingGain = this.totalProduction * sellingTariffByEnergy;
    }

    setTotalRoi() {
        this.roi = 0;
        if (this.sellingGain != 0) {
            this.roi = this.investment / this.sellingGain;
        }
    }

    addConnectionCost(connectionCost: any) {
        // this.connectionCost = connectionCost;
        // if (this.connectionCost) {
        //     this.investment += connectionCost;
        //     this.setTotalRoi();
        // }
        const currentResult = this.currentResult;
        const sellingGain = currentResult.sellingGain;
        const initInvestment = currentResult.investment;
        currentResult.connectionCost = connectionCost;
        this.connectionCost = connectionCost;

        if (connectionCost.value) {
            currentResult.investment = initInvestment + connectionCost.value;
            currentResult.roi = currentResult.investment / sellingGain;

            this.investment = currentResult.investment;
            this.roi = currentResult.roi;
        }
    }

    setTotalModuleAreaByTotalPowerPeak(totalPowerPeak: number) {
        this.totalPowerPeak = totalPowerPeak;
        this.totalModuleArea = this.totalPowerPeak / this.powerPeakByArea;
        this.totalModuleCount = this.totalModuleArea / this.moduleArea;
    }

    updateByTotalModuleCount(totalModuleCount: number) {
        this.totalModuleCount = totalModuleCount;
        this.totalModuleArea = this.totalModuleCount * this.moduleArea;
        this.totalPowerPeak = this.totalModuleArea * this.powerPeakByArea;
    }

    async simulatePvTotality(): Promise<PvTotalityResult[]> {
        const elements = this.solarService.selectedElements.map((element) => ({
            latitude: element.center.latitude,
            longitude: element.center.longitude,
            height: element.height,
            tilt: element.tilt,
            azimuth: element.azimuth,
            usableRoofArea: element.usableRoofArea,
            exists: !element.isNew,
            installationType: element.installationType,
        }));
        const data = {
            elements: elements,
            year: this.terService.geoYear,
            scaleTypeId: this.terService.territoryScale.typeId,
            territoryIds: this.terService.territories.map((t) => t.id),
        };
        this.pvTotalityResults = await this.restService.simulatePvTotality(data);
        return this.pvTotalityResults;
    }

    // getCsvSchema() {
    //     return JSON.parse(JSON.stringify(this.sellCsvSchemaTmp));
    // }

    // getCsvData() {
    //     return {
    //         infoDetail: this.pvInfo.message.info_detail,
    //         invesmentDetail: this.pvInfo.message.investissement_detail,
    //         productionDetail: this.pvInfo.message.production_detail,
    //         economyDetail: this.pvInfo.message.economie_detail,
    //         totalGrossRoofArea: Math.round(this.totalGrossRoofArea),
    //         totalModuleCount: this.totalModuleCount,
    //         totalModuleArea: Math.round(this.totalModuleArea),
    //         totalPowerPeak: this.usefulService.ceil(this.totalPowerPeak, 1),
    //         investment: Math.round(this.investment),
    //         totalProduction: Math.round(this.totalProduction),
    //         sellingGain: Math.round(this.sellingGain),
    //         roi: Math.round(this.roi),
    //     };
    // }
}
