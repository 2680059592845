'use strict';

import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren,
    PipeTransform,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ReversePipe } from 'ngx-pipes';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DashboardService } from 'src/app/services/dashboard.service';
import { NgbdSortableHeader, SortEvent } from 'src/app/components/st-table/sortable.directive';
import { StTableService } from 'src/app/components/st-table/st-table.service';

function matches(row: any, term: string, pipe: PipeTransform) {
    const lowerCaseTerm = term.toLowerCase();
    return (
        row.label.toLowerCase().includes(lowerCaseTerm) ||
        row.stringifiedValue.toLowerCase().includes(lowerCaseTerm)
    );
}

@UntilDestroy()
@Component({
    selector: 'territory-table',
    templateUrl: './territory-table.template.html',
    styleUrls: [
        '../../../../../../../../components/st-table/st-table.component.scss',
        './territory-table.component.scss',
    ],
    providers: [ReversePipe, StTableService],
})
export class DashboardTerritoryTableComponent implements AfterViewInit, OnDestroy, OnInit {
    @Input() dashboard: any;

    private destroy$ = new Subject<void>();
    territoryRows$: Observable<any[]>;
    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

    constructor(
        public territoryTableService: StTableService,
        @Inject(DashboardService) public dashboardService: DashboardService,
    ) {
        this.territoryTableService.isPaginationDefined = false;
        this.territoryRows$ = this.territoryTableService.rows$;
    }

    ngOnInit(): void {
        this.territoryTableService.matches = matches;

        this.dashboardService.territoryDashboard$
            .pipe(takeUntil(this.destroy$))
            .subscribe(async (dashboard) => {
                if (dashboard) {
                    const indicatorId = dashboard.indicatorId;

                    if (indicatorId == this.dashboard.indicatorId) {
                        this.dashboard = dashboard;
                        this.territoryTableService.setRows(this.dashboard.table.rows);

                        const territoryId = this.dashboard.selectedTerritory.id_ter;
                        if (territoryId) {
                            setTimeout(
                                () =>
                                    document
                                        .getElementById(`db-${territoryId}-${indicatorId}`)
                                        .scrollIntoView({
                                            behavior: 'smooth',
                                            block: 'start',
                                            inline: 'nearest',
                                        }),
                                200,
                            );
                        }
                    }
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngAfterViewInit(): void {
        this.territoryTableService.setRows(this.dashboard.table.rows);
    }

    onSort({ column, direction }: SortEvent) {
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        this.territoryTableService.sortColumn = column;
        this.territoryTableService.sortDirection = direction;
    }
}
