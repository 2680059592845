<button
    type="button"
    class="btn {{ btnClass }}"
    [disabled]="isDisabled || null"
    (click)="click()"
    [ngClass]="{'st-collapse-button': isCollapseButton, 'is-collapsed': isCollapsed}"
    [attr.data-st-theme]="theme"
>
    <i class="{{ iconFaLeft }}" *ngIf="iconFaLeft"></i>
    <i class="{{ iconGlyphLeft }}" *ngIf="iconGlyphLeft"></i>
    <span>{{ btnLabel }}</span>
    <i class="{{ iconFaRight }}" *ngIf="iconFaRight"></i>
    <i class="{{ iconGlyphRight }}" *ngIf="iconGlyphRight"></i>
</button>
