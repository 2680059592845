<div class="d-flex flex-column h-100">
    <div class="container-fluid p-3">
        <div class="card st-card">
            <div class="card-body container-fluid">
                <div
                    class="row d-flex align-items-center gx-2"
                    *ngIf="!!selectedDashboard.indicator"
                >
                    <div class="col-auto">Indicateur sélectionné :</div>
                    <div ngbDropdown class="col">
                        <button
                            ngbDropdownToggle
                            type="button"
                            class="btn btn-st-default w-100 text-wrap"
                            id="dashboard-selected-indicator"
                        >
                            {{ selectedDashboard.indicator.libelle_indic_complet }}
                        </button>
                        <div ngbDropdownMenu class="w-100 text-center">
                            <ng-container *ngFor="let data of dashboards">
                                <button
                                    ngbDropdownItem
                                    class="text-wrap"
                                    *ngIf="!!data.indicatorId"
                                    (click)="changeSelectedIndicator(data.indicatorId);"
                                >
                                    <small> {{ data.indicator.libelle_indic_complet }} </small>
                                </button>
                            </ng-container>
                        </div>
                    </div>
                    <stButton
                        class="col-auto"
                        btnClass="btn-st-default"
                        iconFaRight="fa fa-download"
                        (onClick)="downloadTableCsv()"
                        ngbTooltip="Export des données"
                        placement="auto"
                        container="body"
                    >
                    </stButton>
                </div>
            </div>
        </div>

        <div class="card st-card">
            <div class="card-body container-fluid">
                <div class="row d-flex align-items-center gx-2">
                    <div class="col-auto control-label py-0 me-2">
                        <ng-container *ngIf="!!selectedDashboard.selectedTerritory.label">
                            Territoire sélectionné :
                        </ng-container>
                        <ng-container *ngIf="!selectedDashboard.selectedTerritory.label">
                            <ng-container *ngIf="perimeterLabels.length == 1">
                                Territoire sélectionné :
                            </ng-container>
                            <ng-container *ngIf="perimeterLabels.length > 1">
                                Territoires sélectionnés :
                            </ng-container>
                        </ng-container>
                    </div>
                    <div class="col">
                        <ng-container *ngIf="!selectedDashboard.selectedTerritory.label">
                            <div class="container-fluid px-0">
                                <div class="row">
                                    <div
                                        class="col-6"
                                        [ngClass]="{'col-auto': perimeterLabels.length == 1, 'col-6': perimeterLabels.length > 1}"
                                        *ngFor="let label of perimeterLabels"
                                    >
                                        {{ label }}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <button
                            *ngIf="!!selectedDashboard.selectedTerritory.label"
                            class="btn btn-st-default btn-close-icon"
                            (click)="deselectTerritory()"
                        >
                            {{ selectedDashboard.selectedTerritory.label }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="overflow-auto">
        <ng-container *ngFor="let dashboard of dashboards">
            <ng-container *ngIf="dashboard.table.isTerritory">
                <territory-table
                    [dashboard]="dashboard"
                    [hidden]="dashboard.indicatorId != selectedDashboard.indicatorId"
                ></territory-table>
            </ng-container>
            <ng-container *ngIf="!dashboard.table.isTerritory">
                <building-custom-table
                    [dashboard]="dashboard"
                    [hidden]="dashboard.indicatorId != selectedDashboard.indicatorId"
                ></building-custom-table>
            </ng-container>
        </ng-container>
    </div>
</div>
