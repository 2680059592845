import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'info-contact',
    templateUrl: './contact-modal.template.html',
})
export class ContactModalComponent {
    constructor(public activeModal: NgbActiveModal) {}
}
