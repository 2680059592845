('use strict');

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    private _dictFR = {
        housenumber: 'numéro',
        locality: 'lieu-dit',
        municipality: 'commune',
        street: 'rue',
    };
    constructor() {}

    toFR(word: string) {
        if (Object.keys(this._dictFR).includes(word)) {
            return this._dictFR[word];
        }
        return word;
    }
}
