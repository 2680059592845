<stTab
    class="catalog"
    label="Catalogue d'indicateurs"
    [visible]="moduleService.isModuleActive.catalog"
>
    <div class="d-flex flex-column h-100">
        <div class="text-center text-st-active p-5" *ngIf="!selectedTheme">
            <i class="fa fa-4x fa-spinner fa-spin"></i>
        </div>

        <div class="p-3" *ngIf="isModuleDisplayed && indicatorsByTheme.length > 0">
            <stInputSearch
                class="dropdown d-block"
                innerClass="border-07"
                innerId="search-module"
                label="libelle"
                [placeholder]="selectedTheme.libelle"
                [options]="indicatorsByTheme"
                (onSelect)="openTheme($event)"
            >
            </stInputSearch>
        </div>

        <div
            class="flex-grow-1 indicator-section p-3"
            *ngIf="selectedTheme"
            [ngClass]="{'pt-0': !!isModuleDisplayed}"
        >
            <stAccordion
                innerId="catalog-{{ selectedTheme.label }}"
                [items]="selectedTheme.ss_theme"
            >
                <ng-template #contentTemplate let-subTheme>
                    <ng-container
                        *ngFor="let indicator of subTheme.indicators | orderByImpure : 'ordre'"
                    >
                        <div class="d-flex align-items-center indicator">
                            <stCheckbox
                                class="py-05 w-75 pe-2"
                                [innerId]="indicator.id_indicateur"
                                [isDisabled]="!indicator.isAvailable"
                                [(ngModel)]="indicator.active"
                                (ngModelChange)="toggleFlatIndicator(indicator)"
                            >
                                <span
                                    *ngIf="indicator.isAvailable"
                                    class="fw-light cursor-pointer me-2"
                                >
                                    {{ indicator.libelle_indic_short }}
                                </span>
                                <ng-container *ngIf="!indicator.isAvailable">
                                    <ng-template #availableTipContent
                                        >{{ indicator.availableTooltip }}</ng-template
                                    >
                                    <span
                                        class="fw-light cursor-pointer me-2"
                                        [ngbTooltip]="availableTipContent"
                                        placement="top"
                                        container="body"
                                    >
                                        {{ indicator.libelle_indic_short }}
                                    </span>
                                </ng-container>

                                <div class="d-inline-block">
                                    <span class="icon-info">
                                        <ng-template #popoverContent>
                                            <div [innerHTML]="indicator.popoverInfo"></div>
                                        </ng-template>
                                        <i
                                            class="fa fa-info-circle fs-body"
                                            [ngbPopover]="popoverContent"
                                            triggers="mouseenter:mouseleave"
                                            [placement]="['right', 'right-top', 'right-bottom']"
                                            container="body"
                                        ></i>
                                    </span>
                                </div>
                            </stCheckbox>
                            <div
                                class="btn-group granularity w-25"
                                *ngIf="indicator.isAvailable && !indicator.isCustomTerritory"
                            >
                                <div ngbDropdown container="body" class="d-inline-block w-100">
                                    <button
                                        ngbDropdownToggle
                                        type="button"
                                        [disabled]="indicator.availableGranularities.length == 1"
                                        class="btn btn-sm btn-st-default w-100"
                                    >
                                        {{ indicator.granularity.labelLong }}
                                    </button>
                                    <div ngbDropdownMenu>
                                        <ng-container
                                            *ngFor="let granularity of indicator.availableGranularities"
                                        >
                                            <button
                                                ngbDropdownItem
                                                (click)="selectGranularity(indicator, granularity)"
                                            >
                                                {{ granularity.labelLong }}
                                            </button>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-template>
            </stAccordion>
        </div>
    </div>
</stTab>
