<div class="d-flex flex-column h-100">
    <div class="flex-grow-1 overflow-auto">
        <div class="w-100 p-3">
            <div class="header mb-3">
                <div class="container-fluid">
                    <div class="row d-flex align-items-center solar-card">
                        <div class="col-auto px-0 text-center">
                            <span class="fa-stack">
                                <i class="fa fa-circle-thin fa-stack-2x"></i>
                                <i class="fa fa-info fa-stack-1x"></i>
                            </span>
                        </div>
                        <div class="col">
                            <p>
                                L'électricité produite par vos panneaux photovoltaïques peut être
                                utilisée de deux manières différentes.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="body">
                <h5 class="text-st-primary">Je choisis un mode de valorisation</h5>

                <div class="st-pills">
                    <ul ngbNav #nav="ngbNav" [activeId]="activeTabId">
                        <li [ngbNavItem]="1">
                            <a
                                ngbNavLink
                                (click)="setValorization('Totality')"
                                class="h-100 d-flex align-items-center justify-content-center"
                                >Vente totale</a
                            >
                            <ng-template ngbNavContent>
                                <div class="solar-card p-3">
                                    <p>
                                        En vente totale, vous revendez l'intégralité de votre
                                        production d'électricité solaire à votre fournisseur
                                        d'électricité.
                                    </p>
                                    <p>
                                        Le tarif de revente est fixé par arrêté au moment de la
                                        signature du projet. Votre fournisseur d'électricité
                                        s'engage à racheter votre production à prix fixe pendant 20
                                        ans. Ainsi, vous pourrez connaître précisément le temps de
                                        retour sur investissement.
                                    </p>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a
                                ngbNavLink
                                (click)="setValorization('Surplus')"
                                class="h-100 d-flex flex-column align-items-center justify-content-center"
                            >
                                Autoconsommation
                                <small>avec revente du surplus</small>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="solar-card">
                                    <p>
                                        Avec l'autoconsommation les appareils électriques de votre
                                        foyer consomment l'électricité produite par vos panneaux
                                        solaires. L'excédent de production est revendu à votre
                                        fournisseur d'électricité. Vous pourrez ainsi maximiser
                                        l'utilisation de l'énergie solaire produite localement,
                                        réduire vos factures d'électricité et même générer des
                                        revenus supplémentaires grâce à la vente du surplus.
                                    </p>
                                    <p>
                                        Votre fournisseur d'électricité s'engage à racheter le
                                        surplus de production à un prix fixe sur 20 ans.
                                    </p>
                                    <p>
                                        Les économies générées par l'autoconsommation de votre
                                        production solaire dépendent du prix d'achat de votre
                                        électricité, qui peut varier dans le temps.
                                    </p>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                </div>
                <div [ngbNavOutlet]="nav"></div>
            </div>
        </div>
    </div>

    <div class="footer border-top">
        <div class="container-fluid py-3">
            <div class="row">
                <div class="col-5">
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-default w-100 h-100"
                        btnLabel="Retour"
                        (onClick)="cancel()"
                    >
                    </stButton>
                </div>
                <div class="col-7">
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-primary w-100 h-100"
                        btnLabel="Sélectionner cette valorisation"
                        (click)="validate()"
                    >
                    </stButton>
                </div>
            </div>
        </div>
    </div>
</div>
