('use strict');

import { Inject, Injectable } from '@angular/core';

import { ThermalResult } from 'src/app/models/Solar';

import { SolarService } from './cadastre-solaire.service';
import { RestService } from '../RestService';
import { TerService } from '../TerService';

@Injectable({
    providedIn: 'root',
})
export class SolarThermalService {
    public selectedElements: any[];
    public moduleCount: number;
    public maxModuleCount: number;
    public thModuleCountOptions: number[];
    public investment: number;
    public totalModuleCount: number;
    public totalModuleArea: number;
    public totalProduction: number;
    public totalShowerCount: number;
    public totalPersonCount: number;
    public totalSaving: number;
    public totalGrossRoofArea: number;
    public roi: number;

    public thInfo: any;
    public thermalResults: ThermalResult[];
    public currentResult: ThermalResult;

    constructor(
        @Inject(RestService) private restService: RestService,
        @Inject(SolarService) private solarService: SolarService,
        @Inject(TerService) private terService: TerService,
    ) {}

    setThInfo(thInfo: any) {
        this.thInfo = thInfo;
    }

    async simulateThermal(): Promise<ThermalResult[]> {
        const elements = this.solarService.selectedElements.map((element) => ({
            latitude: element.center.latitude,
            longitude: element.center.longitude,
            height: element.height,
            tilt: element.tilt,
            azimuth: element.azimuth,
            usableRoofArea: element.usableRoofArea,
            exists: !element.isNew,
            installationType: element.installationType,
        }));
        const data = {
            elements: elements,
            year: this.terService.geoYear,
            scaleTypeId: this.terService.territoryScale.typeId,
            territoryIds: this.terService.territories.map((t) => t.id),
        };
        this.thermalResults = await this.restService.simulateThermal(data);
        return this.thermalResults;
    }

    setCurrentResult(result: ThermalResult) {
        this.currentResult = result;
        this.investment = result.investment;
        this.totalModuleCount = result.moduleCount;
        this.totalModuleArea = result.totalModuleArea;
        this.totalProduction = result.annualProduction;
        this.totalShowerCount = result.showerCount;
        this.totalPersonCount = result.personCount;
        this.totalSaving = result.saving;
        this.roi = result.roi;
    }

    getMinModuleResult(): ThermalResult {
        return this.thermalResults.find((result) => result.moduleCount == 1);
    }

    getMaxModuleResult(): ThermalResult {
        return this.thermalResults
            .slice(1)
            .reduce(
                (maxResult: ThermalResult, result: ThermalResult) =>
                    maxResult.moduleCount > result.moduleCount ? maxResult : result,
                this.thermalResults[0],
            );
    }

    findResultByModuleCount(moduleCount: number) {
        return this.thermalResults.find((result) => result.moduleCount == moduleCount);
    }
}
