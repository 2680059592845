import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MapService } from 'src/app/services/map.service';
import { ModuleService } from 'src/app/services/module.service';
import { SolarService } from 'src/app/services/cadastre-solaire/cadastre-solaire.service';

@UntilDestroy()
@Component({
    selector: 'cadastre-solaire-cta-mobile',
    templateUrl: './cadastre-solaire-cta-mobile.template.html',
    styleUrls: ['./cadastre-solaire-cta-mobile.component.scss'],
})
export class CadastreSolaireCtaMobileComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();

    public isShown: boolean = false;

    constructor(
        @Inject(MapService) private mapService: MapService,
        @Inject(ModuleService) private moduleService: ModuleService,
        @Inject(SolarService) private solarService: SolarService,
    ) {}

    ngOnInit(): void {
        this.mapService.map$.pipe(takeUntil(this.destroy$)).subscribe((map) => {
            if (map) {
                this._initControl();
            }
        });

        this.solarService.mainStageObs$.pipe(takeUntil(this.destroy$)).subscribe((mainStage) => {
            this.isShown = !!mainStage;
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private _initControl() {
        this.solarService.initCtaMobileControl();
        this.solarService.addCtaMobileControlToMap();
    }

    public toggleSolarModule() {
        this.moduleService.toggleModule('solar');
    }
}
