('use strict');

import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ReversePipe } from 'ngx-pipes';

import { Indicator } from 'src/app/models/Indicator';
import { TerritoryLabel, TerritoryScale, Territory } from 'src/app/models/TerritoryTypes';
import { SubTheme, Theme } from 'src/app/models/Theme';

import { AuthService } from 'src/app/services/AuthService';
import { DataService } from 'src/app/services/DataService';
import { RestService } from 'src/app/services/RestService';
import { TerService } from 'src/app/services/TerService';

@Component({
    selector: 'groupAdd',
    templateUrl: './group-add.template.html',
    providers: [ReversePipe],
})
export class GroupAddComponent implements OnInit {
    public selectedGranularity: any['type'] = [];
    public selectedTerritories = [];
    public group: {
        logo: any;
        name: any;
        endingDateValidity: any;
        formatedDate: string;
        name_site: any;
    };
    public selectedTerritoryScale: any['type'] = [];
    public selectedIndicatorIds = [];
    public territory = null;
    public territoryScales = [];
    public granularities = [];
    public territories = [];
    public indicatorsByTheme = [];
    public territoryToAdd = null;
    public _nomFG = null;
    public indicator = null;

    private groupTemplate = {
        adminId: this.authService.user.id,
        displayedName: '',
        endingDateValidity: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        formatedDate: '',
        logo: '',
        name: '',
        name_site: '',
    };
    private territoryTemplate = {
        territoryScale: null,
        territories: [],
        granularity: null,
    };

    constructor(
        private notification: ToastrService,
        private ref: ChangeDetectorRef,
        private router: Router,
        @Inject(AuthService) private authService: AuthService,
        @Inject(DataService) private dataService: DataService,
        @Inject(RestService) private restService: RestService,
        @Inject(TerService) private terService: TerService,
    ) {}

    ngOnInit(): void {
        this.initialization();
    }

    async initialization() {
        this.group = { ...this.groupTemplate };
        this.convertDateToDateString();

        this.territory = { ...this.territoryTemplate };
        this.selectedIndicatorIds = [];

        this.initIndicatorRight();

        if (!this.terService.territoryScales) {
            await this.terService.initTerritory(false, false);
        }
        this.initTerritory();
    }

    convertDateToDateString() {
        const date = this.group.endingDateValidity;
        const year = date.getFullYear();
        const monthNumber = date.getMonth() + 1;
        const month = monthNumber < 10 ? `0${monthNumber + 1}` : monthNumber + 1;

        const day = date.getDate();
        this.group.formatedDate = `${year}-${month}-${day}`;
    }

    convertDateStringToDate() {
        this.group.endingDateValidity = new Date(this.group.formatedDate);
    }

    async initIndicatorRight() {
        try {
            this.indicatorsByTheme = await this.dataService.getIndicatorLibraryByTheme();

            this.indicatorsByTheme.forEach((theme: Theme) => {
                theme.open = false;

                theme.ss_theme.forEach((subTheme: SubTheme) => {
                    subTheme.open = false;

                    subTheme.indicators.forEach((indicator: Indicator) => {
                        indicator.active = false;

                        indicator.unitLabel = '';
                        if (indicator.unit && indicator.unit !== '%') {
                            indicator.unitLabel = `(${indicator.unit})`;
                        }
                    });
                });
            });

            // updateIndicatorState();
            this.updateSelectionState();
        } catch (error) {
            console.error('Error initIndicatorRight', error);
        }
    }

    initTerritory = () => {
        this.territoryScales = this.terService.territoryScales;
        this.granularities = this.territoryScales;

        this.selectedTerritoryScale = null;
        this.selectedTerritories = [];
        this.selectedGranularity = null;

        this.territories = [];

        this.territoryScales.forEach((territoryScale: TerritoryScale) => {
            territoryScale.toShow =
                !['ter_0', 'ter_1'].includes(territoryScale.type.substring(0, 5)) &&
                !!territoryScale.geom_poly;
        });

        if (this.terService.territoryScale) {
            this.selectedTerritoryScale = this.terService.territoryScale;
            this.updateTerritories();
        }

        if (this.terService.territories) {
            this.selectedTerritories = this.terService.territories.map((t: Territory) => {
                return {
                    id: t.id,
                    label: t.label,
                    labelId: `${t.label} (${t.id})`,
                };
            });
        }

        if (this.terService.granularity) {
            this.selectedGranularity = this.terService.granularity;
        }
    };

    getImagebase64 = (event: any) => {
        const f = event.target.files[0];
        const r = new FileReader();

        r.onloadend = (e) => {
            this.group.logo = e.target.result;
            // this.$apply();
            this.ref.detectChanges();
        };

        r.readAsDataURL(f);
    };

    selectTerritoryScale = () => {
        this.selectedTerritories = [];

        this.updateTerritories();
        this.updateGranularities();

        if (this.selectedGranularity) {
            let currentGranularityType = this.selectedGranularity.type;
            currentGranularityType =
                currentGranularityType[0] == '0'
                    ? currentGranularityType.slice(1)
                    : currentGranularityType;
            let newTerritoryScaleType = this.selectedTerritoryScale.type;
            newTerritoryScaleType =
                newTerritoryScaleType[0] == '0'
                    ? newTerritoryScaleType.slice(1)
                    : newTerritoryScaleType;

            if (newTerritoryScaleType < currentGranularityType) {
                this.selectedGranularity = null;
            }
        }

        // if ($scope.selectedGranularity) {
        //     const territoryScale = $scope.territoryScales.find(
        //         (territoryScale: TerritoryScale) =>
        //             territoryScale.type == $scope.selectedGranularity.type &&
        //             $scope.checkGranularityToShow(territoryScale),
        //     );
        //     if (!territoryScale) {
        //         $scope.selectedGranularity = null;
        //     }
        // }
    };

    removeTerritory = (index: number) => {
        this.selectedTerritories.splice(index, 1);
    };

    addTerritory = (territoryToAdd: TerritoryLabel) => {
        const alreadyAddedTerritory = this.selectedTerritories.find(
            (territory: any) => territory.id == territoryToAdd.id,
        );

        if (!alreadyAddedTerritory) {
            const id = territoryToAdd.id;
            const label = territoryToAdd.label;
            const newSelectedTerritory = {
                id: id,
                label: label,
                labelId: `${label} (${id})`,
            };
            this.selectedTerritories.push(newSelectedTerritory);
        }
    };

    selectAllIndicatorFromTheme = (theme: Theme) => {
        theme.ss_theme.forEach((subTheme: SubTheme) =>
            subTheme.indicators.forEach((indicator: Indicator) => {
                indicator.active = theme.full < 2;
                this.manageIndicator(indicator);
            }),
        );
        this.updateSelectionState();
    };

    selectAllIndicatorFromSubTheme = (subTheme: SubTheme) => {
        subTheme.indicators.forEach((indicator: Indicator) => {
            indicator.active = subTheme.full < 2;
            this.manageIndicator(indicator);
        });
        this.updateSelectionState();
    };

    manageIndicator = (indicator: any) => {
        const indicatorId = indicator.id_indicateur;
        if (this.selectedIndicatorIds.includes(indicatorId)) {
            const index = this.selectedIndicatorIds.indexOf(indicatorId);
            this.selectedIndicatorIds.splice(index, 1);
        } else {
            this.selectedIndicatorIds.push(indicatorId);
        }
    };

    async validate() {
        this.territory.territoryScale = this.selectedTerritoryScale;
        this.territory.territories = this.selectedTerritories;
        this.territory.granularity = this.selectedGranularity;

        this.convertDateStringToDate();

        const data = {
            group: this.group,
            territory: this.territory,
            indicatorIds: this.selectedIndicatorIds,
        };
        try {
            await this.restService.createGroup(data);
            const groupName = this.group.name;
            this.notification.success(`Le groupe "${groupName}" a été créé.`);
            this.router.navigate(['administration/groups/manage/']);
        } catch (error) {
            console.error('Error validate', error);
            this.notification.error('Une erreur est survenue, veuillez rééssayer.');
        }
    }

    redirectToAdminGroups = () => {
        this.router.navigate(['administration/groups/manage/']);
    };

    updateGranularities = () => {
        if (this.selectedTerritoryScale) {
            this.granularities = this.territoryScales.filter(
                (territoryScale: TerritoryScale) =>
                    territoryScale.ordre < this.selectedTerritoryScale.ordre &&
                    territoryScale.geom_poly,
            );
        } else {
            this.granularities = this.territoryScales.filter(
                (territoryScale: TerritoryScale) => territoryScale.geom_poly,
            );
        }
        if (!this.granularities.includes(this.selectedGranularity)) {
            this.selectedGranularity = this.granularities[0];
        }
    };

    async updateTerritories() {
        try {
            this.territories = await this.terService.getTerritoryLabelByYear(
                this.selectedTerritoryScale.year,
                this.selectedTerritoryScale.type,
                'ter_60',
            );
            if (this.territories.length == 1) {
                this.addTerritory(this.territories[0]);
            }
        } catch (error) {
            console.error('Error updateTerritories', error);
            throw error;
        }
    }

    updateSelectionState = () => {
        let p = 0;
        let d = 0;

        this.indicatorsByTheme.forEach((theme) => {
            d = 0;
            theme.ss_theme.forEach((subTheme) => {
                p = 0;
                p += subTheme.indicators.filter((indicator: Indicator) => indicator.active).length;

                if (p == subTheme.indicators.length) {
                    subTheme.full = 2;
                    d += 1;
                } else if (p > 0) {
                    subTheme.full = 1;
                    d += 0.5;
                } else {
                    subTheme.full = 0;
                }
            });

            if (d == theme.ss_theme.length) {
                theme.full = 2;
            } else if (d > 0) {
                theme.full = 1;
            } else {
                theme.full = 0;
            }
        });
    };
}
