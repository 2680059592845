import * as d3 from 'd3';
import { Injectable } from '@angular/core';

('use strict');

@Injectable({
    providedIn: 'root',
})
export class StatsService {
    standardDeviation(data: Array<number>) {
        const mean = d3.mean(data);
        const deviation = Math.pow(d3.variance(data), 0.5);

        let max = d3.max(data);
        let min = d3.min(data);

        if (min > mean - 1.5 * deviation) {
            if (max > mean + 1.5 * deviation) {
                min = mean - 1.5 * deviation - Math.abs(max - (mean + 1.5 * deviation));
            } else {
                max = mean + 2 * deviation;
                min = mean - 2 * deviation;
            }
        } else {
            if (max < mean + 1.5 * deviation) {
                max = mean + 1.5 * deviation + Math.abs(min - (mean + 1.5 * deviation));
            }
        }

        return [
            min,
            mean - 1.5 * deviation,
            mean - 0.5 * deviation,
            mean + 0.5 * deviation,
            mean + 1.5 * deviation,
            max,
        ];
    }

    nestedMeans(data: Array<number>, classesNb: number) {
        let kClasses = [data];
        let mean = 0;
        let kClassesTemp = [];

        let i = 0;
        let nb_loup = 0;
        while (Math.pow(2, i) < classesNb && nb_loup < 10) {
            kClasses.forEach((kClass, j) => {
                kClassesTemp[2 * j] = [];
                kClassesTemp[2 * j + 1] = [];

                mean = d3.mean(kClass);

                kClass.forEach((value) => {
                    if (value < mean) {
                        kClassesTemp[2 * j].push(value);
                    }
                    if (value >= mean) {
                        kClassesTemp[2 * j + 1].push(value);
                    }
                });
            });

            kClasses = kClassesTemp;
            kClassesTemp = [];
            i = i + 1;
            nb_loup = nb_loup + 1;
        }

        const rslt = kClasses.map((kClass) => d3.min(kClass));
        rslt.push(d3.max(kClasses[kClasses.length - 1]));
        return rslt;
    }

    //Auxiliary function to create a matrix line. First element of the line is always 0
    private initializeLine(size: number, value: number) {
        const array = new Array(size).fill(value);
        array[0] = 0;
        return array;
    }

    // Source : https://observablehq.com/@jdev42092/jenks-breaks-using-simple-statistics
    // The goal is to divide the data in <classesNb> groups, minimizing the variance within each group
    jenksMatrices(data: Array<number>, classesNb: number) {
        const lowerClassLimits = []; // optimal lower class limits
        const varianceCombinations = []; // optimal variance combinations for all classes
        let variance = 0;

        // Matrices Initialization
        for (let i = 0; i < data.length + 1; i++) {
            const size = classesNb + 1;
            const limitValue = i === 1 ? 1 : 0;
            const varianceValue = i < 2 ? 0 : Infinity;
            lowerClassLimits.push(this.initializeLine(size, limitValue));
            varianceCombinations.push(this.initializeLine(size, varianceValue));
        }

        // Then we compute the variance for every subset of data, and try to minimize it
        for (let l = 2; l < data.length + 1; l++) {
            let sum = 0;
            let squaresSum = 0;
            let subsetSize = 0;
            let prev = 0;

            for (let m = 1; m < l + 1; m++) {
                const lowerClassLimit = l - m + 1; //contenu entre l et 1
                const value = data[lowerClassLimit - 1];
                subsetSize++;

                sum += value;
                squaresSum += value * value;

                variance = squaresSum - (sum * sum) / subsetSize;

                prev = lowerClassLimit - 1;

                if (prev !== 0) {
                    for (let j = 2; j < classesNb + 1; j++) {
                        const varianceSum = variance + varianceCombinations[prev][j - 1];
                        if (varianceCombinations[l][j] >= varianceSum) {
                            lowerClassLimits[l][j] = lowerClassLimit;
                            varianceCombinations[l][j] = varianceSum;
                        }
                    }
                }
            }

            lowerClassLimits[l][1] = 1;
            varianceCombinations[l][1] = variance;
        }

        return lowerClassLimits;
    }

    jenks(data: Array<number>, classesNb: number) {
        data = data.slice().sort((a, b) => a - b);

        const lowerClassLimits = this.jenksMatrices(data, classesNb);
        let k = data.length - 1;
        const kClass = [];
        let countNum = classesNb;

        kClass[classesNb] = data[data.length - 1];
        kClass[0] = data[0];

        while (countNum > 1) {
            kClass[countNum - 1] = data[lowerClassLimits[k][countNum] - 2];
            k = lowerClassLimits[k][countNum] - 1;
            countNum--;
        }

        return kClass;
    }
}
