import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { distinctUntilChanged, skip, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ChartConfiguration } from 'chart.js';

import { SolarService } from 'src/app/services/cadastre-solaire/cadastre-solaire.service';
import { SolarPvSurplusService } from 'src/app/services/cadastre-solaire/cadastre-solaire-pv-surplus.service';
import { ThemeService } from 'src/app/services/ThemeService';
import { UsefulService } from 'src/app/services/UsefulService';

@Component({
    selector: 'cadastreSolairePvSurplusMonthly',
    templateUrl: './cadastre-solaire-pv-surplus-monthly.component.html',
    styleUrls: ['./cadastre-solaire-pv-surplus-monthly.component.scss'],
})
export class CadastreSolairePvSurplusMonthlyComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();

    public monthlySeries: string[] = [
        'Autoconsommation (kWh)',
        `Achat d'électricité (kWh)`,
        'Vente sur le réseau (kWh)',
    ];
    public monthlyDatasets: ChartConfiguration<'bar'>['data']['datasets'];
    public monthlyLabels: string[] = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
    ];

    private electricitySoldColor: string = 'rgba(255, 229, 149, 0.5)';
    private electricityPurchasedColor: string = 'rgba(33, 95, 154, 0.5)';
    private selfconsumptionColor: string = 'rgba(199, 202, 176, 0.75)';

    constructor(
        @Inject(SolarService) public solarService: SolarService,
        @Inject(SolarPvSurplusService)
        public solarPvSurplusService: SolarPvSurplusService,
        @Inject(ThemeService) public themeService: ThemeService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    ngOnInit(): void {
        this.solarPvSurplusService.pvSurplusResultObs$
            .pipe(skip(1), takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((currentResult) => {
                if (currentResult) {
                    this._setMonthlyDatasets();
                }
            });

        this._setMonthlyDatasets();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private _setMonthlyDatasets() {
        const currentResult = this.solarPvSurplusService.currentResult;
        const production = currentResult.monthlyProduction;
        const consumption = this.solarPvSurplusService.pvSurplusConsumersResults.monthlyConsumption;
        const selfConsumption = currentResult.monthlySelfConsumption;

        const electricityPurchased = production.map((production, index) =>
            this.usefulService.round(Math.max(0, consumption[index] - production), 3),
        );

        const electricitySold = production.map((production, index) =>
            this.usefulService.round(Math.max(0, production - consumption[index]), 3),
        );

        this.monthlyDatasets = [
            {
                data: selfConsumption,
                label: 'Autoconsommation (kWh)',
                backgroundColor: this.selfconsumptionColor,
                borderColor: this.selfconsumptionColor,
                stack: 'Stack 0',
            },
            {
                data: electricityPurchased,
                label: `Achat d'électricité (kWh)`,
                backgroundColor: this.electricityPurchasedColor,
                borderColor: this.electricityPurchasedColor,
                stack: 'Stack 0',
            },
            {
                data: selfConsumption,
                label: 'Autoconsommation (kWh)',
                backgroundColor: this.selfconsumptionColor,
                borderColor: this.selfconsumptionColor,
                stack: 'Stack 1',
            },
            {
                data: electricitySold,
                label: `Vente sur le réseau (kWh)`,
                backgroundColor: this.electricitySoldColor,
                borderColor: this.electricitySoldColor,
                stack: 'Stack 1',
            },
        ];
    }

    // this function needs to be an arrow one
    monthlyTooltipHandler = (context: any) => {
        const { chart, tooltip } = context;
        const tooltipEl =
            CadastreSolairePvSurplusMonthlyComponent._getOrCreateMonthlyTooltip(chart);
        tooltipEl.style.width = '200px';

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        // Set Text
        if (tooltip.body) {
            const titleLines = tooltip.title || [];
            const bodyLines = tooltip.body.map((b) => b.lines);

            const tableHead = document.createElement('thead');

            titleLines.forEach((title: string) => {
                const tr = document.createElement('tr');
                tr.style.borderWidth = '0px';

                const th = document.createElement('th');
                th.style.borderWidth = '0px';
                th.style.color = this.themeService.theme.info.color['--primary'];
                th.style.fontSize = '1rem';
                const text = document.createTextNode(title);

                th.appendChild(text);
                tr.appendChild(th);
                tableHead.appendChild(tr);
            });

            const selfConsumption = context.tooltip.dataPoints[0].raw;
            const electricityPurchasedOrSold = context.tooltip.dataPoints[1].raw;

            const table1Body = document.createElement('tbody');
            const isStack0 = bodyLines[1][0].includes('Achat');
            const label = isStack0 ? 'Consommation' : 'Production';

            const value = selfConsumption + electricityPurchasedOrSold;
            const valueStringified = this.usefulService.stringifyNumber(value);

            const tr = document.createElement('tr');
            tr.classList.add('d-flex');
            tr.style.backgroundColor = 'inherit';
            tr.style.borderWidth = '0px';
            table1Body.appendChild(tr);

            const tdLabel = document.createElement('td');
            tdLabel.style.borderWidth = '0px';
            tdLabel.classList.add('d-flex');
            tdLabel.classList.add('align-items-center');
            tdLabel.classList.add('flex-grow-1');
            tr.appendChild(tdLabel);

            const text = document.createTextNode(label);
            tdLabel.appendChild(text);

            const tdValue = document.createElement('td');
            tdValue.style.borderWidth = '0px';
            tdValue.classList.add('d-flex');
            tdValue.classList.add('align-items-center');
            tr.appendChild(tdValue);

            const textValue = document.createTextNode(`${valueStringified} kWh`);
            tdValue.appendChild(textValue);

            const table1 = tooltipEl.querySelector('#stacked-bar-chart-tooltip-lomia-monthly-1');
            // Remove old children
            while (table1.firstChild) {
                table1.firstChild.remove();
            }

            table1.appendChild(tableHead);
            table1.appendChild(table1Body);

            const table2Body = document.createElement('tbody');
            bodyLines.forEach((bodyLine: string[], i: number) => {
                const tr = document.createElement('tr');
                tr.classList.add('d-flex');
                tr.classList.add('align-items-center');
                tr.style.backgroundColor = 'inherit';
                tr.style.borderWidth = '0px';
                table2Body.appendChild(tr);

                const tdColor = document.createElement('td');
                tdColor.style.borderWidth = '0px';
                tdColor.classList.add('d-flex');
                tdColor.classList.add('align-items-center');
                tr.appendChild(tdColor);

                const colors = tooltip.labelColors[i];

                const span = document.createElement('span');
                span.style.background = colors.backgroundColor;
                span.style.borderColor = colors.borderColor;
                span.style.borderWidth = '2px';
                span.style.marginRight = '10px';
                span.style.height = '10px';
                span.style.width = '15px';
                span.style.display = 'inline-block';
                tdColor.appendChild(span);

                const tdLabel = document.createElement('td');
                tdLabel.style.borderWidth = '0px';
                tdLabel.classList.add('d-flex');
                tdLabel.classList.add('align-items-center');
                tdLabel.classList.add('flex-grow-1');
                tr.appendChild(tdLabel);

                const rawValue = context.tooltip.dataPoints[i].raw;
                const valueStringified = this.usefulService.stringifyNumber(
                    this.usefulService.round(rawValue, 2),
                );
                const bodyLineItems = bodyLine[0].split(': ');
                const labelSplited = bodyLineItems[0].split(' ');
                labelSplited.splice(-1);
                const textLabel = document.createTextNode(labelSplited.join(' '));
                tdLabel.appendChild(textLabel);

                const tdValue = document.createElement('td');
                tdValue.style.borderWidth = '0px';
                tdValue.classList.add('d-flex');
                tdValue.classList.add('align-items-center');
                tr.appendChild(tdValue);

                const textValue = document.createTextNode(`${valueStringified} kWh`);
                tdValue.appendChild(textValue);
            });

            const table2 = tooltipEl.querySelector('#stacked-bar-chart-tooltip-lomia-monthly-2');

            // Remove old children
            while (table2.firstChild) {
                table2.firstChild.remove();
            }

            // Add new children
            table2.appendChild(table2Body);
        }

        SolarPvSurplusService._setTooltipPosition(context, tooltipEl);
    };

    private static _getOrCreateMonthlyTooltip(chart: any) {
        let tooltipEl = chart.canvas.parentNode.querySelector('div');

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.style.padding = '5px';
            tooltipEl.style.fontSize = '0.75rem';
            tooltipEl.style.background = 'rgba(255, 255, 255, 0.9)';
            tooltipEl.style.borderRadius = '3px';
            tooltipEl.style.color = 'rgba(74, 74, 76, 1)';
            tooltipEl.style.opacity = 1;
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.transform = 'translate(-50%, 0)';
            tooltipEl.style.transition = 'all .1s ease';

            const table1 = document.createElement('table');
            tooltipEl.appendChild(table1);
            table1.setAttribute('id', 'stacked-bar-chart-tooltip-lomia-monthly-1');
            table1.classList.add('w-100');
            table1.style.margin = '0px';

            const hr = document.createElement('hr');
            hr.classList.add('my-2');
            tooltipEl.appendChild(hr);

            const table2 = document.createElement('table');
            tooltipEl.appendChild(table2);
            table2.setAttribute('id', 'stacked-bar-chart-tooltip-lomia-monthly-2');
            table2.classList.add('w-100');
            table2.style.margin = '0px';

            chart.canvas.parentNode.appendChild(tooltipEl);
        }

        return tooltipEl;
    }
}
