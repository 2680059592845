'use strict';

import 'leaflet.markercluster';
import * as L from 'leaflet';

import { PlotFactory } from './PlotFactory';
import { MapService } from '../services/map.service';

export class PlotMarkerClusterFactory extends PlotFactory {
    constructor(indicatorId: number, mapService: MapService) {
        super(indicatorId, mapService);
        this.form = 'cluster';
        this.indicatorcontourcolor = '#000000';
        this.indicatorcontourweight = 1.9;
        this.indicatorfillopacity = 0.9;
    }

    addLayer(map: L.Map) {
        if (this.geojsonLayer != null) {
            this.removeLayer(map);
        }

        this.geojsonLayer = (L as any).markerClusterGroup({
            spiderLegPolylineOptions: { weight: 0 },
            clockHelpingCircleOptions: {
                weight: 0.7,
                opacity: 1,
                color: 'black',
                fillOpacity: 0,
                dashArray: '10 5',
            },
            elementsPlacementStrategy: 'clock',
            helpingCircles: true,
            spiderfyDistanceSurplus: 25,
            spiderfyDistanceMultiplier: 1,
            maxClusterRadius: 5,
            elementsMultiplier: 1.4,
            firstCircleElements: 8,
            iconCreateFunction: this.iconCreateFunction.bind(this),
        });

        this.geojson.features.forEach((feature: any) => {
            const isPolygon = feature.geometry.type == 'Polygon';

            let lat: number;
            let lng: number;
            if (isPolygon) {
                lat = feature.geometry.coordinates[0][1];
                lng = feature.geometry.coordinates[0][0];
            } else {
                lat = feature.geometry.coordinates[1];
                lng = feature.geometry.coordinates[0];
            }

            const latLng = L.latLng(lat, lng);
            const styleOptions = this.getStyle(feature);
            const circleLayer = L.circleMarker(latLng, styleOptions);
            circleLayer.feature = feature;

            if (!circleLayer.feature) {
                circleLayer.feature = {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [],
                    },
                    properties: {},
                };
            }
            circleLayer.feature.properties = feature.properties;
            this.onEachFeature(circleLayer.feature, circleLayer);
            this.geojsonLayer.addLayer(circleLayer);
        });

        map.addLayer(this.geojsonLayer);
        // this.mapService.addLayers(this.geojsonLayer.getLayers());
    }

    iconCreateFunction(cluster) {
        const children = cluster.getAllChildMarkers();
        const max = Math.max(...children.map((child) => child.feature.properties.value));
        const color = this.getColor(max);

        return L.divIcon({
            html: `<div class="circle" style="border-color: ${color};">
                    <p style="color:${color}">
                        ${cluster.getChildCount()}
                    </p>
                </div>`,
        });
    }

    unhighlightFeature(layer: any) {
        layer.setStyle({
            weight: 1,
        });
    }

    getStyle(feature: GeoJSON.Feature) {
        return {
            radius: this.getWeight(feature.properties.value),
            fillColor: this.getColor(feature.properties.value),
            color: this.indicatorcontourcolor,
            weight: this.indicatorcontourweight,
            opacity: this.getContourOpacity(feature.properties.value),
            fillOpacity: this.getFillOpacity(feature.properties.value),
        };
    }
}
