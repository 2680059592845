<div class="d-flex flex-column h-100">
    <div class="flex-grow-1 overflow-auto">
        <div class="w-100 p-3">
            <div class="solar-card">
                <h5 class="text-st-primary mb-3">
                    Personnaliser les surfaces des toitures sélectionnées
                </h5>
                <div>
                    <h6>
                        Personnalisez les surfaces disponibles pour l’installation de panneaux
                        solaires sur les toitures sélectionnées :
                    </h6>
                    <ng-container *ngFor="let element of selectedElementsTmp">
                        <div class="custom-usable-roof-area">
                            <div class="d-flex align-items-center">
                                <span class="flex-grow-1 me-4 text-st-primary">
                                    {{ element.addressLabel }}</span
                                >
                                <button
                                    type="button"
                                    class="btn btn-st-inverted-primary px-0"
                                    title="Localiser la toiture"
                                    (click)="findElementOnMap(element.id)"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="25"
                                        fill="currentColor"
                                        class="bi bi-geo-alt-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <div class="container-fluid px-0">
                                <div class="row d-flex align-items-center">
                                    <label
                                        for="custom-usable-roof-area-{{ element.id }}"
                                        class="col-xl-4 py-0 control-label text-start fw-normal"
                                    >
                                        Surface disponible
                                    </label>
                                    <div class="col-md">
                                        <div class="row gx-0">
                                            <div class="col-3 text-st-active">
                                                {{ element.usableRoofArea | round: 0 }} m<sup
                                                    >2</sup
                                                >
                                            </div>
                                            <div class="col">
                                                <stRange
                                                    innerId="custom-usable-roof-area-{{ element.id }}"
                                                    [min]="0"
                                                    [max]="element.surface_disponible"
                                                    [step]="1"
                                                    [value]="element.usableRoofArea"
                                                    [(ngModel)]="element.usableRoofArea"
                                                    (change)="checkOnValue()"
                                                ></stRange>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="footer border-top">
        <div class="container-fluid p-3">
            <div class="row">
                <div class="col-5">
                    <stButton
                        class="btn-st-lg"
                        btnLabel="Annuler"
                        btnClass="btn-st-default w-100 h-100"
                        (click)="cancel()"
                    >
                    </stButton>
                </div>
                <div class="col-7">
                    <stButton
                        class="btn-st-lg"
                        btnLabel="Valider les surfaces"
                        btnClass="btn-st-primary w-100"
                        (click)="validate()"
                        is-disabled="isDisabled"
                    >
                    </stButton>
                </div>
            </div>
        </div>
    </div>
</div>
