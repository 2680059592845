<stHeader [isAdminPage]="true"></stHeader>
<stNavbar page="bases-manage"></stNavbar>

<div class="ligneFlexStart windowAdmin">
  <div class="flexComponentOne bordureExtContenair"></div>

  <div class="colFlexStartWrap admin_maincontenair">
    <div class="adm_main_title ligneFlexStartCentered">
      <div><span class="fa fa-user fa-2x" style="padding-right: 15px; color: rgb(33,68,111);"></span></div>
      <div>Gestion des bases</div>
    </div>
    <div class="ligneFlexStart" style="width:100%">
      <div style="padding: 30px 20px 40px 20px; text-align: justify">
        <h4>
          <span class="fa fa-life-ring "></span>
          <span>Gestion des bases de données</span>
        </h4>
        <p>En tant que super administrateur, vous avez à charge la gestion des bases de données de Siterre.</p>
        <p>Une base de données est enregistrée sous la forme d'une table :</p>
        <ul>
          <li> Dans le schéma Siterre</li>
          <li> Dont le nom commence par bd_ </li>
          <li> Elle contient une colonne territoire équivalente aux code INSEE si existant (du bâtiment au
            pays)</li>
          <li> Elle contient des données continues (pour les indicateurs)</li>
          <li> Et optionnellement des données discrètes (pour les filtres des indicateurs dynamiques)</li>
        </ul>
        <div style="padding-top: 10px">Le tableau ci-dessous vous permet de gérer les bases.</div>
      </div>

      <div class="flexComponentOne"></div>

      <div class="card">
        <div class="list-group list-group-flush" style="padding: 20px;">
          <div class="modal-header list-group-item p-1">
            <h4 class="modal-title">Dashboard administation des bases</h4>
          </div>
          <div class="modal-body list-group-item p-1">
            <div class="p-1">
              <b>Nombre total de bases </b> : {{totalBases}} base(s)
            </div>
          </div>
          <div class="modal-footer p-3">
            <stButton btnLabel="Créer une nouvelle base" btnClass="btn-st-primary" (click)="goToNewBase()"
              [isDisabled]="true">
            </stButton>
          </div>
        </div>
      </div>
    </div>


    <div class="ligneFlexStart" style="width:100%">
      <div class="flexComponentOne" style="padding: 10px 0px 20px 20px;">
        <stPagination text="Nombre de bases par page :" [values]="nbLinesPerPageList"
          [(nbLinesPerPage)]="nbLinesPerPage" [total]="totalBases" [(pageSelected)]="selectedPage">
          <table class="modalTable" style="min-width: 750px">
            <tr>
              <th style="width: 200px; text-align: center">
                Table
                <span class="fa fa fa-sort cursor-pointer" (click)="sort('tbl')"></span>
              </th>
              <th style="min-width: 100px; text-align: center">
                Colonne territoire
                <span class="fa fa fa-sort cursor-pointer" (click)="sort('champ_ter')"></span>
              </th>
              <th style="min-width: 100px; text-align: center">
                Correspondance
                <span class="fa fa fa-sort cursor-pointer" (click)="sort('crrsdc_ter')"></span>
              </th>
              <th style="min-width: 100px; text-align: center">
                Année du territoire
                <span class="fa fa fa-sort cursor-pointer" (click)="sort('crrsdc_ter_year_geo')"></span>
              </th>
              <th style="text-align: center">
                Action
              </th>

            </tr>
            <ng-container *ngFor="let base of bases | orderBy: sortValue; let index=index">
              <tr *ngIf="tabFilter(index)">
                <td style="text-align: center; padding-left: 5px">
                  <span [hidden]="base.new">{{ base.tbl }} </span>
                  <stInput type="text" [hidden]="!base.new" [(ngModel)]="base.tbl"></stInput>
                </td>
                <td style="text-align: center; padding-left: 5px">
                  <span [hidden]="base.modify">{{ base.champ_ter }}</span>
                  <stInput type="text" [hidden]="!base.modify" [(ngModel)]="base.champ_ter"></stInput>
                </td>
                <td style="text-align: center; padding-left: 5px">
                  <span [hidden]="base.modify"> {{ getLabelFromIdTer(base.crrsdc_ter) }}</span>
                  <stSelect *ngIf="base.modify" [(ngModel)]="base.crrsdc_ter" orderBy="id_terr" valueField="id_terr"
                    labelField="libelle_long" [data]="territoryScales"></stSelect>
                </td>
                <td style="text-align: center; padding-left: 5px">
                  <span [hidden]="base.modify">{{ base.crrsdc_ter_year_geo }}</span>
                  <stInput type="number" [hidden]="!base.modify" [(ngModel)]="base.crrsdc_ter_year_geo"></stInput>
                </td>

                <td style="text-align: center">
                  <span (click)="goToUpdateBase(base)" [hidden]="base.modify"
                    class="fa fa-pencil-square-o cursor-pointer" style="padding:5px" ngbTooltip="Modifier"
                    container="body"></span>
                  <span (click)="saveBase(base)" [hidden]="!base.modify" class="fa fa-floppy-o cursor-pointer"
                    style="padding:5px" ngbTooltip="Enregistrer" container="body"></span>
                </td>
              </tr>
            </ng-container>
          </table>
        </stPagination>
      </div>
    </div>
  </div>
  <div class="flexComponentOne bordureExtContenair"></div>
</div>
