'use strict';

import { Component, Inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    AtacBuilding,
    AtacGroup,
    CaseParameters,
    CostInfo,
    SimulationParameters,
} from 'src/app/models/AtacTypes';
import { AtacService } from 'src/app/services/AtacService';
@UntilDestroy()
@Component({
    selector: 'atacParameter',
    templateUrl: './atac-parameter.template.html',
})
export class AtacParameterComponent implements OnInit {
    showCas = true;
    showSituation = false;
    isLoading: boolean;
    hasError: boolean;
    iconClass: string;
    launchAlert: string;
    showEconomique: boolean;
    showInvestCost: boolean;
    hasPotentialCostMessage: boolean;

    //===========Parametrage / Lancement simulation============//
    investCost: CostInfo[] = [
        {
            id: 'cout_invest_b1',
            libelle: 'Installation inférieure à 3 kWc :',
            value: 2500,
            type: 'number',
        }, //valeur par défaut = null ? ou 'tarif biblio' ?
        {
            id: 'cout_invest_b2',
            libelle: 'Installation de 3 à 9 kWc :',
            value: 2000,
            type: 'number',
        }, //valeur par défaut = null ? ou 'tarif biblio' ?
        {
            id: 'cout_invest_b3',
            libelle: 'Installation de 9 à 36 kWc :',
            value: 1500,
            type: 'number',
        }, //valeur par défaut = null ? ou 'tarif biblio' ?
        {
            id: 'cout_invest_b4',
            libelle: 'Installation de 36 à 100 kWc :',
            value: 1100,
            type: 'number',
        }, //valeur par défaut = null ? ou 'tarif biblio' ?
        {
            id: 'cout_invest_b5',
            libelle: 'Installation supérieure à 100 kWc :',
            value: 1000,
            type: 'number',
        }, //valeur par défaut = null ? ou 'tarif biblio' ?
    ];

    feedInTariff: CostInfo[] = [
        {
            id: 'tarif_rachat_1',
            libelle: 'Installation inférieure à 9 kWc :',
            value: 10,
            type: 'number',
        },
        {
            id: 'tarif_rachat_2',
            libelle: 'Installation de 9 à 100 kWc :',
            value: 6,
            type: 'number',
        },
        {
            id: 'tarif_rachat_3',
            libelle: 'Installation supérieure à 100 kWc :',
            value: 9.8,
            type: 'number',
        },
    ];

    extractionCost: CostInfo[] = [
        {
            id: 'tarif_soutirage_resid',
            libelle: 'Résidentiel :',
            value: 7.1,
            type: 'number',
        },
        {
            id: 'tarif_soutirage_ter_bleu',
            libelle: 'Petit tertiaire (< 36 kVA) :',
            value: 7.6,
            type: 'number',
        },
        {
            id: 'tarif_soutirage_ter_jaune',
            libelle: 'Moyen tertiaire (< 250 kVA) :',
            value: 5.9,
            type: 'number',
        },
        {
            id: 'tarif_soutirage_ter_vert',
            libelle: 'Gros tertiaire (> 250 kVA) :',
            value: 4.7,
            type: 'number',
        },
    ];
    investHelp: CostInfo[] = [
        {
            id: 'aide_invest_1',
            libelle: 'Installation inférieure à 3 kWc :',
            value: 390,
            type: 'number',
        },
        {
            id: 'aide_invest_2',
            libelle: 'Installation de 3 à 9 kWc :',
            value: 290,
            type: 'number',
        },
        {
            id: 'aide_invest_3',
            libelle: 'Installation de 9 à 36 kWc :',
            value: 160,
            type: 'number',
        },
        {
            id: 'aide_invest_4',
            libelle: 'Installation de 36 à 100 kWc :',
            value: 80,
            type: 'number',
        },
        {
            id: 'aide_invest_5',
            libelle: 'Installation supérieure à 100 kWc :',
            value: 0,
            type: 'number',
        },
    ];

    economicParameters: SimulationParameters[] = [
        // {id:"TURPE_ACC", libelle:"Utilisation du TURPE spécial autoconsommation :", popoverInfo:"Info", value:true, type:"select", options: [{id: true, libelle:"Oui"},{id: false, libelle:"Non"}]},
        {
            id: 'evol_cout_elec',
            libelle: 'Taux hausse annuelle tarifs éléctricité (%) :',
            popoverInfo:
                'Les hypothèses prises sur le taux de hausse des tarifs de l’électricité influent très sensiblement le calcul de la rentabilité des groupements.<br/>Ces hypothèses de hausse portent uniquement sur la partie variable hors taxe de la fourniture, c’est-à-dire sur l’évolution anticipée des coûts de production à l’échelle nationale.<br/>L’outil est paramétré par défaut avec une hausse annuelle de 1,5%.',
            value: 1.5,
            type: 'number',
        },
        {
            id: 'maturite',
            libelle: "Durée d'amortissement de l'opération (années) :",
            popoverInfo:
                'L’outil <b>AtAC</b> permet d’identifier les groupements les plus rentables économiquement en se basant sur un calcul du Taux de Rentabilité Interne (TRI).<br/>La période d’amortissement sur laquelle est calculée ce TRI est définie par défaut à 25 ans, soit la période pour laquelle les panneaux photovoltaïques font typiquement l’objet d’une garantie de performance.<br/>Cette durée d’amortissement peut être modifiée par l’utilisateur.',
            value: 25,
            type: 'number',
        },
        {
            id: 'tarif_soutirage',
            libelle: 'Coût de fourniture de l’électricité  (c€/kWh) :',
            popoverInfo:
                'Coût de fourniture de l’électricité sur le réseau, hors taxe et hors coût de réseau',
            type: 'menu',
            item: this.extractionCost,
        },
        {
            id: 'tarif_rachat',
            libelle: 'Tarif de vente du surplus (c€/kWh) :',
            popoverInfo:
                'Il n’existe aujourd’hui pas d’obligation d’achat pour le rachat du surplus d’une opération d’autoconsommation collective comme cela peut exister dans le cas de l’autoconsommation individuelle.<br/>La valorisation économique du surplus se fait alors de gré à gré avec un opérateur du marché de l’électricité (fournisseurs notamment).',
            type: 'menu',
            item: this.feedInTariff,
        },
        {
            id: 'cout_invest',
            libelle: 'Coût investissement installation PV (€/kWc) :',
            popoverInfo:
                'Du fait notamment des économies d’échelle, le coût du kWc installé est fonction de la taille des installations.<br/>L’outil est paramétré par défaut autour de 4 bornes correspondant à des seuils critiques de taille des installations (3 kWc, 9 kWc, 36 kWc et 100 kWc) pour lesquels les montants ont été définis en s’appuyant sur les observations du marché (montants d’investissement intégrant l’installation et les coûts de raccordement).<br/>On considère que les coûts augmentent linéairement entre chacune des bornes.<br/>L’utilisateur a la possibilité de modifier le montant de ces bornes.',
            type: 'menu',
            item: this.investCost,
        },
        {
            id: 'aide_invest',
            libelle: "Aide à l'investissement (€/kWc) :",
            popoverInfo: '',
            type: 'menu',
            item: this.investHelp,
        },
    ];
    showProdBox: boolean;
    selectedGroup: AtacGroup;
    simulationParameters: SimulationParameters[];
    caseParameters: CaseParameters = {
        popoverInfo:
            'L’outil <b>AtAC</b> a été conçu pour répondre à différents cas d’usage.<ul><li>L’approche <b>producteur</b> permet de rechercher les bâtiments consommateurs les plus intéressants autour d’un ou plusieurs bâtiments déjà identifiés comme producteur.</li><li>L’approche <b>parc défini</b> permet d’identifier les groupements les plus intéressants au sein d’un parc délimité par l’utilisateur (patrimoine de collectivité, groupement de citoyens engagés à l’échelle d’un quartier).</li><li>L’approche <b>territoriale</b> permet d’identifier des groupements à l’échelle d’un territoire sans identification individuelle préalable des bâtiments.</li></ul>La clé de répartition par défaut de la production entre les différents consommateurs est une clé de répartition dynamique en fonction de la consommation de chacun des consommateurs à chaque instant.',
        availableCases: [
            {
                id: '0',
                libelle: 'Producteur',
                popoverInfo: 'info',
                message:
                    "La simulation ne sera réalisée qu'autour des bâtiments identifiés comme producteurs obligés dans l'onglet « Import données : définition parc bâti »",
            },
            {
                id: '1',
                libelle: 'Parc défini',
                popoverInfo: 'Info',
                message:
                    "La simulation ne prendra en compte que les bâtiments renseignés dans l'onglet « Import données : définition parc bâti »",
            },
            {
                id: '2',
                libelle: 'Territoriale',
                popoverInfo: 'Info',
                message:
                    "La simulation sera réalisée sur l'ensemble du territoire en intégrant les informations relatives au parc défini par l'utilisateur et les contraintes liées au paramétrage ci-dessous",
            },
        ],
        selectedCase: {
            id: null,
            libelle: null,
            popoverInfo: null,
            message: null,
        }, // Default value of the select in the ui
    };
    private _selectedBuildings: AtacBuilding[] = [];

    constructor(@Inject(AtacService) private atacService: AtacService) {}

    ngOnInit(): void {
        this.atacService.selectedBuildings$
            .pipe(untilDestroyed(this))
            .subscribe((selectedBuildings) => {
                this._selectedBuildings = selectedBuildings;
            });
    }

    async launchSimulation() {
        this.isLoading = true;
        this.hasError = false;
        this.atacService.isSuccess = false;

        this.iconClass = 'fa fa-spinner fa-spin';
        const parameters = this.simulationParameters.concat(this.economicParameters);

        try {
            const data = await this.atacService.launchSimulation(
                parameters,
                this.caseParameters.selectedCase,
            );
            this.atacService.groups = Object.keys(data.indicateurs_groupement)
                .map((key) => {
                    const obj = data.indicateurs_groupement[key];
                    obj.key = key;
                    return obj;
                })
                .sort((a, b) => b.TRI - a.TRI);
            this.atacService.setConsumers(data.indicateurs_consommateurs);
            this.atacService.setProducers(data.indicateurs_producteurs);
            this.atacService.isSuccess = true;
            this.isLoading = false;
            this.hasError = false;
            this.atacService.setTab('list');
            this.atacService.showGroupementOnMap(this.atacService.groups[0]);
        } catch (error) {
            this.hasError = true;
            this.isLoading = false;
            this.atacService.isSuccess = false;
        }
    }

    // Cas pour la recherche de consommateurs potentiels
    updateSituation(input) {
        this.hasPotentialCostMessage = false;
        if (input.id == 'search_conso_potentiels') {
            // Si "Oui" sélectionné, on affiche le message
            if (input.value == '1') {
                this.hasPotentialCostMessage = true;
                // Supprimer Typologie des bâtiments consommateurs potentiels
                const index = this.simulationParameters.indexOf(
                    this.simulationParameters.find((x) => x.id === 'typo_conso'),
                );
                this.simulationParameters.splice(index, 1);
            }
            // Si "Non" sélectionné, on n'affiche pas la Typologie des bâtiments consommateurs potentiels
            else if (input.value == '2') {
                this.hasPotentialCostMessage = false;
                // Ajouter Typologie des bâtiments consommateurs potentiels
                const conso_potentiels = {
                    id: 'typo_conso',
                    libelle: 'Typologie des bâtiments consommateurs',
                    popoverInfo:
                        'Choisissez les secteurs dont vous souhaitez intégrer les bâtiments à la simulation.<br/>L’intégration des bâtiments d’un secteur signifie que ces bâtiments seront pris en compte dans le calcul d’optimisation des groupements d’autoconsommation mais ces bâtiments ne seront pas forcément retenus au final dans les meilleurs groupements.',
                    type: 'checkbox',
                    options: [
                        {
                            id: 'consommateur_public',
                            libelle: 'Tertiaire public',
                            value: true,
                        },
                        {
                            id: 'consommateur_resid',
                            libelle: 'Résidentiel hors HLM',
                            value: true,
                        },
                        {
                            id: 'consommateur_tertiaire',
                            libelle: 'Tertiaire privé',
                            value: true,
                        },
                        {
                            id: 'consommateur_hlm',
                            libelle: 'Résidentiel HLM',
                            value: true,
                        },
                        {
                            id: 'consommateur_indetermine',
                            libelle: 'Tertiaire indeterminé',
                            value: true,
                        },
                    ],
                };
                this.simulationParameters.push(conso_potentiels);
            }
        }
    }

    updateCas() {
        // Producteur selected
        if (this.caseParameters.selectedCase.id == '0') {
            this.simulationParameters = [
                {
                    id: 'id_perimetre',
                    libelle: "Périmètre de l'opération :",
                    popoverInfo:
                        'L’outil <b>AtAC</b> permet d’identifier des groupements optimaux d’autoconsommation collective sur un périmètre qualifié d’étendu, c’est-à-dire au-delà du fonctionnement d’un bâtiment abritant plusieurs consommateurs.<br/>Ce périmètre géographique étendu est aujourd’hui défini dans la loi comme un périmètre au sein duquel les participants les plus éloignés sont séparés d’une distance maximale de 2km.<br/>Vous avez cependant la possibilité de réaliser une simulation suivant un critère de raccordement de l’ensemble des participants à un même poste HTA/BT (ancien critère réglementaire de définition de l’autoconsommation collective).<br/>Cette dernière option permet notamment la prise en compte de la possibilité de bénéficier du TURPE spécifique à l’autoconsommation collective (TURPE spécifique accessible réglementairement seulement pour les opérations en aval d’un même poste HTA/BT).',
                    value: 1,
                    type: 'select',
                    options: [
                        { id: 1, libelle: 'Maille réseau BT' },
                        { id: 2, libelle: 'Cercle 1 km' },
                    ],
                },
                {
                    id: 'n_assemblage_to_keep',
                    libelle: 'Nombre de groupements à identifier :',
                    popoverInfo:
                        'Les groupements sont sélectionnés lors de la simulation par ordre décroissant de rentabilité.<br/>Choisissez le nombre de groupements que vous souhaitez identifier.',
                    value: 5,
                    type: 'number',
                },
                {
                    id: 'nbat_max',
                    libelle: 'Nombre maximum de participants par groupement :',
                    popoverInfo:
                        'Le cadre réglementaire ne fixe pas de limite quant au nombre de participants à un groupement d’autoconsommation collective.<br/>Cependant, plus le nombre de bâtiments est élevé plus le nombre d’acteurs participants est susceptible d’être élevé, ce qui peut poser des difficultés d’organisation au sein de la Personne Morale Organisatrice de l’opération d’autoconsommation.<br/>Pour information, l’outil intègre le plafond réglementaire de 3MWc de puissance installée pour une opération d’autoconsommation collective.<br/>Vous pouvez ici définir le nombre maximum de bâtiments par groupement.',
                    value: 5,
                    type: 'number',
                },
                {
                    id: 'search_conso_potentiels',
                    libelle:
                        "Rechercher les consommateurs potentiels uniquement dans le parc défini par l'utilisateur :",
                    popoverInfo:
                        'Cette option permet de réaliser une simulation sur un parc de bâtiments entièrement défini par l’utilisateur dans l’onglet « <b>Import données / Définition parc bâti</b> ».<br/>Si cette option est choisie, la simulation ne prendra en compte que les bâtiments renseignés par l’utilisateur sans intégrer d’autres bâtiments susceptibles d’améliorer la rentabilité des opérations.<br/><b>Cette option est notamment appropriée pour réaliser une simulation sur le patrimoine d’une collectivité ou sur le patrimoine d’un ensemble d’acteurs ayant amorcé une réflexion collective sur la production énergétique.</b>',
                    value: 2,
                    type: 'select',
                    options: [
                        { id: 1, libelle: 'Oui' },
                        { id: 2, libelle: 'Non' },
                    ],
                },
                {
                    id: 'typo_conso',
                    libelle: 'Typologie des bâtiments consommateurs',
                    popoverInfo:
                        'Choisissez les secteurs dont vous souhaitez intégrer les bâtiments à la simulation.<br/>L’intégration des bâtiments d’un secteur signifie que ces bâtiments seront pris en compte dans le calcul d’optimisation des groupements d’autoconsommation mais ces bâtiments ne seront pas forcément retenus au final dans les meilleurs groupements.',
                    type: 'checkbox',
                    options: [
                        {
                            id: 'consommateur_public',
                            libelle: 'Tertiaire public',
                            value: true,
                        },
                        {
                            id: 'consommateur_resid',
                            libelle: 'Résidentiel hors HLM',
                            value: true,
                        },
                        {
                            id: 'consommateur_tertiaire',
                            libelle: 'Tertiaire privé',
                            value: true,
                        },
                        {
                            id: 'consommateur_hlm',
                            libelle: 'Résidentiel HLM',
                            value: true,
                        },
                        {
                            id: 'consommateur_indetermine',
                            libelle: 'Tertiaire indeterminé',
                            value: true,
                        },
                    ],
                },
            ];
            this.showProdBox = false;
        }
        // Parc défini selected
        if (this.caseParameters.selectedCase.id == '1') {
            this.simulationParameters = [
                {
                    id: 'id_perimetre',
                    libelle: "Périmètre de l'opération :",
                    popoverInfo:
                        'L’outil <b>AtAC</b> permet d’identifier des groupements optimaux d’autoconsommation collective sur un périmètre qualifié d’étendu, c’est-à-dire au-delà du fonctionnement d’un bâtiment abritant plusieurs consommateurs.<br/>Ce périmètre géographique étendu est aujourd’hui défini dans la loi comme un périmètre au sein duquel les participants les plus éloignés sont séparés d’une distance maximale de 2km.<br/>Vous avez cependant la possibilité de réaliser une simulation suivant un critère de raccordement de l’ensemble des participants à un même poste HTA/BT (ancien critère réglementaire de définition de l’autoconsommation collective).<br/>Cette dernière option permet notamment la prise en compte de la possibilité de bénéficier du TURPE spécifique à l’autoconsommation collective (TURPE spécifique accessible réglementairement seulement pour les opérations en aval d’un même poste HTA/BT).',
                    value: 1,
                    type: 'select',
                    options: [
                        { id: 1, libelle: 'Maille réseau BT' },
                        { id: 2, libelle: 'Cercle 1 km' },
                    ],
                },
                {
                    id: 'n_assemblage_to_keep',
                    libelle: 'Nombre de groupements à identifier :',
                    popoverInfo:
                        'Les groupements sont sélectionnés lors de la simulation par ordre décroissant de rentabilité.<br/>Choisissez le nombre de groupements que vous souhaitez identifier.',
                    value: 5,
                    type: 'number',
                },
                {
                    id: 'nbat_max',
                    libelle: 'Nombre maximum de participants par groupement :',
                    popoverInfo:
                        'Le cadre réglementaire ne fixe pas de limite quant au nombre de participants à un groupement d’autoconsommation collective.<br/>Cependant, plus le nombre de bâtiments est élevé plus le nombre d’acteurs participants est susceptible d’être élevé, ce qui peut poser des difficultés d’organisation au sein de la Personne Morale Organisatrice de l’opération d’autoconsommation.<br/>Pour information, l’outil intègre le plafond réglementaire de 3MWc de puissance installée pour une opération d’autoconsommation collective.<br/>Vous pouvez ici définir le nombre maximum de bâtiments par groupement.',
                    value: 5,
                    type: 'number',
                },
                {
                    id: 'puissance_min_toit',
                    libelle: 'Puissance minimale installée par toiture (kWc) :',
                    popoverInfo:
                        ' Le cadre réglementaire ne fixe pas de minimum de puissance installée par toiture productrice.<br/>Cependant, imposer un seuil inférieur de puissance dans les simulations peut permettre de se concentrer spécifiquement sur les toitures présentant un potentiel plus important.<br/>Pour information, il faut compter environ 6m2 de toiture favorable pour l’installation d’un kWc.<br/>Une installation sur maison individuelle s’inscrit typiquement dans le segment des petites toitures (<9kWc), une installation sur bâtiments administratifs et scolaires s’inscrit dans le segment des moyennes toitures (9kWc à 100kWc).',
                    value: 10,
                    type: 'number',
                },
                {
                    id: 'puissance_max_toit',
                    libelle: 'Puissance maximale installée par toiture (kWc) :',
                    popoverInfo:
                        'Le cadre réglementaire, à travers l’obligation d’un raccordement au réseau basse tension pour l’autoconsommation collective étendue, impose un seuil supérieur de 250kWc par installation.<br/>Cette valeur par défaut peut être modifiée par l’utilisateur.<br/>Pour information, il faut compter environ 6m2 de toiture favorable pour l’installation d’un kWc.<br/>Une installation sur maison individuelle s’inscrit typiquement dans le segment des petites toitures (<9kWc), une installation sur bâtiments administratifs et scolaires s’inscrit dans le segment des moyennes toitures (9kWc à 100kWc).',
                    value: 1000,
                    type: 'number',
                },
            ];
            this.showProdBox = false;
        }
        // Territoriale selected
        if (this.caseParameters.selectedCase.id == '2') {
            this.simulationParameters = [
                {
                    id: 'id_perimetre',
                    libelle: "Périmètre de l'opération :",
                    popoverInfo:
                        'L’outil <b>AtAC</b> permet d’identifier des groupements optimaux d’autoconsommation collective sur un périmètre qualifié d’étendu, c’est-à-dire au-delà du fonctionnement d’un bâtiment abritant plusieurs consommateurs.<br/>Ce périmètre géographique étendu est aujourd’hui défini dans la loi comme un périmètre au sein duquel les participants les plus éloignés sont séparés d’une distance maximale de 2km.<br/>Vous avez cependant la possibilité de réaliser une simulation suivant un critère de raccordement de l’ensemble des participants à un même poste HTA/BT (ancien critère réglementaire de définition de l’autoconsommation collective).<br/>Cette dernière option permet notamment la prise en compte de la possibilité de bénéficier du TURPE spécifique à l’autoconsommation collective (TURPE spécifique accessible réglementairement seulement pour les opérations en aval d’un même poste HTA/BT).',
                    value: 1,
                    type: 'select',
                    options: [
                        { id: 1, libelle: 'Maille réseau BT' },
                        { id: 2, libelle: 'Cercle 1 km' },
                    ],
                },
                {
                    id: 'n_assemblage_to_keep',
                    libelle: 'Nombre de groupements à identifier :',
                    popoverInfo:
                        'Les groupements sont sélectionnés lors de la simulation par ordre décroissant de rentabilité.<br/>Choisissez le nombre de groupements que vous souhaitez identifier.',
                    value: 5,
                    type: 'number',
                },
                {
                    id: 'nbat_max',
                    libelle: 'Nombre maximum de participants par groupement :',
                    popoverInfo:
                        'Le cadre réglementaire ne fixe pas de limite quant au nombre de participants à un groupement d’autoconsommation collective.<br/>Cependant, plus le nombre de bâtiments est élevé plus le nombre d’acteurs participants est susceptible d’être élevé, ce qui peut poser des difficultés d’organisation au sein de la Personne Morale Organisatrice de l’opération d’autoconsommation.<br/>Pour information, l’outil intègre le plafond réglementaire de 3MWc de puissance installée pour une opération d’autoconsommation collective.<br/>Vous pouvez ici définir le nombre maximum de bâtiments par groupement.',
                    value: 5,
                    type: 'number',
                },
                {
                    id: 'puissance_min_toit',
                    libelle: 'Puissance minimale installée par toiture (kWc) :',
                    popoverInfo:
                        'Le cadre réglementaire ne fixe pas de minimum de puissance installée par toiture productrice.<br/>Cependant, imposer un seuil inférieur de puissance dans les simulations peut permettre de se concentrer spécifiquement sur les toitures présentant un potentiel plus important.<br/>Pour information, il faut compter environ 6m2 de toiture favorable pour l’installation d’un kWc.<br/>Une installation sur maison individuelle s’inscrit typiquement dans le segment des petites toitures (<9kWc), une installation sur bâtiments administratifs et scolaires s’inscrit dans le segment des moyennes toitures (9kWc à 100kWc).',
                    value: 10,
                    type: 'number',
                },
                {
                    id: 'puissance_max_toit',
                    libelle: 'Puissance maximale installée par toiture (kWc) :',
                    popoverInfo:
                        'Le cadre réglementaire, à travers l’obligation d’un raccordement au réseau basse tension pour l’autoconsommation collective étendue, impose un seuil supérieur de 250kWc par installation.<br/>Cette valeur par défaut peut être modifiée par l’utilisateur.<br/>Pour information, il faut compter environ 6m2 de toiture favorable pour l’installation d’un kWc.<br/>Une installation sur maison individuelle s’inscrit typiquement dans le segment des petites toitures (<9kWc), une installation sur bâtiments administratifs et scolaires s’inscrit dans le segment des moyennes toitures (9kWc à 100kWc).',
                    value: 1000,
                    type: 'number',
                },
                {
                    id: 'puissance_max_grp',
                    libelle: 'Puissance maximale installée groupement (kWc) : ',
                    popoverInfo:
                        'Le cadre réglementaire fixe un maximum de puissance installée par groupement de 3MWc.<br/>Cette valeur renseignée par défaut peut être modifiée pour cibler des groupements de taille inférieure susceptible d’être plus facilement gérable d’un point de vue organisationnel.',
                    value: 3000,
                    type: 'number',
                },
                {
                    id: 'typo_prod',
                    libelle: 'Typologie des bâtiments producteurs',
                    popoverInfo:
                        'Choisissez les secteurs dont vous souhaitez intégrer les bâtiments à la simulation.<br/>L’intégration des bâtiments d’un secteur signifie que ces bâtiments seront pris en compte dans le calcul d’optimisation des groupements d’autoconsommation mais ces bâtiments ne seront pas forcément retenus au final dans les meilleurs groupements.<br/>A noter, l’intégration d’un segment en tant que producteur possible génère automatiquement son inclusion en tant que consommateur possible.',
                    type: 'checkbox',
                    options: [
                        {
                            id: 'producteur_public',
                            libelle: 'Tertiaire public',
                            value: true,
                        },
                        {
                            id: 'producteur_resid',
                            libelle: 'Résidentiel hors HLM',
                            value: true,
                        },
                        {
                            id: 'producteur_tertiaire',
                            libelle: 'Tertiaire privé',
                            value: true,
                        },
                        {
                            id: 'producteur_hlm',
                            libelle: 'Résidentiel HLM',
                            value: true,
                        },
                        {
                            id: 'producteur_indetermine',
                            libelle: 'Tertiaire indeterminé',
                            value: true,
                        },
                    ],
                },
                {
                    id: 'typo_conso',
                    libelle: 'Typologie des bâtiments consommateurs',
                    popoverInfo:
                        'Choisissez les secteurs dont vous souhaitez intégrer les bâtiments à la simulation.<br/>L’intégration des bâtiments d’un secteur signifie que ces bâtiments seront pris en compte dans le calcul d’optimisation des groupements d’autoconsommation mais ces bâtiments ne seront pas forcément retenus au final dans les meilleurs groupements.<br/>A noter, l’exclusion d’un segment en tant que consommateur possible génère automatiquement son exclusion en tant que producteur possible.',
                    type: 'checkbox',
                    options: [
                        {
                            id: 'consommateur_public',
                            libelle: 'Tertiaire public',
                            value: true,
                        },
                        {
                            id: 'consommateur_resid',
                            libelle: 'Résidentiel hors HLM',
                            value: true,
                        },
                        {
                            id: 'consommateur_tertiaire',
                            libelle: 'Tertiaire privé',
                            value: true,
                        },
                        {
                            id: 'consommateur_hlm',
                            libelle: 'Résidentiel HLM',
                            value: true,
                        },
                        {
                            id: 'consommateur_indetermine',
                            libelle: 'Tertiaire indeterminé',
                            value: true,
                        },
                    ],
                },
            ];
            this.showProdBox = true;
        }
        this.showSituation = true;
    }

    ifProducteurCheckConsommateur(id_input, opt) {
        if (id_input === 'typo_prod' && opt.value) {
            const index_param = this.simulationParameters.indexOf(
                this.simulationParameters.find((x) => x.id === 'typo_conso'),
            );
            this.simulationParameters[index_param].options.find(
                (x) => x.id.toString().split('_')[1] === opt.id.split('_')[1],
            ).value = true;
        } else if (id_input === 'typo_conso' && !opt.value && this.showProdBox) {
            const index_param = this.simulationParameters.indexOf(
                this.simulationParameters.find((x) => x.id === 'typo_prod'),
            );
            this.simulationParameters[index_param].options.find(
                (x) => x.id.toString().split('_')[1] === opt.id.split('_')[1],
            ).value = false;
        }
    }

    /* Empêcher la possibilité de lancer une simulation dans le cas d'un parc défini / producteur
     * Producteur : il faut définir au moins un producteur dans l'onglet Définition parc Bâti
     * Parc défini : il faut définir au moins un bâtiment dans l'onglet Définition parc Bâti
     */

    launchNotAllowed() {
        this.iconClass = 'fa fa-ban';
        if (Object.keys(this.caseParameters.selectedCase).length === 0) {
            this.launchAlert = "Vérifiez que vous avez bien sélectionné un cas d'usage.";
            return true;
        }
        // Producteur
        else if (
            this.caseParameters.selectedCase.id == '0' &&
            this.atacService.requiredProducersIds.size == 0
        ) {
            this.launchAlert =
                "Vérifiez que vous avez bien défini un/des bâtiments producteurs dans l'onglet Définition parc bâti.";
            return true;
        }
        // Parc défini
        else if (
            this.caseParameters.selectedCase.id == '1' &&
            this._selectedBuildings.length == 0
        ) {
            this.launchAlert =
                "Vérifiez que vous avez bien défini un/des bâtiments dans l'onglet Définition parc bâti.";
            return true;
        } else {
            this.iconClass = undefined;
            return false;
        }
    }
}
