import { Inject, Injectable } from '@angular/core';
import * as shpwrite from '@fielda/shp-write';

import { Indicator } from 'src/app/models/Indicator';

import { MapService } from './map.service';
import { PlotIndicatorService } from './plotIndicator/plot-indicator.service';

('use strict');

@Injectable({
    providedIn: 'root',
})
export class DownloadService {
    private indicatorPlot: Indicator;
    private rows: any[];

    constructor(
        @Inject(MapService) private mapService: MapService,
        @Inject(PlotIndicatorService) private plotIndicatorService: PlotIndicatorService,
    ) {}

    async shapefile(indicatorData: any): Promise<Blob> {
        try {
            const indicatorId = indicatorData.id_indicateur;
            const indicatorPlot = this.plotIndicatorService.plotedIndicators[indicatorId];
            const label = indicatorData.libelle_indic_complet;
            const idKey = indicatorPlot.plotService.idKey;

            const geojson = JSON.parse(JSON.stringify(indicatorPlot.geojson));
            this.mapService.convertMultiPointToPoint(geojson, true);

            if (indicatorPlot.type == 'class') {
                geojson.features.forEach((feature: GeoJSON.Feature) => {
                    feature.properties.value = indicatorPlot.legende.find(
                        (legend: any) => legend.id_class == feature.properties.value,
                    ).lib;
                });
            }

            geojson.features.forEach((feature: GeoJSON.Feature) => {
                const value = feature.properties.originalValue;
                const territoryLabel = feature.properties[idKey];

                feature.properties = {
                    Territoire: territoryLabel,
                    [label]: value,
                };
            });

            const indicatorLabel = this.cleanLabel(indicatorData.libelle_indic_complet);
            const options = {
                folder: 'siterre',
                types: {
                    point: indicatorLabel,
                    polygon: indicatorLabel,
                    multiPolygon: indicatorLabel,
                    line: indicatorLabel,
                },
            };

            const zip = await shpwrite.zip(geojson, options);
            return zip;
        } catch (error) {
            throw error;
        }
    }

    setCsvHeader(indicatorData: any) {
        const indicatorLabel = this.cleanLabel(indicatorData.libelle_indic_complet);
        const header = ['ID', 'Nom', indicatorLabel, 'Unité'];

        if (indicatorData.info_nom) {
            const json = JSON.parse(indicatorData.info_nom);
            for (const key in json) {
                header.push(json[key]);
            }
        }
        return header;
    }

    setCsvBody(indicatorData: any) {
        const indicatorId = indicatorData.id_indicateur;
        const type = indicatorData.type;
        this.indicatorPlot = this.plotIndicatorService.plotedIndicators[indicatorId];

        type == 'valeur' ? this.setIndicatorValueData() : this.setIndicatorClassData();

        if (!!this.indicatorPlot.info_nom) {
            this.setMetadata();
        }

        return this.rows.sort((a: any, b: any) => {
            if (a.id !== b.id) return a.id - b.id;
            else {
                if (a.lib !== b.lib) return a.lib - b.lib;
                else return a.value - b.value;
            }
        });
    }

    setIndicatorValueData() {
        const geojson = this.indicatorPlot.geojson;
        const unit = this.indicatorPlot.unit || '';

        this.rows = geojson.features.map((feature: GeoJSON.Feature) => {
            const id = feature.properties.id_ter || feature.properties.id;
            const label = feature.properties.lib_ter || feature.properties.label;
            return {
                id: id,
                lib: label || id,
                value: feature.properties.value || 'NR',
                unit: unit,
            };
        });
    }

    setIndicatorClassData() {
        const geojson = this.indicatorPlot.geojson;
        const unit = this.indicatorPlot.unit || '';

        this.rows = geojson.features.map((feature: GeoJSON.Feature) => {
            const id = feature.properties.id_ter || feature.properties.id;
            const label = feature.properties.lib_ter || feature.properties.label;
            const classLegend = this.indicatorPlot.legende.find(
                (legend: any) => legend.id_class == feature.properties.value,
            );
            return {
                id: id,
                lib: label || id,
                value: classLegend.lib || '',
                unit: unit,
            };
        });
    }

    setMetadata() {
        const geojson = this.indicatorPlot.geojson;
        const metaData = JSON.parse(this.indicatorPlot.info_nom);

        for (let key in metaData) {
            geojson.features.forEach((feature: GeoJSON.Feature) => {
                const row = this.rows.find((row: any) => row.id == feature.properties.id_ter);
                if (row) {
                    const value = feature.properties[key];
                    row[key] = value || 'NR';
                }
            });
        }
    }

    cleanLabel(label: string) {
        return label
            .replaceAll('%', 'pourcent')
            .replaceAll('>', 'supérieur')
            .replaceAll('<', 'inférieur');
    }
}
