'use strict';

import * as L from 'leaflet';
import { MapService } from '../services/map.service';
import { PlotFactory } from './PlotFactory';

export class PlotMigrationFactory extends PlotFactory {
    constructor(indicatorId: number, mapService: MapService) {
        super(indicatorId, mapService);
        this.form = 'migration';
    }

    addLayer(map: L.Map) {
        if (this.geojsonLayer) {
            this.removeLayer();
        }

        const data = [];
        this.geojson.features.forEach((feature) => {
            if (feature.properties.to_x) {
                data.push({
                    from: [feature.geometry.coordinates[0], feature.geometry.coordinates[1]],
                    to: [feature.properties.to_x, feature.properties.to_y],
                    color: this.getColor(feature.properties.value),
                    value: feature.properties.value,
                });
            }

            if (feature.properties.from_x) {
                data.push({
                    to: [feature.properties.from_x, feature.properties.from_y],
                    from: [feature.geometry.coordinates[0], feature.geometry.coordinates[1]],
                    color: this.getColor(feature.properties.value),
                    value: feature.properties.value,
                });
            }
        });

        this.geojsonLayer = new (L as any).migrationLayer({
            map: map,
            data: data,
            pulseRadius: 30 * this.default_radius_weight,
            pulseBorderWidth: 3 * this.default_radius_weight,
            arcWidth: this.default_radius_weight,
            arcLabel: false,
            maxWidth: 10 * this.default_radius_weight,
        });
        this.geojsonLayer.addTo(map);
        this.geojsonLayer.show();
        this.geojsonLayer.pause();

        // FIX ME !
        // this event listener will go off as many times as how many times this method is called,
        // this is not going to end well
        map.on('moveend', () => {
            this.geojsonLayer.hide();
            this.geojsonLayer.show();
            this.geojsonLayer.pause();
        });
    }

    getStyle(geoJsonFeature: GeoJSON.Feature) {
        // use leaflet's path styling options
        // since the GeoJSON feature properties are modified by the layer,
        // developers can rely on the "isOrigin" property to set different
        // symbols for origin vs destination CircleMarker stylings

        if (geoJsonFeature.properties.isOrigin) {
            return {
                renderer: L.canvas(), //canvasRenderer, // recommended to use your own L.canvas()
                radius: 5,
                weight: 1,
                color: this.getColor(geoJsonFeature.properties.value),
                fillColor: this.getColor(geoJsonFeature.properties.value),
                fillOpacity: this.getFillOpacity(geoJsonFeature.properties.value),
            };
        } else {
            return {
                renderer: L.canvas(), // canvasRenderer,
                radius: 2.5,
                weight: 0.25,
                color: this.getColor(geoJsonFeature.properties.value),
                fillColor: this.getColor(geoJsonFeature.properties.value),
                fillOpacity: 1,
            };
        }
    }

    removeLayer() {
        this.geojsonLayer.destroy();
        delete this.geojsonLayer;
    }

    // fonction de récupération du point le plus au nord d'un polygone
    getPopupPositionX(feature) {
        let latitude = 0;
        let longitude = 0;

        let nbPoints = feature.geometry.coordinates.length;

        if (nbPoints == 2) {
            latitude =
                (feature.geometry.coordinates[0][1] + feature.geometry.coordinates[1][1]) / 2;
            longitude =
                (feature.geometry.coordinates[0][0] + feature.geometry.coordinates[1][0]) / 2;
        } else if (nbPoints > 2) {
            nbPoints = Math.floor(nbPoints / 2);
            try {
                let coord = feature.geometry.coordinates[nbPoints];
                let iteration = 0;

                while (Array.isArray(coord) && Array.isArray(coord[0]) && iteration < 10) {
                    iteration = iteration + 1;
                    coord = coord[0];
                }

                latitude = coord[1];
                longitude = coord[0];
            } catch (err) {
                latitude = null;
                longitude = null;
            }
        } else {
            latitude = null;
            longitude = null;
        }

        return [latitude, longitude];
    }
}
