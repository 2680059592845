'use strict';

import * as L from 'leaflet';
import { MapService } from '../services/map.service';
import { PlotFactory } from './PlotFactory';

export class PlotPointFactory extends PlotFactory {
    private weightMultiplier = 2.5;

    constructor(indicatorId: number, mapService: MapService) {
        super(indicatorId, mapService);
        this.form = 'point';
        this.default_radius_weight = 2;
        this.indicatorcontourcolor = '#000000';
        this.indicatorcontouropacity = 1;
    }

    addLayer(map: L.Map) {
        if (this.geojsonLayer != null) {
            this.removeLayer(map);
        }

        this.geojsonLayer = L.geoJson(this.geojson, {
            pointToLayer: this.getStyle.bind(this),
            onEachFeature: this.onEachFeature.bind(this),
        });

        map.addLayer(this.geojsonLayer);
        // this.mapService.addLayers(this.geojsonLayer.getLayers());
    }

    getStyle(feature: GeoJSON.Feature, latLng?: { lat: number; lng: number }) {
        this.indicatorcontourweight = Math.max(this.indicatorcontourweight, 1);

        return L.circleMarker(latLng, {
            radius: this.weightMultiplier * this.getWeight(feature.properties.value),
            fillColor: this.getColor(feature.properties.value),
            color: this.indicatorcontourcolor,
            weight: this.indicatorcontourweight,
            opacity: this.getContourOpacity(feature.properties.value),
            fillOpacity: this.getFillOpacity(feature.properties.value),
        });
    }

    highlightFeature(layer: any) {
        const feature = layer.feature;
        layer.setStyle({
            radius: 1.5 * this.weightMultiplier * this.getWeight(feature.properties.value),
        });
    }

    unhighlightFeature(layer: any) {
        const feature = layer.feature;
        layer.setStyle({
            radius: this.weightMultiplier * this.getWeight(feature.properties.value),
        });
    }
}
