('use strict');
import { Inject, Injectable } from '@angular/core';

import { DataToPlot, ImportedData } from '../models/FilterCategory';
import { Indicator } from '../models/Indicator';
import { TerritoryScale } from '../models/TerritoryTypes';

import { RestService } from './RestService';
import { TerService } from './TerService';

const MAX_CRITERIA_PER_FILTER = 100;

@Injectable({
    providedIn: 'root',
})
export class ImportedDataService {
    private filteredData: { [indicatorId: number]: DataToPlot } = {};
    public importedIndicator: { [indicatorId: number]: ImportedData } = {};

    constructor(
        @Inject(RestService) private restService: RestService,
        @Inject(TerService) private terService: TerService,
    ) {}

    delete(indicatorId: number) {
        delete this.importedIndicator[indicatorId];
    }

    create(
        indicatorId: number,
        data: any,
        indicator: Indicator,
        geojson: GeoJSON.FeatureCollection,
        propertiesToFilterOn?: Array<string>,
    ) {
        propertiesToFilterOn = propertiesToFilterOn ? propertiesToFilterOn : [];
        this.importedIndicator[indicatorId] = {
            data: { ...data },
            indicator: { ...indicator },
            geojson: geojson,
            propertiesToFilterOn: propertiesToFilterOn,
            form: indicator.form,
        };

        if (propertiesToFilterOn.length > 0) {
            this.setFilters(indicatorId);
        }
    }

    getAggregation(indicatorId: number) {
        this.getDterValueSum(indicatorId);
        const importedIndicator = this.importedIndicator[indicatorId];
        const total = importedIndicator.filters[0].total;
        const datasetSize = Object.keys(importedIndicator.data).length;

        const indicator = importedIndicator.indicator;
        const label = indicator.id_indic_for_filter_defaut;
        const type = indicator.type;

        // let value = null;
        // let decimalCount = null;
        // let unit = null;
        // let method = null;
        const count = total == undefined ? datasetSize : total;

        return {
            label: label,
            value: 0,
            unit: null,
            method: 'sum',
            count: Math.round(count),
            decimalCount: null,
            type: type == 'class' ? 'categorical' : 'numerical',
        };
    }

    getIndicator(indicatorId: number) {
        return this.importedIndicator[indicatorId].indicator;
    }

    async getGeoJson(
        indicatorId: number,
        granularity: TerritoryScale,
        form: string,
        year: number = 2021,
    ) {
        const indicatorImported = this.importedIndicator[indicatorId];
        if (indicatorImported.form === form) {
            // it means that the imported file is a shapefile, not a csv;
            // the geojson is already saved in this.importedIndicator[indicatorId]
            return indicatorImported.geojson;
        }

        try {
            const territoryGeojson = await this.getTerritoryGeojson(granularity, form, year);
            const features = territoryGeojson.features.filter((feature: GeoJSON.Feature) =>
                Object.keys(indicatorImported.data).includes(feature.properties.id_ter),
            );
            const geojson: GeoJSON.FeatureCollection = {
                type: 'FeatureCollection',
                features: features,
            };

            this.importedIndicator[indicatorId].geojson = geojson;
            this.importedIndicator[indicatorId].form = form;
            return geojson;
        } catch (error) {
            console.error('Error getGeoJson', error);
        }
    }

    async getTerritoryGeojson(scale: TerritoryScale, shape: string, year: number = 2021) {
        try {
            const data = {
                perimeterScaleTypeId: this.terService.territoryScale.typeId,
                perimeterTerritoryIds: this.terService.territories.map((t) => t.id),
                granularityScaleTypeId: scale.typeId,
                year: year,
                shape: shape,
            };
            const geojson = await this.restService.getGeojson(data);
            return geojson;
        } catch (error) {
            console.error('Error getTerritoryGeojson', error);
            throw error;
        }
    }

    async getGeojson(
        granularityScale: TerritoryScale,
        shape: string,
        territoryIds: string[],
        year: number = 2021,
    ) {
        try {
            const data = {
                perimeterScaleTypeId: this.terService.territoryScale.typeId,
                perimeterTerritoryIds: this.terService.territories.map((t) => t.id),
                granularityScaleTypeId: granularityScale.typeId,
                granularityTerritoryIds: territoryIds,
                year: year,
                shape: shape,
            };
            const geojson = await this.restService.getGeojson(data);
            return geojson;
        } catch (error) {
            console.error('Error getGeojson', error);
            throw error;
        }
    }

    getData(indicatorId: number) {
        const isFilterable = this.importedIndicator[indicatorId].indicator.static_dynamic === 'dyn';
        if (isFilterable) {
            return this.getFilteredData(indicatorId);
        }
        return this.importedIndicator[indicatorId].data;
    }

    private getFilteredData(indicatorId: number) {
        const geojson: GeoJSON.FeatureCollection = this.importedIndicator[indicatorId].geojson;

        const filteredData = geojson.features
            .filter((feature) => this.isFeatureNotFiltered(indicatorId, feature))
            .reduce((filteredData, feature) => {
                const territoryId = feature.properties.id_ter;
                const indicatorData = this.importedIndicator[indicatorId].data;
                filteredData[territoryId] = indicatorData[territoryId];
                return filteredData;
            }, {});

        this.filteredData[indicatorId] = filteredData;
        return filteredData;
    }

    private isFeatureNotFiltered(indicatorId: number, feature: GeoJSON.Feature) {
        // On vérifie pour chaque filtre que la feature n'est pas filtrée
        return this.importedIndicator[indicatorId].filters.every((category) =>
            category.criteria.every(
                (criteria) =>
                    criteria.active != false ||
                    (category.champ_associe != 'value' &&
                        feature.properties[category.champ_associe] != criteria.label) ||
                    (category.champ_associe == 'value' && feature.properties.value != criteria.id),
            ),
        );
    }

    private getDterValueSum(indicatorId: number) {
        const indicator = this.importedIndicator[indicatorId].indicator;
        const geojson: GeoJSON.FeatureCollection = this.importedIndicator[indicatorId].geojson;
        let toAdd = 1;

        this.importedIndicator[indicatorId].filters.forEach((category) => {
            category.total = 0;
            category.initTotal = 0;
            let associatedField = category.champ_associe;

            category.criteria.forEach((criteria) => {
                criteria.absoluteValue = 0;
                criteria.initAbsoluteValue = 0;

                if (indicator.type == 'class' && associatedField == 'value') {
                    geojson.features
                        .filter((feature) => feature.properties[associatedField] == criteria.id)
                        .forEach((feature) => {
                            if (this.isFeatureNotFiltered(indicatorId, feature)) {
                                criteria.absoluteValue += toAdd;
                                category.total += toAdd;
                            }
                            criteria.initAbsoluteValue += toAdd;
                            category.initTotal += toAdd;
                        });
                } else {
                    geojson.features
                        .filter((feature) => feature.properties[associatedField] == criteria.label)
                        .forEach((feature) => {
                            if (indicator.type == 'valeur') {
                                toAdd = feature.properties[indicator.id_indic_for_filter_defaut];
                            }

                            if (this.isFeatureNotFiltered(indicatorId, feature)) {
                                criteria.absoluteValue += toAdd;
                                category.total += toAdd;
                            }

                            criteria.initAbsoluteValue += toAdd;
                            category.initTotal += toAdd;
                        });
                }
            });
        });

        this.importedIndicator[indicatorId].filters.forEach((category) => {
            category.criteria.forEach((criteria) => {
                criteria.relativeValue = criteria.absoluteValue / category.total;
                criteria.initRelativeValue = criteria.initAbsoluteValue / category.initTotal;

                if (isNaN(criteria.relativeValue)) {
                    criteria.relativeValue = 0;
                }
                if (isNaN(criteria.initRelativeValue)) {
                    criteria.initRelativeValue = 0;
                }
            });
        });

        return this.importedIndicator[indicatorId].filters;
    }

    private getCriteriaValueElement(indicatorId: number, territoryId: string) {
        const filters = { ...this.importedIndicator[indicatorId].filters };
        const geojson: GeoJSON.FeatureCollection = this.importedIndicator[indicatorId].geojson;

        const feature = geojson.features.find(
            (feature) => feature.properties.id_ter === territoryId,
        );

        filters.forEach((category) => {
            const name = category.champ_associe;
            category.criteria.forEach((criteria) => {
                if (criteria.label === feature[name]) {
                    criteria.absoluteValue = feature[name];
                    criteria.initAbsoluteValue = feature[name];

                    criteria.relativeValue = 1;
                    criteria.initRelativeValue = 1;
                } else {
                    criteria.absoluteValue = 0;
                    criteria.initAbsoluteValue = 0;

                    criteria.relativeValue = 0;
                    criteria.initRelativeValue = 0;
                }
            });
        });

        return filters;
    }

    getCriteriaValue(indicatorId: number, territoryIds?: string[]) {
        const criteriaValue = territoryIds
            ? this.getCriteriaValueElement(indicatorId, territoryIds[0])
            : this.getDterValueSum(indicatorId);
        return criteriaValue;
    }

    private getDataSingleTerSum(indicatorId: number) {
        const dataSingleTer = [];

        const values = {};

        this.importedIndicator[indicatorId].geojson.features.forEach((feature: GeoJSON.Feature) => {
            for (const prop in feature.properties) {
                for (const id in this.importedIndicator) {
                    if (
                        this.importedIndicator[id].indicator.tbl ==
                            this.importedIndicator[indicatorId].indicator.tbl &&
                        this.importedIndicator[id].indicator.champ_p_associe == prop
                    ) {
                        if (!values[id]) {
                            values[id] = feature.properties[prop];
                        } else {
                            values[id] += feature.properties[prop];
                        }
                    }
                }
            }
        });

        for (const id in values) {
            dataSingleTer.push({
                champ_divider: ' ',
                champ_p_associe: this.importedIndicator[id].indicator.champ_p_associe,
                decimalCount: 1,
                facteur_to_main_unit: '1',
                id: id,
                libelle_indic_short: this.importedIndicator[id].indicator.libelle_indic_short,
                tbl: this.importedIndicator[id].indicator.tbl,
                unit_short: this.importedIndicator[id].indicator.libelle_indic_short,
                value: values[id],
            });
        }

        return dataSingleTer;
    }

    private getDataSingleTerElement(indicatorId: number, elementId: string) {
        const dataSingleTer = [];

        const geojson = this.importedIndicator[indicatorId].geojson;
        const feature = geojson.features.find(
            (feature: GeoJSON.Feature) => feature.properties.id_ter == elementId,
        );

        for (const key in feature.properties) {
            for (const indicatorId in this.importedIndicator) {
                if (this.importedIndicator[indicatorId].indicator.champ_p_associe == key) {
                    dataSingleTer.push({
                        champ_divider: ' ',
                        champ_p_associe: key,
                        decimalCount: 1,
                        facteur_to_main_unit: '1',
                        id: indicatorId,
                        libelle_indic_short: key,
                        tbl: this.importedIndicator[indicatorId].indicator.tbl,
                        unit_short: key,
                        value: feature.properties[key],
                    });
                    break;
                }
            }
        }
        return dataSingleTer;
    }

    getDataSingleTer(query: {
        id: number;
        filters?: string;
        scale: string;
        scale_filter_init: string;
        scale_init: string;
        year: number;
    }) {
        const indicatorId: number = query.id;
        const elementId: string = query.scale_filter_init;

        const dataSingleTer = elementId
            ? this.getDataSingleTerElement(indicatorId, elementId)
            : this.getDataSingleTerSum(indicatorId);

        return new Promise((resolve) => {
            resolve(dataSingleTer);
        });
    }

    setFilters(indicatorId: number) {
        if (this.importedIndicator[indicatorId].filters) {
            return this.importedIndicator[indicatorId].filters;
        }

        const geojson: GeoJSON.FeatureCollection = this.importedIndicator[indicatorId].geojson;
        const propertiesToFilterOn = this.importedIndicator[indicatorId].propertiesToFilterOn;

        const filters = propertiesToFilterOn
            .map((label, index: number) => {
                const criteria = [];
                const uniqueCriteria = new Set();
                let i = 0;
                while (
                    i < geojson.features.length &&
                    uniqueCriteria.size <= MAX_CRITERIA_PER_FILTER
                ) {
                    const feature = geojson.features[i];
                    const criteriaLabel = feature.properties[label];

                    if (!uniqueCriteria.has(criteriaLabel)) {
                        uniqueCriteria.add(criteriaLabel);
                        criteria.push({
                            id: criteria.length + 1,
                            label: criteriaLabel,
                            order: criteria.length + 1,
                        });
                    }
                    i++;
                }

                return {
                    id: index,
                    label: label,
                    champ_associe: label,
                    criteria: criteria,
                };
            })
            .filter((category) => category.criteria.length <= MAX_CRITERIA_PER_FILTER);

        this.importedIndicator[indicatorId].filters = filters;
        return filters;
    }

    clear() {
        this.filteredData = {};
        this.importedIndicator = {};
    }
}
