<nav class="navbar navbar-expand-lg bg-body-tertiary py-0">
    <div class="container-fluid" id="navbar-admin">
        <div id="navbar-collapse-1" class="collapse navbar-collapse">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="navBarSection nav-item d-flex align-items-center">
                    <a href="/main" class="nav-link">
                        <i class="fa fa-home fa-lg"></i>
                    </a>
                </li>
                <li class="navBarSection nav-item">
                    <a href="/administration/groups/manage/" *ngIf="user.role == 1" class="nav-link"
                        [ngClass]="{active: page == 'groups-manage'}">
                        <span>Gestion des groupes</span>
                    </a>
                </li>

                <li class="navBarSection nav-item" style="margin-left: -1px" *ngIf="user.role == 1 || user.role == 2">
                    <a href="/administration/users/manage/" [ngClass]="{active: page == 'users-manage'}"
                        class="nav-link">
                        <span>Gestion des utilisateurs</span>
                    </a>
                </li>
                <li class="navBarSection nav-item" style="margin-left: -1px" *ngIf="user.role == 1">
                    <a href="/administration/indicators/manage/" [ngClass]="{active: page == 'indicators-manage'}"
                        class="nav-link">
                        <span>Gestion des indicateurs</span>
                    </a>
                </li>

                <li class="navBarSection nav-item" style="margin-left: -1px" *ngIf="user.role == 1">
                    <a href="/administration/filters/manage/" [ngClass]="{active: page == 'filters-manage'}"
                        class="nav-link">
                        <span>Gestion des filtres</span>
                    </a>
                </li>
                <li class="navBarSection nav-item" style="margin-left: -1px" *ngIf="user.role == 1">
                    <a href="/administration/bases/manage/" [ngClass]="{active: page == 'bases-manage'}"
                        class="nav-link">
                        <span>Gestion des bases</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>