'use strict';

import * as L from 'leaflet';
import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PlotIndicatorService } from 'src/app/services/plotIndicator/plot-indicator.service';
import { PlotPolyFactory } from 'src/app/factory/PlotPolyFactory';
import { AtacService } from 'src/app/services/AtacService';
import { MapService } from 'src/app/services/map.service';
import { AtacGroup } from 'src/app/models/AtacTypes';
import { PdfExportService } from 'src/app/services/pdf-export.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@UntilDestroy()
@Component({
    selector: 'atacResultsModal',
    templateUrl: './atac-results.template.html',
})
export class AtacResultsModal implements OnInit, AfterViewInit {
    @Input() set group(value: AtacGroup) {
        this._group = value;
        this._setValues();
    }
    @Input() set index(value: number) {
        this._index = value;
    }

    private _group: AtacGroup;
    plotedIndicator: any;
    map: L.Map;
    _index: number;
    autoProduction: number;
    autoConsommation: number;
    prodLabels = ['Autoconsommée', 'Injectée'];
    prodData: number[] = [];
    prodColors = ['#22427C', '#FFA500'];
    consoLabels = ['Autoproduite', 'Alloproduite'];
    consoData: number[] = [];
    consoColors = ['#FFA500', '#AAF0D1'];
    activeTab = 'groupInfo';
    producers: {
        [index: number]: {
            [id: number]: any;
        };
    };
    consumers: {
        [index: number]: {
            [id: number]: any;
        };
    };
    raster: { id: any; name: any; url: any; attribution: any };
    isExportingPdf = false;

    constructor(
        @Inject(AtacService) private atacService: AtacService,
        @Inject(PdfExportService) private pdfExportService: PdfExportService,
        @Inject(PlotIndicatorService) private plotIndicatorService: PlotIndicatorService,
        @Inject(MapService) private mapService: MapService,
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
    ) {}

    ngOnInit(): void {
        this.atacService.producers$.pipe(untilDestroyed(this)).subscribe((producers) => {
            this.producers = producers;
        });
        this.atacService.consumers$.pipe(untilDestroyed(this)).subscribe((consumers) => {
            this.consumers = consumers;
        });
    }

    ngAfterViewInit(): void {
        this._plotIndicator();
        this._loadMap();
    }

    private _setValues() {
        console.log('_setValues');
        if (this._group) {
            /* Taux autoProduction / autoConsommation point de vue Groupement */
            this.autoProduction = Math.round(this.group.Taux_autoproduction * 100);
            this.autoConsommation = Math.round(this.group.Taux_autoconsommation * 100);

            /* Chart Production PV */
            this.prodData = [this.autoConsommation, 100 - this.autoConsommation];

            /* Chart Consommation */
            this.consoData = [this.autoProduction, 100 - this.autoProduction];
        }
    }

    private _plotIndicator() {
        const indicatorPlot: any = Object.values(this.plotIndicatorService.plotedIndicators).find(
            (indicatorPlot: any) => indicatorPlot.plugin === 'atac',
        );

        this.plotedIndicator = new PlotPolyFactory(indicatorPlot.indicatorId, this.mapService);
        for (let key in indicatorPlot) {
            const fieldsToNotCopy = ['geojsonLayer', 'geojsonlayer', 'plotService', 'mapService'];
            if (key == 'geojson') {
                this.plotedIndicator[key] = {
                    type: 'FeatureCollection',
                    features: [
                        ...indicatorPlot[key].features.filter((x) => x.properties.value > 0),
                    ],
                };
            } else if (!fieldsToNotCopy.includes(key) && typeof indicatorPlot[key] !== 'function') {
                this.plotedIndicator[key] = { ...indicatorPlot[key] };
            }
        }
        this.plotedIndicator.geojsonLayer = null;

        const raster = this.mapService.currentRaster;
        this.raster = {
            id: raster.id,
            name: raster.name,
            url: raster.url,
            attribution: raster.attribution,
        };
    }

    private _loadMap() {
        if (this.plotedIndicator) {
            this.map = L.map('mapatac');
            setTimeout(() => {
                this.map.invalidateSize();
            }, 100);
            this.map.setView(L.latLng(48.85999616743707, 2.33999978951907), 19);

            const fondCarteRaster = L.tileLayer(this.raster.url, {
                attribution: this.raster.attribution,
                maxZoom: 23,
                maxNativeZoom: 19,
            });
            fondCarteRaster.addTo(this.map);

            this.plotedIndicator.geojson.features
                .filter((x) => x.properties.value > 0)
                .forEach((x) => {
                    x.properties.lib_ter = x.properties.id_ter;
                    return x;
                });
            this.plotedIndicator.onSelectElement = (e) => {
                console.log('onselect!');
            };
            this.plotedIndicator.handleClickOnFeature = (e) => {
                console.log('select!');
            };
            //plotedIndicator.highlightFeature=function(e) {console.log('highlight')};
            this.plotedIndicator.info_nom = '{}';
            this.plotedIndicator.geojsonLayer = this.plotedIndicator.addLayer(this.map);
            const bounds = L.latLngBounds(this.plotedIndicator.geojsonLayer.getBounds());
            setTimeout(() => {
                this.map.fitBounds(bounds);
            }, 1000);
        }
    }

    highlightElementOnMap = (terId: string) => {
        this.plotedIndicator.targetElement(terId);
    };
    /* Ajouter groupement favori */
    addGroupementFav(group: AtacGroup) {
        console.log('Add to fav : ' + group);
    }

    async exportPdf() {
        this.isExportingPdf = true;
        const groupInfo = document.getElementById('groupInfo');
        const producersInfo = document.getElementById('producersInfo');
        const consumersInfo = document.getElementById('consumersInfo');
        try {
            await this.pdfExportService.exportDomElements(
                [groupInfo, producersInfo, consumersInfo],
                'atacExport',
            );
        } catch (error) {
            console.log(error);
        } finally {
            this.isExportingPdf = false;
        }
    }

    get group() {
        return this._group;
    }

    get index() {
        return this._index;
    }
}
