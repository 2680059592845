'use strict';

import { Component, Inject, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { FilterCategory, FilterCriteria } from 'src/app/models/FilterCategory';
import { Indicator } from 'src/app/models/Indicator';

import { EventService } from 'src/app/services/event.service';
import { FilterDataService } from 'src/app/services/FilterDataService';

@Component({
    selector: 'singleFilter',
    templateUrl: './single-filter.template.html',
    styleUrls: ['./single-filter.component.css'],
})
export class SingleFilterComponent implements OnInit {
    @Input() indicatorData: Indicator;
    @Input() category: FilterCategory;
    @Input() unit: string;

    public indicatorId: number;
    public isWorkInProgress = false;
    public isWaitingForData = false;
    public isWaitingForAll = false;
    private isWaitingByCriteria: any;

    constructor(
        private notification: ToastrService,
        @Inject(EventService) private eventService: EventService,
        @Inject(FilterDataService) private filterDataService: FilterDataService,
    ) {}

    ngOnInit() {
        this.indicatorId = this.indicatorData.id_indicateur;
        this.category.isCollapsed =
            this.category.isCollapsed == undefined ? true : this.category.isCollapsed;

        this.isWaitingByCriteria = this.category.criteria.reduce((isWaiting, criteria) => {
            isWaiting[criteria.id] = false;
            return isWaiting;
        }, {});
    }

    async updateFilter(criteriaId?: number) {
        this._activateLoader(criteriaId);

        try {
            await this.filterDataService.updateCriteriaValue(this.indicatorId);
            this.eventService.emit('filterCriteriaUpdated');
        } catch (error) {
            console.error('Error updateFilter', error);
            this.notification.error(
                'Une erreur est survenue. Impossible de mettre à jour le filtre.',
            );
        } finally {
            this._deactivateLoader(criteriaId);
        }
    }

    showAddAllFilters() {
        return !!(this.category.criteria as Array<FilterCriteria>)
            .filter((criteria) => criteria.initAbsoluteValue !== 0 || !!criteria.id)
            .find((criteria) => !criteria.active);
    }

    showRemoveAllFilters() {
        return !!(this.category.criteria as Array<FilterCriteria>)
            .filter((criteria) => criteria.initAbsoluteValue !== 0 || !!criteria.id)
            .find((criteria) => criteria.active);
    }

    addAllFilters() {
        this.category.criteria.forEach((criteria: FilterCriteria) => (criteria.active = true));
        this.updateFilter();
    }

    removeAllFilters() {
        this.category.criteria.forEach((criteria: FilterCriteria) => (criteria.active = false));
        this.updateFilter();
    }

    keepAvailableFilter(criteria: FilterCriteria) {
        return !!criteria.initAbsoluteValue || criteria.id != null;
    }

    private _activateLoader(criteriaId?: number) {
        if (criteriaId) {
            this.isWaitingByCriteria[criteriaId] = true;
            this.isWaitingForData = Object.values(this.isWaitingByCriteria).some((bool) => !!bool);
        } else {
            this.isWaitingForAll = true;
        }
    }

    private _deactivateLoader(criteriaId?: number) {
        if (criteriaId) {
            this.isWaitingByCriteria[criteriaId] = false;
            this.isWaitingForData = Object.values(this.isWaitingByCriteria).some((bool) => !!bool);
        } else {
            this.isWaitingForAll = false;
        }
    }
}
