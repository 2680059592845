('use strict');

import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TerritoryScale } from 'src/app/models/TerritoryTypes';
import { RestService } from 'src/app/services/RestService';
import { TerService } from 'src/app/services/TerService';
import { Base } from 'src/app/models/Base';
import { PaginationService } from 'src/app/services/pagination.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'baseManage',
    templateUrl: './base-manage.template.html',
})
export class BaseManageComponent implements OnInit {
    header = environment.header;
    sortValue = '';
    totalBases = 0;
    bases: Base[] = [];
    nbLinesPerPage = 100;
    nbLinesPerPageList = [50, 100, 150, 200, 300];
    selectedPage = 1;
    territoryScales: TerritoryScale[];

    constructor(
        private notification: ToastrService,
        @Inject(RestService) private restService: RestService,
        @Inject(PaginationService) private paginationService: PaginationService,
        @Inject(TerService) private terService: TerService,
    ) {}

    async ngOnInit(): Promise<void> {
        if (this.terService.territoryScales) {
            this.territoryScales = [...this.terService.territoryScales];
        } else {
            this.territoryScales = await this.terService.loadTerritoryScales();
        }

        try {
            const bases = await this.restService.getBases();

            this.bases = bases.map((base) => {
                base.modify = false;
                base.new = false;
                return base;
            });
            this.totalBases = bases.length;
            this.sort('tbl');
        } catch (error) {
            console.log(error);
        }
    }

    sort(value: string) {
        this.sortValue = this.paginationService.sort(this.sortValue, value);
    }

    //==================================================================================================================
    // fonction de gestion du nombre de pages sur le tableau

    tabFilter(index: number) {
        return this.paginationService.filter(index, this.nbLinesPerPage, this.selectedPage);
    }

    //==================================================================================================================
    // fonction de route

    goToNewBase() {
        this.bases.push({
            champ_ter: null,
            champ_year: null,
            crrsdc_ter: 'ter_20',
            crrsdc_ter_year_geo: 2015,
            crrsdc_ter_year_isfixe: 1,
            multi_year: 0,
            tbl: 'bd_',
            tbl_fullname: 'bd_',
            year_data: 9999,
            modify: true,
            new: true,
        });

        this.sort('new');
    }

    goToUpdateBase(base: Base) {
        base.modify = true;
    }

    async saveBase(baseToSave: Base) {
        const isBaseAlreadyExisting = this.bases.some((base) => {
            base.uuid !== baseToSave.uuid && base.tbl_fullname == baseToSave.tbl_fullname;
        });

        if (isBaseAlreadyExisting) {
            this.notification.error('Une base est déjà reliée à cette table de données.');
        } else {
            try {
                await this.restService.updateBase(baseToSave);
                baseToSave.new = false;
                baseToSave.modify = false;
                this.notification.success('La base a bien été enregistrée.');
            } catch (error) {
                this.notification.error(
                    "Une erreur est survenue : les modifications n'ont pas étés enregistrées.",
                );
            }
        }
    }

    getLabelFromIdTer(id: string) {
        const territoryScale = this.territoryScales.find((territoryScale) => {
            return territoryScale.id_terr === id;
        });

        return territoryScale ? territoryScale.libelle_long : 'Aucun';
    }
}
