('use strict');
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'stPagination',
    templateUrl: './st-pagination.template.html',
})
export class StPaginationComponent {
    public pagesNb: number;
    private _total: number;
    @Input() values: Array<number>;
    @Input() nbLinesPerPage: number;
    @Output() nbLinesPerPageChange = new EventEmitter<number>();
    @Input() text: string;
    @Input() pageSelected: number;
    @Output() pageSelectedChange = new EventEmitter<number>();

    @Input() set total(value: number) {
        this._total = value;
        this.pageCount();
    }

    get total(): number {
        return this._total;
    }

    constructor() {
        this.pageSelected = 1;
    }

    pageCount() {
        if (Math.floor(this.total / this.nbLinesPerPage) * this.nbLinesPerPage == this.total) {
            this.pagesNb = Math.floor(this.total / this.nbLinesPerPage);
        } else {
            this.pagesNb = Math.floor(this.total / this.nbLinesPerPage) + 1;
        }

        if (this.pagesNb && this.pageSelected > this.pagesNb) {
            this.pageSelected = this.pagesNb;
        }
        this.pageSelectedChange.emit(this.pageSelected);
        this.nbLinesPerPageChange.emit(this.nbLinesPerPage);
    }

    goToFirstPage() {
        this.pageSelected = 1;
        this.pageSelectedChange.emit(this.pageSelected);
    }

    goToLastPage() {
        this.pageSelected = this.pagesNb;
        this.pageSelectedChange.emit(this.pageSelected);
    }

    tabNextPage() {
        this.pageSelected = Math.min(this.pageSelected + 1, this.pagesNb);
        this.pageSelectedChange.emit(this.pageSelected);
    }

    tabPreviousPage() {
        this.pageSelected = Math.max(this.pageSelected - 1, 1);
        this.pageSelectedChange.emit(this.pageSelected);
    }
}
