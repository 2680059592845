'use strict';

import { Component, Inject, Input, OnInit, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Map, Control, DomUtil, ControlPosition } from 'leaflet';

import { DashboardService } from 'src/app/services/dashboard.service';
import { EventService } from 'src/app/services/event.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MapService } from 'src/app/services/map.service';
import { ModuleService } from 'src/app/services/module.service';

@UntilDestroy()
@Component({
    selector: 'dashboard-control',
    templateUrl: './dashboard-control.template.html',
    styleUrls: ['./dashboard-control.component.css'],
})
export class DashboardControlComponent implements OnInit, OnDestroy {
    public dashboardControl: Control;
    @Input() position: ControlPosition = 'topright';

    public isTableAvailable: boolean;
    public isPlotAvailable: boolean;

    constructor(
        @Inject(DashboardService) public dashboardService: DashboardService,
        @Inject(EventService) private eventService: EventService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(MapService) private mapService: MapService,
        @Inject(ModuleService) public moduleService: ModuleService,
    ) {}

    ngOnInit() {
        const preferences = this.localStorageService.get('preferences');
        this.isTableAvailable = preferences.modularity.dashboard.table;
        this.isPlotAvailable = preferences.modularity.dashboard.plot;

        this._initControl();
        this._listenToEvents();
        this.dashboardService.setIsPlotAvailable();
    }

    ngOnDestroy() {
        this.mapService.map.removeControl(this.dashboardControl);
    }

    private _initControl() {
        const map = this.mapService.map;
        let DashboardControl = Control.extend({
            onAdd(map: Map) {
                return DomUtil.get('dashboard-control');
            },
            onRemove(map: Map) {},
        });
        this.dashboardControl = new DashboardControl({
            position: this.position,
        }).addTo(map);
    }

    private _listenToEvents() {
        this.eventService.indicatorPloted
            .pipe(untilDestroyed(this))
            .subscribe((indicatorId: any) => this.dashboardService.setIsPlotAvailable());

        this.eventService.indicatorUnploted
            .pipe(untilDestroyed(this))
            .subscribe((indicatorId: any) => this.dashboardService.setIsPlotAvailable());
    }

    toggleTableState() {
        this.dashboardService.toggleDashboardState('table');
    }

    togglePlotState() {
        this.dashboardService.toggleDashboardState('plot');
    }
}
