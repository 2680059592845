('use strict');

import { Component, Inject, Input } from '@angular/core';
import { AuthService } from 'src/app/services/AuthService';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
    selector: 'stNavbar',
    templateUrl: './navbar.template.html',
})

export class StNavbarComponent {
    
    public user = this.localStorageService.get('user');
    @Input() page = '@';

    constructor(
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(AuthService) private authService: AuthService,

    ) {}

    logOut = () => {
        this.authService.logOut();
    };

}