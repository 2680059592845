import { Component, Inject, Input, OnInit, ViewChild, SimpleChanges } from '@angular/core';
import { ChartConfiguration, ChartOptions, Chart } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

import { ThemeService } from 'src/app/services/ThemeService';
import { UsefulService } from 'src/app/services/UsefulService';

declare module 'chart.js' {
    interface TooltipPositionerMap {
        cursor: any;
    }
}

@Component({
    selector: 'stStackedBarChart',
    templateUrl: './st-stacked-bar-chart.template.html',
    styleUrls: ['./st-stacked-bar-chart.component.scss'],
})
export class StStackedBarChartComponent implements OnInit {
    @Input() datasets: ChartConfiguration<'bar'>['data']['datasets'] = [];
    @Input() labels: string[] = [];
    @Input() displayLegend: boolean = true;
    @Input() yAxisLabel: string = '';
    @Input() xAxisLabel: string = '';
    @Input() externalTooltipHandler: (context: any) => void;

    @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

    public showMobileUi = false;

    public stackedBarChartOptions: ChartOptions<'bar'> = {
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom',
                display: this.displayLegend,
            },
            tooltip: {
                enabled: true,
                position: 'cursor',
            },
        },
        interaction: {
            mode: 'x',
            intersect: false,
        },
        scales: {
            x: {
                stacked: true,
                title: {
                    display: false,
                    text: this.xAxisLabel,
                },
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                beginAtZero: true,
                title: {
                    display: false,
                    text: this.yAxisLabel,
                },
            },
        },
    };

    public stackedBarChartData: ChartConfiguration<'bar'>['data'] = {
        datasets: [],
        labels: [],
    };

    constructor(
        // even though the services are not explicitly used here, they might be used by the externalTooltipHandler input
        @Inject(UsefulService) private usefulService: UsefulService,
        @Inject(ThemeService) private themeService: ThemeService,
    ) {}

    ngOnInit(): void {
        this.showMobileUi = this.usefulService.showMobileUi;

        this.stackedBarChartOptions.plugins.legend.display = this.displayLegend;
        if (this.xAxisLabel) {
            this.stackedBarChartOptions.scales.x.title.display = true;
            this.stackedBarChartOptions.scales.x.title.text = this.xAxisLabel;
        }
        if (this.yAxisLabel) {
            this.stackedBarChartOptions.scales.y.title.display = true;
            this.stackedBarChartOptions.scales.y.title.text = this.yAxisLabel;
        }
        if (this.externalTooltipHandler) {
            this.stackedBarChartOptions.plugins.tooltip.enabled = false;
            this.stackedBarChartOptions.plugins.tooltip.external =
                this.externalTooltipHandler.bind(this);
        }

        this.stackedBarChartData.datasets = this.datasets.map((dataset) => ({
            ...dataset,
            backgroundColor: dataset.backgroundColor || 'rgba(0, 123, 255, 0.2)',
            borderColor: dataset.borderColor || 'rgba(0, 123, 255, 1)',
            borderWidth: dataset.borderWidth !== undefined ? dataset.borderWidth : 0,
            // pointRadius: dataset.pointRadius !== undefined ? dataset.pointRadius : 0,
            pointBackgroundColor: dataset.borderColor || 'rgba(0, 123, 255, 1)',
        }));
        this.stackedBarChartData.labels = this.labels;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.externalTooltipHandler && !changes.externalTooltipHandler.isFirstChange()) {
            this.stackedBarChartOptions.plugins.tooltip.enabled = false;
            this.stackedBarChartOptions.plugins.tooltip.external =
                this.externalTooltipHandler.bind(this);
        }
        this._updateChart();
    }

    private _updateChart() {
        this.stackedBarChartData.datasets = this.datasets.map((dataset) => ({
            ...dataset,
            backgroundColor: dataset.backgroundColor || 'rgba(0, 123, 255, 0.2)',
            borderColor: dataset.borderColor || 'rgba(0, 123, 255, 1)',
            borderWidth: dataset.borderWidth !== undefined ? dataset.borderWidth : 2,
            pointBackgroundColor: dataset.borderColor || 'rgba(0, 123, 255, 1)',
        }));

        this.chart?.update();
    }
}
