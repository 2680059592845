('use strict');

import { ActivatedRoute, Router } from '@angular/router';
import { Component, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Indicator } from 'src/app/models/Indicator';
import { SubTheme, Theme } from 'src/app/models/Theme';
import { ColorService } from 'src/app/services/ColorService';
import { DistributionValueService } from 'src/app/services/plotIndicator/distributions/DistributionValueService';
import { RestService } from 'src/app/services/RestService';
import { TerService } from 'src/app/services/TerService';
import { ConfirmationModalComponent } from '../../main/modals/confirmation/confirmation-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    templateUrl: './indicator-update.template.html',
})
export class UpdateIndicatorComponent {
    indicatorId: number;
    reference: any;
    granularityMin: any;
    listIndicatorsByTheme: Theme[];
    subThemes: SubTheme[];
    colortypes: { mono: { [id: string]: any }; bi: { [id: string]: any } } = {
        mono: {},
        bi: {},
    };
    gradientType = 'mono';
    categories = [
        {
            id: 'general',
            lib: 'Général',
            definition: "Informations générales de l'indicateur",
        },
        {
            id: 'base',
            lib: 'Base de données',
            definition: 'Gestion du stockage des données en base',
        },

        {
            id: 'legende',
            lib: 'Affichage',
            definition: 'Gestion de la légende',
        },

        {
            id: 'Autre',
            lib: 'Autres',
            definition: 'Autres paramètres',
        },
    ];

    indicator = {
        allow_float: 0,
        champ_divider: null,
        champ_p_associe: null,
        decimalCount: 0,
        defautClassMethod: 'quantile',
        defaut_class_number: 5,
        degrade: 'GrnYelRed',
        exclude_null: 1,
        facteur_to_main_unit: '1',
        form: 'poly',
        granularityMin: 'ter_01',
        granularity_min_int: 1,
        granularity_max: 'ter_99',
        hide_if_null: 1,
        id_archi_class: null,
        id_archi_critdter: null,
        id_indic_for_filter_defaut: null,
        id_indicateur: null,
        info_description: null,
        info_nom: null,
        libelle_indic_complet: null,
        libelle_indic_short: null,
        //separate_zero_in_lgd: null,
        static_dynamic: null,
        tbl: null,
        text_null: null,
        type: null,
        unit: null,
        unit_short: null,
        vector_base: null,
        vector_field: null,
        vector_id_field: null,
        zero_if_null_dyn: 0,
        source: '',
        type_donne: null,
    };

    archiFilters = [];
    private defautClassMethod;
    newTheme = '';
    newSubTheme = '';
    indicators: Indicator[];

    constructor(
        private notification: ToastrService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private modalService: NgbModal,
        @Inject(ColorService) private colorService: ColorService,
        @Inject(DistributionValueService)
        private distributionValueService: DistributionValueService,
        @Inject(RestService) private restService: RestService,
        @Inject(TerService) private terService: TerService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.activatedRoute.data.subscribe(({ indicator }) => {
            this.indicator = indicator;
            this.indicatorId = indicator.id_indicateur;
        });
        this.defautClassMethod = this.distributionValueService.methodsInfo;
        try {
            await this._setTerritoryScales();
            this.granularityMin = [...this.terService.territoryScales];
            this.reference = await this.restService.getIndicatorsReference();
            const filters = await this.restService.getFiltersAdmin();
            this.archiFilters = filters.map((filter) => {
                return {
                    id: filter.id_archi_critdter,
                    label: filter.nom_archi_critdter,
                    filter: filter.tbl,
                };
            });

            this._initThemes();
            this._initIndicators();
            this._setColors();
        } catch (error) {
            console.log(error);
        }
    }

    goToAdminIndicator() {
        this.router.navigate(['administration/indicators/manage/']);
    }

    validate() {
        const body = [
            'Voulez vous enregistrer les modifications apportées de cet indicateur ?',
            'Cette action est irreéersible !',
        ].join('<br/>');

        const modalRef = this.modalService.open(ConfirmationModalComponent);
        modalRef.componentInstance.body = body;
        modalRef.result.then(
            (result) => {
                this.confirmValidate();
            },
            () => {},
        );
    }

    async confirmValidate() {
        try {
            await this.restService.updateIndicator(this.indicatorId, this.indicator);
            var indicatorsToAdd = [];

            this.listIndicatorsByTheme.forEach((theme: Theme) => {
                if (theme.active) {
                    theme.ss_theme.forEach((subTheme: SubTheme) => {
                        if (subTheme.active) {
                            indicatorsToAdd.push({
                                id_theme: theme.id_theme,
                                id_ss_theme: subTheme.id_ss_theme,
                                id_indicateur: this.indicatorId,
                                ordre: subTheme.indicators.length + 1,
                            });
                        }
                    });
                }
            });
            if (indicatorsToAdd.length > 0) {
                await this.restService.updateTheme(indicatorsToAdd);
            }
            this.notification.success("L'indicateur a bien été créé.");
            this.goToAdminIndicator();
        } catch (error) {
            this.notification.error(
                "Une erreur est survenue: Impossible d'enregistrer l'indicateur.",
            );
            console.log(error);
        }
    }

    private async _initThemes() {
        try {
            this.listIndicatorsByTheme = await this.restService.getIndicatorByModule();
            this.listIndicatorsByTheme.forEach((theme) => {
                theme.ss_theme.forEach((subTheme) => {
                    subTheme.active = false;
                });
                theme.active = false;
                theme.open = false;
            });
            this.subThemes = await this.restService.getAllSubThemes();
            this.updateStars();
        } catch (error) {
            console.log(error);
        }
    }

    updateStars() {
        this.listIndicatorsByTheme.forEach((theme) => {
            theme.active = false;
            theme.ss_theme.forEach((subTheme) => {
                if (subTheme.active) theme.active = true;
            });
        });
    }

    async addSubTheme(theme: Theme, subTheme: SubTheme) {
        if (subTheme) {
            theme.ss_theme.push({
                id_ss_theme: subTheme.id_ss_theme,
                indicators: [],
                libelle: subTheme.libelle,
                ordre: theme.ss_theme.length + 1,
                active: true,
            });
        } else {
            const newSubTheme = {
                id_theme: theme.id_theme,
                libelle: theme.newSubTheme,
                ordre: theme.ss_theme.length + 1,
            };

            try {
                const createdSubTheme = await this.restService.createSubTheme(newSubTheme);
                theme.ss_theme.push({
                    id_ss_theme: createdSubTheme.id_ss_theme,
                    indicators: [],
                    libelle: theme.newSubTheme,
                    ordre: theme.ss_theme.length + 1,
                    active: true,
                });
                theme.newSubTheme = '';
                this.notification.success('Le sous - module a bien été enregistré.');
            } catch (error) {
                console.log(error);
            }
        }
    }

    async addTheme() {
        const newTheme = { libelle: this.newTheme };
        try {
            const createdTheme = await this.restService.createTheme(newTheme);
            this.listIndicatorsByTheme.push({
                id_theme: createdTheme.id_theme,
                libelle: this.newTheme,
                open: true,
                ordre: this.listIndicatorsByTheme.length + 1,
                ss_theme: [],
            });
            this.newTheme = '';
        } catch (error) {
            console.log(error);
        }
    }

    private _setTerritoryScales = () => {
        if (!this.terService.territoryScales) {
            return this.terService.initTerritory(false, false);
        }
        return;
    };

    private _setColors() {
        this.colortypes = {
            mono: {},
            bi: {},
        };

        const colorChoices = this.colorService.colorCatalog;
        colorChoices.forEach((colorChoice) => {
            const obj = {
                colors: this.colorService.getColorPalette(
                    colorChoice.name,
                    this.indicator.defaut_class_number,
                ),
                name: colorChoice.name,
            };
            this.colortypes[colorChoice.type][colorChoice.id] = obj;
        });

        for (let type in this.colortypes) {
            for (let id in this.colortypes[type]) {
                if (this.colortypes[type][id].name == this.indicator.degrade) {
                    this.gradientType = type;
                }

                this.colortypes[type][id].colorsIndexed = [];
                this.colortypes[type][id].colors.forEach((color: any, index: number) => {
                    this.colortypes[type][id].colorsIndexed.push({
                        id: index,
                        color: color,
                    });
                });
            }
        }
    }
    private _initIndicators() {
        this.reference.forEach((ref) => {
            ref.data_type = 'select';
            switch (ref.field) {
                case 'granularity_min':
                case 'granularity_max':
                    ref.data = this.granularityMin.map((scale) => {
                        return {
                            id: scale.id_terr,
                            label: scale.libelle_long,
                        };
                    });
                    break;
                case 'type':
                    ref.data = [
                        { id: 'valeur', label: 'continu' },
                        { id: 'class', label: 'discret' },
                    ];
                    break;
                case 'static_dynamic':
                    ref.data = [
                        { id: 'stat', label: 'Non' },
                        { id: 'dyn', label: 'Oui' },
                    ];
                    break;
                case 'form':
                    ref.data = [
                        { id: 'poly', label: 'polygone' },
                        { id: 'poly3D', label: 'polygone 3D' },
                        { id: 'point', label: 'point' },
                        { id: 'line', label: 'ligne' },
                    ];
                    break;
                case 'defaut_class_method':
                    ref.data = this.defautClassMethod.map((item) => {
                        return {
                            id: item.id,
                            label: item.label,
                        };
                    });
                    ref.data_type = 'select';
                    break;
                case 'id_indic_for_filter_defaut':
                    ref.data = this.indicators?.map((indicator) => {
                        return {
                            id: indicator.id_indicateur,
                            label: `${indicator.libelle_indic_short} (${indicator.unit_short})`,
                            filter: indicator.tbl,
                        };
                    });
                    ref.data_type = 'input'; //must be select_filter
                    break;
                case 'id_archi_critdter':
                    ref.data = this.archiFilters;
                    break;
                case 'type_donnee':
                    ref.data = [
                        { id: 1, label: 'Statistique' },
                        { id: 2, label: 'Modélisation' },
                        { id: 3, label: 'Donnée réelle' },
                    ];
                    break;

                case 'info_description':
                case 'source':
                case 'info_nom':
                    ref.data_type = 'textarea';
                    break;
                case 'degrade':
                    ref.data_type = 'color';
                    break;
                case 'id_archi_class':
                case 'champ_p_associe':
                case 'tbl':
                case 'champ_divider':
                case 'defaut_class_number':
                case 'decimal_count':
                case 'facteur_to_main_unit':
                case 'text_null':
                case 'libelle_indic_complet':
                case 'unit':
                case 'unit_short':
                case 'libelle_indic_short':
                case 'vector_field':
                case 'vector_id_field':
                    ref.data_type = 'input';
                    ref.vector_id_field = 'input';
                    break;
                case 'exclude_null':
                case 'hide_if_null':
                case 'zero_if_null_dyn':
                case 'separate_zero_in_lgd':
                    ref.data_type = 'checkbox';
                    break;
                case 'vector_base':
                    ref.data = [
                        { id: 'territories', label: 'territories' },
                        { id: 'none', label: 'none' },
                        { id: 'solaire', label: 'solaire' },
                        { id: 'res_01_01', label: 'res_01_01' },
                        { id: 'res_01_05', label: 'res_01_05' },
                        { id: 'res_01_08', label: 'res_01_08' },
                        { id: 'res_01_09', label: 'res_01_09' },
                        { id: 'res_01_10', label: 'res_01_10' },
                        { id: 'res_01_15', label: 'res_01_15' },
                        { id: 'res_01_18', label: 'res_01_18' },
                        { id: 'res_01_20', label: 'res_01_20' },
                    ];
                    break;
            }
        });
    }
}
