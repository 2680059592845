'use strict';

import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { TerritoryLabel, TerritoryScale, Territory } from 'src/app/models/TerritoryTypes';

import { CatalogService } from 'src/app/services/catalog.service';
import { EventService } from 'src/app/services/event.service';
import { LoaderService } from 'src/app/services/LoaderService';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ModuleService } from 'src/app/services/module.service';
import { PlausibleAnalyticsService } from 'src/app/services/plausible-analytics.service';
import { TerService } from 'src/app/services/TerService';

@UntilDestroy()
@Component({
    selector: 'territorySelectionModal',
    templateUrl: './territory-selection-modal.template.html',
    styleUrls: ['./territory-selection-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TerritorySelectionModalComponent {
    selected = {
        year: null,
        territoryScale: null,
        territories: [],
    };
    yearOptions: number[];
    territoryScalesOptions: TerritoryScale[];
    territoryScales: TerritoryScale[] = [];
    territories: TerritoryLabel[] = [];

    isValidateDisabled: boolean = false;
    isLoading = false;
    isDataLoading = {
        years: false,
        territoryScales: false,
        territories: false,
    };

    constructor(
        private activeModal: NgbActiveModal,
        private notification: ToastrService,
        @Inject(CatalogService) private catalogService: CatalogService,
        @Inject(EventService) private eventService: EventService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(LoaderService) private loaderService: LoaderService,
        @Inject(ModuleService) private moduleService: ModuleService,
        @Inject(PlausibleAnalyticsService)
        private plausibleAnalyticsService: PlausibleAnalyticsService,
        @Inject(TerService) private terService: TerService,
    ) {}

    ngOnInit(): void {
        this._init();
        this._listenToEvents();
    }

    private _listenToEvents() {
        this.eventService.territoryPloted.pipe(untilDestroyed(this)).subscribe(() => this._init());

        this.eventService.territorySelectionShow
            .pipe(untilDestroyed(this))
            .subscribe(() => this._init());
    }

    private _init() {
        this.yearOptions = this.terService.geoYears;
        this.selected.year = this.terService.geoYear;
        this.territoryScales = this.terService.territoryScales;

        this.territoryScalesOptions = this.territoryScales.filter(
            (territoryScale: TerritoryScale) => territoryScale.order >= 3, // iris order = 3
        );
        this.territoryScalesOptions.sort(
            (a: TerritoryScale, b: TerritoryScale) => b.order - a.order,
        );

        if (this.terService.territoryScale) {
            this.selected.territoryScale = this.territoryScales.find(
                (t: TerritoryScale) => t.id == this.terService.territoryScale.id,
            );
        }
        if (this.terService.territories) {
            this.selected.territories = [...this.terService.territories];
        }

        if (this.terService.territoryScale) {
            this._getTerritoriesLabel();
        }
    }

    private async _getTerritoriesLabel() {
        try {
            this.territories = await this.terService.getTerritoryLabelByYear(
                this.selected.territoryScale.year,
                this.selected.territoryScale.type,
            );
        } catch (error: any) {
            console.error('Error getTerritoriesLabel', error);
            this.notification.error(
                'Une erreur est survenue. Impossible de charger la liste des territoires.',
            );
            throw error;
        }
    }

    async changeYear(year: number) {
        if (year == this.selected.year) {
            return;
        }

        this.selected.year = year;
        this.selected.territories = [];

        try {
            this.isDataLoading.territoryScales = true;
            this.territoryScales = await this.terService.loadTerritoryScales(
                this.selected.year,
                false,
            );
            this.isDataLoading.territoryScales = false;

            this.territoryScalesOptions = this.territoryScales.filter(
                (territoryScale: TerritoryScale) => territoryScale.order >= 3, // iris order = 3
            );
            this.territoryScalesOptions.sort(
                (a: TerritoryScale, b: TerritoryScale) => b.order - a.order,
            );
            const territoryScale = this.territoryScalesOptions.find(
                (t: TerritoryScale) => t.typeId == this.selected.territoryScale.typeId,
            );
            if (territoryScale) {
                this.selected.territoryScale = territoryScale;
            } else {
                this.selected.territoryScale = this.territoryScalesOptions[0];
            }
            return this.selectTerritoryScale();
        } catch (error) {
            console.error('Error selectGeoYear', error);
        } finally {
            this.isDataLoading.years = false;
        }
    }

    async selectTerritoryScale() {
        try {
            await this._updateTerritories();
            this._isSelectionModified();
        } catch (error) {
            console.error('Error selectTerritoryScale', error);
            throw error;
        }
    }

    private async _updateTerritories() {
        this.selected.territories = [];

        const isSelectedTerritoryScaleBuilding = this.selected.territoryScale.type == 'ter_01';
        const isSelectedTerritoryScaleParcel = this.selected.territoryScale.type == 'ter_02';

        if (!isSelectedTerritoryScaleBuilding && !isSelectedTerritoryScaleParcel) {
            this.isDataLoading.territories = true;
            this.territories = [];
            this._isSelectionModified();

            try {
                const year = this.selected.territoryScale.year;
                const type = this.selected.territoryScale.type;
                const territories = await this.terService.getTerritoryLabelByYear(year, type);
                if (
                    !this.selected.territoryScale.year == year ||
                    !this.selected.territoryScale.type == type
                ) {
                    this;
                    return;
                }

                this.territories = territories;

                if (this.territories.length == 1) {
                    this.addTerritory(this.territories[0]);
                }
            } catch (error) {
                console.error('Error updateTerritories', error);
                this.territories = [];
                throw error;
            } finally {
                this.isDataLoading.territories = false;
            }
        } else {
            this.territories = [];
            this.selected.territories = [];
        }
    }

    private _isSelectionModified() {
        // const isYearModified = this.terService.geoYear !== this.selected.year;
        // const isTerriroryScaleModified =
        //     this.terService.territoryScale.type !== this.selected.territoryScale.type;
        // const isTerritoryModified =
        //     JSON.stringify(this.terService.territories.map((t: any) => t.id).sort()) !==
        //     JSON.stringify(this.selected.territories.map((t: any) => t.id).sort());

        const isTerritoriesDefined = this.selected.territories.length > 0;
        this.isValidateDisabled = !isTerritoriesDefined;
        // || !(isYearModified || isTerriroryScaleModified || isTerritoryModified);
    }

    addTerritory(territoryToAdd: TerritoryLabel) {
        const alreadyAddedTerritory = this.selected.territories.find(
            (territory: any) => territory.id == territoryToAdd.id,
        );

        if (!alreadyAddedTerritory) {
            this.selected.territories.push({ ...territoryToAdd });
            this._isSelectionModified();
        }
    }

    removeTerritory(index: number) {
        this.selected.territories.splice(index, 1);
        this._isSelectionModified();
    }

    async validate() {
        const isSameyear = this.selected.year == this.terService.geoYear;
        const isSameTerritoryScale =
            this.selected.territoryScale.type == this.terService.territoryScale.type;
        const isSameTerritories =
            JSON.stringify(this.selected.territories.map((t: any) => t.id).sort()) ==
            JSON.stringify(this.terService.territories.map((t: Territory) => t.id).sort());

        if (isSameyear && isSameTerritoryScale && isSameTerritories) {
            this.activeModal.close('confirm');
        }

        this.loaderService.start('validNewTerritory');
        this.isValidateDisabled = true;
        this.isLoading = true;

        this.terService.saveTerritoryScales(this.territoryScales);
        this.terService.updateGeoYear(this.selected.year);
        this.terService.updateTerritoryScale(this.selected.territoryScale);
        this.terService.updateTerritories(this.selected.territories);

        try {
            await this.terService.updateTerritoryLayer();
            this.moduleService.openModule('catalog');
            this.eventService.emit('selectionTerritoryUpdated');
            this.activeModal.close('confirm');
        } catch (error: any) {
            console.error('Error validate', error);
            this.notification.error(
                'Une erreur est survenue. Impossible de charger le territoire.',
            );
        } finally {
            this.isValidateDisabled = false;
            this.isLoading = false;
            this.loaderService.stop('validNewTerritory');

            const user = this.localStorageService.get('user');
            this.plausibleAnalyticsService.trackEvent('Territoires', {
                utilisateur_id: user.id,
                echelle: this.selected.territoryScale.type,
                echelle_nom: this.selected.territoryScale.labelLong,
                territoires_nom: JSON.stringify(this.selected.territories.map((t) => t.label)),
                territoires_ids: JSON.stringify(this.selected.territories.map((t) => t.id)),
            });
        }
    }

    close() {
        this.selected.year = this.terService.geoYear;
        this.selected.territoryScale = null;
        this.selected.territories = [];

        if (this.terService.territoryScale) {
            this.selected.territoryScale = this.territoryScales.find(
                (t: TerritoryScale) => t.id == this.terService.territoryScale.id,
            );
        }
        if (this.terService.territories) {
            this.selected.territories = [...this.terService.territories];
        }

        this.activeModal.dismiss('cancel');
    }
}
