('use strict');
import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'stColor',
    template: `<input
        type="color"
        class="form-control"
        id="{{ innerId }}"
        [(ngModel)]="val"
        (ngModelChange)="onChange($event)"
    />`,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => StColorComponent),
            multi: true,
        },
    ],
})
export class StColorComponent implements ControlValueAccessor {
    @Input() name: string;
    @Input() innerId: string;

    constructor() {}
    onChange: any = () => {};
    onTouch: any = () => {};
    val = '';

    set value(val: string) {
        if (val !== undefined && this.val !== val) {
            this.val = val;
            this.onChange(val);
            this.onTouch(val);
        }
    }

    writeValue(value: string) {
        this.value = value;
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }
}
