<div>
    <div class="modal-header flex-column align-items-start cursor-default">
        <h4 class="modal-title">Comment ça marche ?</h4>
    </div>

    <div class="separator">
        <hr />
    </div>

    <div class="modal-body cursor-default">
        <div class="container-fluid px-0">
            <div class="row g-3">
                <div class="col-xl-8 d-flex flex-column justify-content-center">
                    <img
                        src="../../../../../assets/img/cadastre-solaire/tutorial_sydesl_label.png"
                        alt="tutorial"
                        width="100%"
                        height="100%"
                    />
                </div>

                <div class="col-xl-4">
                    <h5 class="text-inherit fw-bold">
                        Une fois votre adresse renseignée, vous êtes dirigés vers l'interface
                        ci-contre.
                    </h5>
                    <ul class="list-group mb-0">
                        <li class="list-group-item border-0 ps-0 pb-0 no-bg">
                            1 - Visualisez le potentiel solaire de votre toiture grâce à
                            l’indicateur colorimétrique.
                        </li>
                        <li class="list-group-item border-0 ps-0 pb-0 no-bg">
                            2 - Cliquez sur votre toiture pour connaitre ses caractéristiques.
                        </li>
                        <li class="list-group-item border-0 ps-0 pb-0 no-bg">
                            3 - Modifiez le type d’indicateur.
                        </li>
                        <li class="list-group-item border-0 ps-0 pb-0 no-bg">
                            4 - Sélectionnez plusieurs toitures en cliquant sur « Ajouter à la
                            sélection ».
                        </li>
                        <li class="list-group-item border-0 ps-0 pb-0 no-bg">
                            5 - Modifiez la mesure estimée en cliquant sur « Personnaliser la
                            surface disponible ».
                        </li>
                        <li class="list-group-item border-0 ps-0 pb-0 no-bg">
                            6 - Simulez la production d’eau chaude sanitaire ou d’électricité et
                            estimez les gains en revente ou en autoconsommation.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="separator">
        <hr />
    </div>

    <div class="modal-footer">
        <stButton
            btnLabel="Fermer"
            btnClass="bg-blue border-blue text-white rounded-pill px-4"
            (click)="activeModal.dismiss()"
        ></stButton>
    </div>
</div>
