<stHeader [isAdminPage]="true"></stHeader>
<stNavbar page="users-manage"></stNavbar>

<div class="container py-4">
  <h2 class="text-st-primary mb-4">
    <span class="fa fa-user me-3"></span>
    Gestion des utilisateurs
  </h2>
  <div class="row mb-4 d-flex align-items-center">
    <div class="col-lg">
      <div class="text-justify">
        <p>
          En tant qu'administrateur de compte, vous avez à charge la gestion des utilisateurs de votre entité.
        </p>
        <p>
          On distingue deux types d'utilisateurs :
        </p>
        <ul>
          <li>
            Les administrateurs locaux : ils peuvent créer, modifier et supprimer des utilisateurs sur leur périmètre
            géographique.
          </li>
          <li>
            Les utilisateurs : ils ont un simple accès à l'applications.
          </li>
        </ul>
        <p>
          Le tableau ci-dessous vous permet de gérer les utilisateurs de votre entité.
        </p>
      </div>
    </div>

    <div class="col-lg-4">
      <div class="card">
        <div class="card-body p-4">
          <h4 class="modal-title">Dashboard administation des utilisateurs</h4>
          <div class="mb-3" *ngIf="users.length">
            <div class="p-1">
              <b>Nombre d'utilisateurs</b> : {{ userCount }} {{ userCount > 1 ? 'utilisateurs' : 'utilisateur'}}
            </div>
            <div class="p-1">
              <b>Nombre d'administrateurs locaux</b> : {{ localAdminCount }} {{ localAdminCount > 1 ? 'utilisateurs' :
              'utilisateur'}}
            </div>
            <div *ngIf="isAdmin" class="p-1">
              <b>Nombre de super administrateurs</b> : {{ superAdminCount }} {{ superAdminCount > 1 ? 'utilisateurs' :
              'utilisateur'}}
            </div>
          </div>

          <stButton btnLabel="Créer un utilisateur" btnClass="btn-st-primary" (click)="goToNewUser()"></stButton>
        </div>
      </div>
    </div>
  </div>

  <form *ngIf="users.length">
    <div class="row mb-2">
      <div class="col-sm-auto">
        <div class="row">
          <div class="col px-0 position-relative">
            <input id="table-complete-search" type="text" class="form-control px-5" name="searchTerm"
              [(ngModel)]="stTableService.searchTerm" placeholder="Rechercher" />
            <i class="fa fa-search position-absolute top-50 translate-middle-y"></i>
          </div>
        </div>
      </div>
      <span class="col col-form-label" *ngIf="stTableService.loading$ | async">Chargement...</span>
    </div>

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col" sortable="id" (sort)="onSort($event)" class="id-col">ID</th>
          <th scope="col" sortable="name" (sort)="onSort($event)" class="name-col">Nom</th>
          <th scope="col" sortable="surname" (sort)="onSort($event)" class="surname-col">Prénom</th>
          <th scope="col" sortable="login" (sort)="onSort($event)" class="login-col">Identifiant</th>
          <th scope="col" sortable="mail" (sort)="onSort($event)" class="mail-col">Mail</th>
          <th scope="col" sortable="role" (sort)="onSort($event)" class="role-col">Rôle</th>
          <th scope="col" sortable="group" (sort)="onSort($event)" class="group-col">Groupe</th>
          <th scope="col" sortable="d_nb_con" (sort)="onSort($event)" class="connection-count-col">
            Nombre&nbsp;de<br>connexion</th>
          <th scope="col" sortable="lastConnectionDateTime" (sort)="onSort($event)" class="last-connection-col">
            Dernière<br>connexion
          </th>
          <th scope="col" class="handling-col">Gestion</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of users$ | async">
          <td scope="row">
            <ngb-highlight [result]="user.id" [term]="stTableService.searchTerm">
            </ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="user.name" [term]="stTableService.searchTerm">
            </ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="user.surname" [term]="stTableService.searchTerm">
            </ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="user.login" [term]="stTableService.searchTerm">
            </ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="user.mail" [term]="stTableService.searchTerm">
            </ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="user.roleName" [term]="stTableService.searchTerm">
            </ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="user.group_name" [term]="stTableService.searchTerm">
            </ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="user.d_nb_con" [term]="stTableService.searchTerm">
            </ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="user.lastConnectionDate" [term]="stTableService.searchTerm">
            </ngb-highlight>
          </td>
          <td>
            <ng-template #tooltipContent>
              <div>Voir / modifier</div>
            </ng-template>
            <span (click)="goToUpdateUser(user)" class="fa fa-pencil-square-o cursor-pointer" style="padding:5px"
              [ngbTooltip]="tooltipContent"></span>
            <ng-template #tooltipContentDel>
              <div>Supprimer</div>
            </ng-template>
            <span class="fa fa-trash-o cursor-pointer" style="padding:5px" (click)="openDeleteUser(user)"
              [ngbTooltip]="tooltipContentDel"></span>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="d-flex justify-content-between p-2">
      <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="stTableService.page"
        [pageSize]="stTableService.pageSize" [maxSize]="5" [rotate]="true" [boundaryLinks]="true">
      </ngb-pagination>

      <select class="form-select selectPageSize" name="pageSize" [(ngModel)]="stTableService.pageSize">
        <option [ngValue]="value" *ngFor="let value of stTableService.pageSizeOptions">{{ value }}</option>
      </select>
    </div>
  </form>
</div>