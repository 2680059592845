'use strict';

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, skip, takeUntil } from 'rxjs/operators';

import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SolarPvSurplusService } from 'src/app/services/cadastre-solaire/cadastre-solaire-pv-surplus.service';

@Component({
    selector: 'cadastreSolairePvSurplus',
    templateUrl: './cadastre-solaire-pv-surplus.template.html',
})
export class CadastreSolairePvSurplusComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();

    public stage: string;
    public modularity: any;

    constructor(
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(SolarPvSurplusService)
        private solarPvSurplusService: SolarPvSurplusService,
    ) {}

    ngOnInit(): void {
        this.modularity = this.localStorageService.get('preferences').modularity.solar;

        this.stage = 'equipmentsStage';
        this._listenToEvents();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private _listenToEvents() {
        this.solarPvSurplusService.pvSurplusStageObs$
            .pipe(skip(1), takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((stage) => {
                if (stage) {
                    this.stage = stage;
                }
            });
    }
}
