<div
    ngbTooltip="{{ percentageValue | percentage: 1: true }}% - {{ absoluteValue | stringifyNumber }} {{ unit }}"
    placement="left"
>
    <div class="st-progress-bar" [ngClass]="type">
        <div class="progress">
            <div
                class="progress-bar"
                role="progressbar"
                [ngStyle]="{width: (percentageValue < 1 ? percentageValue * 100 : 100) + '%'}"
                aria-labelledby="progressbar"
            ></div>
        </div>
    </div>
</div>
