import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    set(name: string, value: any) {
        const stringValue = JSON.stringify(value);
        localStorage.setItem(name, stringValue);
    }

    get(name: string) {
        const stringValue = localStorage.getItem(name);
        try {
            return JSON.parse(stringValue);
        } catch (error) {
            return stringValue;
        }
    }

    remove(name: string) {
        localStorage.removeItem(name);
    }

    clear() {
        // some data must be cleared, some must be kept,
        // so we remove the ones that must be cleared one by one
        const toBeCleared = [
            'address',
            'logo',
            'name_site',
            'preferences',
            'profile',
            'prosperGroup',
            'prosperToken',
            'token',
            'user',
        ];
        toBeCleared.forEach((key) => this.remove(key));
    }
}
