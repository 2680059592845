import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/AuthService';

@Component({
    selector: 'app-external-login',
    template: '',
})
export class ExternalLoginComponent implements OnInit {
    constructor(
        @Inject(AuthService) private authService: AuthService,
        private route: ActivatedRoute,
    ) {}

    ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            const group = params.get('group');
            const token = params.get('token');
            this.authService.externalLogIn(group, token);
        });
    }
}
