import { Inject, Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './AuthService';

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(private _router: Router, @Inject(AuthService) private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const role = route.data.role;
        return this.authService.checkRouting(role);
    }
}
