'use strict';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, Inject, OnInit } from '@angular/core';

import { AtacService } from 'src/app/services/AtacService';
import { EventService } from 'src/app/services/event.service';
import { ModuleService } from 'src/app/services/module.service';

@UntilDestroy()
@Component({
    selector: 'atac',
    templateUrl: './atac.template.html',
})
export class AtacComponent implements OnInit {
    tabActive: string;

    constructor(
        @Inject(AtacService) private atacService: AtacService,
        @Inject(EventService) private eventService: EventService,
        @Inject(ModuleService) public moduleService: ModuleService,
    ) {}

    ngOnInit(): void {
        this._initialisation();

        this.atacService.tabActive$.pipe(untilDestroyed(this)).subscribe((tabName) => {
            this.tabActive = tabName;
        });

        this.eventService.newTerritory.pipe(untilDestroyed(this)).subscribe(() => {
            //Reset
            this._initialisation();
        });
    }

    goToTab(tabName: string) {
        this.atacService.setTab(tabName);
    }

    private _initialisation() {
        this.goToTab('parameter');
    }
}
