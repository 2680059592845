('use strict');

import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
    providedIn: 'root',
})
export class FilesService {
    readCsv(file: Blob): Promise<any[]> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = (e) => {
                try {
                    const textData = e.target.result.toString();
                    const arrayData = this.decodeFileIntoArray(textData, ';');
                    resolve(arrayData);
                } catch (e) {
                    reject(e);
                }
            };
            reader.onerror = (e) => {
                reject(e);
            };

            reader.readAsText(file);
        });
    }

    // =============== Fonction de traitement de fichier recu ==========================================================
    private decodeFileIntoArray(data: string, separator: string) {
        return data.split(/\r?\n/).map((line) => line.split(separator));
    }

    readXlsxFile(
        file: File,
        sheetToJsonConverters?: Array<(ws: XLSX.WorkSheet) => any>,
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            const reader: FileReader = new FileReader();

            reader.onload = (e: any) => {
                const arrayBuffer: ArrayBuffer = e.target.result;
                const data: Uint8Array = new Uint8Array(arrayBuffer);
                const wb: XLSX.WorkBook = XLSX.read(data, { type: 'array' });

                /* Get the first worksheet */
                const wsname: string = wb.SheetNames[0];
                const ws: XLSX.WorkSheet = wb.Sheets[wsname];

                let jsonData: any[];
                if (sheetToJsonConverters) {
                    try {
                        jsonData = this.tryAllParsers(ws, sheetToJsonConverters);
                        resolve(jsonData);
                    } catch (error) {
                        reject(error);
                    }
                } else {
                    XLSX.utils.sheet_to_json(ws, { header: 1 });
                }

                resolve(jsonData);
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsArrayBuffer(file);
        });
    }

    private tryAllParsers(
        ws: XLSX.WorkSheet,
        sheetToJsonConverters: Array<(ws: XLSX.WorkSheet) => any>,
    ): any {
        for (let i = 0; i < sheetToJsonConverters.length; i++) {
            const sheetToJsonConverter = sheetToJsonConverters[i];

            try {
                const parsedData = sheetToJsonConverter.call(this, ws);
                return parsedData;
            } catch (error) {
                console.warn(i, error);
                continue;
            }
        }
        throw new Error('Unable to parse file with known structures');
    }
}
