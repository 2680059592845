import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PaginationService {
    constructor() {}

    sort(currentSort: string, newSort: string) {
        if (currentSort === newSort) {
            return currentSort[0] === '-' ? currentSort.slice(1) : `-${currentSort}`;
        }
        return newSort;
    }

    filter(index: number, nbLinesPerPage: number, selectedPage: number) {
        return (
            index < nbLinesPerPage * selectedPage && index >= nbLinesPerPage * (selectedPage - 1)
        );
    }
}
