'use strict';

import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { Indicator } from 'src/app/models/Indicator';

@Component({
    selector: 'legend-modal',
    templateUrl: './legend-modal.template.html',
    styleUrls: ['./legend-modal.component.scss'],
})
export class LegendModalComponent {
    @Input() indicatorPlot: Indicator;
    @Input() customLegend: any;
    @Input() legend: any;

    constructor(public activeModal: NgbActiveModal) {}

    validate() {
        this.activeModal.close('confirm');
    }

    close() {
        this.activeModal.dismiss('cancel');
    }

    isPatternSelected = (option: Number[]) => {
        return JSON.stringify(this.legend.dashArray) === JSON.stringify(option);
    };

    duplicateDashArray = (option: Number[], width = 100) => {
        const toReturn = [];
        while (this.sum(toReturn) < width) {
            toReturn.push(...option);
        }
        return toReturn;
    };

    sum = (array: Array<number>) => {
        return array.reduce((prev, curr) => prev + curr, 0);
    };

    round = (value: number) => Math.round(value).toString();

    search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            map((term) =>
                term.length < 2
                    ? []
                    : this.indicatorPlot.icon_list
                          .filter((icon) => icon.toLowerCase().indexOf(term.toLowerCase()) > -1)
                          .slice(0, 10),
            ),
        );

    formatter(x: any) {
        return x;
    }
}
