('use strict');

import { Inject, Injectable } from '@angular/core';
import { EventService } from './event.service';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    public tasks: Set<string> = new Set();
    public value = false;

    constructor(@Inject(EventService) private eventService) {}

    start(id?: string) {
        if (id && !this.tasks.has(id)) {
            this.tasks.add(id);
        }

        this.value = true;
        this.eventService.emit('loaderState', true);
    }

    stop(id?: string) {
        this.tasks.delete(id);

        if (this.tasks.size === 0) {
            this.value = false;
            this.eventService.emit('loaderState', false);
        }
    }

    clear() {
        this.tasks = new Set();
        this.value = false;
    }
}
