<stHeader></stHeader>

<div (keypress)="pressEnter($event)" class="animated fadeInUp login-page">
  <div class="container">
    <div class="row row-cols-1 row-cols-md-2 g-5 my-4">
      <div class="col">
        <div class="card h-100 login-card">
          <div class="card-header bg-transparent py-45">
            <h4 class="text-center">
              Connexion à SITERRE
            </h4>
          </div>

          <div class="card-body flex-grow-1">
            <form novalidate class="mb-5" name="Fuser">
              <div class="row mx-0 d-flex align-items-center mb-3">
                <label for="username" class="col-4 control-label py-0 text-left fw-normal">
                  Login :
                </label>
                <div class="col-8">
                  <stInput type="text" innerId="username" name="user" autofocus="" autocomplete="username"
                    [(ngModel)]="userCredentials.login" (change)="resetErrorMessage()"
                    [isInvalid]="msg.errorConnection == true"></stInput>
                </div>
              </div>
              <div class="row mx-0 d-flex align-items-center mb-3">
                <label for="password" class="col-4 control-label py-0 text-left fw-normal">
                  Mot de passe :
                </label>
                <div class="col-8">
                  <stInput type="password" innerId="password" name="password" autocomplete="current-password"
                    [(ngModel)]="userCredentials.password" (change)="resetErrorMessage()"
                    [isInvalid]="msg.errorConnection == true"></stInput>
                </div>
              </div>
            </form>

            <div class="text-center">
              <a class="text-st-active mix-text-decoration cursor-pointer" (click)="openForgetPasswordModal()">
                <i>J'ai oublié mon mot de passe...</i>
              </a>
            </div>

            <div class="text-st-active cursor-pointer text-center mt-4">
              <p class="text-st-danger" [hidden]="msg.errorConnection !== true">
                <strong>{{ msg.errorMessage }}</strong>
              </p>
              <p class="text-st-danger" [hidden]="msg.errorback !== true">
                <strong>Oups ! Une erreur inconnue est survenue... </strong>
              </p>
            </div>
          </div>

          <div class="card-footer bg-transparent text-end py-3">
            <stButton btnLabel="Connexion" btnClass="btn-st-primary" (click)="validate()"></stButton>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card h-100 login-card">
          <div class="card-header bg-transparent py-1 text-center">
            <a href="https://stopexclusionenergetique.org/" target="_blank">
              <img src='../../../../assets/img/login/logo_stop_exclusion.jpg' alt="logo-stop-exclusion" width="140" />
            </a>
          </div>

          <div class="card-body flex-grow-1">
            <div class="text-center">
              <span>
                Énergies Demain et Stop Exclusion Énergétique mettent à disposition gratuitement des
                données
                nationales sur la précarité énergétique
              </span>

              <div class="stop-img my-3">
                <a href="https://stopexclusionenergetique.org/" target="_blank">
                  <img src='../../../../assets/img/login/stop_exclusion_ensemble.png' alt="ensemble-stop-exclusion"
                    width="90%" />
                </a>
              </div>

              <div class="stop-exclustion-contact">
                <a href="mailto:siterre.preca@energies-demain.com?Subject=SITERRE" target="_top"
                  class="mix-text-decoration">
                  <i>siterre.preca@energies-demain.com</i>
                </a>
              </div>
            </div>
          </div>

          <div class="card-footer bg-transparent text-end py-3">
            <a class="btn btn-st-primary no-text-decoration" href="https://stopexclusionenergetique.siterre.fr/">
              Découvrir les données
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>