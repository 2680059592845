'use strict';

import { Component, Inject, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { Indicator } from 'src/app/models/Indicator';

import { EventService } from 'src/app/services/event.service';
import { ProsperReseauxProsperActionsScenarioService } from 'src/app/services/prosper-reseaux/prosper-reseaux-prosper-actions-scenario.service';
import { ProsperReseauxService } from 'src/app/services/prosper-reseaux/prosper-reseaux.service';
import { PlotIndicatorService } from 'src/app/services/plotIndicator/plot-indicator.service';

@Component({
    selector: 'prosperActionsScenario',
    templateUrl: './prosper-actions-scenario.template.html',
})
export class ProsperActionsScenario {
    @Input() show: {
        main: boolean;
        prosperActionsScenario: boolean;
    };

    optionsScenario = [];
    isOptionsLoading: boolean = false;
    isYearsLoading: boolean = false;
    isYearSelected: boolean = false;
    scenarioByYear = [];
    availableYears = [];
    scenarioSelected = {
        year: null,
        scenario: null,
    };

    constructor(
        private notification: ToastrService,
        @Inject(EventService) private eventService: EventService,
        @Inject(ProsperReseauxProsperActionsScenarioService)
        public prProsperActionsScenarioService: ProsperReseauxProsperActionsScenarioService,
        @Inject(ProsperReseauxService) public prService: ProsperReseauxService,
        @Inject(PlotIndicatorService) private plotIndicatorService: PlotIndicatorService,
    ) {
        this.getOptionsScenario();
    }

    async getOptionsScenario() {
        try {
            this.isOptionsLoading = true;
            this.scenarioByYear = [];
            this.availableYears = [];

            this.optionsScenario =
                await this.prProsperActionsScenarioService.getPrProsperActionsScenarios();
        } catch (error) {
            console.error('Error getOptionsScenario', error);
            this.notification.error(
                "Une erreur s'est produite. Impossible de récupérer la liste de scénarios disponibles.",
            );
            this.scenarioByYear = [];
            this.availableYears = [];
        } finally {
            this.isOptionsLoading = false;
            this.isYearSelected = false;
        }
    }

    async selectScenario(scenarioInfo: any) {
        try {
            this.isYearsLoading = true;
            const id = scenarioInfo.id;
            this.scenarioByYear = await this.prProsperActionsScenarioService.getScenarioByYear(id);

            this.availableYears = this.scenarioByYear.map((scenario: any) => {
                return {
                    label: scenario.year,
                    value: scenario.year,
                };
            });

            this.scenarioSelected.year = this.scenarioByYear[0].year;
            this.isYearSelected = true;
        } catch (error) {
            console.error('Error selectScenario', error);
            this.notification.error(
                "Une erreur s'est produite. Impossible de récupérer ce scénario.",
            );
            this.scenarioByYear = [];
            this.availableYears = [];
            this.isYearSelected = false;
        } finally {
            this.isYearsLoading = false;
        }
    }

    async loadScenario() {
        const indicatorPlots: Indicator[] = Object.values(
            this.plotIndicatorService.plotedIndicators,
        );
        const year = this.scenarioSelected.year;
        const scenario = this.scenarioByYear.find((scenario: any) => scenario.year == year);
        this.prProsperActionsScenarioService.loadProsperActionsScenario(scenario, indicatorPlots);

        this.prService.setCollapsePrTypeIds();
        this.prService.setCollapseElementIds();

        if (!this.prService.miscInfo.selectedElement) {
            const gridType = this.prService.elementsToModifyByType.find(
                (selectedType) => selectedType.elements.length,
            );
            gridType.isActive = true;
            this.prService.miscInfo.selectedElement = {
                id: gridType.elements[0].id,
                label: gridType.elements[0].label,
                typeId: gridType.id,
                typeLabel: gridType.label,
            };
            this.prService.miscInfo.isInitialized = true;
        }
        this.eventService.emit('prosperReseauxHypothesisUpdated');
        this.back();
    }

    back() {
        this.show.main = true;
        this.show.prosperActionsScenario = false;
    }
}
