('use strict');

import { Component, Input, ViewChild } from '@angular/core';
import { ChartData } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
    selector: 'stDoughnut',
    templateUrl: './st-doughnut.template.html',
})
export class StDoughnutComponent {
    @Input() labels: string[];
    @Input() data: any[];
    @Input() colors: string[];

    constructor() {}

    @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

    public doughnutData: ChartData<'doughnut'>;

    private _updateChart() {
        this.doughnutData = {
            labels: this.labels,
            datasets: [
                {
                    data: this.data,
                    backgroundColor: this.colors,
                },
            ],
        };
        this.chart?.update();
    }

    ngOnChanges() {
        this._updateChart();
    }
}
