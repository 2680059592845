'use strict';
import { Injectable } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import html2canvas from 'html2canvas';

@Injectable({
    providedIn: 'root',
})
export class PdfExportService {
    constructor() {}

    async exportDomElements(domElements: HTMLElement[], fileName: string) {
        try {
            const promises = domElements.map((element) => this._elementToImage(element));
            const images = await Promise.all(promises);

            const docDefinition = {
                content: images.map((image) => {
                    return {
                        image: image.toDataURL(),
                        width: 500,
                    };
                }),
            };

            this._createPdf(docDefinition, fileName);
            return true;
        } catch (error) {
            console.error('Error exportDomElements', error);
            throw error;
        }
    }

    private async _elementToImage(domElement: HTMLElement) {
        try {
            const image = await html2canvas(domElement, {
                onclone: (clonedDoc) => {
                    const element = clonedDoc.getElementById(domElement.id);
                    element.hidden = false;
                    element.style.display = 'block';
                },
            });
            return image;
        } catch (error) {
            console.error('Error _elementToImage', error);
            throw error;
        }
    }

    private _createPdf(docDefinition: any, fileName: string) {
        pdfMake.createPdf(docDefinition).download(fileName);
    }
}
