import { Inject, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { Indicator } from 'src/app/models/Indicator';
import { Territory, TerritoryScale } from 'src/app/models/TerritoryTypes';

import { ColorService } from 'src/app/services/ColorService';
import { DataService } from 'src/app/services/DataService';
import { DistributionClassService } from './distributions/DistributionClassService';
import { DistributionValueService } from './distributions/DistributionValueService';
import { EventService } from 'src/app/services/event.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { LoaderService } from 'src/app/services/LoaderService';
import { MapService } from 'src/app/services/map.service';
import { PlausibleAnalyticsService } from 'src/app/services/plausible-analytics.service';
import { PlotProsperReseauxIndicatorService } from 'src/app/services/plotIndicator/plot-prosper-reseaux.service';
import { PlotTerritoryIndicatorService } from 'src/app/services/plotIndicator/plot-territory.service';
import { PlotCadastreSolaireIndicatorService } from 'src/app/services/plotIndicator/plot-cadastre-solaire.service';
import { PlotCadastreSolaireIndicatorRoofSectionService } from 'src/app/services/plotIndicator/plot-cadastre-solaire-roof-section.service';
import { PlotCustomTerritoryIndicatorService } from 'src/app/services/plotIndicator/plot-custom-territory.service';
import { PlotLineFactory } from '../../factory/PlotLineFactory';
import { PlotPolyFactory } from '../../factory/PlotPolyFactory';
import { PlotProportionalPointFactory } from '../../factory/PlotProportionalPointFactory';
import { PlotPointFactory } from '../../factory/PlotPointFactory';
import { PlotIconFactory } from '../../factory/PlotIconFactory';
import { PlotMarkerClusterFactory } from '../../factory/PlotMarkerClusterFactory';
import { PlotHeatMapFactory } from '../../factory/PlotHeatMapFactory';
import { PlotMigrationFactory } from '../../factory/PlotMigrationFactory';
import { PlotMiniChartFactory } from '../../factory/PlotMiniChartFactory';
import { TerService } from 'src/app/services/TerService';
import { UsefulService } from 'src/app/services/UsefulService';

@Injectable({
    providedIn: 'root',
})
export class PlotIndicatorService {
    private newGeojsonByIndicatorId: { [indicatorId: number]: GeoJSON.FeatureCollection };
    public geojsonsInfo: any = [];
    public plotedIndicators: any;
    public distribution: any;

    private autoRefreshIndicatorMinZoom: number = 16.75;

    constructor(
        private notification: ToastrService,
        @Inject(ColorService) private colorService: ColorService,
        @Inject(DataService) private dataService: DataService,
        @Inject(DistributionClassService)
        private distributionClassService: DistributionClassService,
        @Inject(DistributionValueService)
        private distributionValueService: DistributionValueService,
        @Inject(EventService) private eventService: EventService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(LoaderService) private loaderService: LoaderService,
        @Inject(MapService) private mapService: MapService,
        @Inject(PlausibleAnalyticsService)
        private plausibleAnalyticsService: PlausibleAnalyticsService,
        @Inject(PlotProsperReseauxIndicatorService)
        private plotProsperReseauxIndicatorService: PlotProsperReseauxIndicatorService,
        @Inject(PlotTerritoryIndicatorService)
        private plotTerritoryIndicatorService: PlotTerritoryIndicatorService,
        @Inject(PlotCadastreSolaireIndicatorService)
        private plotCadastreSolaireIndicatorService: PlotCadastreSolaireIndicatorService,
        @Inject(PlotCadastreSolaireIndicatorRoofSectionService)
        private plotCadastreSolaireIndicatorRoofSectionService: PlotCadastreSolaireIndicatorRoofSectionService,
        @Inject(PlotCustomTerritoryIndicatorService)
        private plotCustomTerritoryIndicatorService: PlotCustomTerritoryIndicatorService,
        @Inject(TerService) private terService: TerService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {
        this.plotedIndicators = {};
        this.newGeojsonByIndicatorId = {};
    }

    clear() {
        this.geojsonsInfo = [];
        this.newGeojsonByIndicatorId = {};
        for (let indicatorId in this.plotedIndicators) {
            this.removeIndicator(parseInt(indicatorId), true);
        }
    }

    removeIndicator(indicatorId: number, broadcastEvent: boolean = true) {
        const indicatorPlot = this.plotedIndicators[indicatorId];
        if (indicatorPlot) {
            if (indicatorPlot.isAutoRefreshedOnMapMove) {
                this.mapService.deactivateMoveEvent(indicatorPlot.onMoveCallback);
                const indicators = Object.values(this.plotedIndicators);
                const isAnyAutoRefreshIndicatorDefined =
                    indicators.filter(
                        (indicator: Indicator) => !!indicator.isAutoRefreshedOnMapMove,
                    ).length > 1;
                if (!isAnyAutoRefreshIndicatorDefined) {
                    this.mapService.setDefaultMinZoom();
                }
            }

            this.removeLayer(indicatorId);
            delete this.plotedIndicators[indicatorId];

            if (broadcastEvent) {
                this.eventService.emit('indicatorToggled');
                this.eventService.emit('indicatorUnploted', indicatorId);
            }
        }
    }

    async createIndicator(indicatorId: number, granularity: TerritoryScale) {
        const shape = this.setIndicatorShape(indicatorId);
        const indicatorPlot = this.createIndicatorPlot(indicatorId, shape);
        indicatorPlot.granularity = granularity;

        this.setAttributes(indicatorPlot);
        this.setPlotService(indicatorPlot);
        this.setEventSelectionHandler(indicatorPlot);
        await this._handleMinZoomForAutoRefreshIndicator(indicatorPlot);

        const promises = this.setValuesAndGeojsonPromises(indicatorPlot);
        try {
            const results = await Promise.all(promises);
            this.newGeojsonByIndicatorId[indicatorId] = results[1];
            return indicatorPlot;
        } catch (error) {
            console.error('Error createIndicator', error);
            throw error;
        }
    }

    setIndicator(indicatorPlot: Indicator, refreshIntervals: boolean = true, flyToBounds = false) {
        const indicatorId = indicatorPlot.indicatorId;

        this.removeIndicator(indicatorId, false);
        this.plotedIndicators[indicatorId] = indicatorPlot;

        this.manageAutoRefresh(indicatorPlot);
        const isValuesEmpty = Object.keys(indicatorPlot.dataToPlot).length == 0;
        if (isValuesEmpty) {
            this.manageIndicatorWithNoData();
        } else {
            this.createGeojson(indicatorPlot);
            this.createStyle(indicatorPlot, refreshIntervals);

            this.plotIndicator(indicatorPlot);
            this.broadcastEvent(indicatorPlot);
        }

        const user = this.localStorageService.get('user');
        this.plausibleAnalyticsService.trackEvent('Indicateur', {
            utilisateur_id: user.id,
            indicateur_id: indicatorId,
            indicateur_nom: indicatorPlot.libelle_indic_complet,
            granularite: indicatorPlot.granularity?.type,
            granularite_nom: indicatorPlot.granularity?.labelLong,
        });
        // console.log('indicatorPlot', indicatorPlot);
        return;
    }

    async addIndicator(
        indicatorId: number,
        granularity: TerritoryScale,
        refreshIntervals: boolean = true,
        broadcastEvent: boolean = true,
        parameters?: any,
    ) {
        const shape = this.setIndicatorShape(indicatorId);
        const indicatorPlot = this.createIndicatorPlot(indicatorId, shape);
        indicatorPlot.granularity = granularity;

        const indicatorData = this.dataService.activeIndicators[indicatorId];
        indicatorPlot.isFiltered = indicatorData.isFiltered;

        this.setAttributes(indicatorPlot, parameters);
        this.setPlotService(indicatorPlot);
        this.setEventSelectionHandler(indicatorPlot);
        await this._handleMinZoomForAutoRefreshIndicator(indicatorPlot);

        const promises = this.setValuesAndGeojsonPromises(indicatorPlot);
        try {
            const results = await Promise.all(promises);
            this.newGeojsonByIndicatorId[indicatorId] = results[1];

            // make sure indicatorPlot has a geojson if it is filtered
            // ie: when user add a new territory to a filtered indicator
            if (indicatorPlot.isFiltered && !indicatorPlot.geojson) {
                indicatorPlot.geojson = results[1];
            }

            this.removeIndicator(indicatorId, false);
            this.plotedIndicators[indicatorId] = indicatorPlot;

            this.manageAutoRefresh(indicatorPlot);
            const isValuesEmpty = Object.keys(indicatorPlot.dataToPlot).length == 0;
            if (isValuesEmpty) {
                this.manageIndicatorWithNoData();
            } else {
                this.createGeojson(indicatorPlot);
                this.createStyle(indicatorPlot, refreshIntervals);

                this.plotIndicator(indicatorPlot);
                if (broadcastEvent) {
                    this.broadcastEvent(indicatorPlot);
                }
            }

            const user = this.localStorageService.get('user');
            this.plausibleAnalyticsService.trackEvent('Indicator', {
                utilisateur_id: user.id,
                indicateur_id: indicatorId,
                indicateur_nom: indicatorPlot.libelle_indic_complet,
                granularite: indicatorPlot.granularity?.type,
                granularite_nom: indicatorPlot.granularity?.labelLong,
            });
            // console.log('indicatorPlot', indicatorPlot);
            return;
        } catch (error) {
            console.error('Error addIndicator', error);
            throw error;
        }
    }

    createIndicatorPlot(indicatorId: any, shape: string) {
        try {
            // const indicatorId = indicatorData.id_indicateur;
            // shape = shape ? shape : this.setIndicatorShape(indicatorId);

            let indicatorPlot: any;
            if (shape == 'line') {
                indicatorPlot = new PlotLineFactory(indicatorId, this.mapService);
            } else if (shape === 'poly') {
                indicatorPlot = new PlotPolyFactory(indicatorId, this.mapService);
            } else if (shape === 'point_proportional') {
                indicatorPlot = new PlotProportionalPointFactory(indicatorId, this.mapService);
            } else if (shape.includes('point')) {
                indicatorPlot = new PlotPointFactory(indicatorId, this.mapService);
            } else if (shape === 'icon') {
                indicatorPlot = new PlotIconFactory(indicatorId, this.mapService);
            } else if (shape === 'cluster') {
                indicatorPlot = new PlotMarkerClusterFactory(indicatorId, this.mapService);
            } else if (shape == 'heatmap') {
                indicatorPlot = new PlotHeatMapFactory(indicatorId, this.mapService);
            } else if (shape === 'migration') {
                indicatorPlot = new PlotMigrationFactory(indicatorId, this.mapService);
            } else if (shape.indexOf('chart') === 0) {
                indicatorPlot = new PlotMiniChartFactory(indicatorId, this.mapService);
            } else {
                throw new Error(`Indicator shape is not understood: ${shape}`);
            }
            return indicatorPlot;
        } catch (error) {
            console.error('Error createIndicatorPlot', error);
            throw new Error(error);
        }
    }

    setIndicatorShape(indicatorId: number) {
        const indicatorData = this.dataService.activeIndicators[indicatorId];

        const isIndicatorTerritory =
            indicatorData.form.includes('poly') && indicatorData.vector_base === 'territories';

        if (isIndicatorTerritory) {
            indicatorData.form = 'poly';
        }

        return indicatorData.form;
    }

    setPlotService(indicatorPlot: Indicator) {
        const vectorBase = indicatorPlot.vector_base;
        const plugin = indicatorPlot.plugin;

        if (vectorBase == 'territories' || vectorBase == 'imported') {
            indicatorPlot.plotService = this.plotTerritoryIndicatorService;
        } else if (vectorBase == 'solaire') {
            indicatorPlot.plotService = this.plotCadastreSolaireIndicatorRoofSectionService;
        } else if (vectorBase.includes('res_')) {
            indicatorPlot.plotService = this.plotProsperReseauxIndicatorService;
        } else if (vectorBase == 'none') {
            indicatorPlot.plotService = this.plotCustomTerritoryIndicatorService;
        } else {
            const message = `Vector base is not understood: ${vectorBase}`;
            console.error(message);
            throw Error(message);
        }

        if (plugin == 'solaire') {
            this.plotCadastreSolaireIndicatorService.setTooltip(indicatorPlot);
        }
    }

    setAttributes(indicatorPlot: Indicator, parameters?: any) {
        const indicatorId = indicatorPlot.indicatorId;
        const indicatorData = this.dataService.activeIndicators[indicatorId];

        const attributes = [
            'chart_divider',
            'class_label',
            'classParameters',
            'crrsdc_ter_year_geo',
            'decimal_count',
            'default_radius_weight',
            'defaut_class_method',
            'defaut_class_number',
            'degrade',
            'exclude_null',
            'form',
            'granularity_min',
            'granularity_max',
            'hide_if_null',
            'id_archi_class',
            'indicatorcontourcolor',
            'indicatorcontouropacity',
            'indicatorcontourweight',
            'indicatorfillopacity',
            'info_nom',
            'isCustomTerritory',
            'isFiltered',
            'isImported',
            'libelle_indic_complet',
            'libelle_indic_short',
            'plugin',
            'separate_zero_in_lgd',
            'static_dynamic',
            'type',
            'unit',
            'unit_short',
            'vector_base',
            'zero_if_null_dyn',
        ];

        attributes.forEach((attribute) => {
            if (indicatorData[attribute]) {
                indicatorPlot[attribute] = indicatorData[attribute];
            }
        });
        indicatorPlot.shortLabel = indicatorData.libelle_indic_short;
        indicatorPlot.label = indicatorData.libelle_indic_complet;
        indicatorPlot.dashArray = indicatorData.dash_array;
        indicatorPlot.degradeId = this.colorService.getGradientId(indicatorPlot.degrade);
        indicatorPlot.decimalCount = indicatorData.decimal_count;
        indicatorPlot.classCount = indicatorPlot.defaut_class_number;
        indicatorPlot.distributionMethodId = indicatorPlot.defaut_class_method;
        indicatorPlot.text_null = indicatorData.text_null || 'Non disponible';
        indicatorPlot.defaultShape = indicatorPlot.form;

        const form = indicatorPlot.form;

        if (form === 'icon') {
            indicatorPlot.default_icon = indicatorData.default_icon || indicatorPlot.default_icon;
        }

        if (parameters) {
            this.setCustomParameters(indicatorPlot, parameters);
        }
    }

    setCustomParameters(indicatorPlot: Indicator, parameters: any) {
        for (let attribute in parameters) {
            const value = parameters[attribute];
            indicatorPlot[attribute] = value;
        }
    }

    setEventSelectionHandler(indicatorPlot: Indicator) {
        const plugin = indicatorPlot.plugin;
        const vectorBase = indicatorPlot.vector_base;
        indicatorPlot.isAutoRefreshedOnMapMove = vectorBase == 'solaire';

        if (plugin == 'solaire') {
            this.plotCadastreSolaireIndicatorService.setClickOnFeatureHandler(indicatorPlot);
        } else if (plugin == 'prosper_reseaux') {
            indicatorPlot.handleClickOnFeature =
                this.plotProsperReseauxIndicatorService.handleClickOnFeature.bind(
                    this.plotProsperReseauxIndicatorService,
                );
        } else {
            indicatorPlot.handleClickOnFeature = indicatorPlot.plotService.eventSelectionWrapper(
                this.plotedIndicators,
            );
        }
    }

    setValuesAndGeojsonPromises(indicatorPlot: Indicator) {
        const promises = [indicatorPlot.plotService.getValues(indicatorPlot)];

        const loadedGeojson = this.getGeojsonAlreadyLoaded(indicatorPlot);
        if (loadedGeojson) {
            promises.push(
                indicatorPlot.plotService.completeGeojson(indicatorPlot, loadedGeojson.geojson),
            );
        } else {
            promises.push(indicatorPlot.plotService.getGeojson(indicatorPlot, this.geojsonsInfo));
        }
        return promises;
    }

    getGeojsonAlreadyLoaded(indicatorPlot: Indicator) {
        const granularity = indicatorPlot.granularity;
        const geojsonInfoId = indicatorPlot.geojsonInfoId;
        const currentTerritoryScaleType = this.terService.territoryScale.type;
        const currentTerritoryIds = this.terService.territories.map((t: Territory) => t.id).sort();
        const currentGranularityType = granularity ? granularity.type : null;

        const geojsonInfo = this.geojsonsInfo.find(
            (geojsonInfo: any) =>
                geojsonInfo.id == geojsonInfoId &&
                geojsonInfo.territoryScale.type == currentTerritoryScaleType &&
                JSON.stringify(geojsonInfo.territoryIds.sort()) ==
                    JSON.stringify(currentTerritoryIds) &&
                geojsonInfo.granularity?.type == currentGranularityType,
        );

        return geojsonInfo;
    }

    manageIndicatorWithNoData() {
        this.notification.warning(`Aucune donnée sur ce territoire pour cet indicateur.`);
    }

    createGeojson(indicatorPlot: Indicator) {
        const indicatorId = indicatorPlot.indicatorId;
        const isFiltered = indicatorPlot.isFiltered;
        const isImported = indicatorPlot.isImported;

        let loadedGeojson: GeoJSON.FeatureCollection;
        if (isFiltered && !isImported) {
            loadedGeojson = this.getGeojsonAlreadyLoaded(indicatorPlot).geojson;
        }

        const geojsonMatch: GeoJSON.FeatureCollection = {
            features: [],
            type: 'FeatureCollection',
        };

        const dataToPlot = indicatorPlot.dataToPlot;
        const showIfNull = !indicatorPlot.hide_if_null;
        const idKey = indicatorPlot.plotService.idKey;

        // const features = indicatorPlot.geojson.features;
        const features = this.newGeojsonByIndicatorId[indicatorId].features;

        features.forEach((feature: GeoJSON.Feature) => {
            const id = feature.properties[idKey];
            const data = dataToPlot[id];
            if (data) {
                this.addFeatureWithValue(geojsonMatch, indicatorPlot, feature, data, isFiltered);
            } else {
                if (isFiltered && !isImported && !!loadedGeojson) {
                    this.addFilteredNullFeature(geojsonMatch, indicatorPlot, feature);
                } else {
                    if (showIfNull) {
                        this.addNullFeature(geojsonMatch, indicatorPlot, feature);
                    }
                }
            }
            feature.properties.originalValue = feature.properties.value;
        });

        indicatorPlot.geojson = geojsonMatch;
    }

    replaceGeojson(indicatorPlot: Indicator) {
        const indicatorId = indicatorPlot.indicatorId;
        const newGeojson = JSON.parse(JSON.stringify(this.newGeojsonByIndicatorId[indicatorId]));

        indicatorPlot.geojson.features.forEach((feature: GeoJSON.Feature) => {
            const territoryId = feature.properties.id_ter;
            const newFeature = newGeojson.features.find(
                (feature: GeoJSON.Feature) => feature.properties.id_ter == territoryId,
            );
            newFeature.properties = JSON.parse(JSON.stringify(feature.properties));
        });
        indicatorPlot.geojson = newGeojson;
    }

    addFeatureWithValue(
        geojsonMatch: GeoJSON.FeatureCollection,
        indicatorPlot: Indicator,
        feature: GeoJSON.Feature,
        data: any,
        isFiltered: boolean,
    ) {
        const value = data.value;
        const isValueDefined = ![null, undefined].includes(value);
        const showIfNull = !indicatorPlot.hide_if_null;
        const isFilterable = indicatorPlot.isFilterable;

        if (!isFiltered) {
            data.isRealNA = !isValueDefined;
        }

        if (isFilterable) {
            const setZeroIfNullForFilterable = indicatorPlot.zero_if_null_dyn == 1;
            if (setZeroIfNullForFilterable && !isValueDefined) {
                data.value = 0;
            }
        }

        if (isValueDefined || showIfNull) {
            Object.assign(feature.properties, data);
            geojsonMatch.features.push(feature);
        }
    }

    addFilteredNullFeature(
        geojsonMatch: GeoJSON.FeatureCollection,
        indicatorPlot: Indicator,
        feature: GeoJSON.Feature,
    ) {
        const idKey = indicatorPlot.plotService.idKey;
        const id = feature.properties[idKey];
        const setZeroIfNullForFilterable = indicatorPlot.zero_if_null_dyn == 1;

        // const isProsperReseaux = indicatorPlot.plugin == 'prosper_reseaux';
        // let loadedFeature: GeoJSON.Feature;
        // if (isProsperReseaux && feature.properties.isNew) {
        //     const features = indicatorPlot.geojson.features;
        //     loadedFeature = features.find(
        //         (feature: GeoJSON.Feature) => feature.properties[idKey] == id,
        //     );
        // } else {
        //     loadedFeature = indicatorPlot.geojson.features.find(
        //         (loadedFeature: GeoJSON.Feature) => loadedFeature.properties[idKey] == id,
        //     );
        // }
        const loadedFeature = indicatorPlot.geojson.features.find(
            (loadedFeature: GeoJSON.Feature) => loadedFeature.properties[idKey] == id,
        );

        if (loadedFeature) {
            const isRealNA = loadedFeature.properties.isRealNA;
            if (isRealNA) {
                const data = {
                    [idKey]: id,
                    value: null,
                    isRealNA: true,
                };
                Object.assign(feature.properties, data);
                geojsonMatch.features.push(feature);
            } else {
                if (setZeroIfNullForFilterable) {
                    const data = {
                        [idKey]: id,
                        value: 0,
                    };
                    Object.assign(feature.properties, data);
                    geojsonMatch.features.push(feature);
                }
                // else {
                //     const showIfNull = !indicatorPlot.hide_if_null;
                //     if (showIfNull) {
                //         const data = {
                //             [idKey]: id,
                //             value: null,
                //         };
                //         Object.assign(feature.properties, data);
                //         geojsonMatch.features.push(feature);
                //     }
                // }
            }
        }
    }

    addNullFeature(
        geojsonMatch: GeoJSON.FeatureCollection,
        indicatorPlot: Indicator,
        feature: GeoJSON.Feature,
    ) {
        const idKey = indicatorPlot.plotService.idKey;
        const id = feature.properties[idKey];

        const data = {
            [idKey]: id,
            value: null,
            isRealNA: true,
        };

        Object.assign(feature.properties, data);
        geojsonMatch.features.push(feature);
    }

    createStyle(indicatorPlot: Indicator, refreshIntervals: boolean) {
        this.setDistributionService(indicatorPlot);
        if (indicatorPlot.type == 'class' && !!indicatorPlot.classParameters) {
            this.setClassIndicatorAggregatedValue(indicatorPlot);
        }

        this.setParameters(indicatorPlot, refreshIntervals);
    }

    setDistributionService(indicatorPlot: Indicator) {
        const type = indicatorPlot.type;
        if (type == 'valeur') {
            indicatorPlot.distributionService = this.distributionValueService;
        } else if (type == 'class') {
            indicatorPlot.distributionService = this.distributionClassService;
        } else {
            const message = `Indicator type is not understood: ${type}`;
            console.error(message);
            throw Error(message);
        }
        indicatorPlot.distributionService.init(indicatorPlot);
    }

    setParameters(indicatorPlot: Indicator, refreshIntervals: boolean) {
        indicatorPlot.legendBoundaries = indicatorPlot.distributionService.run(
            indicatorPlot,
            refreshIntervals,
        );

        this.setColorType(indicatorPlot);
        indicatorPlot.distributionService.setLegend(indicatorPlot, refreshIntervals);

        // this.eventService.emit('indicatorParametersUpdated');
    }

    setColorType(indicatorPlot: Indicator) {
        indicatorPlot.colortypes = {
            mono: {},
            bi: {},
        };

        this.colorService.colorCatalog.forEach((color: any) => {
            const obj = {
                name: color.name,
                colors: this.colorService.getColorPalette(color.name, indicatorPlot.classCount),
            };
            if (color.type == 'bi') {
                indicatorPlot.colortypes.bi['id:' && color.id] = obj;
            } else {
                indicatorPlot.colortypes.mono['id:' && color.id] = obj;
            }
        });
    }

    broadcastEvent(indicatorPlot: Indicator) {
        const indicatorId = indicatorPlot.indicatorId;

        this.eventService.emit('indicatorToggled');
        this.eventService.emit('indicatorPloted', indicatorId);
    }

    private async _handleMinZoomForAutoRefreshIndicator(indicatorPlot: Indicator) {
        if (indicatorPlot.isAutoRefreshedOnMapMove) {
            const currentZoom = this.mapService.map.getZoom();
            this.mapService.setMinZoom(this.autoRefreshIndicatorMinZoom);
            if (currentZoom < this.autoRefreshIndicatorMinZoom) {
                await this.mapService.waitForZoomEnd();
            }
        }
    }

    manageAutoRefresh(indicatorPlot: Indicator) {
        if (indicatorPlot.isAutoRefreshedOnMapMove) {
            const indicatorId = indicatorPlot.indicatorId;
            indicatorPlot.onMoveCallback = async () => {
                const center = this.mapService.map.getCenter();
                const loaderId = `${indicatorId}_dragging_${center.lat}_${center.lng}`;
                this.loaderService.start(loaderId);
                try {
                    await this.updateIndicatorByDragging(indicatorId, false, false);
                } catch (error) {
                    console.error('Error manageAutoRefresh', error);
                } finally {
                    this.loaderService.stop(loaderId);
                }

                // console.log('indicatorPlot', indicatorPlot);
            };
            this.mapService.activateMoveEvent(indicatorPlot.onMoveCallback);
        }
    }

    removeLayer(indicatorId: number) {
        const indicatorPlot = this.plotedIndicators[indicatorId];
        if (indicatorPlot.geojsonLayer) {
            indicatorPlot.removeLayer(this.mapService.map);
        }
    }

    plotIndicator(indicatorPlot: Indicator) {
        // const form = indicatorPlot.form;
        // const renderer = form == 'chart' ? 'svg' : 'canvas';
        indicatorPlot.addLayer(this.mapService.map);
    }

    refreshLayer(indicatorPlot: Indicator) {
        indicatorPlot.refreshLayer();
    }

    updateParameters(indicatorPlot: Indicator, refreshIntervals: boolean = true) {
        indicatorPlot.degradeId = this.colorService.getGradientId(indicatorPlot.degrade);
        this.setParameters(indicatorPlot, refreshIntervals);
    }

    setAvailableColorGradient = (indicatorId: number) => {
        const indicatorPlot = this.plotedIndicators[indicatorId];
        const colorTypesInfo = indicatorPlot.colortypes;

        const tintTemplate = [
            {
                name: 'mono',
                label: 'Une teinte',
                colorsInfo: [],
                classHeader: 'active',
                classPane: 'active in',
            },
            {
                name: 'bi',
                label: 'Deux teintes',
                colorsInfo: [],
                class: '',
            },
        ];

        const gradientColorInfo = [];
        // order is important so, we check color type one by one in the right order
        ['mono', 'bi'].forEach((name) => {
            if (colorTypesInfo.hasOwnProperty(name)) {
                const tintInfo = tintTemplate.find((tmp) => tmp.name === name);
                const info = { ...tintInfo };

                // todo: remove color tint with duplicates color
                info.colorsInfo = Object.values(colorTypesInfo[name]);
                gradientColorInfo.push(info);
            }
        });

        return gradientColorInfo;
    };

    async updateIndicatorData(indicatorId: number) {
        const indicatorPlot = this.plotedIndicators[indicatorId];
        try {
            await indicatorPlot.plotService.getValues(indicatorPlot);
            this.createGeojson(indicatorPlot);
            this.createStyle(indicatorPlot, false);
            this.refreshLayer(indicatorPlot);
            return;
        } catch (error) {
            console.error('Error updateIndicatorData', error);
            throw error;
        }
    }

    async updateIndicator(
        indicatorId: number,
        refreshIntervals: boolean = true,
        broadcastEvent: boolean = true,
        updateGeojson: boolean = true,
    ) {
        let indicatorPlot = this.plotedIndicators[indicatorId];

        const promises = [indicatorPlot.plotService.getValues(indicatorPlot)];
        if (updateGeojson) {
            promises.push(
                indicatorPlot.plotService.getGeojson(indicatorPlot, this.geojsonsInfo, false),
            );
        } else {
            const loadedGeojson = this.getGeojsonAlreadyLoaded(indicatorPlot);
            if (loadedGeojson) {
                promises.push(loadedGeojson.geojson);
            } else {
                promises.push(
                    indicatorPlot.plotService.getGeojson(indicatorPlot, this.geojsonsInfo, false),
                );
            }
        }

        try {
            const results = await Promise.all(promises);
            this.newGeojsonByIndicatorId[indicatorId] = results[1];
            this.createGeojson(indicatorPlot);

            const isValuesEmpty = Object.keys(indicatorPlot.dataToPlot).length == 0;
            if (!isValuesEmpty) {
                this.createStyle(indicatorPlot, refreshIntervals);
            }
            this.removeLayer(indicatorId);
            this.plotIndicator(indicatorPlot);

            if (broadcastEvent) {
                this.broadcastEvent(indicatorPlot);
            }

            const user = this.localStorageService.get('user');
            this.plausibleAnalyticsService.trackEvent('Indicator', {
                utilisateur_id: user.id,
                indicateur_id: indicatorId,
                indicateur_nom: indicatorPlot.libelle_indic_complet,
                granularite: indicatorPlot.granularity?.type,
                granularite_nom: indicatorPlot.granularity?.labelLong,
            });
            // console.log('indicatorPlot', indicatorPlot);
            return;
        } catch (error) {
            console.error('Error updateIndicator', error);
            throw error;
        }
    }

    async updateIndicatorByDragging(
        indicatorId: number,
        refreshIntervals: boolean = true,
        broadcastEvent: boolean = true,
    ) {
        const indicatorPlot = this.plotedIndicators[indicatorId];

        const promises = [
            indicatorPlot.plotService.getValues(indicatorPlot, true),
            indicatorPlot.plotService.getGeojson(indicatorPlot, this.geojsonsInfo, false, true),
        ];
        try {
            const results = await Promise.all(promises);
            this.newGeojsonByIndicatorId[indicatorId] = results[1];
            this.createGeojson(indicatorPlot);

            if (indicatorPlot.geojsonLayer) {
                indicatorPlot.updateLayer();
                this.createStyle(indicatorPlot, refreshIntervals);
                const isValuesEmpty = Object.keys(indicatorPlot.dataToPlot).length == 0;
                if (!isValuesEmpty) {
                    this.createStyle(indicatorPlot, refreshIntervals);
                }
                if (broadcastEvent) {
                    this.broadcastEvent(indicatorPlot);
                }
            } else {
                this.createStyle(indicatorPlot, true);
                this.plotIndicator(indicatorPlot);
                this.broadcastEvent(indicatorPlot);
            }

            return;
        } catch (error) {
            console.error('Error updateIndicatorByDragging', error);
            throw error;
        }
    }

    async updateIndicatorShape(indicatorId: number, newShape: string) {
        const indicatorPlot = this.plotedIndicators[indicatorId];
        const currentShape = indicatorPlot.form;

        const type = indicatorPlot.type;
        const granularity = indicatorPlot.granularity;

        // const parameters = JSON.parse(JSON.stringify(indicatorPlot));
        const parameters: any = {
            form: newShape,
            newColorGradient: indicatorPlot.newColorGradient,
            indicatorfillopacity: indicatorPlot.indicatorfillopacity,
        };

        if (type == 'valeur') {
            parameters.classCount = indicatorPlot.classCount;
            parameters.classCountsAvailable = indicatorPlot.classCountsAvailable;
            parameters.distributionMethodId = indicatorPlot.distribution.method.id;
            parameters.legendBoundaries = indicatorPlot.legendBoundaries;
        }

        if (!newShape.includes('poly') && !currentShape.includes('poly')) {
            parameters.default_radius_weight = indicatorPlot.default_radius_weight;
        }

        if (newShape == 'line') {
            if (type == 'valeur') {
                parameters.dashArray = indicatorPlot.dashArray;
            }

            if (type == 'class') {
                parameters.class_label = indicatorPlot.class_label;
            }
        }

        if (newShape == 'icon') {
            parameters.default_icon = indicatorPlot.default_icon;
        }

        if (!['line', 'migration', 'chart', 'heatmap', 'poly3D'].includes(newShape)) {
            parameters.indicatorcontourcolor = indicatorPlot.indicatorcontourcolor;
            parameters.indicatorcontourweight = indicatorPlot.indicatorcontourweight;
            parameters.indicatorcontouropacity = indicatorPlot.indicatorcontouropacity;
        }

        const newIndicatorPlot = this.createIndicatorPlot(indicatorId, newShape);
        newIndicatorPlot.granularity = granularity;
        newIndicatorPlot.geojson = indicatorPlot.geojson;
        newIndicatorPlot.dataToPlot = indicatorPlot.dataToPlot;

        this.setAttributes(newIndicatorPlot, parameters);
        this.setPlotService(newIndicatorPlot);
        this.setEventSelectionHandler(newIndicatorPlot);
        await this._handleMinZoomForAutoRefreshIndicator(indicatorPlot);

        const refreshIntervals = false;
        const broadcastEvent = true;

        try {
            let geojson: GeoJSON.FeatureCollection;
            const loadedGeojson = this.getGeojsonAlreadyLoaded(newIndicatorPlot);
            if (loadedGeojson) {
                geojson = loadedGeojson.geojson;
            } else {
                geojson = await newIndicatorPlot.plotService.requestGeojson(
                    indicatorPlot.granularity,
                    newShape,
                    newIndicatorPlot.crrsdc_ter_year_geo,
                );
                if (!indicatorPlot.isImported) {
                    newIndicatorPlot.plotService.setGeojsonInfo(
                        newIndicatorPlot,
                        geojson,
                        this.geojsonsInfo,
                    );
                }
            }

            this.newGeojsonByIndicatorId[indicatorId] = geojson;

            this.removeIndicator(indicatorId, false);
            this.plotedIndicators[indicatorId] = newIndicatorPlot;
            this.manageAutoRefresh(newIndicatorPlot);
            this.replaceGeojson(newIndicatorPlot);

            this.createStyle(newIndicatorPlot, refreshIntervals);
            this.plotIndicator(newIndicatorPlot);
            if (broadcastEvent) {
                this.broadcastEvent(newIndicatorPlot);
            }
            // console.log('newIndicatorPlot', newIndicatorPlot);
            return;
        } catch (error) {
            console.error('Error updateIndicatorShape', error);
            throw error;
        }
    }

    async updateIndicatorGranularity(indicatorId: number, granularity: TerritoryScale) {
        const indicatorPlot = this.plotedIndicators[indicatorId];
        indicatorPlot.granularity = granularity;

        const type = indicatorPlot.type;
        const newShape = indicatorPlot.form;

        const parameters: any = {
            form: newShape,
            newColorGradient: indicatorPlot.indicatorPlot,
            indicatorfillopacity: indicatorPlot.indicatorfillopacity,
        };

        if (type == 'valeur') {
            // parameters.classCount = indicatorPlot.classCount;
            // parameters.classCountsAvailable = indicatorPlot.classCountsAvailable;
            parameters.distributionMethodId = indicatorPlot.distribution.method.id;
        }

        if (!newShape.includes('poly')) {
            parameters.default_radius_weight = indicatorPlot.default_radius_weight;
        }

        if (newShape == 'line') {
            if (type == 'valeur') {
                parameters.dashArray = indicatorPlot.dashArray;
            }

            if (type == 'class') {
                parameters.class_label = indicatorPlot.class_label;
            }
        }

        if (newShape == 'clone') {
            parameters.default_icon = indicatorPlot.default_icon;
        }

        if (!['line', 'migration', 'chart', 'heatmap', 'poly3D'].includes(newShape)) {
            parameters.indicatorcontourcolor = indicatorPlot.indicatorcontourcolor;
            parameters.indicatorcontourweight = indicatorPlot.indicatorcontourweight;
            parameters.indicatorcontourcpacity = indicatorPlot.indicatorcontouropacity;
        }

        this.setAttributes(indicatorPlot, parameters);

        const refreshIntervals = true;
        const broadcastEvent = true;

        const promises = [
            indicatorPlot.plotService.getValues(indicatorPlot),
            indicatorPlot.plotService.getGeojson(indicatorPlot, this.geojsonsInfo),
        ];
        try {
            const results = await Promise.all(promises);
            this.newGeojsonByIndicatorId[indicatorId] = results[1];

            this.removeIndicator(indicatorId, false);
            this.plotedIndicators[indicatorId] = indicatorPlot;

            this.manageAutoRefresh(indicatorPlot);
            const isValuesEmpty = Object.keys(indicatorPlot.dataToPlot).length == 0;
            if (isValuesEmpty) {
                this.manageIndicatorWithNoData();
            } else {
                this.createGeojson(indicatorPlot);
                this.createStyle(indicatorPlot, refreshIntervals);
                this.plotIndicator(indicatorPlot);
                if (broadcastEvent) {
                    this.broadcastEvent(indicatorPlot);
                }
            }

            const user = this.localStorageService.get('user');
            this.plausibleAnalyticsService.trackEvent('Indicator', {
                utilisateur_id: user.id,
                indicateur_id: indicatorId,
                indicateur_nom: indicatorPlot.libelle_indic_complet,
                granularite: indicatorPlot.granularity?.type,
                granularite_nom: indicatorPlot.granularity?.labelLong,
            });
            // console.log('indicatorPlot', indicatorPlot);
            return;
        } catch (error) {
            console.error('Error updateIndicatorGranularity', error);
            throw error;
        }
    }

    updateAllIndicators() {
        const promises = [];
        for (let indicatorId in this.plotedIndicators) {
            this.updateIndicator(parseInt(indicatorId));
        }
        return Promise.all(promises);
    }

    async replotAllIndicators(broadcastEvent: boolean) {
        const indicatorIds = Object.keys(this.plotedIndicators);
        const promises = indicatorIds.map((indicatorId) => {
            const indicatorPlot = this.plotedIndicators[indicatorId];
            const granularity = indicatorPlot.granularity;
            return this.addIndicator(parseInt(indicatorId), granularity, true, broadcastEvent);
        });
        await Promise.all(promises);
    }

    setClassIndicatorAggregatedValue(indicatorPlot: Indicator) {
        const topClassIds = this.getTopClassIds(indicatorPlot);

        // Modify the values in each objects
        indicatorPlot.geojson.features.forEach((feature) => {
            feature.properties.value = feature.properties.originalValue;
            if (!topClassIds.includes(feature.properties.originalValue)) {
                feature.properties.value = indicatorPlot.classParameters.aggregated_class_id;
            }
        });
    }

    getTopClassIds(indicatorPlot: Indicator) {
        const sortLegendBy = indicatorPlot.classParameters.sort_legend_by;
        const maxClassCount = indicatorPlot.classParameters.max_class_count;
        const aggregatedClassId = indicatorPlot.classParameters.aggregated_class_id;
        const classIds = indicatorPlot.geojson.features.map(
            (feature) => feature.properties.originalValue,
        );
        const classLabels = indicatorPlot.class_label.filter(
            (classInfo) =>
                classIds.includes(classInfo.id_class) || classInfo.id_class == aggregatedClassId,
        );
        // indicatorPlot.class_label = classLabels;
        indicatorPlot.classLabels = classLabels;

        if (maxClassCount == 0) {
            return classIds;
        }

        if (maxClassCount == 1) {
            return [];
        }

        if (sortLegendBy == 'default') {
            const getTopDefaultClassIds = this.getTopDefaultClassIds(
                indicatorPlot,
                classIds,
                maxClassCount,
            );
            return getTopDefaultClassIds;
        }

        const topClassIdsByFrequency = this.getTopClassIdsByFrequency(
            classIds,
            sortLegendBy,
            maxClassCount,
        );
        return topClassIdsByFrequency;
    }

    getTopDefaultClassIds(indicatorPlot: Indicator, classIds: number[], maxClassCount: number) {
        const sortedClassIds: number[] = indicatorPlot.classLabels
            .filter((classLabel: any) => classIds.includes(classLabel.id_class))
            .map((classLabel: any) => classLabel.id_class);
        const uniqueClassIds = [...new Set(sortedClassIds)];
        const topDefaultClassIds = uniqueClassIds.slice(0, maxClassCount - 1);
        return topDefaultClassIds;
    }

    getTopClassIdsByFrequency(classIds: any[], sortLegendBy: string, maxClassCount: number) {
        const frequencyByClassId = this.usefulService.countFrequencyByValue(classIds);
        const uniqueClassIds = [...new Set(classIds)];

        // sort by desc
        if (sortLegendBy == 'desc') {
            const topMostFrequent = this.usefulService.getTopMostFrequent(
                uniqueClassIds,
                frequencyByClassId,
                maxClassCount - 1,
            );
            return topMostFrequent;
        }

        // sort by asc
        const topLessFrequent = this.usefulService.getTopLessFrequent(
            uniqueClassIds,
            frequencyByClassId,
            maxClassCount - 1,
        );
        return topLessFrequent;
    }
}
