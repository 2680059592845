'use strict';

import {
    AfterViewInit,
    Component,
    Input,
    OnInit,
    QueryList,
    ViewChildren,
    PipeTransform,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ReversePipe } from 'ngx-pipes';
import { Observable } from 'rxjs';

import { NgbdSortableHeader, SortEvent } from 'src/app/components/st-table/sortable.directive';
import { StTableService } from 'src/app/components/st-table/st-table.service';

function matches(row: any, term: string, pipe: PipeTransform) {
    const lowerCaseTerm = term.toLowerCase();
    return (
        row.label.toLowerCase().includes(lowerCaseTerm) ||
        row.stringifiedValue.toLowerCase().includes(lowerCaseTerm)
    );
}

@UntilDestroy()
@Component({
    selector: 'building-custom-table',
    templateUrl: './building-custom-table.template.html',
    styleUrls: ['../../../../../../../../components/st-table/st-table.component.scss'],
    providers: [ReversePipe, StTableService],
})
export class DashboardBuildingCustomTableComponent implements AfterViewInit, OnInit {
    @Input() dashboard: any;

    buildingAndCustomTerritoryRows$: Observable<any[]>;
    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

    constructor(public buildingCustomTableService: StTableService) {
        this.buildingCustomTableService.isPaginationDefined = false;
        this.buildingAndCustomTerritoryRows$ = this.buildingCustomTableService.rows$;
    }

    ngOnInit(): void {
        this.buildingCustomTableService.matches = matches;
    }

    ngAfterViewInit(): void {
        this.buildingCustomTableService.setRows(this.dashboard.table.rows);
    }

    onSort({ column, direction }: SortEvent) {
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        this.buildingCustomTableService.sortColumn = column;
        this.buildingCustomTableService.sortDirection = direction;
    }
}
