import { Component, EventEmitter, Input, Output, Inject, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';

import { ThemeService } from 'src/app/services/ThemeService';

@Component({
    selector: 'stButton',
    templateUrl: './st-button.template.html',
    styleUrls: ['./st-button.component.scss'],
})
export class StButtonComponent implements OnInit {
    private destroy$ = new Subject<void>();

    @Input() btnLabel: string;
    @Input() btnClass: string;
    @Input() iconFaLeft: string;
    @Input() iconFaRight: string;
    @Input() iconGlyphLeft: string;
    @Input() iconGlyphRight: string;
    @Input() isDisabled: boolean;
    @Input() isCollapseButton: boolean = false;
    @Input() isCollapsed: boolean = false;

    @Output() onClick: EventEmitter<any> = new EventEmitter();

    public theme: string;

    click() {
        this.onClick.emit();
    }

    constructor(@Inject(ThemeService) private themeService: ThemeService) {}

    ngOnInit() {
        this.theme = this.themeService.theme.id;

        this.themeService.themeObs$
            .pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((theme) => {
                if (theme) {
                    this.theme = this.themeService.theme.id;
                }
            });
    }
}
