import { Inject, Injectable } from '@angular/core';

import { Indicator } from 'src/app/models/Indicator';
import { TerritoryScale } from 'src/app/models/TerritoryTypes';

import { TerService } from 'src/app/services/TerService';

@Injectable({
    providedIn: 'root',
})
export class GranularityService {
    // to bypass parcelle, grand quartier, arrondissement, canton, ept, epci, ancienne region
    private irrelevantScaleIds = [2, 11, 20, 30, 39, 40, 60];

    constructor(@Inject(TerService) private terService: TerService) {}

    setIndicatorGranularity(indicator: Indicator) {
        indicator.isFilterable = indicator.static_dynamic == 'dyn';
        indicator.isCustomTerritory =
            ['none', 'solaire'].includes(indicator.vector_base) ||
            indicator.plugin == 'prosper_reseaux';

        if (indicator.isCustomTerritory) return;

        indicator.availableGranularities = this.getAvailables(indicator);
        indicator.granularity = this.getDefault(indicator);
    }

    getAvailables(indicator: Indicator) {
        const minGranularity = this.getMin(indicator);
        const maxGranularity = this.getMax(indicator);

        let availableGranularities = this.terService.territoryScales.filter(
            (scale) =>
                scale.order >= minGranularity.order &&
                scale.order <= maxGranularity.order &&
                scale.order <= this.terService.territoryScale.order,
        );

        const territoryScale = this.terService.territoryScale;
        if (territoryScale.typeId >= 50) {
            availableGranularities = availableGranularities.filter((scale) => scale.typeId >= 10);
        }

        availableGranularities.sort((a, b) => b.order - a.order);
        return availableGranularities;
    }

    getDefault(indicator: Indicator) {
        const defaultGranularityType = indicator.default_granularity;
        if (defaultGranularityType) {
            const defaultGranularity = this.terService.territoryScales.find(
                (g) => g.type == defaultGranularityType,
            );
            return defaultGranularity;
        }

        const minGranularity = this.getMin(indicator);
        const territoryScale = this.terService.territoryScale;

        if (minGranularity.order == territoryScale.order) {
            return territoryScale;
        }

        const availableGranularities = indicator.availableGranularities;
        const availableGranularitiesReduced = availableGranularities.filter(
            (g) => !this.irrelevantScaleIds.includes(g.typeId),
        );
        if (availableGranularitiesReduced.length == 1) {
            return availableGranularitiesReduced[0];
        }

        const defaultGranularityReduced = this.findNearestSmaller(
            availableGranularitiesReduced,
            territoryScale.order,
        );
        if (defaultGranularityReduced) {
            return defaultGranularityReduced;
        }

        const defaultGranularity = this.findNearestSmaller(
            availableGranularities,
            territoryScale.order,
        );
        return defaultGranularity;
    }

    findNearestSmaller(scales: TerritoryScale[], targetOrder: number) {
        let nearestSmaller = null;

        for (const scale of scales) {
            if (scale.order < targetOrder) {
                if (!nearestSmaller || scale.order > nearestSmaller.order) {
                    nearestSmaller = scale;
                }
            }
        }

        return nearestSmaller;
    }

    getMin(indicator: Indicator) {
        const indicatorMinGranularity = this.getIndicatorMinGranularity(indicator);
        const minGranularity = indicatorMinGranularity
            ? indicatorMinGranularity
            : this.terService.getUserMinScale();

        return minGranularity;
    }

    getMax(indicator: Indicator) {
        const indicatorMaxGranularity = this.getIndicatorMaxGranularity(indicator);

        const maxGranularity = indicatorMaxGranularity
            ? indicatorMaxGranularity
            : this.terService.getUserMaxScale();

        return maxGranularity;
    }

    getIndicatorMinGranularity(indicator: Indicator) {
        const indicatorMinGranularity = this.terService.territoryScales.find(
            (scale) => scale.type == indicator.granularity_min,
        );
        return indicatorMinGranularity;
    }

    getIndicatorMaxGranularity(indicator: Indicator) {
        const indicatorMaxGranularity = this.terService.territoryScales.find(
            (scale) => scale.type == indicator.granularity_max,
        );
        return indicatorMaxGranularity;
    }

    updateAfterTerritoryChange(indicator: Indicator, indicatorPlot: Indicator) {
        const availableGranularities = indicator.availableGranularities;
        const isCurrentGranularityAvailable = availableGranularities.some(
            (scale) => scale.id == indicatorPlot.granularity.id,
        );
        if (isCurrentGranularityAvailable) {
            indicator.granularity = indicatorPlot.granularity;
        } else {
            indicatorPlot.granularity = indicator.granularity;
        }
    }
}
