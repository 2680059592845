'use strict';

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'confirmationModal',
    templateUrl: './confirmation-modal.template.html',
})
export class ConfirmationPRModalComponent {
    @Input() size = 'modal-sm';
    @Input() identifier = 'confirmationModal';
    @Input() header = 'Attention !';
    @Input() body = [];
    @Input() applyChangesInfo = { stream: null, element: null, hypothesisInfo: null };
    @Output() onConfirm = new EventEmitter();

    constructor(public activeModal: NgbActiveModal, private modalConfig: NgbModalConfig) {
        this.modalConfig.size = 'lg';
    }

    validate() {
        this.activeModal.close('confirm');
    }

    close() {
        this.activeModal.dismiss('cancel');
    }
}
