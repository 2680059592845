<div class="d-flex flex-column h-100">
    <div class="flex-grow-1 overflow-auto">
        <div class="body">
            <div class="container-fluid mb-4 p-2">
                <h5 class="form-title">
                    Importer un scénario PROSPER Actions
                </h5>

                <div class="container-fluid pr-card">
                    <div class="row parameters-field mb-3">
                        <label for="scenario-name" class="col-3 control-label text-start fw-normal">
                            Nom
                        </label>
                        <div class="col-2 text-right">
                            <span class="fa fa-spinner fa-2x fa-pulse text-st-active" *ngIf="isOptionsLoading"></span>
                        </div>
                        <div class="col-7">
                            <stInputSearch class="dropdown w-100" innerClass="border-07" innerId="scenario-name"
                                [clear]="false" [isDisabled]="isOptionsLoading"
                                placeholder="Saissiser un nom de scénario" [options]="optionsScenario" label="label"
                                (onSelect)="selectScenario($event)">
                            </stInputSearch>
                        </div>
                    </div>

                    <div class="row parameters-field">
                        <label for="scenario-year" class="col-3 control-label text-start fw-normal">
                            Année
                        </label>
                        <div class="col-2 text-right">
                            <span class="fa fa-spinner fa-2x fa-pulse text-st-active" *ngIf="isYearsLoading"></span>
                        </div>
                        <div class="col-7">
                            <stSelect innerId="scenario-year" [(ngModel)]="scenarioSelected.year"
                                placeholder="Aucun scénario disponible" [isDisabled]="!scenarioByYear.length"
                                [data]="availableYears" labelField="label" valueField="value" type="primary">
                            </stSelect>
                        </div>
                    </div>
                </div>
            </div>
            <div>
            </div>
        </div>
    </div>
    <div class="footer border-top">
        <div class="container-fluid text-center py-3">
            <div class="row">
                <div class="col-5">
                    <stButton btnClass="btn-st-default w-100" btnLabel="Retour" (click)="back()">
                    </stButton>
                </div>
                <div class="col-7">
                    <stButton btnClass="btn-st-primary w-100" btnLabel="Importer le scénario"
                        [isDisabled]="!isYearSelected" (onClick)="loadScenario()"></stButton>
                </div>
            </div>
        </div>
    </div>
</div>