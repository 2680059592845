import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class EventService {
    public clickOnProsperReseauxElement = new EventEmitter<void>();
    public clickOnSolarElement = new EventEmitter<void>();
    public clickOnTerritory = new EventEmitter<void>();
    public clickOnAtacElement = new EventEmitter<void>();
    public customIndicatorsAdded = new EventEmitter<void>();
    public dynamicIndicatorInitialized = new EventEmitter<void>();
    public filterCriteriaUpdated = new EventEmitter<void>();
    public granularityUpdated = new EventEmitter<void>();
    public gridIndicatorSelected = new EventEmitter<void>();
    public prosperReseauxHypothesisUpdated = new EventEmitter<void>();
    public gridSelected = new EventEmitter<void>();
    public gridServiceClear = new EventEmitter<void>();
    public gridServiceReload = new EventEmitter<void>();
    public indicatorDataAdded = new EventEmitter<void>();
    public indicatorDataDeleted = new EventEmitter<void>();
    public indicatorDeactivated = new EventEmitter<void>();
    public indicatorParametersUpdated = new EventEmitter<void>();
    public indicatorPloted = new EventEmitter<void>();
    public indicatorsPlotedFromScenario = new EventEmitter<void>();
    public indicatorToggled = new EventEmitter<void>();
    public indicatorUnploted = new EventEmitter<void>();
    public loaderState = new EventEmitter<void>();
    public mainInitialized = new EventEmitter<void>();
    public modelingDataUpdated = new EventEmitter<void>();
    public newTerritory = new EventEmitter<void>();
    public oveloModuleSelected = new EventEmitter<void>();
    public territorySelectionShow = new EventEmitter<void>();
    public selectionTerritoryUpdated = new EventEmitter<void>();
    public searchAddressSelected = new EventEmitter<void>();
    public solarModuleSelected = new EventEmitter<void>();
    public territoryPloted = new EventEmitter<void>();
    public territorySelected = new EventEmitter<void>();
    public themeUpdated = new EventEmitter<void>();
    public updatselectionModuleUpdatededTheme = new EventEmitter<void>();

    constructor() {}

    getObservable(eventName: String) {
        switch (eventName) {
            case 'clickOnProsperReseauxElement':
                return this.clickOnProsperReseauxElement;
            case 'clickOnSolarElement':
                return this.clickOnSolarElement;
            case 'clickOnTerritory':
                return this.clickOnTerritory;
            case 'clickOnAtacElement':
                return this.clickOnAtacElement;
            case 'customIndicatorsAdded':
                return this.customIndicatorsAdded;
            case 'dynamicIndicatorInitialized':
                return this.dynamicIndicatorInitialized;
            case 'filterCriteriaUpdated':
                return this.filterCriteriaUpdated;
            case 'granularityUpdated':
                return this.granularityUpdated;
            case 'gridIndicatorSelected':
                return this.gridIndicatorSelected;
            case 'prosperReseauxHypothesisUpdated':
                return this.prosperReseauxHypothesisUpdated;
            case 'gridSelected':
                return this.gridSelected;
            case 'gridServiceClear':
                return this.gridServiceClear;
            case 'gridServiceReload':
                return this.gridServiceReload;
            case 'indicatorDataAdded':
                return this.indicatorDataAdded;
            case 'indicatorDataDeleted':
                return this.indicatorDataDeleted;
            case 'indicatorDeactivated':
                return this.indicatorDeactivated;
            case 'indicatorParametersUpdated':
                return this.indicatorParametersUpdated;
            case 'indicatorPloted':
                return this.indicatorPloted;
            case 'indicatorsPlotedFromScenario':
                return this.indicatorsPlotedFromScenario;
            case 'indicatorToggled':
                return this.indicatorToggled;
            case 'indicatorUnploted':
                return this.indicatorUnploted;
            case 'loaderState':
                return this.loaderState;
            case 'mainInitialized':
                return this.mainInitialized;
            case 'modelingDataUpdated':
                return this.modelingDataUpdated;
            case 'newTerritory':
                return this.newTerritory;
            case 'oveloModuleSelected':
                return this.oveloModuleSelected;
            case 'territorySelectionShow':
                return this.territorySelectionShow;
            case 'selectionTerritoryUpdated':
                return this.selectionTerritoryUpdated;
            case 'searchAddressSelected':
                return this.searchAddressSelected;
            case 'solarModuleSelected':
                return this.solarModuleSelected;
            case 'territoryPloted':
                return this.territoryPloted;
            case 'territorySelected':
                return this.territorySelected;
            case 'themeUpdated':
                return this.themeUpdated;
            case 'updatselectionModuleUpdatededTheme':
                return this.updatselectionModuleUpdatededTheme;
            default:
                return null;
        }
    }

    emit(eventName: String, data?: any) {
        const observable = this.getObservable(eventName);
        if (observable) {
            observable.emit(data);
        } else {
            console.log(`Unknown event: ${eventName}`);
        }
    }
}
