('use strict');

import { Component, Inject, OnInit } from '@angular/core';
import { PaginationService } from 'src/app/services/pagination.service';
import { RestService } from 'src/app/services/RestService';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '../../main/modals/confirmation/confirmation-modal.component';

@Component({
    templateUrl: './filter-manage.template.html',
})
export class ManageFilterComponent implements OnInit {
    sortValue = '';
    totalFilters = 0;
    filters = [];
    nbLinesPerPage = 10;
    nbLinesPerPageList = [10, 20, 40, 50, 100];
    selectedPage = 1;
    filterToDelete: any;

    constructor(
        private router: Router,
        @Inject(RestService) private restService: RestService,
        @Inject(PaginationService) private paginationService: PaginationService,
        private modalService: NgbModal,
    ) {}

    ngOnInit(): void {
        this._initialisation();
    }

    varSort(value: string) {
        this.sortValue = this.paginationService.sort(this.sortValue, value);
    }

    toNoCase(item: string) {
        if (!item) {
            return;
        }

        let temp = String(item).toLowerCase();

        temp = temp.replace(/[a\341\301\340\300\342\302\344\304]/gi, 'a');
        temp = temp.replace(/[e\351\311\350\310\352\312\353\313]/gi, 'e');
        temp = temp.replace(/[i\355\315\354\314\356\316\357\317]/gi, 'i');
        temp = temp.replace(/[o\363\323\362\322\364\324\366\326]/gi, 'o');
        temp = temp.replace(/[u\372\332\371\331\373\333\374\334]/gi, 'u');
        temp = temp.replace(/[c\347\307]/gi, 'c');
        temp = temp.replace(/['+\-]/gi, ' ');
        temp = temp.replace(/[(\)\.\,]/gi, '');

        return temp;
    }

    //==================================================================================================================
    // fonction de gestion du nombre de pages sur le tableau

    tabFilter(index: number) {
        return this.paginationService.filter(index, this.nbLinesPerPage, this.selectedPage);
    }

    //==================================================================================================================
    // fonction de route

    goToNewFilter() {
        this.router.navigate(['administration/filters/add/']);
    }

    goToUpdateFilter(filter) {
        this.router.navigate(['administration/filters/update/' + filter.id_archi_critdter + '/']);
    }

    //==================================================================================================================
    // fonction de construction des groups
    private build_filtres_for_ihm(res) {
        this.filters = res;
    }

    deleteFiltre(filter) {
        this.filterToDelete = filter;

        const body = [
            'Voulez-vous vraiment supprimer cette famille ?',
            'Cette action est irréversible.',
        ].join('<br/>');

        const modalRef = this.modalService.open(ConfirmationModalComponent);
        modalRef.componentInstance.body = body;
        modalRef.result.then(
            (result) => {
                this.confirmDeleteFilter();
            },
            () => {},
        );
        modalRef.componentInstance.name = 'confirmationModal';
    }

    async confirmDeleteFilter() {
        try {
            await this.restService.deleteFilter(this.filterToDelete.id_archi_critdter);
            this._initialisation();
        } catch (error) {
            console.log(error);
        }
    }

    //==================================================================================================================
    // Initialisation
    private async _initialisation() {
        try {
            this.filters = await this.restService.getFiltersAdmin();
            this.totalFilters = this.filters.length;
            this.varSort('id_archi_critdter');
        } catch (error) {
            console.log(error);
        }
    }
}
