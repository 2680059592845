import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { User } from 'src/app/models/User';

import { LocalStorageService } from './local-storage.service';
import { PlausibleAnalyticsService } from 'src/app/services/plausible-analytics.service';
import { RestService } from 'src/app/services/RestService';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public user: User = {};

    constructor(
        private router: Router,
        @Inject(PlausibleAnalyticsService)
        private plausibleAnalyticsService: PlausibleAnalyticsService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(RestService) private restService: RestService,
    ) {}

    async logIn(credentials: { login: string; password: string }) {
        this.clear();

        try {
            const data = await this.restService.logIn(credentials);
            this.user = data.user;

            this.plausibleAnalyticsService.trackEvent('Connexion', {
                utilisateur_id: this.user.id,
            });

            this.setToken(data.token);
            this.setUserInfo(data.user);
            this.localStorageService.set('profile', 'siterre');

            if (this.user.d_last_con === null) {
                // meaning this is the first user connexion
                // so we return the user id to trigger the password reset
                return this.user.id;
            }
        } catch (error) {
            console.error('Error logIn', error);
            throw error;
        }
    }

    async externalLogIn(group: string, token: string) {
        this.clear();

        try {
            const data = await this.restService.externalLogIn(group, token);
            this.user = data.user;

            this.localStorageService.set('prosperToken', token);
            this.localStorageService.set('prosperGroup', group);
            this.localStorageService.set('token', data.token);
            this.setToken(data.token);
            this.setUserInfo(data.user);

            await this.getUserPreferences();
            this.router.navigate(['main']);
            return;
        } catch (error) {
            throw error;
        }
    }

    logOut() {
        this.clear();
        this.router.navigate(['/']);
    }

    checkRouting(role: number) {
        if ((!this.user || !this.user.id) && this.localStorageService.get('user')) {
            this.user = this.localStorageService.get('user');
        }

        if (!this.user) {
            this.logOut();
            return false;
        } else if (role < this.user.role) {
            this.router.navigate(['/404']);
            return false;
        }
        return true;
    }

    clear() {
        this.user = {};
        this.localStorageService.remove('token');
        this.localStorageService.remove('prosperToken');
        this.localStorageService.remove('prosperGroup');
        this.localStorageService.remove('user');
    }

    async getUserPreferences(addUserInfo: boolean = false) {
        try {
            const parameters = { addUserInfo: addUserInfo };
            const preferences = await this.restService.getUserPreference(parameters);
            const user = preferences.user;
            delete preferences.user;

            this.localStorageService.set('preferences', preferences);
            return user;
        } catch (error) {
            console.error('Error getUserPreferences', error);
            throw error;
        }
    }

    setToken(token: string) {
        this.localStorageService.set('token', token);
    }

    setUserInfo(user: User) {
        this.user = user;
        this.localStorageService.set('user', user);
        this.localStorageService.set('logo', user.group.logo);
        this.localStorageService.set('name_site', user.group.name_site);
        this.localStorageService.set('prosper_url', user.group.prosper_url);
    }
}
